import { useRouter } from 'vue-router'

export function useDelete<T extends { id: string }>(
  deleteFn: (id: string) => Promise<any>,
  routeBackConfig?: {
    check: (items: T[]) => boolean
    routeName: string
  }
) {
  const router = useRouter()
  async function deleteItems(items: T[]) {
    if (routeBackConfig?.check(items)) {
      router.push({ name: routeBackConfig?.routeName })
    }
    for (const item of items) {
      await deleteFn(item.id)
    }
  }
  return { deleteItems }
}
