<script lang="ts">
import { defineComponent } from 'vue'
import { ElMessage } from 'element-plus'
import { useMediaGroupStore } from '@/stores/media-group'
import { MediaGroup, MediaGroupId, ProjectId } from '@/model'
import DetailPanel from '@/layouts/pages/DetailPanel.vue'
import MediaGroupForm from './MediaGroupForm.vue'
import { useProjectStore } from '@/stores/project'
import { RouteLocationRaw } from 'vue-router'
import { RouteParams } from '@/router/routeParams'
import { MediaBatchRequestProperties } from '@gridside/hsb-api/dist/models/MediaBatchRequestProperties'

export default defineComponent({
  name: 'MediaGroupDetail',
  components: { MediaGroupForm, DetailPanel },

  setup() {
    const mediaGroupStore = useMediaGroupStore()
    const projectStore = useProjectStore()
    return { mediaGroupStore, projectStore }
  },

  data: () => ({
    saving: false
  }),

  computed: {
    create(): boolean {
      return !!this.$route.name?.toString().includes('create')
    },
    defaultItem(): Partial<MediaGroup> {
      const projectType = this.projectStore.findById(this.projectId)?.mediaType
      const item: Partial<MediaGroup> = {
        ...this.mediaGroupStore.draft,
        type: projectType === 'telecom' ? 'wire' : projectType,
        project: this.projectId,
        height: 0
      }

      // pipe default values
      const lastMediaGroup = this.mediaGroupStore.items[this.mediaGroupStore.items.length - 1]
      if (this.mediaGroupStore.isPipe(item) && this.mediaGroupStore.isPipe(lastMediaGroup)) {
        item.rhoE = item.rhoE ?? lastMediaGroup.rhoE
        item.pipeType = item.pipeType ?? lastMediaGroup.pipeType
      }
      return item
    },
    projectId(): ProjectId {
      return this.$route.params[RouteParams.ProjectId] as string
    },
    selectedItems(): MediaGroup[] {
      if (this.mediaGroupStore.draft) {
        return [this.defaultItem as MediaGroup]
      } else {
        return this.mediaGroupStore.selection
          .map((id: MediaGroupId) => this.mediaGroupStore.findById(id))
          .filter((item): item is MediaGroup => item !== undefined)
      }
    },
    title(): string {
      if (this.create) {
        return 'Neue Mediengruppe'
      }

      const selectionCount = this.selectedItems.length
      if (selectionCount > 1) {
        return `${selectionCount} Mediengruppen bearbeiten`
      } else {
        return 'Mediengruppe bearbeiten'
      }
    }
  },

  methods: {
    onClose() {
      this.$router.push(
        (this.$route.meta.returnRoute as RouteLocationRaw) || { name: 'project-media-group' }
      )
      this.mediaGroupStore.draft = null

      // to make it possible to close the drawer but keep selection when multiple are selected
      if (this.mediaGroupStore.selection.length === 1) {
        this.mediaGroupStore.selection = []
      }
    },
    async onSave(items: MediaGroup[]) {
      this.saving = true
      try {
        for (const item of items) {
          await this.mediaGroupStore.save(item)
        }

        ElMessage.success(
          this.create
            ? 'Mediengruppe wurde erfolgreich angelegt.'
            : 'Daten wurden erfolgreich gespeichert.'
        )
      } finally {
        this.saving = false
      }
    },

    async onSaveChanges(changeset: Partial<MediaBatchRequestProperties>) {
      this.saving = true
      try {
        const mediaGroupIds = this.selectedItems.map((item) => item.id)
        await this.mediaGroupStore.saveBatch(this.projectId, mediaGroupIds, changeset)
        ElMessage.success('Daten wurden erfolgreich gespeichert.')
      } finally {
        this.saving = false
      }
    }
  }
})
</script>

<template>
  <DetailPanel :title="title" data-test="mediagroup-detail" @close="onClose">
    <MediaGroupForm
      v-if="mediaGroupStore.loaded"
      :items="selectedItems"
      :saving="saving"
      :defaultItem="defaultItem"
      @cancel="onClose"
      @submit="(items: MediaGroup[]) => create && onSave(items)"
      @submit:changes="(changes: Partial<MediaBatchRequestProperties>) => !create && onSaveChanges(changes)"
    />
  </DetailPanel>
</template>

<style scoped lang="css"></style>
