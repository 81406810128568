import {
  Decoration,
  DecorationSet,
  EditorView,
  MatchDecorator,
  ViewPlugin,
  ViewUpdate,
  WidgetType
} from '@codemirror/view'
import { FunctionsImplementedEnum } from '@/util/codemirror/helper'

export const ExtensionCommonViewPlugin = ViewPlugin.fromClass(
  class {
    placeholders: DecorationSet
    constructor(view: EditorView) {
      this.placeholders = AtomicRangeDecorator.createDeco(view)
    }
    update(update: ViewUpdate) {
      this.placeholders = AtomicRangeDecorator.updateDeco(update, this.placeholders)
    }
  },
  {
    decorations: (instance) => instance.placeholders,
    provide: (plugin) =>
      EditorView.atomicRanges.of((view) => {
        return view.plugin(plugin)?.placeholders || Decoration.none
      })
  }
)

/**
 * Widget for styling the Atomic Range
 */
export class FunctionAtomicRangeWidget extends WidgetType {
  constructor(private functionName: string) {
    super()
  }
  eq(other: FunctionAtomicRangeWidget) {
    return this.functionName == other.functionName
  }
  toDOM() {
    const elt = document.createElement('span')
    elt.style.fontWeight = 'bold'
    // elt.style.cssText = `
    //   border-radius: 4px;
    //   padding: 0 0.3rem;
    //   margin: 0 0.1rem;
    //   color: black;
    //   background: #e0e0e0;`
    elt.textContent = this.functionName
    return elt
  }
  ignoreEvent() {
    return true
  }
}

const AtomicRangeDecorator = new MatchDecorator({
  regexp: new RegExp(Object.values(FunctionsImplementedEnum).join('|'), 'g'), // /abs|min|max|pow/g,
  // decoration: (match) => Decoration.replace({})
  decoration: (match) => {
    return Decoration.replace({
      widget: new FunctionAtomicRangeWidget(match[0])
    })
  }
})
