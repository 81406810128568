<script setup lang="ts">
import { CalculationModes, CalculationModesTranslation } from '@/model'
import { useField } from 'vee-validate'

const { value } = useField('configuration.modeSelected')
</script>

<template>
  <el-radio-group v-model="value" class="gap-4 flex w-full" name="configuration.modeSelected">
    <el-radio
      v-for="mode in CalculationModes"
      :key="mode"
      :value="mode"
      :name="mode"
      border
      size="default"
      class="!mr-0"
    >
      {{ CalculationModesTranslation[mode] }}
    </el-radio>
  </el-radio-group>
</template>

<style scoped lang="css"></style>
