<script setup lang="ts">
import { UseMapInjectKeys, UseMapItems } from '@/components/map/composables'
import { configToTreeData } from '@/components/map/controls/layers/functions'
import LayerOptions from '@/components/map/controls/layers/LayerOptions.vue'
import { computed, inject, onMounted, ref, watch } from 'vue'
import { useLayerTreeConfig } from './config'

import { LayerTreeItemType, TreeNode } from './types'

const { toggleLayer, showLayer, layers, getLayerOption, setLayerOption } = inject(
  UseMapInjectKeys.useMap
) as UseMapItems

// The tree DOM element
const treeEl = ref()

// Build Element UI tree nodes from the LayerConfig object
const layerConfig = useLayerTreeConfig()
const treeData = computed<TreeNode[]>(() => configToTreeData(layerConfig.value))

// We need to save the state of check nodes because the tree will re-render on each
// change of underlying data (i.e. when an overhead line is renamed)
const checkedNodes = computed<string[]>(() => {
  const checkedNodes: string[] = []
  Object.values(layers.value)
    // only visible layers can be checked
    .filter((layer) => layer.visible)
    .forEach((layer) => {
      if (layer.options.visibleFeatures === undefined) {
        // if the layer has no visible features, select itself
        checkedNodes.push(layer.id)
      } else if (layer.options.visibleFeatures.length > 0) {
        // if it has visible features selected, use them instead
        checkedNodes.push(
          ...layer.options.visibleFeatures.map((id: string) => [layer.id, id].join('.'))
        )
      }
    })
  return checkedNodes
})

watch(checkedNodes, () => treeEl.value?.setCheckedKeys(checkedNodes.value))
onMounted(() => treeEl.value?.setCheckedKeys(checkedNodes.value))

// track state of expanded nodes (for the same reason as for checked items)
const expandedNodes = ref<string[]>([])

const onCollapse = (node: TreeNode) => {
  expandedNodes.value = expandedNodes.value.filter((id) => id !== node.id)
}
const onExpand = (node: TreeNode) => {
  if (!expandedNodes.value.includes(node.id)) {
    expandedNodes.value = [...expandedNodes.value, node.id]
  }
}

/**
 * Event handler when the user checks/unchecks a tree node
 */
const onCheck = (node: TreeNode, treeState: { checkedKeys: string[] }) => {
  const { checkedKeys } = treeState
  const checked = checkedKeys.includes(node.id)

  const [nodeLayerId] = node.id.split('.')
  const visibleFeaturesByLayer: Record<string, string[]> = { [nodeLayerId]: [] }

  const nodeType = node.config?.type
  if (nodeType === LayerTreeItemType.LAYER) {
    // an actual layer node was checked -> all child nodes will also be checked
    showLayer(node.id, checked)
    setLayerOption(nodeLayerId, 'visibleFeatures', undefined) // undefined -> show all features
  } else if (nodeType === LayerTreeItemType.FEATURE) {
    // collect checked features by layer
    checkedKeys.forEach((item) => {
      const [layerId, featureId] = item.split('.')
      if (layerId && featureId) {
        if (!visibleFeaturesByLayer[layerId]) {
          visibleFeaturesByLayer[layerId] = []
        }
        visibleFeaturesByLayer[layerId].push(featureId)
      }
    })

    // Make sure all layers with/without selected features are shown/hidden
    Object.entries(visibleFeaturesByLayer).forEach(([layerId, features]) => {
      setLayerOption(layerId, 'visibleFeatures', features.length > 0 ? features : undefined)
      if (features.length > 0) {
        showLayer(layerId, true)
      } else {
        showLayer(layerId, false)
      }
    })
  }
}
</script>

<template>
  <el-tree
    ref="treeEl"
    :data="treeData"
    class="!bg-transparent !text-sm"
    check-on-click-node
    :default-expanded-keys="expandedNodes"
    show-checkbox
    :expand-on-click-node="false"
    node-key="id"
    :render-after-expand="false"
    @check="onCheck"
    @node-expand="onExpand"
    @node-collapse="onCollapse"
    v-slot="{ data }"
  >
    <div class="flex items-center w-full overflow-hidden">
      <div class="flex-1 text-ellipsis overflow-hidden min-w-0" :title="data.id">
        {{ data.label }}
      </div>
      <LayerOptions
        v-if="data.config?.options"
        class="layer-options opacity-0"
        :layer="data.id"
        :options="data.config.options"
      />
    </div>
  </el-tree>
</template>

<style scoped lang="css">
:deep(.el-tree-node__content) {
  @apply py-3;
}

:deep(.el-tree-node) {
  @apply font-semibold;
}

:deep(.el-tree-node .el-tree-node) {
  @apply font-normal;
}

:deep(.el-tree-node:hover .layer-options) {
  opacity: 1;
  @apply transition-opacity;
}
</style>
