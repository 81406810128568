<script lang="ts">
import { defineComponent } from 'vue'
import { ConductorAllocation, ConductorAllocationId } from '@/model'
import { useConductorAllocationStore } from '@/stores/conductor-allocation'
import { conductorAllocation } from '@/config/fields'
import { v4 as uuid } from 'uuid'
import ConductorAllocationFormMapping from '@/views/project/conductor-allocations/ConductorAllocationFormMapping.vue'
import { useProject } from '@/composables/useProject'
import { copy } from '@/util'
import { SelectOption } from '@util/element-plus'

const DEFAULT_MAPPING_LENGTH = 3
export default defineComponent({
  name: 'ConductionAllocationForm',
  components: { ConductorAllocationFormMapping },

  setup() {
    const { projectId } = useProject()
    return { conductorAllocationStore: useConductorAllocationStore(), projectId }
  },

  data: () => ({
    fieldConfig: conductorAllocation,
    positionCountMax: 30,
    positionCount: DEFAULT_MAPPING_LENGTH
  }),

  watch: {
    item: {
      immediate: true,
      handler(value: ConductorAllocation) {
        this.positionCount = value.mapping.length
      }
    }
  },

  methods: {
    createDraft() {
      return {
        id: uuid(),
        name: '',
        mapping: Array.from({ length: DEFAULT_MAPPING_LENGTH }).map(() => ({
          system: null,
          index: null
        })),
        project: this.projectId
      }
    }
  },

  computed: {
    id(): ConductorAllocationId {
      return this.$route.params.id as string
    },
    positionCountItems(): SelectOption<number>[] {
      const items = []
      for (let i = 1; i <= this.positionCountMax; i++) {
        items.push({ value: i, label: `${i}` })
      }
      return items
    },
    item(): ConductorAllocation {
      const item = this.conductorAllocationStore.findById(this.id)
      // eliminate object reference (because of nested objects)
      return item === undefined ? this.createDraft() : copy(item)
    }
  },

  mounted() {
    this.conductorAllocationStore.ensureLoaded(this.projectId)
  }
})
</script>

<template>
  <p-form v-bind="$attrs" :item="item">
    <p-field v-bind="fieldConfig.name" />

    <p-field
      v-bind="{ ...fieldConfig.positionCount, standalone: true }"
      v-model="positionCount"
      :items="positionCountItems"
    />

    <p-form-section title="Zuordnung">
      <ConductorAllocationFormMapping :position-count="positionCount" />
    </p-form-section>
  </p-form>
</template>
