import { LayerId } from '@/components/map'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import useMapInteraction from '@/components/map/composables/useMapInteraction'
import { MapMode, useMapMode } from '@/components/map/composables/useMapMode'
import { HitTolerance } from '@/components/map/layer/util'
import { Collection, Feature } from 'ol'
import { never, pointerMove } from 'ol/events/condition'
import { Geometry } from 'ol/geom'
import { Select } from 'ol/interaction'
import { FilterFunction } from 'ol/interaction/Select'
import { Layer } from 'ol/layer'
import { computed, inject, onUnmounted, ref, Ref, triggerRef } from 'vue'

export type FeatureHoverScoped = {
  scope: LayerId | string
  hoveredFeatures: Feature[]
}

const hoveredFeaturesScoped = ref([]) as Ref<FeatureHoverScoped[]>
const disableHoverInformation = ref(false)

/**
 * Computed property to get all scoped features as one array
 */
const hoveredFeatures = computed((): Feature[] => {
  if (disableHoverInformation.value) {
    return []
  }
  return hoveredFeaturesScoped.value.flatMap((scope) => scope.hoveredFeatures)
})

export default function useHoverInformation() {
  /**
   * Container for OL to put hovered items into
   */
  const featuresHovered = new Collection<Feature<Geometry>>()

  /**
   * Returns a function to trigger reactivity of hovered features
   * @param scope
   */
  function getScopedFeatureHoverSetter(scope: string) {
    const featureHoverScoped: FeatureHoverScoped = {
      scope,
      hoveredFeatures: []
    }
    hoveredFeaturesScoped.value.push(featureHoverScoped)
    return (selected: Feature[]) => {
      featureHoverScoped.hoveredFeatures = selected
      triggerRef(hoveredFeaturesScoped)
    }
  }

  /**
   * Setup hovering items in layer
   */
  const registerLayerFeaturesForHover = (
    scope: LayerId | string,
    layer: Layer,
    filter?: FilterFunction
  ) => {
    // safety check
    if (hoveredFeaturesScoped.value.find((item) => item.scope === scope)) {
      console.warn('Layer with scope already registered for hover information:', scope)
      return
    }
    // tidy up
    onUnmounted(() => {
      const index = hoveredFeaturesScoped.value.findIndex((item) => item.scope === scope)
      if (index >= 0) {
        hoveredFeaturesScoped.value.splice(index, 1)
      }
    })
    const hoverInteraction = new Select({
      condition: pointerMove,
      hitTolerance: HitTolerance,
      layers: [layer],
      filter,
      features: featuresHovered,
      toggleCondition: never,
      multi: false,
      style: null // do not change style
    })

    // register displaying information on hover for features
    const setHoveredFeatures = getScopedFeatureHoverSetter(scope)

    const map = inject(UseMapInjectKeys.map)
    const mapMode = useMapMode()

    // Change cursor to pointer on hover
    hoverInteraction.on('select', () => {
      const selected = featuresHovered.getArray()
      setHoveredFeatures(selected)
      if (map && mapMode.value === MapMode.EDIT) {
        map.getViewport().style.cursor = selected.length ? 'pointer' : ''
      }
    })
    useMapInteraction(hoverInteraction)
  }
  return { hoveredFeatures, registerLayerFeaturesForHover, disableHoverInformation }
}
