<script setup lang="ts">
import SystemSelector from '@/components/study-case/SystemSelector.vue'
import SystemTag from '@/components/system/SystemTag.vue'
import { fieldsFailConfig } from '@/config/fields/study-case'
import { AutoFailConfig } from '@/config/schemas/study-case'
import { StudyCaseWithConfig } from '@/model'
import { useSystemStore } from '@/stores/system'
import { System } from '@gridside/hsb-api'
import { FormContext, useFieldArray } from 'vee-validate'
import { inject } from 'vue'

const systemStore = useSystemStore()
const form = inject<FormContext<StudyCaseWithConfig>>('studyCaseForm')
const { fields, remove, push } = useFieldArray<AutoFailConfig>('configuration.autoFail')

const addSystem = (system: System) => {
  push({ system: system.id, current: 0 })
}
</script>

<template>
  <p-form-section title="Betrachtete Systeme und Fehlerströme">
    <p class="text-gray-400 mb-4">
      Wählen Sie Systeme aus und legen Sie für jedes System den Fehlerstrom fest. Es werden
      Betriebszustände erzeugt, bei denen jeweils ein Leiter den angegebenen Fehlerstrom führt.
    </p>
    <div class="table-simple table-simple--dividers table-auto">
      <table class="w-full">
        <thead>
          <tr>
            <th class="w-8"></th>
            <th>System</th>
            <th style="width: 120px">Fehlerstrom</th>
            <th style="width: 60px">Aktionen</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(rowField, rowOpIndex) in fields" :key="rowOpIndex">
            <!-- System Tag -->
            <td class="!py-4">
              <SystemTag :system="rowField.value.system" type="small" class="font-bold">
                &nbsp;
              </SystemTag>
            </td>

            <!-- System name -->
            <td class="font-bold !py-4">
              {{ systemStore.systemName(rowField.value.system) }}
            </td>

            <td>
              <p-field
                v-bind="fieldsFailConfig.current"
                :name="`configuration.autoFail.${rowOpIndex}.${fieldsFailConfig.current.name}`"
                label=""
                dense
              />
            </td>

            <!-- Actions -->
            <td class="!pt-3 !text-center">
              <!-- Remove -->
              <el-button class="inline-block !m-0" text circle @click="remove(rowOpIndex)">
                <RemoveIcon />
              </el-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <SystemSelector
      class="mt-4"
      :has-error="fields.length === 0 && form?.meta.value.touched"
      @click="addSystem"
    />
  </p-form-section>
</template>

<style scoped>
table {
  td {
    vertical-align: top;
  }
}
</style>
