import { useMapSelection } from '@/components/map/composables/useMapSelection'
import { TowerId } from '@/model'
import { OverheadLineId } from '@/model/overhead-line'
import { useOverheadLineStore } from '@/stores/overhead-lines'
import { Feature } from 'ol'
import { LineString, Point } from 'ol/geom'
import { v4 } from 'uuid'
import { SourceLayerContext } from '@/components/map/layer/util'
import { useOverheadLine } from '@/composables/useOverheadLine'
import { values } from '@/util/helpers'
import { featureIsDraft } from '@/components/map/LayerItemConfig'

const { deselect } = useMapSelection()

export function createTowerDraft(coordinate: { x: number; y: number }, ctx: SourceLayerContext) {
  const { clear } = useMapSelection()
  const { overheadLineStore } = useOverheadLine()

  const overheadLineFeature = ctx.selectedOverheadLines.value[0]
  const overheadLineRecord = overheadLineStore.findById(
    overheadLineFeature.getId() as OverheadLineId
  )
  if (!overheadLineRecord) {
    console.log("Can't find an overhead line to create a tower for.")
    return
  }

  clear()
  deleteDraftFeatures(ctx)

  const transformedCoordinate = ctx.projectToMap([coordinate.x, coordinate.y])
  const id = v4()
  const draft = new Feature({
    geometry: new Point(transformedCoordinate),
    _type: 'tower',
    _draft: true,
    name: 'neuer Mast'
  })
  draft.setId(id)
  ctx.selectInteraction?.getFeatures().push(draft)

  const previousTower = values(overheadLineRecord.towersById)
    .sort((a, b) => a.position - b.position)
    .slice(-1)[0]
  if (previousTower) {
    const previousCoordinate = [previousTower.x, previousTower.y]
    const spanDraft = new Feature({
      geometry: new LineString([previousCoordinate, transformedCoordinate]),
      _type: 'span',
      _draft: true
    })
    spanDraft.setId(id + '-span')
    ctx.layer.getSource()?.addFeature(spanDraft)
  }

  ctx.layer.getSource()?.addFeature(draft)
  overheadLineStore.createTowerDraft(overheadLineRecord.overheadLine.id, {
    id,
    overheadLine: overheadLineRecord.overheadLine.id,
    x: coordinate.x,
    y: coordinate.y
  })
}

export function deleteDraftFeatures(ctx: SourceLayerContext) {
  const source = ctx.layer.getSource()
  source
    ?.getFeatures()
    .filter(featureIsDraft)
    .forEach((feature: Feature) => {
      deselect(feature)
      source.removeFeature(feature)
    })

  const overheadLineStore = useOverheadLineStore()
  overheadLineStore.towerDraft = undefined
  overheadLineStore.overheadLineDraft = undefined
}

export async function deleteTowers(towerIds: TowerId[]) {
  const { overheadLineStore } = useOverheadLine()
  for (const id of towerIds) {
    deselect(id)
    await overheadLineStore.towerDelete(id)
  }
}

export function deleteOverheadLines() {
  const store = useOverheadLineStore()
  store.itemsToDelete.forEach((item) => {
    store.delete(item.id)
    deselect(item.id)
  })
  store.itemsToDelete = []
}
