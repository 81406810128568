import z from 'zod'
import { ConductorAllocation } from '@gridside/hsb-api'

const ConductorAllocationMapping = z.object({
  system: z.string().nullable(),
  index: z.number().nullable()
})

const ConductorAllocationBase = z.object({
  name: z.string(),
  mapping: z.array(ConductorAllocationMapping),
  project: z.string()
})
export const ConductorAllocationSchema: z.ZodType<ConductorAllocation> =
  ConductorAllocationBase.extend({ id: z.string() })
