<script setup lang="ts">
import { useConductorAllocationStore } from '@/stores/conductor-allocation'
import { useConductorTypeStore } from '@/stores/conductor-type'
import { useTowerTypeStore } from '@/stores/tower-type'
import { Feature } from 'ol'
import { computed, onMounted, PropType } from 'vue'
import { TowerTypeId } from '@/model'
import { TowerResponse } from '@gridside/hsb-api/dist/models/TowerResponse'
import { useProject } from '@/composables/useProject'
import { useOverheadLine } from '@/composables/useOverheadLine'

const props = defineProps({
  feature: {
    type: Object as PropType<Feature>,
    required: true
  }
})

const { projectId } = useProject()
const conductorAllocationStore = useConductorAllocationStore()
const conductorTypeStore = useConductorTypeStore()
const { overheadLineStore } = useOverheadLine()
const towerTypeStore = useTowerTypeStore()

const tower = computed(() => overheadLineStore.findTowerById(props.feature.getId() as string))

function getTowerTypeName(type: TowerTypeId | undefined): string {
  return towerTypeStore.findById(type)?.name || 'unbekannt'
}
onMounted(async () => {
  await conductorAllocationStore.ensureLoaded(projectId.value)
  await conductorTypeStore.ensureLoaded()
  await conductorTypeStore.ensureLoadedByProject(projectId.value)
  await towerTypeStore.ensureLoaded()
  await towerTypeStore.ensureLoadedByProject(projectId.value)
})

const getConductorAllocation = (allocation: string) =>
  conductorAllocationStore.findById(allocation)?.name || 'unbekannt'

const getConductorType = (conductorType: string) =>
  conductorTypeStore.findById(conductorType)?.name || 'unbekannt'

function earthwiresLength(tower: TowerResponse): number {
  return Math.max(tower.in.earthwires.length, tower.out?.earthwires?.length || 0)
}
</script>

<template>
  <div v-if="tower">
    <h3>
      Mast
      {{ tower.name ? `"${tower.name}"` : `#${tower.position}` }}
    </h3>
    <table>
      <thead v-if="tower.out">
        <tr>
          <td></td>
          <th class="text-left">in</th>
          <th class="text-left">out</th>
        </tr>
      </thead>
      <tbody>
        <!-- Type -->
        <tr>
          <th>Masttyp</th>
          <td>{{ getTowerTypeName(tower.in.type) }}</td>
          <td class="out" v-if="tower.out?.type">{{ getTowerTypeName(tower.out.type) }}</td>
        </tr>

        <!-- Conductor Allocations -->
        <tr>
          <th>Leiterzuordnung</th>
          <td>
            {{ getConductorAllocation(tower.in.allocation || '') }}
          </td>
          <td class="out" v-if="tower.out?.allocation">
            {{ getConductorAllocation(tower.out.allocation || '-') }}
          </td>
        </tr>

        <!-- Earthwires -->
        <tr v-for="n in earthwiresLength(tower)" :key="n">
          <th>Erdseil {{ n }}</th>
          <td>
            {{ getConductorType(tower.in.earthwires[n - 1]) }}
          </td>
          <td class="out" v-if="tower.out?.earthwires">
            {{ getConductorType(tower.out.earthwires[n - 1]) }}
          </td>
        </tr>

        <!-- Offset -->
        <tr>
          <th>Aufstockung</th>
          <td>{{ tower.in.offset }} m</td>
          <td class="out" v-if="tower.out?.offset">{{ tower.out.offset }} m</td>
        </tr>

        <!-- Earth-resistivity-->
        <tr>
          <th>Erdwiderstand</th>
          <td colspan="2">{{ tower.earthResistivity }} Ω</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style scoped lang="scss">
td {
  @apply px-1;
}

.out {
  @apply border-l-2;
}
</style>
