import { z } from 'zod'
import { Span } from '@gridside/hsb-api'

const SpanBase = z.object({
  beginTower: z.string(),
  endTower: z.string(),
  overheadLine: z.string(),
  sag: z.number().optional(),
  corridor: z.number(),
  rx: z.number().optional(),
  rs: z.number().optional(),
  rk: z.number().optional()
})

export const SpanSchema: z.ZodType<Span> = SpanBase.extend({ id: z.string() })
