<script setup lang="ts">
import { UseMapItems } from '@/components/map/composables/useMap'
import { useMediaGroupStore } from '@/stores/media-group'
import { inject } from 'vue'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import { useOverheadLine } from '@/composables/useOverheadLine'
import { FeaturePropEnum } from './LayerItemConfig'

const { layers, mapState, selectedFeatures } = inject(UseMapInjectKeys.useMap) as UseMapItems

const mediaGroupStore = useMediaGroupStore()
const { overheadLineStore } = useOverheadLine()
</script>

<template>
  <div
    class="absolute top-4 left-72 z-1 w-96 bg-white/70 p-4 text-xs rounded shadow backdrop-blur overflow-y-auto"
  >
    <h3 class="uppercase font-bold">MAP DEBUG PANEL</h3>
    <hr />
    <h4 class="uppercase font-medium">Selection</h4>
    <div>
      {{ selectedFeatures.length }} selectedFeatures:
      {{
        selectedFeatures.map(
          (feature) =>
            feature.get(FeaturePropEnum.type) +
            '#' +
            (feature.getId() || '').toString().substring(0, 4)
        )
      }}
    </div>

    <h4 class="uppercase font-medium mt-4">OverheadLineStore</h4>
    <div>
      <em>Tower count:</em>
      {{ overheadLineStore.towersAll.length }}
      <br />
    </div>

    <div>
      <em>Span count:</em>
      {{ overheadLineStore.spansAll.length }}
      <br />
    </div>

    <div class="mt-2">
      <em>OverheadLine draft:</em>
      {{ overheadLineStore.overheadLineDraft || '&ndash;' }}
    </div>
    <div>
      <em>Tower draft:</em>
      {{ overheadLineStore.towerDraft || '&ndash;' }}
    </div>

    <h4 class="uppercase font-medium mt-2">mediaGroupStore</h4>
    <div>
      <em>selection count:</em>
      {{ mediaGroupStore.selection.length }}
      <br />
      <em>draft:</em>
      {{ mediaGroupStore.draft }}
    </div>

    <h4 class="uppercase font-medium mt-4">LayerState</h4>
    <div>{{ layers }}</div>

    <h4 class="uppercase font-medium mt-4">MapState</h4>
    <div>{{ mapState }}</div>
  </div>
</template>

<style scoped lang="css"></style>
