<script lang="ts">
import RequestsProgress from '@/components/common/RequestsProgress.vue'
import { defineComponent, PropType } from 'vue'
import type { Progress } from '@/composables/useProgress'

export default defineComponent({
  name: 'ImportProgress',
  components: { RequestsProgress },
  props: {
    progress: {
      type: Object as PropType<Progress>,
      required: true
    }
  }
})
</script>

<template>
  <div class="flex items-center justify-center h-full w-full">
    <div class="w-[60%]">
      <div class="text-center mb-2 text-lg">Projekt wird importiert...</div>
      <RequestsProgress :progress="progress" class="flex-grow" />
    </div>
  </div>
</template>

<style scoped lang="css"></style>
