<script lang="ts">
import { useProjectStore } from '@/stores/project'
import { unifyProjectionCode } from '@/util'
import deepmerge from 'deepmerge'
import { Feature, FeatureCollection, LineString } from 'geojson'
import { defineComponent, PropType } from 'vue'
import PreviewMap from '@/components/map/PreviewMap.vue'
import { useProject } from '@/composables/useProject'

export default defineComponent({
  name: 'SelectFileStep',
  components: { PreviewMap },

  props: {
    isValid: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: Object as PropType<FeatureCollection>
    },
    file: {
      type: Object as PropType<File>
    }
  },

  data: () => ({
    errors: [] as string[],
    geoJSON: undefined as FeatureCollection | undefined,
    selectedFile: undefined as File | undefined,
    validated: false
  }),

  setup() {
    const { projectId } = useProject()
    return { projectId }
  },

  computed: {
    crs(): string | undefined {
      const crsName = (this.geoJSON as any)?.crs?.properties?.name || undefined
      return unifyProjectionCode(crsName)
    },

    featureCount() {
      return (
        this.geoJsonWithPoints?.features.filter(
          (feature: Feature) => feature.geometry.type === 'Point'
        ).length || 0
      )
    },

    fileIsValid() {
      return this.selectedFile && this.validated && this.errors.length === 0
    },

    geoJsonWithPoints(): FeatureCollection | undefined {
      if (!this.geoJSON) {
        return undefined
      }
      const json = deepmerge({}, this.geoJSON)

      // Add towers as points
      const lineCoordinates = (json.features[0].geometry as LineString).coordinates
      lineCoordinates.forEach((coord) => {
        json.features.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: coord as [number, number]
          },
          properties: { _type: 'tower' }
        })
      })

      return json
    },

    projectCrs() {
      const projectId = this.projectId as string
      const project = useProjectStore().findById(projectId)
      return project?.crs
    }
  },

  mounted() {
    if (this.modelValue) {
      this.geoJSON = this.unifyGeoJson(this.modelValue)
    }
    if (this.file) {
      this.onFileSelected(this.file)
    }
  },

  watch: {
    fileIsValid() {
      this.$emit('update:isValid', this.fileIsValid)
    },
    geoJSON() {
      this.$emit('update:modelValue', this.geoJSON)
    }
  },

  methods: {
    async onFileSelected(file: File) {
      if (!file) {
        return
      }

      this.selectedFile = file
      this.geoJSON = undefined
      this.errors = []

      // Read actual data from file
      const data = await file.text()
      let rawJson: FeatureCollection | undefined = undefined

      try {
        rawJson = JSON.parse(data)
        this.geoJSON = this.unifyGeoJson(rawJson as FeatureCollection)
      } catch (e) {
        this.errors.push('Datei ist nicht im JSON-Format')
        return
      }

      this.$emit('update:file', file)
      this.validateJson()
    },

    unifyGeoJson(rawJson: FeatureCollection) {
      if (!rawJson) {
        return undefined
      }
      const json = deepmerge({}, rawJson)

      // convert MultiLineString to LineString
      if (
        json?.features &&
        json.features.length > 0 &&
        json.features[0].geometry.type === 'MultiLineString'
      ) {
        // @ts-ignore
        json.features[0].geometry.type = 'LineString'

        const firstLineString = json?.features[0].geometry.coordinates[0]
        // @ts-ignore
        json.features[0].geometry.coordinates = firstLineString
      }

      json.features.forEach((feature) => {
        feature.properties = {
          ...feature.properties,
          _type: 'overheadLine'
        }
      })

      return json
    },

    validateJson() {
      this.validated = false
      this.errors = []
      const json = this.geoJSON

      if (json?.type !== 'FeatureCollection') {
        this.errors.push('Datei ist keine gültige GeoJSON-Datei.')
        return
      }

      if (!(json?.features.length > 0 && json?.features[0].geometry.type === 'LineString')) {
        this.errors.push(
          'Quellverlauf kann nicht gelesen werden. Erwartet wird ein Feature vom Typ "LineString".'
        )
        return
      }

      /*
      // TODO: We cannot be this strict until the user can choose any CRS in the project settings (not only presets)
      if (this.projectCrs !== this.crs) {
        this.errors.push(
          `Das Koordinaten-Referenzsystem der GeoJSON-Datei (${this.crs}) ` +
            `passt nicht zum Referenzsystem des Projektes (${this.projectCrs}).` +
            `<br/><br/>Passen Sie das Referenzsystem gegebenenfalls in den Projekteinstellungen an.`
        )
      }*/

      this.validated = true
    }
  }
})
</script>

<template>
  <div class="flex space-x-8 min-h-[60vh]">
    <div class="flex-1">
      <p-form-section title="Datei auswählen">
        <template #actions>
          <a
            class="inline-block text-sm flex items-center justify-end text-gray-400 hover:text-gray-600"
            href="/import-templates/overheadLine_geojson.json"
            target="_blank"
            download
          >
            <el-icon size="20" class="mr-2">
              <DownloadIcon />
            </el-icon>
            Beispiel herunterladen
          </a>
        </template>

        <p-file-input @change="onFileSelected" label="GeoJSON-Datei hierher ziehen oder klicken" />
      </p-form-section>

      <p-form-section v-if="selectedFile" :title="selectedFile && selectedFile.name">
        <div v-if="fileIsValid" class="flex space-x-6 px-6">
          <p-success-icon class="w-12 h-12 text-success-500" />
          <div class="mt-1">
            <p class="font-semibold">Das Dateiformat ist gültig.</p>
            <p>{{ featureCount }} {{ featureCount === 1 ? 'Mast' : 'Masten' }}</p>
            <p>Koordinaten-Referenzsystem: {{ crs }}</p>

            <div
              v-if="projectCrs && projectCrs !== crs"
              class="mt-4 text-sm p-4 rounded bg-warning-200 text-warning-800"
            >
              <p>
                Das Referenzsystem der GeoJSON-Datei unterscheidet sich von den Projekteinstellungen
                ({{ projectCrs }}).
              </p>

              <p class="mt-2">
                Wenn Sie den Import durchführen, wird das Referenzsystem des Projektes auf
                <b>{{ crs }}</b>
                gesetzt. Vorhandene Koordinaten werden ungültig.
              </p>
            </div>
          </div>
        </div>
        <div v-else-if="selectedFile && !fileIsValid" class="flex space-x-6 px-6">
          <p-error-icon class="w-12 h-12 text-error-500" />
          <div class="space-y-2">
            <p class="font-semibold">Fehler beim Lesen der Datei:</p>
            <p v-for="(error, index) in errors" :key="index" v-html="error"></p>
          </div>
        </div>
      </p-form-section>
    </div>
    <div class="flex-1 flex flex-col">
      <p-form-section class="!my-0" title="Vorschau" />
      <preview-map v-if="fileIsValid" class="flex-1" :crs="crs" :geojson="geoJsonWithPoints" />
      <div v-else class="bg-gray-100 rounded flex-1 flex justify-center items-center">
        <p class="w-2/3 text-center text-gray-400 text-sm">
          Bitte wählen Sie zunächst links eine Importdatei,
          <br />
          um die Kartenvorschau zu sehen.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="css"></style>
