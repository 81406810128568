import { SuperpositionPreset } from '@/model'
import { OperationState } from '@gridside/hsb-api'

export function generateSuperpositionPresetExpression(
  preset: SuperpositionPreset,
  operationStates: OperationState[]
) {
  const operationStateKeys = Object.keys(operationStates).map((i) => parseInt(i) + 1)
  switch (preset) {
    case SuperpositionPreset.ABSOLUTE:
      return operationStateKeys.map((i) => `abs(op_${i})`).join(' + ')
    case SuperpositionPreset.COMPLEX:
      return operationStateKeys.map((i) => `op_${i}`).join(' + ')
    case SuperpositionPreset.SQUARE:
      return `sqrt(${operationStateKeys.map((i) => `pow(op_${i}, 2)`).join(' + ')})`
    case SuperpositionPreset.MAXIMUM:
      return `max(${operationStateKeys.map((i) => `op_${i}`).join(', ')})`
    default:
      return ''
  }
}
