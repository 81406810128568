<script lang="ts">
import { useMapControlState } from '@/components/map/composables/useMapControlState'
import ResultTabs from '@/components/map/controls/calculation/CalculationTabs.vue'
import MapControlHeading from '@/components/map/controls/MapControlHeading.vue'
import { useProject } from '@/composables/useProject'
import { ProjectId } from '@/model'
import { RouteParams } from '@/router/routeParams'
import { useCalculationStore } from '@/stores/calculation'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { useResizeObserver } from '@vueuse/core'
import { defineComponent } from 'vue'
import CalculationLauncher from './CalculationLauncher.vue'
import CalculationResult from './CalculationResult.vue'

export default defineComponent({
  components: {
    ResultTabs,
    PopoverPanel,
    MapControlHeading,
    PopoverButton,
    Popover,
    CalculationLauncher,
    CalculationResult
  },

  setup() {
    const calculationStore = useCalculationStore()
    calculationStore.init()
    const { expanded, height, width } = useMapControlState('calculation')
    const { project } = useProject()

    return { calculationStore, expanded, width, height, project }
  },

  data: () => ({
    resizing: false,
    previousHeight: undefined as number | undefined,
    previousWidth: undefined as number | undefined,
    previousMousePosition: undefined as number | undefined
  }),

  computed: {
    projectId(): ProjectId {
      return this.$route.params[RouteParams.ProjectId] as string
    },
    showResultPanel(): boolean {
      return false
    }
  },

  watch: {
    expanded() {
      if (this.expanded) {
        this.adjustSize()
      }
    }
  },

  mounted() {
    this.adjustSize()
    useResizeObserver(document.body, () => {
      this.adjustSize()
    })
  },

  methods: {
    adjustSize() {
      const panelEl = this.getPanelHtmlElement()
      const mapEl = document.querySelector('.ol-viewport')

      if (panelEl && mapEl) {
        const { width, height } = mapEl.getBoundingClientRect()
        panelEl.style.maxWidth = width - 320 + 'px'
        panelEl.style.maxHeight = height - 100 + 'px'
      }
    },

    finishResize() {
      this.resizing = false
      this.previousMousePosition = undefined
      this.previousHeight = undefined

      document.removeEventListener('mouseup', this.finishResize)
      document.removeEventListener('mousemove', this.onResizeHeight)
      document.removeEventListener('mousemove', this.onResizeWidth)
    },

    getPanelHtmlElement() {
      return (this.$refs.panel as any)?.el as HTMLDivElement
    },

    onResizeHeight(event: MouseEvent) {
      const el = this.getPanelHtmlElement()

      if (el && this.resizing && this.previousMousePosition && this.previousHeight) {
        const mouseDiff = this.previousMousePosition - event.clientY
        const newHeight = this.previousHeight + mouseDiff
        this.height = newHeight
        el.style.height = newHeight + 'px'
      }
      this.adjustSize()
    },

    onResizeWidth(event: MouseEvent) {
      const el = this.getPanelHtmlElement()

      if (el && this.resizing && this.previousMousePosition && this.previousWidth) {
        const mouseDiff = this.previousMousePosition - event.clientX
        const newWidth = this.previousWidth - mouseDiff
        this.width = newWidth
        el.style.width = newWidth + 'px'
      }
      this.adjustSize()
    },

    startResizeHeight(event: MouseEvent) {
      const el = (this.$refs.panel as any).el as HTMLDivElement
      const { height } = el.getBoundingClientRect()
      this.resizing = true
      this.previousHeight = height
      this.previousMousePosition = event.clientY

      document.addEventListener('mouseup', this.finishResize)
      document.addEventListener('mousemove', this.onResizeHeight)
    },

    startResizeWidth(event: MouseEvent) {
      const el = (this.$refs.panel as any).el as HTMLDivElement
      const { width } = el.getBoundingClientRect()
      this.resizing = true
      this.previousWidth = width
      this.previousMousePosition = event.clientX

      document.addEventListener('mouseup', this.finishResize)
      document.addEventListener('mousemove', this.onResizeWidth)
    }
  }
})
</script>

<template>
  <Popover class="flex flex-col-reverse">
    <PopoverButton @click="expanded = !expanded" @keydown.enter="expanded = !expanded">
      <div class="trigger">
        <MapControlHeading class="px-4" :expanded="expanded">Berechnung</MapControlHeading>
      </div>
    </PopoverButton>
    <transition
      enter-active-class="transition duration-150 origin-bottom-left"
      enter-from-class="scale-y-0 scale-x-10 opacity-0"
      enter-to-class="scale-y-100 scale-x-100 opacity-100"
      leave-active-class="transition duration-150 origin-bottom-left"
      leave-from-class="scale-y-100  "
      leave-to-class="scale-y-0 opacity-0 "
    >
      <PopoverPanel
        v-show="expanded"
        ref="panel"
        class="panel"
        :class="{ 'panel--result': showResultPanel, 'select-none': resizing }"
        :style="{
          height: height ? height + 'px' : undefined,
          width: width ? width + 'px' : undefined
        }"
        static
      >
        <div class="w-72 px-4 p-2 mt-8 overflow-auto">
          <div class="absolute top-2">
            <MapControlHeading :expanded="!expanded" @click="expanded = false">
              Berechnung
            </MapControlHeading>
          </div>

          <CalculationLauncher />
          <CalculationResult />

          <button class="absolute right-4 top-4 hover:text-gray-900" @click="expanded = false">
            <el-icon size="20"><CloseIcon /></el-icon>
          </button>
        </div>
        <ResultTabs
          v-if="calculationStore.selectedStudyCase"
          class="flex-1 my-3 mx-4 overflow-hidden"
        />
        <div v-else class="flex items-center justify-center flex-1 text-gray-400">
          Bitte wählen Sie einen Berechnungsfall aus und starten Sie die Berechnung.
          {{ calculationStore.current }}
        </div>

        <div class="resize-handler resize-handler--height" @mousedown="startResizeHeight"></div>
        <div class="resize-handler resize-handler--width" @mousedown="startResizeWidth"></div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<style scoped lang="css">
.trigger,
.panel {
  @apply bg-gray-50 bg-opacity-80 backdrop-blur-lg rounded-md shadow-lg;
}

.trigger {
  @apply w-64 h-9;
}

.panel {
  @apply z-50 absolute h-[25rem] bottom-0 flex bg-gray-50 bg-opacity-90 min-h-[350px];
}

.panel--result {
  @apply w-[70vw];
}

.resize-handler {
  @apply absolute transition;
}

.resize-handler--height {
  @apply h-4 -top-2 w-full cursor-ns-resize;
}
.resize-handler--width {
  @apply w-4 -right-2 h-full cursor-ew-resize;
}
</style>
