const withAlpha =
  (color: [r: number, g: number, b: number]) =>
  (alpha = 1) =>
    `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${alpha})`

export default {
  fontFamily: '"Inter var", sans-serif',
  mediaGroup: {
    color: withAlpha([18, 11, 218]),
    hoverColor: withAlpha([30, 100, 240]),
    mutedColor: withAlpha([17, 13, 134])
  },
  media: {
    default: withAlpha([11, 46, 218]),
    hoverColor: withAlpha([30, 100, 240]),
    selected: withAlpha([17, 13, 134])
  },
  relation: {
    color: withAlpha([123, 158, 27])
  },
  source: {
    color: withAlpha([231, 36, 60]),
    hoverColor: withAlpha([255, 100, 100])
  },
  selected: {
    color: withAlpha([0, 153, 255])
  }
} as const

const baseColors = {
  source: withAlpha([231, 36, 60])
}

const defaultStrokeWidth = 4

export const styleTokens = {
  fontFamily: '"Inter var", sans-serif',
  source: {
    overheadLine: {
      color: baseColors.source(),
      shimColor: baseColors.source(0.2),
      strokeWidth: defaultStrokeWidth
    },
    tower: {
      color: baseColors.source(),
      fillColor: 'white',
      size: 6,
      strokeWidth: defaultStrokeWidth
    }
  }
}
