import { defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'
import { ProjectId, TowerType, TowerTypeId } from '@/model'
import { HsbApi } from '@/api'
import { alphabeticallyBy, findUniqueCopyName, values } from '@/util/helpers'
import { TowerTypeSchema } from '@/config/schemas/tower-type-schema'

let init = false
const alphabeticallyByName = alphabeticallyBy<TowerType>('name')
const TowerTypeApi = HsbApi.towerTypes

// Store
export const useTowerTypeStore = defineStore('towerType', {
  state: () => ({
    globalItemsById: {} as Record<TowerTypeId, TowerType>,
    loaded: false,
    loadedProject: undefined as undefined | ProjectId,
    loading: false,
    projectItemsById: {} as Record<TowerTypeId, TowerType>,
    selection: [] as TowerTypeId[]
  }),

  getters: {
    findById(state) {
      return (id: TowerTypeId | undefined): TowerType | undefined =>
        id === undefined ? undefined : state.projectItemsById[id] || state.globalItemsById[id]
    },
    getConductorCount() {
      return (id: TowerTypeId | undefined): number | undefined =>
        this.findById(id)?.conductorPositions.length
    },
    getEarthwireCount() {
      return (id: TowerTypeId | undefined): number | undefined =>
        this.findById(id)?.earthwirePositions.length
    },
    globalItems(): TowerType[] {
      return values(this.globalItemsById).sort(alphabeticallyByName)
    },
    projectItems(): TowerType[] {
      return values(this.projectItemsById).sort(alphabeticallyByName)
    }
  },

  actions: {
    /**
     * Handle WS-update of global lib object
     */
    init() {
      if (init) {
        return
      }
      init = true

      TowerTypeApi.onTowerTypeUpdated((data) => {
        const result = TowerTypeSchema.safeParse(data)
        if (!result.success) {
          return
        }
        if (!result.data.project) {
          this.globalItemsById[result.data.id] = result.data
        }
      })
      TowerTypeApi.onTowerTypeDeleted((data) =>
        typeof data === 'object' && 'id' in data ? delete this.globalItemsById[data.id] : null
      )
    },
    async copyIntoProject(id: TowerTypeId, projectId: ProjectId) {
      const item = this.globalItemsById[id]
      const newId = uuid()

      return await this.save({
        ...item,
        id: newId,
        name: findUniqueCopyName(
          item.name,
          this.projectItems.map((item) => item.name)
        ),
        project: projectId
      })
    },

    async delete(id: TowerTypeId) {
      const towerType = this.findById(id)
      if (towerType) {
        await TowerTypeApi.deleteTowerType(towerType.id)
        delete this.globalItemsById[id]
        delete this.projectItemsById[id]
      }
    },

    async ensureLoaded() {
      if (!this.loaded && !this.loading) {
        await this.load()
      }
    },

    async ensureLoadedByProject(projectId: ProjectId) {
      if (projectId && this.loadedProject !== projectId) {
        await this.load(projectId)
      }
    },

    async load(projectId?: ProjectId) {
      const itemsById: Record<TowerTypeId, TowerType> = {}
      this.loading = true

      try {
        const towerTypes = projectId
          ? (await TowerTypeApi.getProjectTowerTypes(projectId)).results
          : (await TowerTypeApi.getGlobalTowerTypes()).results

        towerTypes.forEach((item) => {
          itemsById[item.id] = item
        })
        if (projectId) {
          this.projectItemsById = { ...itemsById }
          this.loadedProject = projectId
        } else {
          this.globalItemsById = { ...itemsById }
          this.loaded = true
        }
      } finally {
        this.loading = false
      }
    },

    async save(item: TowerType) {
      if (!item.id) {
        item.id = uuid()
      }
      const updatedItem = await TowerTypeApi.saveTowerType(item.id, item)

      if (updatedItem.project) {
        this.projectItemsById = { ...this.projectItemsById, [item.id]: updatedItem }
      } else {
        this.globalItemsById = { ...this.globalItemsById, [item.id]: updatedItem }
      }

      return updatedItem
    },

    /**
     * Returns the amount of earthwire positions for given TowerType
     */
    earthwireCountForType(typeId: TowerTypeId | undefined): number {
      let count = 0
      if (typeId === undefined) {
        return count
      }
      const selectedTowerType = this.findById(typeId)
      count = selectedTowerType?.earthwirePositions.length || 0
      return count
    }
  }
})
