import useMapInteraction from '@/components/map/composables/useMapInteraction'
import { MapMode, useMapMode } from '@/components/map/composables/useMapMode'
import { useMapSelection } from '@/components/map/composables/useMapSelection'
import { HitTolerance, SystemsLayerContext } from '@/components/map/layer/util'
import { systemsStyle } from '@/components/map/style/systems'
import { RouteParams } from '@/router/routeParams'
import { useSystemStore } from '@/stores/system'
import { click, platformModifierKeyOnly, shiftKeyOnly } from 'ol/events/condition'
import { Select } from 'ol/interaction'
import { watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export function useSystemSelect(ctx: SystemsLayerContext) {
  const mapMode = useMapMode()
  const systemStore = useSystemStore()
  const router = useRouter()
  const { registerSelectInteraction, selectedFeatures, select } = useMapSelection()

  const selectInteraction = new Select({
    condition: click,
    style: systemsStyle('select'),
    layers: [ctx.layer],
    hitTolerance: HitTolerance,
    toggleCondition: (e) => platformModifierKeyOnly(e) || shiftKeyOnly(e)
  })

  registerSelectInteraction(selectInteraction)
  useMapInteraction(selectInteraction)

  // only activate on edit mode
  watch(
    mapMode,
    () => {
      selectInteraction.setActive(mapMode.value === MapMode.EDIT)
    },
    { immediate: true }
  )

  // initial selections
  ctx.layer.on('change:source', () => {
    const routeParam = router.currentRoute.value.params[RouteParams.SystemId]
    if (routeParam) {
      const feature = ctx.layer
        .getSource()
        ?.getFeatures()
        .find((feature) => feature.getId() === routeParam)

      if (feature) {
        select(feature)
        selectInteraction.getFeatures().push(feature)
      }
    }
  })

  // dispatch selection changes to their respective routes
  watch(selectedFeatures, () => {
    const projectId = router.currentRoute.value.params[RouteParams.ProjectId]
    if (!projectId) {
      return
    }

    const { selectedSystems } = ctx

    if (selectedSystems.value.length === 1) {
      router.push({
        name: 'project-map-system-edit',
        params: { systemId: selectedSystems.value[0].getId() }
      })
    }
  })

  return { selectInteraction }
}
