<script setup lang="ts">
const isStaging = window.location.hostname.includes('staging')
</script>

<template>
  <div
    v-if="isStaging"
    class="bg-yellow-500 inset-x-0 top-0 z-50 text-center text-opacity-40 p-1"
    style="background: yellow"
  >
    <span class="font-semibold">Staging-System</span>
    &ndash; Nur für Testzwecke, Daten werden beim nächsten Update überschrieben.
  </div>
</template>
