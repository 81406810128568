import { useHttpClient } from '@/api/useHttpClient'
import { getEnv } from '@/util'
import { Configuration, FetchParams, Middleware, ResponseContext } from '@gridside/hsb-api'

/**
 * Auth middleware to insert the current access token
 *
 * (by https://richardwillis.info/blog/generate-a-type-script-http-client-from-an-open-api-spec-in-dot-net-5)
 */
export class AuthMiddleware implements Middleware {
  public async pre(context: ResponseContext): Promise<FetchParams | void> {
    const accessToken = this.acquireToken()
    if (accessToken) {
      return {
        ...context,
        init: {
          ...context.init,
          headers: new Headers({
            ...context.init.headers,
            Authorization: `Bearer ${accessToken}`
          })
        }
      }
    } else {
      return context
    }
  }

  public post(context: ResponseContext): Promise<Response | void> {
    return Promise.resolve(context.response)
  }

  private acquireToken() {
    const { authToken } = useHttpClient()
    return authToken.value
  }
}

export const defaultConfig = new Configuration({
  basePath: getEnv('VITE_REST_BASE_URL', '/'),
  middleware: [new AuthMiddleware()]
})
