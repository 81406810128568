import Projections from '@/config/projections'
import { CorridorModeEnum, LogLevelEnum } from '@/model'
import { FieldConfig, ValidationRule } from '@prionect/ui/dist/types/components/form/types'
import { HsbLibParameters } from '@gridside/hsb-api'

enum Primitives {
  Number = 'number',
  String = 'string',
  Boolean = 'boolean',
  Object = 'object',
  Function = 'function',
  Undefined = 'undefined',
  Symbol = 'symbol',
  BigInt = 'bigint'
}

/**
 * Value is contained in given list of values
 */
const containsValidator =
  (list: any[]): ValidationRule =>
  (value: any) =>
    list.includes(value)
      ? true
      : 'Wert muss einer der folgenden sein: ' + list.map((value) => `'${value}'`).join(', ')

const isGreaterThanValidator =
  (value: number): ValidationRule =>
  (e: any) =>
    Number(e) > value ? true : 'Wert muss größer sein als ' + value + '!'

const isGreaterEqualThanValidator =
  (value: number): ValidationRule =>
  (e: any) =>
    Number(e) >= value ? true : 'Wert muss größer/gleich sein als ' + value + '!'

const isIntegerValidator: ValidationRule = (e: any) =>
  Number.isInteger(e) ? true : 'Wert muss ganzzahlig sein!'

/**
 * If value given is null use "whenNull"-Rules, else use "whenNotNull"-Rules
 */
const whenNullElse = (
  whenNull: ValidationRule | ValidationRule[],
  whenNotNull: ValidationRule | ValidationRule[]
): ValidationRule => {
  return (val: any) => {
    if (val === null) {
      const checkNull = Array.isArray(whenNull) ? whenNull : [whenNull]
      for (const rule of checkNull) {
        const result = rule(val)
        if (result !== true) {
          return result
        }
      }
      return true
    }

    const checkNotNull = Array.isArray(whenNotNull) ? whenNotNull : [whenNotNull]
    for (const rule of checkNotNull) {
      const result = rule(val)
      if (result !== true) {
        return result
      }
    }

    return true
  }
}

/**
 * Value can be null or must be of given type
 */
const nullOrTypeValidator = (type: Primitives, errorMessage?: string) => (value: any) =>
  value === null
    ? true
    : typeof value === type
    ? true
    : errorMessage ?? `Wert muss typ ${type}  sein oder null! ("${typeof value}" gegeben)`

const fields: Record<string, FieldConfig> = {
  name: {
    name: 'name',
    label: 'Projektname',
    required: 'Bitte geben Sie einen Projektnamen an.'
  },
  customer: {
    name: 'customer',
    label: 'Kunde'
  },
  mediaType: {
    name: 'mediaType',
    type: 'select',
    label: 'Typ der beeinflussten Mediengruppe',
    items: [
      { value: 'telecom', label: 'Telekommunikationsleitung' },
      { value: 'pipe', label: 'Rohrleitung' }
    ],
    required: true
  },
  crs: {
    name: 'crs',
    type: 'select',
    label: 'Koordinaten-Referenzsystem',
    placeholder: 'EPSG-Code für alle verwendeten Koordinaten',
    items: Object.keys(Projections).map((key) => ({
      value: key,
      label: `${key} - ${Projections[key].name}`
    }))
  },
  users: {
    name: 'users',
    label: 'Zugeordnete Nutzer',
    type: 'select',
    // @ts-ignore
    multiple: true,
    items: []
  }
}

export type FieldConfigWithBackendDefault = FieldConfig & {
  backendDefault?: string | number | boolean | object
}
export const FieldConfigHsbLibParameters: Record<
  keyof HsbLibParameters,
  FieldConfigWithBackendDefault
> = {
  corridorFlatend: {
    name: 'hsblibParameter.corridorFlatend',
    label: 'corridor_flatend',
    placeholder: 'z.B. true',
    hint: 'Legt fest, ob das Korridor-Ende flach oder kreisförmig sein soll.',
    rules: [nullOrTypeValidator(Primitives.Boolean)],
    type: 'switch',
    backendDefault: true
  },

  corridorFlatstart: {
    name: 'hsblibParameter.corridorFlatstart',
    label: 'corridor_flatstart',
    placeholder: 'z.B. true',
    hint: 'Legt fest, ob der Korridor-Anfang flach oder kreisförmig sein soll.',
    rules: [nullOrTypeValidator(Primitives.Boolean)],
    type: 'switch',
    backendDefault: true
  },

  corridorMode: {
    name: 'hsblibParameter.corridorMode',
    label: 'corridor_mode',
    placeholder: 'z.B. Flach',
    hint: 'Legt fest, wie der Korridor berechnet werden soll (gerundet oder flach)',
    rules: [
      nullOrTypeValidator(Primitives.String),
      containsValidator([...Object.values(CorridorModeEnum), null])
    ],
    type: 'select',
    backendDefault: CorridorModeEnum.FLAT,
    items: [
      { value: CorridorModeEnum.FLAT, label: 'Flach' },
      { value: CorridorModeEnum.ROUND, label: 'Rund' }
    ]
  },

  displayPrint: {
    name: 'hsblibParameter.displayPrint',
    label: 'display_print',
    placeholder: 'z.B. true',
    hint: 'Enables or disables common printout.',
    rules: [nullOrTypeValidator(Primitives.Boolean)],
    type: 'switch',
    backendDefault: true
  },

  loggingTraceback: {
    name: 'hsblibParameter.loggingTraceback',
    label: 'logging_traceback',
    placeholder: 'z.B. true',
    hint: 'Aktiviert oder deaktiviert traceback der Log-Ausgaben',
    rules: [nullOrTypeValidator(Primitives.Boolean)],
    type: 'switch',
    backendDefault: true
  },

  logLevel: {
    name: 'hsblibParameter.logLevel',
    label: 'log_level',
    placeholder: 'z.B. INFO',
    hint: 'Setzt den Grad der Log-Ausgaben',
    rules: [
      nullOrTypeValidator(Primitives.String),
      containsValidator([...Object.values(LogLevelEnum), null])
    ],
    type: 'select',
    backendDefault: LogLevelEnum.INFO,
    items: [
      { value: LogLevelEnum.CRITICAL, label: 'CRITICAL' },
      { value: LogLevelEnum.ERROR, label: 'ERROR' },
      { value: LogLevelEnum.WARNING, label: 'WARNING' },
      { value: LogLevelEnum.INFO, label: 'INFO' },
      { value: LogLevelEnum.DEBUG, label: 'DEBUG' },
      { value: LogLevelEnum.NOTSET, label: 'NOTSET' }
    ]
  },

  flagInnerImpedanceBesselFunction: {
    name: 'hsblibParameter.flagInnerImpedanceBesselFunction',
    label: 'FLAG_Inner_Impedance_Bessel_Function',
    placeholder: 'z.B. false',
    hint: 'Aktiviert oder deaktiviert die Bessel-Funktion bei der Berechnung der inneren Impedanz.',
    rules: [nullOrTypeValidator(Primitives.Boolean)],
    type: 'switch',
    backendDefault: false
  },

  flagDebugging: {
    name: 'hsblibParameter.flagDebugging',
    label: 'Flag_Debugging',
    placeholder: 'z.B. false',
    hint: 'Aktiviert oder deaktiviert den Debuggin-Modus für umfangreiche Log-Ausgaben.',
    rules: [nullOrTypeValidator(Primitives.Boolean)],
    type: 'switch',
    backendDefault: false
  },

  pipelineSegmentation: {
    name: 'hsblibParameter.pipelineSegmentation',
    label: 'PipelineSegmentation',
    placeholder: 'z.B. 100',
    hint: 'Maximale Segmentlänge bei der Lastflussberechnung einer Rohrleitung',
    rules: [whenNullElse(nullOrTypeValidator(Primitives.Number), isGreaterThanValidator(0))],
    type: 'number',
    unit: 'm',
    backendDefault: 100
  },

  relAbstol: {
    name: 'hsblibParameter.relAbstol',
    label: 'Rel_abstol',
    placeholder: 'z.B. 1e-8',
    hint: 'Legt die absolute Toleranz bei der Erstellung von Beeinflussungsabschnitten fest',
    rules: [whenNullElse(nullOrTypeValidator(Primitives.Number), isGreaterThanValidator(0))],
    type: 'number',
    min: 1e-8,
    step: 1e-8, // 0.00000001
    maxFractionDigits: 8,
    minFractionDigits: 0,
    unit: 'm',
    backendDefault: 1e-8
  },

  relAngletol: {
    name: 'hsblibParameter.relAngletol',
    label: 'Rel_angletol',
    placeholder: 'z.B. 1',
    hint: 'Legt die Winkeltoleranz bei der Erstellung von Berechnungsabschnitten fest',
    rules: [whenNullElse(nullOrTypeValidator(Primitives.Number), isGreaterThanValidator(0))],
    type: 'number',
    min: 0.1,
    unit: '°',
    backendDefault: 1
  },

  relMaxAngle: {
    name: 'hsblibParameter.relMaxAngle',
    label: 'Rel_max_angle',
    placeholder: 'z.B. 80',
    hint: 'Maximaler Winkel, für den Beeinflussungsabschnitte erstellt werden',
    rules: [whenNullElse(nullOrTypeValidator(Primitives.Number), isGreaterThanValidator(0))],
    type: 'number',
    min: 0,
    max: 90,
    unit: '°',
    backendDefault: 80
  },

  relMinSegmentLength: {
    name: 'hsblibParameter.relMinSegmentLength',
    label: 'Rel_min_segment_length',
    placeholder: 'z.B. 0.1',
    hint: 'Minimale Segmentlänge bei der Erstellung von Beeinflussungsabschnitten',
    rules: [whenNullElse(nullOrTypeValidator(Primitives.Number), isGreaterThanValidator(0))],
    type: 'number',
    unit: 'm',
    backendDefault: 0.1
  },

  relSimplifyMedium: {
    name: 'hsblibParameter.relSimplifyMedium',
    label: 'Rel_simplifyMedium',
    placeholder: 'z.B. false',
    hint: 'Legt fest, ob Medien für die Erstellung der Beeinflussungsabschnitte vereinfacht werden',
    rules: [nullOrTypeValidator(Primitives.Boolean)],
    type: 'switch',
    backendDefault: false
  },

  trimTillDecimalPoints: {
    name: 'hsblibParameter.trimTillDecimalPoints',
    label: 'Trim_till_decimal_points',
    placeholder: 'z.B. 2',
    hint: 'Legt fest, ob Medien für die Erstellung der Beeinflussungsabschnitte vereinfacht werden',
    rules: [
      whenNullElse(nullOrTypeValidator(Primitives.Number), [
        isIntegerValidator,
        isGreaterEqualThanValidator(0)
      ])
    ],
    type: 'number',
    min: 0,
    backendDefault: 2
  },

  utmReimportTolerance: {
    name: 'hsblibParameter.utmReimportTolerance',
    label: 'UTM_Reimport_Tolerance',
    placeholder: 'z.B. 0.001',
    hint: 'Toleranz (in Metern) für geometrische Operationen',
    rules: [whenNullElse(nullOrTypeValidator(Primitives.Number), isGreaterThanValidator(0))],
    type: 'number',
    min: 1e-3,
    step: 1e-3, // 0.001
    maxFractionDigits: 3,
    minFractionDigits: 0,
    unit: 'm',
    backendDefault: 1e-3
  },

  utmTrimMode: {
    name: 'hsblibParameter.utmTrimMode',
    label: 'UTM_trim_mode',
    placeholder: 'z.B. false',
    hint: 'Gibt an, ob Koordinatenzahlen beim Import abgeschnitten werden sollen.',
    rules: [nullOrTypeValidator(Primitives.Boolean)],
    type: 'switch',
    backendDefault: false
  }
}

export default fields
