<script lang="ts">
import { useTowerTypeStore } from '@/stores/tower-type'
import { defineComponent } from 'vue'
import SecondaryNavigation from '@/components/navigation/SecondaryNavigation.vue'
import ProjectNavigation from '@/components/navigation/ProjectNavigation.vue'
import { useProjectStore } from '@/stores/project'
import { Project, ProjectId } from '@/model'
import ProjectLockedOverlay from '@/components/project/ProjectLockedOverlay.vue'
import { useAuthentication } from '@prionect/ui'
import { useCalculationStore } from '@/stores/calculation'
import { useProject } from '@/composables/useProject'

export default defineComponent({
  name: 'ProjectView',
  components: { ProjectLockedOverlay, ProjectNavigation, SecondaryNavigation },

  data: () => ({
    unlocking: false
  }),

  computed: {
    projectLockedByAnotherUser(): boolean {
      const { currentUser } = useAuthentication()
      if (!this.project) {
        // project is not loaded yet
        return false
      }

      return (this.project.locked && this.project.lockedBy !== currentUser.value.id) || false
    }
  },

  setup() {
    const calculationStore = useCalculationStore()
    const projectStore = useProjectStore()

    calculationStore.reset()
    projectStore.ensureLoaded()

    const { projectId, project } = useProject()

    return {
      projectStore,
      calculationStore,
      towerTypeStore: useTowerTypeStore(),
      projectId,
      project
    }
  },

  mounted() {
    this.loadStores()
    this.lockProject()
  },

  watch: {
    projectId(current?: ProjectId, prev?: ProjectId) {
      if (current) {
        this.calculationStore.reset()
        this.lockProject()
        this.loadStores()
      }
    }
  },

  methods: {
    loadStores() {
      // Project id might be empty while creating a new project
      if (this.projectId) {
        this.towerTypeStore.ensureLoaded()
        this.towerTypeStore.ensureLoadedByProject(this.projectId)
      }
    },

    /**
     * Lock this project, no matter whether it is locked by another user
     */
    async takeOver() {
      this.unlocking = true
      try {
        await this.projectStore.unlock(this.projectId)
        await this.projectStore.lock(this.projectId)
      } finally {
        this.unlocking = false
      }
    },

    async lockProject() {
      if (this.projectId && (!this.project || !this.project.locked)) {
        await this.projectStore.lock(this.projectId)
      }
    }
  }
})
</script>

<template>
  <div class="flex w-full h-full overflow-hidden relative" data-component="ProjectView">
    <SecondaryNavigation class="project-navigation w-[15rem] overflow-auto">
      <ProjectNavigation v-if="project" :project="project" />
    </SecondaryNavigation>
    <div class="project-content flex-1 overflow-auto bg-white">
      <router-view></router-view>
    </div>
    <ProjectLockedOverlay
      v-if="projectLockedByAnotherUser"
      :project-id="projectId"
      :unlocking="unlocking"
      @request-lock="takeOver"
    />
  </div>
</template>

<style lang="css">
.fullscreen .project-navigation {
  display: none;
}
</style>
