<script lang="ts">
import { defineComponent } from 'vue'
import { MapMode, useMapMode } from '@/components/map/composables/useMapMode'
import ButtonControlBase from '@/components/map/controls/ButtonControlBase.vue'

export default defineComponent({
  name: 'MapModeControl',
  components: { ButtonControlBase },

  setup() {
    return { mapMode: useMapMode() }
  },

  data: () => ({
    actions: [
      { icon: 'Pan', mode: MapMode.VIEW, tooltip: 'Karte verschieben' },
      { icon: 'Pen', mode: MapMode.EDIT, tooltip: 'Elemente bearbeiten' },
      { icon: 'Ruler', mode: MapMode.MEASURE, tooltip: 'Entfernungen messen' }
    ]
  })
})
</script>

<template>
  <div class="flex space-x-3">
    <template v-for="action in actions" :key="action.icon">
      <ButtonControlBase
        :actions="[{ ...action, action: () => (mapMode = action.mode) }]"
        :active="action.mode === mapMode"
        :data-test="`mapmode-${action.mode}`"
      />
    </template>
  </div>
</template>

<style scoped lang="css"></style>
