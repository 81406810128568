<script lang="ts">
import TypeSelectDialog from '@/components/common/TypeSelectDialog.vue'
import TowerTypeImage from '@/components/tower-type/TowerTypeImage.vue'
import { LineTowerTypeTranslations, TowerType, TowerTypeId } from '@/model'
import { useTowerTypeStore } from '@/stores/tower-type'
import { defineComponent, PropType } from 'vue'
import { RouteParams } from '@/router/routeParams'

function towerTypesToListItems(types: TowerType[]) {
  return types.map((item) => ({
    value: item.id,
    label: item.name
  }))
}

export default defineComponent({
  name: 'TowerTypeSelectDialog',
  components: { TowerTypeImage, TypeSelectDialog },

  props: {
    modelValue: String as PropType<TowerTypeId>
  },
  emits: ['update:modelValue'],

  data: () => ({
    currentId: undefined as undefined | TowerTypeId
  }),

  computed: {
    LineTowerTypeTranslations() {
      return LineTowerTypeTranslations
    },
    conductorCount(): number {
      return this.towerType?.conductorPositions.length ?? 0
    },
    earthwireCount(): number {
      return this.towerType?.earthwirePositions.length ?? 0
    },
    globalItems() {
      return towerTypesToListItems(this.store.globalItems)
    },
    projectId() {
      return this.$route.params[RouteParams.ProjectId] as string
    },
    projectItems() {
      return towerTypesToListItems(this.store.projectItems)
    },
    towerType(): TowerType | null {
      return this.currentId ? this.store.findById(this.currentId) ?? null : null
    }
  },

  setup() {
    return {
      store: useTowerTypeStore()
    }
  },

  mounted() {
    this.currentId = this.modelValue
    this.store.ensureLoaded()
    this.store.ensureLoadedByProject(this.projectId)
  },

  methods: {
    async onSubmit() {
      let id = this.currentId
      if (!this.towerType?.project && this.currentId) {
        // duplicate global type into project
        const newItem = await this.store.copyIntoProject(this.currentId, this.projectId)
        id = newItem.id
      }
      this.$emit('update:modelValue', id)
    }
  }
})
</script>

<template>
  <TypeSelectDialog
    v-if="store.loaded"
    v-bind="$attrs"
    :modelValue="modelValue"
    title="Masttyp auswählen"
    :global-items="globalItems"
    :on-submit="onSubmit"
    :project-items="projectItems"
    @update:modelValue="currentId = $event"
  >
    <div v-if="towerType" class="flex flex-col items-center max-w-[60ch] h-full">
      <div class="w-full text-xl font-semibold pt-1 pb-4">
        {{ towerType.name }}
        <small class="text-gray-400">
          ({{ LineTowerTypeTranslations[towerType.lineTowerType] }})
        </small>
      </div>
      <div class="flex mb-4 text-gray-600">
        <span class="font-semibold pr-2 text-gray-800">{{ conductorCount }}</span>
        Leiterseile
        <span class="pl-16 font-semibold pr-2 text-gray-800">{{ earthwireCount }}</span>
        Erdseile
      </div>
      <TowerTypeImage v-if="towerType" :tower-type="towerType" max-height="360px" class="flex-1" />
      <div v-if="!towerType?.project" class="text-base bg-info-200 text-info-700 p-4 mt-8">
        <p class="mb-2">
          Mit der Auswahl dieses Masttyps aus der globalen Typenbibliothek wird er in die
          Projektbibliothek übernommen. Sie können den Masttyp in der Projektbibliothek anschließend
          nach Bedarf anpassen.
        </p>
      </div>
    </div>
  </TypeSelectDialog>
</template>

<style scoped lang="css"></style>
