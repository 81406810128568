<script lang="ts" setup>
import { Feature } from 'ol'
import { computed, inject, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { GeoJSON } from 'geojson'
import { GeoJSON as GeoJSONFormat } from 'ol/format'
import { Vector as VectorSource } from 'ol/source'

import { LayerId } from '@/components/map'
import { UseMapItems } from '@/components/map/composables/useMap'
import { loadFlowMaxVoltageStyle } from '@/components/map/style/loadFlow'
import { useProject } from '@/composables/useProject'
import { useCalculationStore } from '@/stores/calculation'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import LayerVector from '@/components/map/layer/LayerVector.vue'

const calculationStore = useCalculationStore()
const result = computed(() => calculationStore.result)

const { view } = inject(UseMapInjectKeys.useMap) as UseMapItems
const { project } = useProject()
const { n } = useI18n()

const source = ref<VectorSource>()

watch(
  result,
  () => {
    const voltage = result?.value?.resultVoltage
    const coordinate = result?.value?.resultVoltageCoordinate

    if (voltage && coordinate && project.value) {
      const geoJsonOptions = {
        dataProjection: project.value.crs,
        featureProjection: view.value.getProjection()
      }

      const geojson: GeoJSON = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [coordinate.x, coordinate.y]
        },
        properties: {
          voltage: n(voltage, { maximumFractionDigits: 1 }) + ' V (max)'
        }
      }

      // create or update source
      if (!source.value) {
        source.value = new VectorSource({
          features: new GeoJSONFormat(geoJsonOptions).readFeatures(geojson) as Feature[]
        })
      } else {
        const features = new GeoJSONFormat(geoJsonOptions).readFeatures(geojson) as Feature[]
        source.value.clear()
        source.value.addFeatures(features)
      }
    }
  },
  { immediate: true }
)
</script>

<template>
  <LayerVector
    v-if="source"
    v-bind="$attrs"
    :id="LayerId.MAX_VOLTAGE"
    :layer-style="loadFlowMaxVoltageStyle"
    :source="source"
  />
</template>
