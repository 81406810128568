<script setup lang="ts">
import { computed, PropType } from 'vue'
import { Feature } from 'ol'
import { formatNumber } from '@/util/helpers'
import { useOverheadLine } from '@/composables/useOverheadLine'

const props = defineProps({
  feature: {
    type: Object as PropType<Feature>,
    required: true
  }
})
const { overheadLineStore } = useOverheadLine()

const span = computed(() => overheadLineStore.findSpanById(props.feature?.getId() as string))
const spanTitle = computed(() =>
  span.value ? `Spannfeld ${overheadLineStore.getSpanPosition(span.value) + 1}` : '(unbekannt)'
)
</script>

<template>
  <div v-if="span">
    <h3 class="">{{ spanTitle }}</h3>
    <table>
      <tbody>
        <tr>
          <th>Durchhang</th>
          <td>{{ formatNumber(span.sag, 'm') }}</td>
        </tr>
        <tr>
          <th>Korridor</th>
          <td>{{ formatNumber(span.corridor, 'm') }}</td>
        </tr>
        <tr>
          <!-- prettier-ignore -->
          <th>
            r<sub>x</sub> / r<sub>s</sub> / r<sub>k</sub>
          </th>
          <td>
            {{ `${formatNumber(span.rx)} / ${formatNumber(span.rs)} / ${formatNumber(span.rk)}` }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
