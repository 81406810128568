<script setup lang="ts">
import CalculationModeSwitcher from '@/components/study-case/CalculationModeSwitcher.vue'
import { CalculationModes, StudyCaseWithConfig } from '@/model'
import AweOperationStatesForm from '@/views/project/study-cases/operation-states/AweOperationStatesForm.vue'
import FailureOperationStatesForm from '@/views/project/study-cases/operation-states/FailureOperationStatesForm.vue'
import { FormContext } from 'vee-validate'
import { computed, inject } from 'vue'
import ManualOperationStatesForm from './ManualOperationStatesForm.vue'

const form = inject('studyCaseForm') as FormContext<StudyCaseWithConfig>
const calculationMode = computed(() => form.values.configuration.modeSelected)
</script>

<template>
  <div class="operation-states-tab">
    <p class="text-gray-400">
      Die Betriebszustände beschreiben, welche Ströme auf den Leitern der Systeme wirken.
      Betriebszustände können manuell definiert oder auf Basis bestimmter Regeln automatisiert
      erzeugt werden.
    </p>

    <p-form-section title="Wie werden Betriebszustände definiert?">
      <!-- Calculation modes -->
      <CalculationModeSwitcher />
    </p-form-section>

    <!-- Manual -->
    <ManualOperationStatesForm v-if="calculationMode == CalculationModes.MANUAL" />
    <FailureOperationStatesForm v-else-if="calculationMode == CalculationModes.AUTO_FAIL" />
    <AweOperationStatesForm v-else-if="calculationMode == CalculationModes.AUTO_AWE" />
  </div>
</template>

<style scoped lang="css"></style>
