<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OverheadLineImportHint'
})
</script>

<template>
  <p>
    Um eine neue Freileitung hinzuzufügen, zeichnen Sie diese in der
    <router-link class="text-primary hover:underline" :to="{ name: 'project-map' }">
      Kartenansicht
    </router-link>
    oder nutzen den
    <span class="text-primary font-bold hover:underline cursor-pointer" @click="$emit('import')">
      Import.
    </span>
  </p>
</template>
