import { z } from 'zod'
import { LineTowerType, TowerType } from '@gridside/hsb-api'

const WirePosition = z.object({
  x: z.number(),
  y: z.number()
})

const TowerTypeBase = z.object({
  name: z.string(),
  project: z.string().nullish(),
  conductorPositions: z.array(WirePosition),
  earthwirePositions: z.array(WirePosition),
  lineTowerType: z.nativeEnum(LineTowerType)
})

export const TowerTypeSchema: z.ZodType<TowerType> = TowerTypeBase.extend({ id: z.string() })
