import { z } from 'zod'
import { HSBLibOverheadline, HSBLibOverheadlineSchema } from '@/config/schemas/hsb-lib/OverheadLine'
import { HSBLibCorridor, HSBLibCorridorSchema } from '@/config/schemas/hsb-lib/Corridor'
import { HSBLibConductor, HSBLibConductorSchema } from '@/config/schemas/hsb-lib/Conductor'
import {
  hasChangingAllocationConductors,
  hasChangingAllocationPositions,
  HSBLibTower,
  HSBLibTowerSchema
} from '@/config/schemas/hsb-lib/Tower'
import {
  HSBLibConductorType,
  HSBLibConductorTypeSchema
} from '@/config/schemas/hsb-lib/ConductorType'
import { HSBLibCurrent, HSBLibCurrentSchema } from '@/config/schemas/hsb-lib/Current'
import { Container, HSBGuiItemsEnum, HSBLibItemsEnum } from '@/config/schemas/hsb-lib/util'
import {
  ConductorAllocation,
  ConductorAllocationMapping,
  ConductorType,
  fromHSBConductorTypes,
  fromHSBLibTower,
  getSystemNameAndPos,
  getValidStudyCaseWithConfig,
  SystemWithConductorPositionInfo,
  TowerFromHSBPartial,
  TowerType
} from '@/model'
import { v4 } from 'uuid'
import { ConductorState, OperationState, OverheadLine, Span, StudyCase } from '@gridside/hsb-api'
import { TowerResponse } from '@gridside/hsb-api/dist/models/TowerResponse'

import { randomColor } from '@/util/colors'
import { importDateTimeString } from '@/util'

export const HSBLibModelsSchema = z.record(
  z.discriminatedUnion('objclass', [
    HSBLibOverheadlineSchema,
    HSBLibCorridorSchema,
    HSBLibTowerSchema,
    HSBLibConductorSchema,
    HSBLibConductorTypeSchema,
    HSBLibCurrentSchema
  ])
)
export type HSBLibModels = z.infer<typeof HSBLibModelsSchema>

/**
 * Extracts all HSBGui models from HSBLib models data.
 */
export function toHSBGuiModels(
  modelsHSBLibRaw: HSBLibModels,
  systemNameSeparator: string,
  projectId: string,
  file: File
): { containers: HSBGuiContainers; studyCase: StudyCase; overheadLine: OverheadLine } {
  const modelsHSBLib = initLibContainers(modelsHSBLibRaw)
  const modelsHSBGui = initGuiContainers(modelsHSBLib)
  const towersPartialContainer: Container<TowerFromHSBPartial> = {}

  /**
   * Puts System into given container and returns a Mapping
   */
  const putSystem = (conductorObjId: string): ConductorAllocationMapping => {
    const conductor = modelsHSBLib[HSBLibItemsEnum.Conductor][toId(conductorObjId)]
    if (!conductor) throw Error(`Conductor ${conductorObjId} fehlt!`)

    const current = modelsHSBLib[HSBLibItemsEnum.Current][toId(conductor.current)]
    if (!current) throw Error(`Current ${conductor.current} fehlt!`)

    const { name, position } = getSystemNameAndPos(current, systemNameSeparator)
    const condTypeObjId = toId(conductor.type)
    const condType = modelsHSBGui.conductorTypes[condTypeObjId]
    if (!condType) throw Error(`Conductor Type ${condTypeObjId} fehlt!`)

    /**
     * System - add or create new
     */
    const conductorState: ConductorState = {
      current: current.rms_current,
      angle: current.angle
    }
    if (modelsHSBGui.systems[name]) {
      // add
      modelsHSBGui.systems[name].conductorTypesPositions[position] = condType.id
      modelsHSBGui.systems[name].conductorTypes = Object.values(
        modelsHSBGui.systems[name].conductorTypesPositions
      )
      modelsHSBGui.systems[name].conductorStates[position] = conductorState
      modelsHSBGui.systems[name].wireCount = modelsHSBGui.systems[name].conductorTypes.length
    } else {
      // create new
      modelsHSBGui.systems[name] = {
        id: v4(),
        name,
        project: projectId,
        wireCount: 1,
        conductorTypesPositions: { [position]: condType.id },
        conductorTypes: [condType.id],
        conductorStates: { [position]: conductorState }, // used later for operational mode
        isolatorLength: 0,
        color: randomColor()
      }
    }
    return { system: modelsHSBGui.systems[name].id, index: position }
  }

  /**
   * Checks if given TowerType already exists with same positions and returns the unique one
   */
  const putTowerType = (towerTypeNew: TowerType) => {
    const hasSamePositions = Object.values(modelsHSBGui[HSBGuiItemsEnum.towerTypes]).find(
      (towerTypeOld) => {
        if (
          towerTypeOld.conductorPositions.length !== towerTypeNew.conductorPositions.length ||
          towerTypeOld.earthwirePositions.length !== towerTypeNew.earthwirePositions.length
        ) {
          return false
        }
        return (
          towerTypeOld.earthwirePositions.every(
            (value, index) =>
              value.x === towerTypeNew.earthwirePositions[index].x &&
              value.y === towerTypeNew.earthwirePositions[index].y
          ) &&
          towerTypeOld.conductorPositions.every(
            (value, index) =>
              value.x === towerTypeNew.conductorPositions[index].x &&
              value.y === towerTypeNew.conductorPositions[index].y
          )
        )
      }
    )

    if (hasSamePositions) {
      return hasSamePositions
    }

    modelsHSBGui[HSBGuiItemsEnum.towerTypes][towerTypeNew.id] = towerTypeNew
    return towerTypeNew
  }

  /**
   * Checks if given ConductorAllocation already exists with same mapping and returns the unique one
   */
  const putAllocation = (allocNew: ConductorAllocation) => {
    const hasSameMapping = Object.values(modelsHSBGui.allocations).find((allOld) => {
      if (allOld.mapping.length !== allocNew.mapping.length) {
        return false
      }
      return allOld.mapping.every(
        (value, index) =>
          value.system === allocNew.mapping[index].system &&
          value.index === allocNew.mapping[index].index
      )
    })

    if (hasSameMapping) {
      return hasSameMapping
    }

    modelsHSBGui.allocations[allocNew.id] = allocNew
    return allocNew
  }

  /**
   * After putSystem use mapping to create allocation
   */
  function makeAllocation(towerHsb: HSBLibTower, towerObjId: string, conductorObjIds: string[]) {
    const allocation: ConductorAllocation = makeBaseAllocation(towerHsb, towerObjId, projectId)

    allocation.mapping = conductorObjIds.map((conductorObjId) => putSystem(conductorObjId))

    return allocation
  }

  /**
   * Return conductor ids for earthwires
   */
  const getEarthwireConductorIds = (earthwireCondObjIds: string[]): string[] => {
    return earthwireCondObjIds.map((condObjId) => {
      const cond = modelsHSBLib[HSBLibItemsEnum.Conductor][toId(condObjId)]
      if (!cond) {
        throw Error(`Conductor ${toId(condObjId)} fehlt!`)
      }
      const el = modelsHSBGui.conductorTypes[toId(cond.type)]
      if (!el) {
        throw Error(`ConductorType ${toId(cond.type)} fehlt!`)
      }
      return el.id
    })
  }

  /**
   * OverheadLine
   */
  const overheadLine: OverheadLine = {
    id: v4(),
    name: `Importierte Freileitung vom ${importDateTimeString()}`,
    description: `${file.name} - Datei zuletzt bearbeitet ${importDateTimeString(
      file.lastModified
    )}`,
    project: projectId,
    towerCount: Object.keys(modelsHSBLib[HSBLibItemsEnum.Tower]).length
  }

  let towerIndex = 1
  for (const towerObjId of Object.keys(modelsHSBLib[HSBLibItemsEnum.Tower])) {
    const towerHsb = modelsHSBLib[HSBLibItemsEnum.Tower][towerObjId]

    /**
     * ConductorAllocation (in)
     */
    const conductorsIn = towerHsb.incoming_phase_conductors_objects.slice()
    const allocationIn = putAllocation(makeAllocation(towerHsb, towerObjId, conductorsIn))

    /**
     * TowerType (in)
     */
    const towerTypeIn: TowerType = putTowerType(fromHSBLibTower(towerHsb, 'in'))
    towerTypeIn.project = projectId
    modelsHSBGui.towerTypes[towerTypeIn.id] = towerTypeIn

    /**
     * Tower
     */
    const towerPartial: TowerFromHSBPartial = {
      id: v4(),
      overheadLine: overheadLine.id,
      name: `${towerHsb.name.trim()} (${towerObjId.trim()})`,
      in: {
        type: towerTypeIn.id,
        allocation: allocationIn.id,
        earthwires: getEarthwireConductorIds(towerHsb.incoming_earthwire_objects),
        offset: 0
      },
      earthResistivity: towerHsb.earth_resistivity,
      position: towerIndex++ // after assignment increase index
    }

    // if (hasChangingAllocationPositions(towerHsb)) {
    //   throw Error(
    //     `Die ein- und ausgehenden Positionen am Tower ${towerObjId} sind nicht identisch!`
    //   )
    // }

    if (hasChangingAllocationConductors(towerHsb) || hasChangingAllocationPositions(towerHsb)) {
      /**
       * TowerType (out)
       */
      const towerTypeOut: TowerType = putTowerType(fromHSBLibTower(towerHsb, 'out'))
      towerTypeOut.project = projectId
      modelsHSBGui.towerTypes[towerTypeOut.id] = towerTypeOut

      /**
       * ConductorAllocation (out)
       */
      const conductorsOut = towerHsb.outgoing_phase_conductors_objects.slice()
      const allocationOut = putAllocation(makeAllocation(towerHsb, towerObjId, conductorsOut))

      // update allocation names
      allocationIn.name += ' (ingoing)'
      allocationOut.name += ' (outgoing)'

      // update tower
      towerPartial.out = {
        allocation: allocationOut.id,
        type: towerTypeOut.id,
        earthwires: getEarthwireConductorIds(towerHsb.outgoing_earthwire_objects),
        offset: 0
      }
    }

    towersPartialContainer[towerObjId] = towerPartial
  }

  const overheadLineKeys = Object.keys(modelsHSBLib[HSBLibItemsEnum.OverheadLine])
  const overheadLineObjId = overheadLineKeys[0]
  const overheadLineHSB = modelsHSBLib[HSBLibItemsEnum.OverheadLine][overheadLineObjId]
  if (overheadLineKeys.length != 1 || !overheadLineHSB) {
    throw Error(
      `Es wurden ${overheadLineKeys.length} OverheadLine gefunden. Es wird genau eins benötigt.`
    )
  }

  /**
   * Apply tower coords from OverheadLine
   */
  for (const index in overheadLineHSB.list_of_towers) {
    const towerObjId = overheadLineHSB.list_of_towers[index]
    const tower = towersPartialContainer[toId(towerObjId)]
    if (!tower) {
      throw Error(`Tower ${towerObjId} für OverheadLine fehlt!`)
    }
    const coords = overheadLineHSB.coordinate_list[index]
    if (!coords) {
      throw Error(`Koordinaten für Tower ${towerObjId} fehlt! Index: ${index}`)
    }
    modelsHSBGui.towers[toId(towerObjId)] = {
      ...tower,
      x: coords[0],
      y: coords[1]
    }
  }

  /**
   * Study Case
   */
  const operationStates: OperationState[] = []
  for (const system of Object.values(modelsHSBGui.systems)) {
    operationStates.push({
      conductorStates: Object.values(system.conductorStates),
      system: system.id
    })
  }

  const studyCase = getValidStudyCaseWithConfig()
  studyCase.name = `Importierter Berechnungsfall vom ${importDateTimeString()}`
  studyCase.description = `Importierter Berechnungsfall vom ${importDateTimeString()}`
  studyCase.operationStates = operationStates
  studyCase.superposition = operationStates.map((_, i) => `op_${i + 1}`).join(' + ')

  /**
   * Corridor Checks
   */
  const corridorKeys = Object.keys(modelsHSBLib[HSBLibItemsEnum.Corridor])
  const corridorObjId = toId(overheadLineHSB.corridor)
  const corridor = modelsHSBLib[HSBLibItemsEnum.Corridor][corridorObjId]
  if (corridorKeys.length != 1) {
    throw Error(`Es wurden ${corridorKeys.length} Corridor gefunden. Es wird genau eins benötigt.`)
  }
  if (!corridor) {
    throw Error(`Corridor ${corridorObjId} von OverheadLine ${overheadLineObjId} fehlt`)
  }

  const corridorCount = corridor.width.length
  if (
    corridorCount !== overheadLineHSB.list_of_rs.length ||
    corridorCount !== overheadLineHSB.list_of_rk.length ||
    corridorCount !== overheadLineHSB.list_of_rx.length ||
    corridorCount !== overheadLineHSB.list_of_sags.length ||
    corridorCount !== overheadLineHSB.list_of_towers.length - 1
  ) {
    throw Error(
      'Anzahl von Corridor width stimmt nicht mit länge der Listen in OverheadLine überein!'
    )
  }

  /**
   * Spans
   */
  for (const index in corridor.width) {
    const span: Span = {
      id: v4(),
      overheadLine: overheadLine.id,
      beginTower: modelsHSBGui.towers[toId(overheadLineHSB.list_of_towers[index])].id,
      endTower: modelsHSBGui.towers[toId(overheadLineHSB.list_of_towers[parseInt(index) + 1])].id,
      corridor: corridor.width[index],
      sag: overheadLineHSB.list_of_sags[index],
      rs: overheadLineHSB.list_of_rs[index],
      rk: overheadLineHSB.list_of_rk[index],
      rx: overheadLineHSB.list_of_rx[index]
    }
    modelsHSBGui.spans[span.id] = span
  }

  return {
    containers: modelsHSBGui,
    studyCase: studyCase,
    overheadLine
  }
}

export type HSBLibContainers = {
  [HSBLibItemsEnum.ConductorType]: Container<HSBLibConductorType>
  [HSBLibItemsEnum.OverheadLine]: Container<HSBLibOverheadline>
  [HSBLibItemsEnum.Corridor]: Container<HSBLibCorridor>
  [HSBLibItemsEnum.Conductor]: Container<HSBLibConductor>
  [HSBLibItemsEnum.Tower]: Container<HSBLibTower>
  [HSBLibItemsEnum.Current]: Container<HSBLibCurrent>
}

/**
 * Sort object values into containers
 */
export function initLibContainers(hsbObjects: HSBLibModels): HSBLibContainers {
  const hsbContainers: HSBLibContainers = {
    [HSBLibItemsEnum.ConductorType]: {},
    [HSBLibItemsEnum.OverheadLine]: {},
    [HSBLibItemsEnum.Corridor]: {},
    [HSBLibItemsEnum.Conductor]: {},
    [HSBLibItemsEnum.Tower]: {},
    [HSBLibItemsEnum.Current]: {}
  }
  for (const key in hsbObjects) {
    const hsbObject = hsbObjects[key]
    if (hsbObject.objclass in hsbContainers) {
      hsbContainers[hsbObject.objclass][key] = hsbObject
    }
  }

  return hsbContainers
}

export type HSBGuiContainers = {
  [HSBGuiItemsEnum.systems]: Container<SystemWithConductorPositionInfo>
  [HSBGuiItemsEnum.allocations]: Container<ConductorAllocation>
  [HSBGuiItemsEnum.towers]: Container<TowerResponse>
  [HSBGuiItemsEnum.towerTypes]: Container<TowerType>
  [HSBGuiItemsEnum.spans]: Container<Span>
  [HSBGuiItemsEnum.conductorTypes]: Container<ConductorType>
}

let allocationCount = 1

function initGuiContainers(hsbLibContainer: HSBLibContainers): HSBGuiContainers {
  allocationCount = 1
  return {
    systems: {},
    allocations: {},
    towers: {},
    towerTypes: {},
    spans: {},
    conductorTypes: fromHSBConductorTypes(hsbLibContainer[HSBLibItemsEnum.ConductorType]) // keys are objIds
  }
}

/**
 * Create ConductorAllocation without mapping
 */
function makeBaseAllocation(
  towerHsb: HSBLibTower,
  towerObjId: string,
  project: string
): ConductorAllocation {
  return {
    id: v4(),
    name: `Leiterzuordnung ${allocationCount++}`,
    project,
    mapping: []
  }
}

/**
 *  "obj::140065965365120" | "140065965365120" -> "140065965365120"
 */
function toId(str: string) {
  if (str.includes('::')) {
    return str.split('::')[1]
  }
  return str
}
