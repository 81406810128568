import { FieldConfig } from '@prionect/ui/dist/types/components/form/types'
import { MediaGroup } from '@/model'

const fields: Record<
  keyof Omit<MediaGroup, 'id' | 'mediaGeometry' | 'project'> | 'pipeType' | 'rhoE',
  FieldConfig
> = {
  name: {
    name: 'name',
    label: 'Name',
    required: true
  },
  type: {
    name: 'type',
    label: 'Typ',
    type: 'select',
    items: []
  },
  pipeType: {
    name: 'pipeType',
    label: 'Rohrleitungstyp',
    type: 'select',
    items: [],
    required: true
  },
  height: {
    name: 'height',
    label: 'Höhe / Tiefe',
    type: 'number',
    step: 1,
    unit: 'm',
    required: true
  },
  // diameter: {
  //   name: 'diameter',
  //   label: 'Innendurchmesser Rohr',
  //   type: 'number',
  //   step: 0.1,
  //   unit: 'm',
  //   required: true
  // },
  // muR: {
  //   name: 'muR',
  //   label: 'Relative Permeabilität µr',
  //   type: 'number',
  //   min: 0,
  //   step: 0.1,
  //   required: true
  // },
  // epsR: {
  //   name: 'epsR',
  //   label: 'Relative Permeabilität Umhüllung ɛr',
  //   type: 'number',
  //   min: 0,
  //   step: 0.1,
  //   required: true
  // },
  // materialResistivity: {
  //   name: 'materialResistivity',
  //   label: 'Spezifischer Widerstand Rohr ρ',
  //   type: 'number',
  //   min: 0,
  //   step: 0.1,
  //   unit: 'Ωm',
  //   required: true
  // },
  // alpha: {
  //   name: 'alpha',
  //   label: 'Temperaturkoeffizient',
  //   type: 'number',
  //   min: 0,
  //   unit: '1/K',
  //   required: true
  // },
  rhoE: {
    name: 'rhoE',
    label: 'Spezifischer Widerstand Erde ρE',
    type: 'number',
    min: 0,
    unit: 'Ωm',
    required: true
  }
  // coatingResistance: {
  //   name: 'coatingResistance',
  //   label: 'Umhüllungswiderstand ru',
  //   type: 'number',
  //   min: 0,
  //   step: 0.1,
  //   unit: 'Ωm²',
  //   required: true
  // },
  // coatingThickness: {
  //   name: 'coatingThickness',
  //   label: 'Dicke der Umhüllung',
  //   type: 'number',
  //   min: 0,
  //   step: 0.1,
  //   unit: 'm',
  //   required: true
  // }
}

export default fields
