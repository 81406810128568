<script lang="ts">
import { HsbApi } from '@/api'
import FileInputResettable from '@/components/common/FileInputResettable.vue'
import ImportErrors from '@/components/project/import/ImportErrors.vue'
import ImportPreview from '@/components/project/import/ImportPreview.vue'
import ImportProgress from '@/components/project/import/ImportProgress.vue'
import ImportSuccess from '@/components/project/import/ImportSuccess.vue'
import { useBulkRequests } from '@/composables/useBulkRequests'
import useFileReader from '@/composables/useFileReader'
import { project } from '@/config/fields'
import { ProjectExportResponseSchema } from '@/config/schemas/project-export-schema'
import { useUserStore } from '@/stores/user'
import { ImportProjectRequestFromJSON, ProjectExportResponse } from '@gridside/hsb-api'
import { defineComponent, ref } from 'vue'
import { ZodError } from 'zod'

export default defineComponent({
  name: 'ProjectImportDialog',
  components: {
    FileInputResettable,
    ImportSuccess,
    ImportProgress,
    ImportPreview,
    ImportErrors
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    fieldConfig: project,
    ZodError: ZodError
  }),
  emits: ['update:model-value'],

  setup() {
    const parsedData = ref<null | ProjectExportResponse>(null)
    const errors = ref<Array<string | Error | ZodError>>([])
    const success = ref(false)
    const importing = ref(false)

    const bulkRequests = useBulkRequests((item: ProjectExportResponse) =>
      HsbApi.projects.importProject(item as any)
    )

    const handleFileData = (data: string | ArrayBuffer | null) => {
      try {
        parsedData.value = null
        const fileJsonContent: object | any = JSON.parse(data as string)
        // Prevent iterating on null
        if (typeof fileJsonContent !== 'object') {
          errors.value.push('Datei kann nicht gelesen werden, kein gültiges JSON-Format.')
          return
        }

        // apply basic transform
        const fileJsonData = ImportProjectRequestFromJSON(fileJsonContent)

        // run zod validations
        parsedData.value = ProjectExportResponseSchema.parse(fileJsonData)
      } catch (e) {
        if (e instanceof SyntaxError) {
          errors.value.push('Datei kann nicht gelesen werden, kein gültiges JSON-Format.')
        } else {
          errors.value.push(e as Error)
        }
      }
    }

    /**
     * Reset errors and parsed data
     */
    function reset() {
      parsedData.value = null
      errors.value = []
      success.value = false
      importing.value = false
      bulkRequests.progress.reset()
    }

    return {
      errors,
      importing,
      parsedData,
      reset,
      success,
      userStore: useUserStore(),
      useBulkRequests: bulkRequests,
      useFileReader: useFileReader(handleFileData, errors)
    }
  },

  methods: {
    doClose() {
      this.reset()
      this.$emit('update:model-value', false)
    },

    onFileChange(file: File | undefined) {
      this.reset()
      if (file) {
        this.useFileReader.onFileChange(file)
      }
    },

    /**
     * Send parsed data to backend
     */
    async handleImport() {
      this.errors = []
      this.importing = true
      if (!this.parsedData) {
        return
      }
      await this.useBulkRequests.doRequests([this.parsedData])
      if (!this.useBulkRequests.progress.errors.length) {
        this.success = true
      } else {
        this.errors = this.useBulkRequests.progress.errors
      }
      this.importing = false
    }
  }
})
</script>

<template>
  <PDialog class="!max-w-none" :show="modelValue" title="Projekt importieren" @close="doClose">
    <div class="flex h-full gap-8">
      <file-input-resettable
        accept=".json"
        dropzone-class="h-full w-96 justify-center flex flex-col"
        @change="onFileChange"
      />
      <div class="flex-1 h-[60vh] w-[40vw] min-w-[30ch]">
        <transition name="fade" mode="out-in">
          <ImportProgress v-if="importing" :progress="useBulkRequests.progress" />
          <ImportErrors v-else-if="errors.length > 0" :errors />
          <ImportSuccess v-else-if="success" />
          <ImportPreview v-else-if="parsedData" :data="parsedData" />
          <div
            v-else
            class="bg-gray-50 rounded border border-gray-200 flex items-center justify-center text-gray-400 text-sm h-full"
          >
            Bitte wählen Sie links eine Datei zum Importieren aus.
          </div>
        </transition>
      </div>
    </div>

    <template #footer="{ close }">
      <p-btn v-if="!parsedData || errors.length > 0 || success" @click="close">Schließen</p-btn>
      <p-btn
        v-if="!errors.length && !success"
        type="primary"
        :disabled="!parsedData"
        @click="handleImport"
      >
        Projekt importieren
      </p-btn>
    </template>
  </PDialog>
</template>
