import { useProjectStore } from '@/stores/project'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { RouteParams } from '@/router/routeParams'

export function useProject() {
  const router = useRouter()
  const projectStore = useProjectStore()

  const projectId = computed(
    () => router.currentRoute.value?.params[RouteParams.ProjectId] as string
  )

  const project = computed(
    () => (projectStore.loaded && projectStore.findById(projectId.value)) || undefined
  )

  return { project, projectId }
}
