import { WebSocket } from '@/api'
import { ProjectId } from '@/model'

type ResultInvalidatedEvent = { project: ProjectId }

/**
 * API class that extends the generated REST API with websocket functions
 */
export class ResultExtendedApi {
  constructor(public webSocket: WebSocket) {}

  onInvalidated(handler: (data: ResultInvalidatedEvent) => void) {
    return this.webSocket.subscribe('Result.invalidated', handler)
  }
}
