import { FeatureLike } from 'ol/Feature'
import { Fill, Stroke, Style, Text } from 'ol/style'
import constants from '@/components/map/style/constants'
import { constrainedSize, lineWidth, shimWidth, State } from '@/components/map/style/util'
import { colorsByState } from './util'
import { FeaturePropEnum } from '@/components/map/LayerItemConfig'

export function overheadLineStyle(
  feature: FeatureLike,
  resolution: number,
  state: State = 'default'
) {
  const baseStyle = new Style({
    stroke: new Stroke({
      width: lineWidth(resolution),
      color: colorsByState[state]()
    }),
    text: new Text({
      placement: 'line',
      textAlign: 'center',
      repeat: 500,
      text: feature.get(FeaturePropEnum.name),
      offsetY: 16,
      font: `${constrainedSize(13, 14, resolution)}px ${constants.fontFamily}`,
      fill: new Fill({ color: colorsByState[state]() })
    })
  })

  const shimStrokeStyle = new Style({
    stroke: new Stroke({
      width: 2 * shimWidth(resolution),
      color: colorsByState[state](0.2)
    })
  })

  if (state === 'default') {
    return [baseStyle]
  } else {
    return [shimStrokeStyle, baseStyle]
  }
}
