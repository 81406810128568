import { useProgress } from '@/composables/useProgress'
import { ResponseError } from '@gridside/hsb-api'
import { AxiosError } from 'axios'
import { toAxiosErrorFromErrorResponse } from '@/util'

/**
 * Perform same callback to multiple items and track their progress
 */
export function useBulkRequests<Payload, Response>(callback: (item: Payload) => Promise<Response>) {
  const progress = useProgress()

  /**
   * Executes the callback on all items and updates process values accordingly
   * @param items
   */
  async function doRequests(items: Payload[]) {
    progress.reset()
    progress.of = items.length
    progress.pending = true

    // add min progress
    await new Promise((res) => setTimeout(res, 500))

    const jobs: Promise<Response | Error>[] = []

    for (const item of items) {
      jobs.push(
        callback(item)
          .then((res) => {
            return res
          })
          .catch(async (e: Error) => {
            let error: Error | AxiosError = e

            // creat "mock" Axios error because of async json() in ResponseError
            if (e instanceof ResponseError) {
              error = await toAxiosErrorFromErrorResponse(e)
            }
            progress.errors.push(error)
            return e
          })
          .finally(() => {
            progress.done++
          })
      )
    }
    const results = await Promise.all(jobs)
    progress.pending = false
    return results
  }

  return {
    doRequests,
    progress
  }
}
