import { WebSocket } from '@/api'
import { Configuration, ConductorTypesApi } from '@gridside/hsb-api'

/**
 * API class that extends the generated REST API with websocket functions
 */
export class ConductorTypesExtendedApi extends ConductorTypesApi {
  constructor(configuration: Configuration, public webSocket: WebSocket) {
    super(configuration)
  }

  onConductorTypeUpdated(handler: (data: any) => void) {
    return this.webSocket.subscribe('ConductorType.updated', handler)
  }

  onConductorTypeDeleted(handler: (data: any) => void) {
    return this.webSocket.subscribe('ConductorType.deleted', handler)
  }
}
