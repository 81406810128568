<script lang="ts">
import ConductorTypeSelectField from '@/components/conductor-type/ConductorTypeSelectField.vue'
import { useMapSelection } from '@/components/map/composables'
import TowerTypeSelectField from '@/components/tower-type/TowerTypeSelectField.vue'
import TowerAllocationSelectField from '@/components/tower/TowerAllocationSelectField.vue'
import TowerSVGMapped from '@/components/tower/TowerSVGMapped.vue'
import { useProject } from '@/composables/useProject'
import towerFields, { fieldsInOut } from '@/config/fields/tower'
import { useOverheadLineStore } from '@/stores/overhead-lines'
import { useTowerTypeStore } from '@/stores/tower-type'
import { OverheadLine, Tower, TowerResponse } from '@gridside/hsb-api'
import deepmerge from 'deepmerge'
import { defineComponent, PropType } from 'vue'

type FormItem = {
  name: Tower['name']
  earthResistivity: Tower['earthResistivity']
  towers: Tower[]
}
export default defineComponent({
  name: 'BranchTowerForm',
  components: {
    ConductorTypeSelectField,
    TowerAllocationSelectField,
    TowerTypeSelectField,
    TowerSVGMapped
  },

  props: {
    tower: {
      type: Object as PropType<TowerResponse>,
      required: true
    }
  },
  emits: ['submit'],

  setup() {
    const { projectId } = useProject()
    return {
      mapSelection: useMapSelection(),
      overheadLineStore: useOverheadLineStore(),
      towerTypeStore: useTowerTypeStore(),
      projectId
    }
  },

  data: () => ({
    earthWiresInCount: [] as number[],
    earthWiresOutCount: [] as number[],
    hasChangingAllocation: true,
    towerFieldsInOut: fieldsInOut,
    towerFields
  }),

  computed: {
    formItem(): FormItem {
      return {
        name: this.tower.name,
        earthResistivity: this.tower.earthResistivity,
        towers: this.towers.map((tower) => ({
          ...tower,
          changingAllocation: tower.out !== undefined
        }))
      }
    },

    overheadLines(): OverheadLine[] {
      return this.towers
        .map((tower) => this.overheadLineStore.findById(tower.overheadLine))
        .filter((item) => item !== undefined)
        .map((item) => item!.overheadLine)
    },

    overheadLineTabs() {
      return this.overheadLines.map((item) => ({ id: item.id, label: item.name }))
    },

    towers(): TowerResponse[] {
      const towers = [this.tower]
      const relatedTowers = this.tower.relatedTowers || []
      relatedTowers.forEach((relatedTowerItem) => {
        if (relatedTowerItem.tower) {
          const tower = this.overheadLineStore.findTowerById(relatedTowerItem.tower!)
          if (tower) {
            towers.push(tower)
          }
        }
      })
      return towers
        .map((tower) => deepmerge({}, tower))
        .sort((a, b) => {
          // sort towers by overheadline names
          const overheadLineA = this.overheadLineStore.findById(a.overheadLine)
          const overheadLineB = this.overheadLineStore.findById(b.overheadLine)
          return (overheadLineA?.overheadLine.name || '').localeCompare(
            overheadLineB?.overheadLine.name || ''
          )
        })
    }
  },

  methods: {
    onSubmit(formData: FormItem) {
      // transform our integrated form data into distinct towers
      const towers = formData.towers.map((tower) => ({
        ...tower,
        name: formData.name,
        earthResistivity: formData.earthResistivity
      }))
      this.$emit('submit', towers)
    }
  }
})
</script>

<template>
  <p-form
    v-if="towerTypeStore.loadedProject === projectId"
    v-slot="{ values, setFieldValue }"
    cancelable
    :item="formItem"
    :key="tower.id"
    resettable
    @submit="onSubmit"
    v-bind="$attrs"
  >
    <p-field v-bind="towerFields.name" dense />
    <p-field v-bind="towerFields.earthResistivity" dense />
    <p-tabs :tabs="overheadLineTabs" class="mt-4">
      <template
        v-for="(overheadLine, index) in overheadLines"
        :key="overheadLine.id + formItem.towers[0].id"
        #[`tab:${overheadLine.id}`]
      >
        <p-field
          type="switch"
          :name="`towers[${index}].changingAllocation`"
          :model-value="values.towers[index].changingAllocation"
          @change="(v:boolean) => setFieldValue(`towers[${index}].changingAllocation`, v) && (values.towers[index].out = towers[index].out)"
          standalone
          label="Belegung wechselt (hybrider Mast)"
        />

        <TowerSVGMapped
          class="mb-4"
          :tower="
            values.towers[index].changingAllocation
              ? values.towers[index]
              : { ...values.towers[index], out: undefined }
          "
        />

        <div
          class="grid gap-x-6 gap-y-3 mb-4"
          :class="values.towers[index].changingAllocation ? 'grid-cols-2' : 'grid-cols-1'"
        >
          <template v-if="values.towers[index].changingAllocation">
            <div class="font-semibold py-1 border-b mb-3 text-sm col-span-1">eingehend</div>
            <div class="font-semibold py-1 border-b mb-3 text-sm col-span-1">ausgehend</div>
          </template>

          <!-- IN-->
          <div>
            <p-field
              v-bind="towerFieldsInOut.in.offset"
              :name="`towers[${index}].in.offset`"
              dense
            />
            <!-- type -->
            <TowerTypeSelectField
              v-bind="towerFieldsInOut.in.type"
              :name="`towers[${index}].in.type`"
              dense
              v-model:earthwire-count="earthWiresInCount[index]"
            />
            <!-- conductor allocation -->
            <TowerAllocationSelectField
              v-bind="towerFieldsInOut.in.allocation"
              :name="`towers[${index}].in.allocation`"
            />
            <!-- earthwire allocation -->
            <ConductorTypeSelectField
              v-for="n in earthWiresInCount[index]"
              :key="n"
              :field-config="towerFieldsInOut.in.earthwires"
              :index="n - 1"
              :conductors="values.towers[index].in.earthwires"
              :name="`towers[${index}].in.earthwires[${n - 1}]`"
              dense
            />
          </div>

          <!-- OUT -->
          <div v-if="values.towers[index].changingAllocation">
            <!-- offset  -->
            <p-field
              v-bind="towerFieldsInOut.out.offset"
              :name="`towers[${index}].out.offset`"
              dense
            />
            <!-- type -->
            <TowerTypeSelectField
              v-bind="towerFieldsInOut.out.type"
              v-model:earthwire-count="earthWiresOutCount[index]"
              :name="`towers[${index}].out.type`"
              dense
            />
            <!-- conductor allocation -->
            <TowerAllocationSelectField
              v-bind="towerFieldsInOut.out.allocation"
              :name="`towers[${index}].out.allocation`"
            />
            <!-- earthwire allocation -->
            <ConductorTypeSelectField
              v-for="n in earthWiresOutCount[index]"
              :key="n"
              :field-config="{ ...towerFieldsInOut.out.earthwires }"
              :index="n - 1"
              :conductors="values.towers[index].out.earthwires"
              dense
              :name="`towers[${index}].out.earthwires[${n - 1}]`"
            />
          </div>
        </div>
      </template>
    </p-tabs>
  </p-form>
</template>

<style scoped lang="css"></style>
