<script lang="ts">
import { useProject } from '@/composables/useProject'
import { HsbLibObjectEnum } from '@/model/calculation'
import { RouteParams } from '@/router/routeParams'
import { useCalculationStore } from '@/stores/calculation'
import { useProjectStore } from '@/stores/project'
import { useStudyCaseStore } from '@/stores/study-case'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CalculationResult',

  data() {
    return {
      HsbLibObjectEnum: HsbLibObjectEnum,
      pendingDownload: false
    }
  },
  computed: {
    calculation() {
      return this.calculationStore.current
    },
    limitVoltage() {
      const studyCaseId = this.calculationStore.selectedStudyCase
      if (studyCaseId) {
        const studyCase = this.studyCaseStore.findById(studyCaseId)
        return studyCase?.limitVoltage
      }
      return undefined
    },
    isPipeProject() {
      const project = this.projectStore.findById(
        this.$route.params[RouteParams.ProjectId] as string
      )
      return project?.mediaType === 'pipe'
    },
    result() {
      return this.calculationStore.result
    }
  },

  setup() {
    const { projectId, project } = useProject()
    return {
      calculationStore: useCalculationStore(),
      projectStore: useProjectStore(),
      studyCaseStore: useStudyCaseStore(),
      projectId,
      project
    }
  }
})
</script>

<template>
  <div>
    <div v-if="result" class="text-center my-3">
      <div class="text-base text-gray-600 font-medium mb-4 px-4 leading-snug">
        <template v-if="isPipeProject">maximales Rohrleitungspotenzial:</template>
        <template v-else>induzierte Längsspannung:</template>
      </div>
      <div
        v-if="result.resultVoltage !== undefined && limitVoltage"
        :class="result.resultVoltage > limitVoltage ? 'text-error-500' : 'text-success-600'"
        class="mt-2 font-bold text-2xl"
      >
        {{ $n(result.resultVoltage, { maximumFractionDigits: 1 }) }} V
      </div>
      <div v-if="limitVoltage" class="font-semibold text-gray-400">
        Grenzwert: {{ $n(limitVoltage, { maximumFractionDigits: 1 }) }} V
      </div>
    </div>
    <div v-if="calculation" class="text-center">
      <div
        v-if="calculation.status === 'ERROR'"
        class="text-error-500 font-semibold my-2 bg-error-100 p-2 px-3"
      >
        Bei der Berechnung ist ein Fehler aufgetreten (siehe Log).
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped></style>
