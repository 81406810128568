<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ImportSuccess'
})
</script>

<template>
  <div class="flex items-center justify-center h-full w-full">
    <div class="flex flex-col items-center">
      <p-success-icon class="text-success-500 w-16 mb-4" />
      <p class="text-success-600 text-lg">Der Import war erfolgreich.</p>
    </div>
  </div>
</template>
