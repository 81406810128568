<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MapControlHeading',
  props: {
    expanded: {
      type: Boolean,
      default: false
    },
    title: String
  }
})
</script>

<template>
  <div class="section-heading">
    <div class="flex-1 leading-none">
      <slot></slot>
    </div>
    <div class="!leading-none mt-1">
      <el-icon size="20" class="!leading-none">
        <ChevronDownIcon
          class="transition-transform duration-100 ease-in"
          :class="{ 'rotate-180': expanded }"
        />
      </el-icon>
    </div>
  </div>
</template>

<style scoped lang="css">
.section-heading {
  @apply flex h-full items-center text-sm font-semibold text-gray-500 uppercase text-left hover:text-gray-700 transition-colors tracking-wide cursor-pointer;
}
</style>
