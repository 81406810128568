<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import { RouteLocationRaw } from 'vue-router'
import { useOverheadLineStore } from '@/stores/overhead-lines'

export default defineComponent({
  name: 'MenuItem',

  props: {
    to: {
      type: [String, Object] as PropType<RouteLocationRaw | string>,
      required: false
    }
  },
  setup() {
    return { overheadLineStore: useOverheadLineStore() }
  }
})
</script>

<template>
  <div>
    <router-link
      :to="to ?? ''"
      class="menu-item block px-2 py-1 hover:bg-gray-200 transition-colors"
      :class="{ disabled: !to }"
    >
      <slot></slot>
    </router-link>
    <div v-if="$slots['nested']" class="ml-4">
      <slot name="nested"></slot>
    </div>
  </div>
</template>

<style scoped lang="css">
.menu-item {
  transition-property: background-color;
}
.menu-item.router-link-active {
  @apply text-primary font-bold rounded;
}
.disabled {
  color: #999999 !important;
  cursor: not-allowed;
}
</style>
