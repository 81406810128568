import z from 'zod'
import { RelatedTowerItem, Tower, TowerDirectionProperties } from '@gridside/hsb-api'

const TowerDirectionPropertiesZod: z.ZodType<TowerDirectionProperties> = z.object({
  allocation: z.string(),
  earthwires: z.array(z.string()),
  offset: z.number().optional(),
  type: z.string()
})

const RelatedTowerItemSchema: z.ZodType<RelatedTowerItem> = z.object({
  overheadLine: z.string().optional(),
  tower: z.string().optional()
})

const TowerBase = z.object({
  earthResistivity: z.number().optional(),
  in: TowerDirectionPropertiesZod,
  out: TowerDirectionPropertiesZod.nullish(),
  name: z.string().optional(),
  overheadLine: z.string(),
  position: z.number().optional(),
  relatedTowers: z.array(RelatedTowerItemSchema).optional(),
  x: z.number(),
  y: z.number()
})

export const TowerBaseSchema: z.ZodType<Tower> = TowerBase.extend({ id: z.string() })
