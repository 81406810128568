import { useOffsetSegments } from '@/components/map/layer/systems/useOffsetSegments'
import { SystemsLayerContext } from '@/components/map/layer/util'
import { useProject } from '@/composables/useProject'
import { SystemId } from '@/model'
import { useSystemStore } from '@/stores/system'
import { Feature, FeatureCollection, LineString, MultiLineString, Position } from 'geojson'
import { storeToRefs } from 'pinia'
import { computed, ComputedRef, watch } from 'vue'
import { systemsData } from './useSystemsData'

/**
 * Generates a GeoJSON FeatureCollection that visualizes the system allocations to overhead lines
 */
export function useSystemLines(
  ctx: SystemsLayerContext
): ComputedRef<FeatureCollection<MultiLineString>> {
  const systemStore = useSystemStore()
  const { loaded } = storeToRefs(systemStore)

  const { projectId } = useProject()
  watch(
    projectId,
    () => {
      systemStore.ensureLoaded(projectId.value)
    },
    { immediate: true }
  )

  return computed(() => {
    const offsetSegments = useOffsetSegments(ctx).value
    const segmentsBySystem: Record<SystemId, Feature<LineString>[]> = {}

    offsetSegments.features.forEach((offsetSegment) => {
      const { overheadLine, side, part, span, index } = offsetSegment.properties
      const overheadLineSpans = systemsData.value[overheadLine]?.spans || []
      const spanItem = overheadLineSpans[span]

      if (spanItem && spanItem[part] && spanItem[part][side][index]) {
        const systemId = spanItem[part][side][index]
        if (!segmentsBySystem[systemId]) {
          segmentsBySystem[systemId] = []
        }
        segmentsBySystem[systemId].push(offsetSegment)
      }
    })

    const systemFeatures: FeatureCollection<MultiLineString> = {
      type: 'FeatureCollection',
      features: []
    }

    for (const systemId in segmentsBySystem) {
      const multiLineString = segmentsBySystem[systemId].reduce<Position[][]>((result, feature) => {
        result.push(feature.geometry.coordinates)
        return result
      }, [])

      if (loaded.value) {
        const system = systemStore.findById(systemId)
        systemFeatures.features.push({
          type: 'Feature',
          id: systemId,
          geometry: { type: 'MultiLineString', coordinates: multiLineString },
          properties: { _type: 'system', ...system }
        })
      }
    }

    return systemFeatures
  })
}
