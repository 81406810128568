import z from 'zod'
import { ConductorAllocationSchema } from '@/config/schemas/conductor-allocation-schema'
import { TowerTypeSchema } from '@/config/schemas/tower-type-schema'
import { ConductorTypeSchema } from '@/config/schemas/conductor-type-schema'
import { MediaGroupSchema } from '@/config/schemas/media-group-schema'
import { PipeTypeSchema } from '@/config/schemas/pipe-type-schema'
import { SystemSchema } from '@/config/schemas/system-schema'
import { HsbLibParameterSchema } from '@/config/schemas/hsb-lib-parameters-schema'
import { OverheadLineExportSchema } from '@/config/schemas/overhead-line'
import {
  ProjectExportData,
  ProjectExportResponse,
  ProjectExportResponseMediaType
} from '@gridside/hsb-api'
import { StudyCaseSchema } from '@/config/schemas/study-case'

const ProjectExportDataSchema: z.ZodType<ProjectExportData> = z.object({
  conductorAllocation: z.array(ConductorAllocationSchema).optional(),
  conductorType: z.array(ConductorTypeSchema).optional(),
  mediaGroup: z.array(MediaGroupSchema).optional(),
  studyCase: z.array(StudyCaseSchema).optional(),
  overheadLine: z.array(OverheadLineExportSchema).optional(),
  pipeType: z.array(PipeTypeSchema).optional(),
  system: z.array(SystemSchema).optional(),
  towerType: z.array(TowerTypeSchema).optional()
})

export const ProjectExportResponseSchema: z.ZodType<ProjectExportResponse> = z.object({
  id: z.string(),
  apiVersion: z.string(),

  crs: z.string().optional(),
  customer: z.string().optional(),
  hsblibParameter: HsbLibParameterSchema.nullish(),
  mediaType: z.nativeEnum(ProjectExportResponseMediaType),
  name: z.string(),
  users: z.string().array(),

  createdAt: z.coerce.date(), // Use new Date() - See https://zod.dev/?id=coercion-for-primitives
  createdBy: z.string(),
  extent: z.number().array(),
  locked: z.boolean(),
  lockedBy: z.string().nullable(),
  updatedAt: z.coerce.date(), // Use new Date() - See https://zod.dev/?id=coercion-for-primitives
  updatedBy: z.string(),

  data: ProjectExportDataSchema.optional()
})
