<script lang="ts">
import EntityTableView from '@/components/common/EntityTableView.vue'
import { MediaGroup, MediaGroupId, ProjectId } from '@/model'
import { useMediaGroupStore } from '@/stores/media-group'
import { defineComponent } from 'vue'
import { useDuplicate } from '@/composables/crud-helpers/useDuplicate'
import { useDelete } from '@/composables/crud-helpers/useDelete'
import { useRoute } from 'vue-router'
import { RouteParams } from '@/router/routeParams'
import MediaGroupImportHint from '@/components/media-group/MediaGroupImportHint.vue'

export default defineComponent({
  name: 'MediaGroupListView',
  components: { MediaGroupImportHint, EntityTableView },
  setup() {
    const route = useRoute()
    const mediaGroupStore = useMediaGroupStore()

    const { duplicateItems } = useDuplicate(mediaGroupStore.save)
    const { deleteItems } = useDelete(mediaGroupStore.delete, {
      check: (items: MediaGroup[]) => items.some((item) => item.id === route.params.id),
      routeName: 'project-media-group'
    })

    return { mediaGroupStore, duplicateItems, deleteItems }
  },

  computed: {
    projectId(): ProjectId {
      return this.$route.params[RouteParams.ProjectId] as string
    },
    searchStrings(): string[] {
      return this.mediaGroupStore.items.map((item) => [item.name].join(' ').toLowerCase())
    },
    MediaGroupId(): MediaGroupId {
      return this.$route.params[RouteParams.MediaGroupId] as string
    }
  },

  mounted() {
    this.mediaGroupStore.load(this.projectId)
    this.mediaGroupStore.selection = this.MediaGroupId ? [this.MediaGroupId] : []
  },

  methods: {
    importDialog() {
      this.mediaGroupStore.selection = []
      this.$router.push({ name: 'project-media-group-import' })
    },

    onSelectionChange(selectedIds: MediaGroupId[]) {
      if (selectedIds.length === 0) {
        this.$router.push({ name: 'project-media-group' })
      } else {
        this.$router.push({
          name: 'project-media-group-edit',
          params: { [RouteParams.MediaGroupId]: selectedIds[0] }
        })
      }
    }
  }
})
</script>

<template>
  <EntityTableView
    v-model:selection="mediaGroupStore.selection"
    allow-duplicate
    :allow-edit="false"
    :allow-create="false"
    :items="mediaGroupStore.items"
    :loading="mediaGroupStore.loading"
    :search-strings="searchStrings"
    confirm-delete-label="Mediengruppe löschen"
    default-sort="name"
    title="Mediengruppen"
    @delete-items="deleteItems"
    @duplicate-items="duplicateItems"
    @update:selection="onSelectionChange"
  >
    <template #extra-tools>
      <p-btn @click="importDialog">Import</p-btn>
    </template>
    <template #columns>
      <el-table-column prop="name" label="Name" sortable>
        <template #default="{ row }">
          <span :class="{ 'text-gray-400': !row.name }">{{ row.name }}</span>
        </template>
      </el-table-column>
    </template>

    <template #empty>
      <p class="text-gray-500 text-xl">Sie haben bisher keine Mediengruppen definiert.</p>

      <p class="mt-8 font-normal text-gray-400 text-lg leading-relaxed">
        Eine Mediengruppe wird durch die als Quelle definierte Hochspannungsleitung beeinflusst. Sie
        können ein oder mehrere Mediengruppe erstellen, die jeweils aus mehreren Segmenten (Medien)
        bestehen.
      </p>
    </template>
  </EntityTableView>
  <MediaGroupImportHint class="mt-4 text-center text-gray-500" @import="importDialog" />
</template>

<style scoped lang="css"></style>
