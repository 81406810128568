import { HsbApi } from '@/api'
import { ConductorAllocationId, ConductorAllocationMapping, ProjectId } from '@/model'
import { ConductorAllocation } from '@gridside/hsb-api'
import { defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'

export const useConductorAllocationStore = defineStore('conductorAllocation', {
  state: () => ({
    itemsById: {} as Record<ConductorAllocationId, ConductorAllocation>,
    loaded: false,
    loadedProject: undefined as undefined | ProjectId,
    loading: false,
    selection: [] as ConductorAllocationId[]
  }),

  getters: {
    findById(state) {
      return (id: ConductorAllocationId | undefined): ConductorAllocation | undefined =>
        id ? state.itemsById[id] : undefined
    },
    items(): ConductorAllocation[] {
      return Object.values(this.itemsById)
    },
    itemsWithConductorCount() {
      return (count: number) => {
        return this.items.filter((item) => item.mapping.length === count)
      }
    }
  },

  actions: {
    async delete(id: ConductorAllocationId) {
      const conductorAllocation = this.findById(id)
      if (!conductorAllocation) {
        return
      }
      await HsbApi.conductorAllocations.deleteConductorAllocation(
        conductorAllocation.project,
        conductorAllocation.id
      )
      delete this.itemsById[id]
    },

    async ensureLoaded(projectId: ProjectId) {
      const projectChanged = this.loadedProject !== projectId
      if (!(this.loaded || this.loading) || projectChanged) {
        await this.load(projectId)
      }
    },

    async load(projectId: ProjectId) {
      this.loading = true
      this.itemsById = {}

      try {
        if (!projectId) {
          throw new Error('ConductorAllocationStore.load: Project id must not be empty.')
        }

        const allocations = (await HsbApi.conductorAllocations.getConductorAllocations(projectId))
          .results
        allocations.forEach((item) => {
          this.itemsById[item.id] = item
        })
        this.loaded = true
        this.loadedProject = projectId
      } finally {
        this.loading = false
      }
    },

    async save(item: ConductorAllocation) {
      if (!item.id) {
        item.id = uuid()
      }

      // HSB-728 - check integrity of mappings
      item.mapping = item.mapping.filter((item) => this.isMapping(item))

      const updatedItem = await HsbApi.conductorAllocations.saveConductorAllocation(
        item.project,
        item.id,
        item
      )
      this.itemsById = { ...this.itemsById, [item.id]: updatedItem }
      return updatedItem
    },

    isMapping(obj?: {}): obj is ConductorAllocationMapping {
      if (!obj) {
        return false
      }
      const base: ConductorAllocationMapping = {
        system: null,
        index: null
      }
      return Object.keys(base).every((key) => key in obj)
    }
  }
})
