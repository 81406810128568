import { HsbApi } from '@/api'
import { Project, ProjectId } from '@/model'
import { defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'
import { ProjectFromJSON } from '@gridside/hsb-api'

const ProjectApi = HsbApi.projects

export const useProjectStore = defineStore('project', {
  state: () => {
    return {
      projectsById: {} as Record<ProjectId, Project>,
      loaded: false,
      loading: false,
      selection: [] as ProjectId[],
      unsubscribe: [] as Array<() => void>,
      pendingUnlocks: [] as ProjectId[],
      pendingLocks: [] as ProjectId[]
    }
  },

  getters: {
    findById(state) {
      return (projectId: ProjectId): Project | undefined => state.projectsById[projectId]
    },
    projects(): Project[] {
      return Object.values(this.projectsById)
    }
  },

  actions: {
    reset() {
      this.unsubscribe.map((unsub) => unsub())
      this.$reset()
    },
    // Initialize event handlers
    init() {
      this.unsubscribe.push(
        ProjectApi.onProjectUpdated((project) => {
          this.projectsById[project.id] = ProjectFromJSON(project)
        }),
        ProjectApi.onProjectDeleted((data) => {
          if (this.projectsById[data.id]) {
            delete this.projectsById[data.id]
          }
        })
      )
    },

    async delete(id: ProjectId) {
      await ProjectApi.deleteProject(id)
      delete this.projectsById[id]
    },

    async ensureLoaded() {
      if (!this.loaded && !this.loading) {
        await this.load()
      }
    },

    async lock(id: ProjectId) {
      if (this.loaded && !this.loading) {
        await ProjectApi.lockProject(id)
        // state will be changed via the 'Project.updated' event
      } else {
        // Defer locking when the store is still loading
        this.pendingLocks.push(id)
      }
    },

    async load() {
      const projectsById: Record<ProjectId, Project> = {}
      this.loading = true

      try {
        const projects = (await ProjectApi.getProjects()).results
        projects.forEach((project) => {
          projectsById[project.id] = project
        })
        this.projectsById = { ...projectsById }
        this.loaded = true
      } finally {
        this.loading = false
      }

      // execute waiting locks / unlocks
      this.pendingUnlocks.forEach((projectId) => this.unlock(projectId))
      this.pendingUnlocks = []
      this.pendingLocks.forEach((projectId) => this.lock(projectId))
      this.pendingLocks = []
    },

    async save(project: Project) {
      if (!project.id) {
        project.id = uuid()
      }
      const updatedProject = await ProjectApi.saveProject(project.id, project)
      this.projectsById = { ...this.projectsById, [project.id]: updatedProject }
      return updatedProject
    },

    async unlock(id: ProjectId) {
      if (this.loaded && !this.loading) {
        await ProjectApi.unlockProject(id)
        this.projectsById[id] = { ...this.projectsById[id], locked: false }
      } else {
        // Defer unlocking when the store is still loading
        this.pendingUnlocks.push(id)
      }
    }
  }
})
