import { defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'
import { User, UserId } from '@/model'
import { HsbApi } from '@/api'
import { useAuthentication } from '@prionect/ui'

const UserApi = HsbApi.users

export const useUserStore = defineStore('user', {
  state: () => ({
    displayNames: {} as Record<UserId, string>,
    usersById: {} as Record<UserId, User>,
    loaded: false,
    loading: false,
    saving: false,
    selection: [] as UserId[]
  }),

  getters: {
    findById(state) {
      return (userId: UserId) => {
        return state.usersById[userId]
      }
    },
    findNameById(state) {
      return (id: UserId | undefined | null) => {
        return id && state.displayNames[id] ? state.displayNames[id] : '(unbekannter Nutzer)'
      }
    },
    users(): User[] {
      return Object.values(this.usersById)
    },
    userItems(state): { value: string; label: string }[] {
      const names = state.displayNames
      return Object.keys(names).map((id) => ({ value: id, label: names[id] }))
    }
  },

  actions: {
    // Initialize event handlers
    init() {
      UserApi.onUserUpdated((user) => {
        this.usersById[user.id] = user
      })

      UserApi.onUserDeleted((id) => {
        if (this.usersById[id]) {
          delete this.usersById[id]
        }
      })
    },

    /**
     * Delete user but prevent deleting logged-in user
     */
    async delete(id: UserId) {
      const auth = useAuthentication()
      if (auth.currentUser.value.id === id) {
        throw Error('Löschen des eingeloggten Nutzers nicht möglich')
      }
      await UserApi.deleteUser(id)
      delete this.usersById[id]
    },

    async ensureLoaded() {
      if (!this.loaded && !this.loading) {
        await this.load()
      }
    },

    async load() {
      const usersById: typeof this.usersById = {}
      this.loading = true

      try {
        await this.loadDisplayNames()
        const users = (await UserApi.getUsers()).results
        users.forEach((user) => {
          usersById[user.id] = user
        })
        this.usersById = { ...usersById }
        this.loaded = true
      } finally {
        this.loading = false
      }
    },

    async loadDisplayNames() {
      if (Object.keys(this.displayNames).length === 0 && !this.loading) {
        this.displayNames = await UserApi.getDisplayNames()
      }
    },

    async save(user: User) {
      this.saving = true
      let userRequest: User

      if (!user.id) {
        userRequest = { ...user, id: uuid() }
      } else {
        userRequest = { ...user }
      }
      try {
        const updatedUser = await UserApi.saveUser(userRequest.id, userRequest)
        this.usersById = { ...this.usersById, [userRequest.id]: updatedUser }
        return updatedUser
      } finally {
        this.saving = false
      }
    }
  }
})
