<script lang="ts">
import TypeSelectDialog from '@/components/common/TypeSelectDialog.vue'
import { PipeType, PipeTypeId } from '@/model'
import { defineComponent, PropType } from 'vue'
import { usePipeTypeStore } from '@/stores/pipe-type'
import { RouteParams } from '@/router/routeParams'

function towerTypesToListItems(types: PipeType[]) {
  return types.map((item) => ({
    value: item.id,
    label: item.name
  }))
}

export default defineComponent({
  name: 'MediaGroupPipeTypeSelectDialog',
  components: { TypeSelectDialog },

  props: {
    modelValue: String as PropType<PipeTypeId>
  },
  emits: ['update:modelValue'],

  data: () => ({
    currentId: undefined as undefined | PipeTypeId
  }),

  computed: {
    projectId() {
      return this.$route.params[RouteParams.ProjectId] as string
    },
    globalItems() {
      return towerTypesToListItems(this.pipeTypeStore.globalItems)
    },
    projectItems() {
      return towerTypesToListItems(this.pipeTypeStore.projectItems)
    },
    pipeType(): PipeType | null {
      return this.currentId ? this.pipeTypeStore.findById(this.currentId) ?? null : null
    }
  },

  setup() {
    return {
      pipeTypeStore: usePipeTypeStore()
    }
  },

  mounted() {
    this.currentId = this.modelValue
    this.pipeTypeStore.ensureLoadedByProject(this.projectId)
    this.pipeTypeStore.ensureLoaded()
  },

  methods: {
    async onSubmit() {
      let id = this.currentId
      if (!this.pipeType?.project && this.currentId) {
        // duplicate global type into project
        const newItem = await this.pipeTypeStore.copyIntoProject(this.currentId, this.projectId)
        id = newItem.id
      }
      this.$emit('update:modelValue', id)
    }
  }
})
</script>

<template>
  <TypeSelectDialog
    v-if="pipeTypeStore.loaded"
    v-bind="$attrs"
    :modelValue="modelValue"
    title="Rohrleitungstyp auswählen"
    :global-items="globalItems"
    :on-submit="onSubmit"
    :project-items="projectItems"
    @update:modelValue="currentId = $event"
  >
    <div v-if="pipeType" class="flex flex-col items-center max-w-[60ch] h-full">
      <div class="w-full text-xl font-semibold pt-1 pb-4">
        {{ pipeType.name }}
      </div>
      <div class="flex-grow"></div>
      <div v-if="!pipeType.project" class="text-base bg-info-200 text-info-700 p-4 mt-8">
        <p class="mb-2">
          Mit der Auswahl dieses Rohrleitungstyps aus der globalen Typenbibliothek wird er in die
          Projektbibliothek übernommen. Sie können den Rohrleitungstyp in der Projektbibliothek
          anschließend nach Bedarf anpassen.
        </p>
      </div>
    </div>
  </TypeSelectDialog>
</template>

<style scoped lang="css"></style>
