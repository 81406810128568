<script lang="ts">
import { AxiosError } from 'axios'
import { defineComponent, PropType } from 'vue'
import { ZodError } from 'zod'

type ErrorItem = {
  type: 'validation' | 'other' | 'server'
  message: string
  path?: string
}
export default defineComponent({
  name: 'ImportErrors',
  props: {
    errors: {
      type: Array as PropType<(string | Error | ZodError)[]>,
      required: true
    }
  },
  computed: {
    parsedErrors() {
      const errors: ErrorItem[] = []
      this.errors.forEach((error) => {
        if (error instanceof ZodError) {
          const singleErrors = error.errors.map(
            (e) =>
              ({
                type: 'validation',
                message: e.message,
                path: e.path.join('/')
              } satisfies ErrorItem)
          )
          errors.push(...singleErrors)
        } else if (error instanceof AxiosError) {
          errors.push({
            message: ` ${error.response?.data.message ?? JSON.stringify(error.response?.data)}`,
            type: 'server'
          })
        } else {
          errors.push({ type: 'other', message: error.toString() })
        }
      })
      return errors
    }
  }
})
</script>

<template>
  <div>
    <div class="flex items-center gap-2 text-error-500 border border-error-500 rounded p-4">
      <WarningIcon class="w-8" />
      Das Projekt kann nicht importiert werden.
    </div>
    <div>
      <p class="mt-8 my-2">Erkannte Fehler:</p>
      <div
        v-for="(error, index) in parsedErrors"
        :key="index"
        class="bg-error-100 text-error-700 rounded px-4 py-2 my-2 text-sm"
      >
        <template v-if="error.type === 'validation'">
          <p>
            Validierungsfehler: Pfad
            <code>[{{ error.path }}]</code>
            -
            {{ error.message }}
          </p>
        </template>
        <template v-else>
          <span v-if="error.type === 'server'" class="font-bold">Serverfehler:</span>
          {{ error.message }}
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped lang="css"></style>
