import { SystemsLayerContext } from '@/components/map/layer/util'
import { OverheadLineId } from '@/model'
import { useOverheadLineStore } from '@/stores/overhead-lines'
import {
  bearing,
  lineIntersect,
  lineString,
  point,
  transformScale,
  transformTranslate
} from '@turf/turf'
import { Feature, FeatureCollection, LineString } from 'geojson'
import { computed, ComputedRef } from 'vue'
import { systemsData } from './useSystemsData'
import { OffsetFeatureProperties, OverheadLineSide, pixelsToMeters } from './util'

/**
 * Distances (in pixels) of the offset lines
 */
const BASE_DISTANCE = 20 // from overhead line to the first offset line
const LINE_DISTANCE = 18 // between multiple offset lines

const overheadLineStore = useOverheadLineStore()

/**
 * Calculate the geometry of system visualization lines which run parallel to their respective overhead lines
 */
export function useOffsetLines(
  ctx: SystemsLayerContext
): ComputedRef<FeatureCollection<LineString, OffsetFeatureProperties>> {
  /**
   * Convert the coordinates of a LineString to WGS84 (needed for TurfJS)
   */
  function lineStringToLonLat<T extends Feature<LineString>>(feature: T): T {
    return {
      ...feature,
      geometry: {
        ...feature.geometry,
        coordinates: feature.geometry.coordinates.map((coordinate) =>
          ctx.projectToLonLat(coordinate)
        )
      }
    }
  }

  /**
   * Convert the coordinates of a LineString from WGS84 to project coordinates (needed for TurfJS)
   */
  function lineStringFromLonLat<T extends Feature<LineString>>(feature: T): T {
    const converted = { ...feature }
    converted.geometry.coordinates = feature.geometry.coordinates.map((coordinate) =>
      ctx.lonLatToProject(coordinate)
    )
    return converted
  }

  /**
   * Calculate an offset line to a given line string
   *
   * We need to implement this ourselves since TurfJS is incorrectly implemented
   * (see https://github.com/Turfjs/turf/issues/1838)
   *
   * @param line LineString with cartesian coordinates (i.e. UTM)
   * @param distance offset distance in meters
   */
  function getLineOffset(line: Feature<LineString>, distance: number) {
    // turf JS only accepts WGS84, therefore we need to convert the geometry
    const lineCoords = lineStringToLonLat(line).geometry.coordinates
    const transformAngle = distance < 0 ? -90 : 90
    if (distance < 0) {
      distance = -distance
    }

    const offsetLines = []
    for (let i = 0; i < lineCoords.length - 1; i++) {
      // Translating each segment of the line to correct position
      const angle = bearing(lineCoords[i], lineCoords[i + 1]) + transformAngle

      const firstPoint = transformTranslate(point(lineCoords[i]), distance, angle, {
        units: 'meters'
      })?.geometry.coordinates

      const secondPoint = transformTranslate(point(lineCoords[i + 1]), distance, angle, {
        units: 'meters'
      })?.geometry.coordinates
      offsetLines.push([firstPoint, secondPoint])
    }

    const offsetCoords = [offsetLines[0][0]] // First point inserted
    for (let i = 0; i < offsetLines.length; i++) {
      // For each translated segment of the initial line
      if (offsetLines[i + 1]) {
        // If there's another segment after this one
        const firstLine = transformScale(lineString(offsetLines[i]), 3) // transformScale is useful in case the two segment don't have an intersection point
        const secondLine = transformScale(lineString(offsetLines[i + 1]), 3) // Which happen when the resulting offset line is bigger than the initial one

        // We're calculating the intersection point between the two translated & scaled segments
        const intersectFeatures = lineIntersect(firstLine, secondLine).features
        if (intersectFeatures.length > 0) {
          offsetCoords.push(intersectFeatures[0].geometry.coordinates)
        }
      } else offsetCoords.push(offsetLines[i][1]) // If there's no other segment after this one, we simply push the last point of the line
    }

    return lineStringFromLonLat(lineString(offsetCoords))
  }

  /**
   * Compute the actual offset lines (1...n per overhead line)
   */
  return computed(() => {
    const overheadLineFeatures = overheadLineStore.overheadLineGeoJSON.features
    const features: Feature<LineString, OffsetFeatureProperties>[] = []

    overheadLineFeatures.forEach((overheadLineFeature) => {
      const overheadLineSystemsData = systemsData.value[overheadLineFeature.id as OverheadLineId]
      if (!overheadLineSystemsData) {
        // no data for this overhead line (i.e. on drafts)
        return
      }
      const leftOffsetCount = overheadLineSystemsData.maxLeftSystemCount
      const rightOffsetCount = overheadLineSystemsData.maxRightSystemCount
      const sides = Object.values(OverheadLineSide)

      // generate offset lines for both left and right sides
      sides.forEach((side) => {
        let count = rightOffsetCount
        let multiplier = Math.min(1, 3 / ctx.resolution.value)
        if (side === OverheadLineSide.LEFT) {
          count = leftOffsetCount
          multiplier = -1 * multiplier
        }
        for (let i = 0; i < count; i++) {
          const distanceInPixels = multiplier * (BASE_DISTANCE + i * LINE_DISTANCE)

          const offsetFeature = getLineOffset(
            overheadLineFeature,
            pixelsToMeters(distanceInPixels, ctx.resolution.value)
          )
          offsetFeature.properties = { overheadLine: overheadLineFeature.id, side, index: i }
          features.push(offsetFeature as Feature<LineString, OffsetFeatureProperties>)
        }
      })
    })

    return { type: 'FeatureCollection', features }
  })
}
