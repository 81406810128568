<script lang="ts">
import { useSystemColor } from '@/composables/useSystemColor'
import { useSystemStore } from '@/stores/system'
import { getContrastColor } from '@/util/colors'
import { System } from '@gridside/hsb-api'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'SystemTag',
  props: {
    system: {
      type: [String, Object] as PropType<string | System>,
      required: true
    },
    type: {
      type: String as PropType<'large' | 'small'>,
      default: 'large'
    }
  },
  setup() {
    return {
      systemStore: useSystemStore(),
      useSystemColor
    }
  },
  computed: {
    displayName() {
      return this.systemStore.systemName(this.system)
    },
    colorBackground() {
      return this.useSystemColor(typeof this.system == 'string' ? this.system : this.system.id)
    },
    colorText() {
      return getContrastColor(this.colorBackground)
    },
    style() {
      return {
        color: this.colorText
      }
    }
  }
})
</script>

<template>
  <!-- use :color instead of style attribute because of cypress transition-stub wrapper... -->
  <el-tag v-if="type == 'large'" class="system-tag" :color="colorBackground" disable-transitions>
    <span :style="style">
      <slot>
        {{ displayName }}
      </slot>
    </span>
  </el-tag>
  <span
    v-else
    class="inline-block px-1 min-w-6 rounded-[5px] border text-center leading-0 font-bold"
    :style="{ background: colorBackground, ...style }"
  >
    <slot></slot>
  </span>
</template>

<style scoped lang="css">
.system-tag {
  @apply border border-gray-200 !important;
}
</style>
