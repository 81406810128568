import z from 'zod'
import { HSBLibItemsEnum, xyPositionTupleArray } from '@/config/schemas/hsb-lib/util'

/**
 * Schema used for CSV string parsing
 * Only pick keys important for GUI
 * https://gridside.gitlab.io/hsb-backend/hsblib_api.html#hsblib.OverheadLine
 */
export const HSBLibOverheadlineSchema = z.object({
  objclass: z.literal(HSBLibItemsEnum.OverheadLine),
  coordinate_list: xyPositionTupleArray,
  corridor: z.string(),
  // geojson_file: string
  // reverse_source: boolean
  list_of_towers: z.array(z.string()),
  list_of_rx: z.array(z.number()),
  list_of_rs: z.array(z.number()),
  list_of_rk: z.array(z.number()),
  list_of_sags: z.array(z.number())
})

export type HSBLibOverheadline = z.infer<typeof HSBLibOverheadlineSchema>

export const validOverheadline = (data: HSBLibOverheadline) => {
  const amountOfSpansNeeded = data.list_of_towers.length - 1
  return (
    amountOfSpansNeeded === data.list_of_rx.length &&
    amountOfSpansNeeded === data.list_of_rs.length &&
    amountOfSpansNeeded === data.list_of_rk.length &&
    amountOfSpansNeeded === data.list_of_sags.length &&
    data.list_of_towers.length === data.coordinate_list.length
  )
}
export const invalidOverheadlineErrorMessage =
  'Die anzahl von Spannfelder, Masten und Koordinaten stimmen nicht überein. \n' +
  'Anzahl Spannfelder muss Anzahl Masten minus 1 sein . \n' +
  'Anzahl Masten muss Anzahl Koordinaten sein. '
/**
 * Cross field validation
 */
const refinement: Parameters<z.ZodType<HSBLibOverheadline>['refine']> = [
  validOverheadline,
  {
    message: invalidOverheadlineErrorMessage
  }
]
export const HSBLibOverheadlineSchemaRefined = HSBLibOverheadlineSchema.refine(...refinement)
