/**
 * Used in
 * - router-paths
 * - router.push()
 * - route.params[]
 * - (route component props)
 */
export enum RouteParams {
  OverheadLineId = 'overheadLineId',
  ProjectId = 'projectId',
  SpanId = 'spanId',
  SystemId = 'systemId',
  TowerId = 'towerId',
  MediaGroupId = 'mediaGroupId',
  OperationalModeId = 'id',
  StudyCaseId = 'studyCaseId'
}

/**
 * Used to designate route parameters
 */
export function prependColon(routeParam: RouteParams) {
  return `:${routeParam}`
}
