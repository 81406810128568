import { HsbApi } from '@/api'
import { ref, watch } from 'vue'

let pollingInterval = 30

const healthy = ref(true)
const checksActive = ref(false)
const errorMessage = ref<string>()

let intervalHandle: any = undefined

const check = async () => {
  try {
    const healthStatus = await HsbApi.health.getHealthStatus()
    healthy.value = healthStatus.status === 'ok'
    errorMessage.value = healthy.value ? undefined : healthStatus.status
  } catch (e) {
    healthy.value = false
    errorMessage.value = 'Verbindung zum Server nicht möglich.'
  }
}

const setupPolling = () => {
  if (intervalHandle) {
    clearInterval(intervalHandle)
    intervalHandle = undefined
  }
  if (checksActive.value) {
    intervalHandle = setInterval(check, pollingInterval * 1000)
  }
}

watch(checksActive, (active) => {
  if (active) {
    check()
  }
  setupPolling()
})

watch(healthy, () => {
  if (healthy.value === true) {
    pollingInterval = 30
  } else {
    pollingInterval = 5
  }
  setupPolling()
})

export function useAppHealth() {
  checksActive.value = true
  return { healthy, errorMessage }
}

export default useAppHealth
