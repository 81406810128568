import { OffsetFeatureProperties } from '@/components/map/layer/systems/util'
import { SystemsLayerContext } from '@/components/map/layer/util'
import { lineSegment } from '@turf/turf'
import { Feature, FeatureCollection, LineString } from 'geojson'
import { computed, ComputedRef } from 'vue'
import { useOffsetLines } from './useOffsetLines'

/**
 * Divides the offset lines into singular offset segments.
 *
 * Segment count per offset line is 2 * span count (each span is divided into an ingoing and
 * an outgoing part)
 */
export function useOffsetSegments(
  ctx: SystemsLayerContext
): ComputedRef<FeatureCollection<LineString, OffsetFeatureProperties>> {
  const offsetLines = useOffsetLines(ctx)

  return computed(() => {
    const dividedFeatures: Feature<LineString, OffsetFeatureProperties>[] = []

    offsetLines.value.features
      // split LineString into segments...
      .map(
        (feature) => lineSegment(feature) as FeatureCollection<LineString, OffsetFeatureProperties>
      )
      // ...which results in a FeatureCollection
      .forEach((featureCollection) => {
        featureCollection.features.forEach((spanFeature, index) => {
          // Split each span segment into an incoming and an outgoing part
          const coord = spanFeature.geometry.coordinates
          const xCenter = (coord[0][0] + coord[1][0]) / 2
          const yCenter = (coord[0][1] + coord[1][1]) / 2
          const centerPoint = [xCenter, yCenter]

          const outgoingSegment: Feature<LineString, OffsetFeatureProperties> = {
            ...spanFeature,
            geometry: { type: 'LineString', coordinates: [coord[0], centerPoint] },
            properties: { ...spanFeature.properties, part: 'out', span: index }
          }
          dividedFeatures.push(outgoingSegment)

          const incomingSegment: Feature<LineString, OffsetFeatureProperties> = {
            ...spanFeature,
            geometry: { type: 'LineString', coordinates: [centerPoint, coord[1]] },
            properties: { ...spanFeature.properties, part: 'in', span: index }
          }
          dividedFeatures.push(incomingSegment)

          return dividedFeatures
        }, [])
      })

    return {
      type: 'FeatureCollection',
      features: [...dividedFeatures]
    }
  })
}
