import { Circle, Fill, Stroke, Style, Text } from 'ol/style'
import { lineWidth, shimWidth, State } from './util'
import constants from './constants'
import { StyleFunction } from 'ol/style/Style'
import { FeatureLike } from 'ol/Feature'
import { featureIsHidden, FeaturePropEnum } from '@/components/map/LayerItemConfig'

const colorsByState: Record<State, (alpha?: number) => string> = {
  default: constants.media.default,
  hover: constants.media.hoverColor,
  select: constants.selected.color
}

export function shimStyle(resolution: number, state: State = 'default') {
  return new Stroke({ width: shimWidth(resolution), color: colorsByState[state](0.15) })
}

export function strokeStyle(resolution: number, state: State = 'default') {
  return new Stroke({ width: lineWidth(resolution), color: colorsByState[state]() })
}

function baseTextStyle(feature: FeatureLike, resolution: number, state: State = 'default') {
  return resolution < 5 || state === 'select'
    ? new Text({
        placement: 'line',
        text: feature.get(FeaturePropEnum.name),
        font: `12px ${constants.fontFamily}`,
        fill: new Fill({ color: colorsByState[state]() }),
        stroke: new Stroke({ color: 'rgba(255,255,255,0.9)', width: 5 })
      })
    : undefined
}

export const mediaGroupStyle: StyleFunction = (feature, resolution) => {
  if (featureIsHidden(feature)) {
    return undefined
  }
  return new Style({
    stroke: strokeStyle(resolution),
    text: baseTextStyle(feature, resolution)
  })
}

export const mediaGroupSelectStyle: StyleFunction = (feature, resolution) => {
  return [
    // Line
    new Style({
      stroke: strokeStyle(resolution, 'select'),
      image: new Circle({
        radius: lineWidth(resolution),
        fill: new Fill({ color: colorsByState['select']() })
      })
      //text: baseTextStyle(feature, resolution, 'select')
    }),
    // Line Buffer
    new Style({
      stroke: shimStyle(resolution, 'select')
    })
  ]
}

export const mediaGroupHoverStyle: StyleFunction = (feature, resolution) => {
  return [
    new Style({
      stroke: strokeStyle(resolution, 'hover'),
      text: baseTextStyle(feature, resolution, 'hover')
    }),
    new Style({ stroke: shimStyle(resolution) })
  ]
}
