import useMapInteraction from '@/components/map/composables/useMapInteraction'
import { MapMode, useMapMode } from '@/components/map/composables/useMapMode'
import { mediaGroupHoverStyle } from '@/components/map/style/media-group'
import { never, pointerMove } from 'ol/events/condition'
import { Select } from 'ol/interaction'
import { HitTolerance, MediaLayerContext } from '../util'

/**
 * Highlight MediaGroup features on mouse hover
 */
export default function useMediaGroupHover(ctx: MediaLayerContext) {
  const hoverInteraction = new Select({
    condition: pointerMove,
    filter: (feature) => {
      const id = feature.getId()
      // Do not highlight selected features
      return !ctx.selectedFeatures.value.some((feat) => feat.getId() === id)
    },
    toggleCondition: never,
    hitTolerance: HitTolerance,
    layers: [ctx.layer],
    style: mediaGroupHoverStyle
  })

  // Change cursor to pointer on hover
  const mapMode = useMapMode()
  hoverInteraction.on('select', (event) => {
    ctx.map.getViewport().style.cursor =
      event.selected.length > 0 && mapMode.value === MapMode.EDIT ? 'pointer' : ''
  })

  useMapInteraction(hoverInteraction)

  return hoverInteraction
}
