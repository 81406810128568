import { z } from 'zod'
import { System } from '@gridside/hsb-api'

const SystemBase = z.object({
  conductorTypes: z.array(z.string()),
  frequency: z.number(),
  name: z.string(),
  project: z.string(),
  wireCount: z.number(),
  isolatorLength: z.number()
})

export const SystemSchema: z.ZodType<System> = SystemBase.extend({ id: z.string() })
