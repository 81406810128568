<script setup lang="ts">
/**
 * MediaGroupLayer shows and controls media-group data in the map view
 */

import { LayerId } from '@/components/map'
import { UseMapItems } from '@/components/map/composables/useMap'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import { useMapSelection } from '@/components/map/composables/useMapSelection'
import LayerBase from '@/components/map/layer/LayerBase.vue'
import useMediaGroupContextMenu from '@/components/map/layer/media-group/useMediaGroupContextMenu'
import useMediaGroupHover from '@/components/map/layer/media-group/useMediaGroupHover'
import { featureIsDraft, featureOfType, FeatureTypeEnum } from '@/components/map/LayerItemConfig'
import { mediaGroupStyle } from '@/components/map/style/media-group'
import useCoordinates from '@/composables/useCoordinates'
import { useProject } from '@/composables/useProject'
import { useMediaGroupStore } from '@/stores/media-group'
import { Feature } from 'ol'
import { GeoJSON } from 'ol/format'
import { FilterFunction } from 'ol/interaction/Select'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { computed, inject, watch } from 'vue'
import { MediaLayerContext } from '../util'
import { deleteDraftFeatures, deleteMediaGroups } from './functions'
import { useMediaGroupLayerOptions } from './useMediaGroupLayerOptions'
import { useMediaGroupSelect } from './useMediaGroupSelect'

const { project } = useProject()

const mediaGroupStore = useMediaGroupStore()
const mediaGroupGeoJSON = computed(() => mediaGroupStore.geoJSON)
const mediaGroupSource = new VectorSource()

// 1. Define OpenLayers layer
const layer = new VectorLayer({
  source: mediaGroupSource,
  style: mediaGroupStyle,
  properties: { id: LayerId.MEDIA_GROUP }
})

// 2. Setup map context
const { map, view, layers } = inject(UseMapInjectKeys.useMap) as UseMapItems
const { selectedFeatures } = useMapSelection()
const { mapToProject, projectToMap } = useCoordinates(map.value, project.value?.crs)
const ctx: MediaLayerContext = {
  map: map.value,
  layer,
  mapToProject,
  projectToMap,
  selectedFeatures,
  selectedMediaGroup: computed(() =>
    selectedFeatures.value.filter((f) => featureOfType(f, FeatureTypeEnum.mediaGroup))
  ),
  selectedMedia: computed(() =>
    selectedFeatures.value.filter((f) => featureOfType(f, FeatureTypeEnum.media))
  ),
  getFeatureById: (id) => {
    return mediaGroupSource.getFeatureById(id)
  },
  visibleFeatures: computed(() => layers.value[LayerId.MEDIA_GROUP].options.visibleFeatures)
}

// 3. Add interactions
const mediaGroupSelect = useMediaGroupSelect(ctx)
useMediaGroupHover(ctx)
useMediaGroupContextMenu(ctx)
useMediaGroupLayerOptions(ctx)

/**
 * Delete all draft features when another or no item is selected
 */
watch(selectedFeatures, (value) => {
  const selectedDraft = value.some((f) => featureIsDraft(f))
  const selectedOtherType = value.some((f) => !featureOfType(f, FeatureTypeEnum.mediaGroup))
  if (!selectedDraft || selectedOtherType) {
    deleteDraftFeatures(ctx)
  }
})

/**
 * Re-read data on GeoJSON update
 */
watch(
  mediaGroupGeoJSON,
  () => {
    if (!project.value) {
      return
    }
    // create or update source
    const features = new GeoJSON({
      dataProjection: project.value.crs,
      featureProjection: view.value.getProjection()
    }).readFeatures(mediaGroupGeoJSON.value) as Feature[]
    mediaGroupSource.clear()
    mediaGroupSource.addFeatures(features)

    // restore selection despite changed source
    const selectMediaGroups = mediaGroupSelect.getFeatures()
    const selectedIds = selectedFeatures.value.map((feature) => feature.getId())
    selectMediaGroups.clear()

    // We have a draft - select this one
    const draftFeature = features.find(featureIsDraft)
    if (draftFeature) {
      selectMediaGroups.push(draftFeature)
      return
    }

    features.forEach((feature) => {
      if (selectedIds.includes(feature.getId())) {
        selectMediaGroups.push(feature)
      }
    })
  },
  { immediate: true }
)

const showInformationOnHover: FilterFunction = (feat) => {
  return !featureIsDraft(feat)
}
</script>

<template>
  <LayerBase
    v-if="mediaGroupSource"
    v-bind="$attrs"
    :id="LayerId.MEDIA_GROUP"
    :layer="layer"
    :information-on-hover="showInformationOnHover"
    :z-index="5"
  />
  <teleport to="body">
    <p-dialog
      confirmLabel="Löschen"
      danger
      :show="mediaGroupStore.itemsToDelete.length > 0"
      title="Mediengruppe löschen"
      @update:show="mediaGroupStore.itemsToDelete = []"
      @confirm="deleteMediaGroups"
    >
      <slot
        v-if="mediaGroupStore.itemsToDelete"
        name="confirm-delete"
        :item="mediaGroupStore.itemsToDelete"
      >
        <template v-if="mediaGroupStore.itemsToDelete.length === 1">
          Sind Sie sicher, dass Sie die Mediengruppe
          <b v-if="mediaGroupStore.itemsToDelete[0].name">
            {{ mediaGroupStore.itemsToDelete[0].name }}
          </b>
          löschen möchten?
        </template>
        <template v-else>
          Sind Sie sicher, dass Sie
          <b>{{ mediaGroupStore.itemsToDelete.length }} Mediengruppen</b>
          löschen möchten?
        </template>
      </slot>
    </p-dialog>
  </teleport>
</template>
