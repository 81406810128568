import { MapMode, useMapMode } from '@/components/map/composables/useMapMode'
import { useMapSelection } from '@/components/map/composables/useMapSelection'
import { HitTolerance, MediaLayerContext } from '../util'
import { useMediaGroupStore } from '@/stores/media-group'
import { onBeforeUnmount, onMounted, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import VectorSource from 'ol/source/Vector'
import { Feature as FeatureOl } from 'ol'
import { useMediaEdit } from '@/components/map/layer/media-group/useMediaEdit'
import { Select } from 'ol/interaction'
import { click } from 'ol/events/condition'
import { mediaGroupSelectStyle } from '@/components/map/style/media-group'
import useMapInteraction from '@/components/map/composables/useMapInteraction'
import { RouteParams } from '@/router/routeParams'

export function useMediaGroupSelect(ctx: MediaLayerContext) {
  const { registerSelectInteraction } = useMapSelection()
  const router = useRouter()
  const mediaGroupStore = useMediaGroupStore()
  const mapMode = useMapMode()

  const mediaGroupSelect = new Select({
    condition: (e) => click(e) && !mediaGroupStore.drawing,
    style: mediaGroupSelectStyle,
    layers: [ctx.layer],
    hitTolerance: HitTolerance
  })
  ctx.mediaGroupSelect = mediaGroupSelect
  useMapInteraction(mediaGroupSelect)
  registerSelectInteraction(mediaGroupSelect)

  /**
   * Add whole interaction with LineStrings of MediaGroup
   */
  useMediaEdit(ctx, mediaGroupSelect)

  /**
   * Select feature from route param on mount
   */
  onMounted(() => {
    const id = router.currentRoute.value.params[RouteParams.MediaGroupId]
    if (typeof id != 'string') {
      return
    }

    const src = ctx.layer.getSource()
    if (!(src instanceof VectorSource)) {
      return
    }
    const feat = src
      .getFeatures()
      .find((feat) => (feat instanceof FeatureOl ? feat.getId() == id : false))
    if (feat) {
      mediaGroupSelect.getFeatures().push(feat)
    }
  })

  /**
   * 1. Activate/deactivate select interaction
   * 2. If something is selected, router.push() to it
   */
  watchEffect(() => {
    // activate/deactivate
    mediaGroupSelect.setActive(mapMode.value === MapMode.EDIT)

    // computed property of selected features filtered by _type MediaGroup
    const selectedMedia = ctx.selectedMediaGroup.value
    if (selectedMedia.length <= 0) {
      mediaGroupStore.selection = []
      return
    }

    // handle draft/create
    const draft = mediaGroupStore.draft
    if (draft) {
      mediaGroupStore.selection = [draft.id]
      router.push({
        name: 'project-map-media-group-create'
      })
      return
    }

    // handle edit
    mediaGroupStore.selection = selectedMedia.map((item) => item.getId() as string)
    router.push({
      name: 'project-map-media-group-edit',
      params: { [RouteParams.MediaGroupId]: selectedMedia[0].getId() }
    })
  })

  onBeforeUnmount(() => {
    useMapSelection().clear()
  })

  return mediaGroupSelect
}
