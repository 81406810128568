<script lang="ts">
import { useSystemColor } from '@/composables/useSystemColor'
import { factorInIsolatorLength, hasConductorSystemData, WirePoint } from '@/model'
import { useSystemStore } from '@/stores/system'
import { conductorLabelFn } from '@/util'
import { defineComponent, PropType } from 'vue'
import { LineTowerType } from '@gridside/hsb-api/dist/models/LineTowerType'

export default defineComponent({
  name: 'TowerSVGHoverInformation',
  props: {
    wirePoint: {
      type: Object as PropType<WirePoint>,
      required: true
    },
    towerOffset: {
      type: Number,
      required: true
    },
    lineTowerType: {
      type: String as PropType<LineTowerType>
    }
  },
  setup() {
    return {
      useSystemColor,
      hasConductorSystemData,
      systemStore: useSystemStore(),
      conductorLabelFn
    }
  },
  computed: {
    hoverInformation() {
      const yCalculationInfo: [string, string][] = []
      const isolatorLength = factorInIsolatorLength(this.lineTowerType, this.wirePoint)

      // tower offset
      if (this.towerOffset) {
        yCalculationInfo.push(['Aufstockung', `+ ${this.towerOffset} m`])
      }

      // isolator length
      if (isolatorLength) {
        yCalculationInfo.push(['Isolator', `- ${isolatorLength} m`])
      }

      // add base y
      if (isolatorLength || this.towerOffset) {
        yCalculationInfo.unshift(['Typ', `${this.$n(this.wirePoint.y)} m`])
      }

      const x = this.$n(this.wirePoint.x)
      const y = this.$n(this.wirePoint.y + this.towerOffset - isolatorLength)

      return {
        x,
        y,
        yCalculationInfo
      }
    }
  },

  methods: {}
})
</script>

<template>
  <table>
    <caption>Position {{ wirePoint.label }}</caption>
    <tbody>
      <!-- x -->
      <tr>
        <td>x</td>
        <th>{{ hoverInformation.x }} m</th>
      </tr>

      <!-- y -->
      <tr>
        <td>y</td>
        <th>
          {{ hoverInformation.y }} m
          <!-- Additional Info -->
          <table v-if="hoverInformation.yCalculationInfo.length" class="additional-info">
            <tbody>
              <tr v-for="(info, i) in hoverInformation.yCalculationInfo" :key="i">
                <td>{{ info[1] }}</td>
                <th>{{ info[0] }}</th>
              </tr>
            </tbody>
          </table>
        </th>
      </tr>

      <template v-if="hasConductorSystemData(wirePoint)">
        <!-- System Info -->
        <tr>
          <td>System</td>
          <th>
            <el-tag
              v-if="wirePoint.conductorSystemData.system"
              :color="useSystemColor(wirePoint.conductorSystemData.system)"
              style="height: 1.2rem !important"
              disable-transitions
            >
              <span class="text-white">
                {{
                  systemStore.findById(wirePoint.conductorSystemData.system)?.name ||
                  wirePoint.conductorSystemData.system
                }}
              </span>
            </el-tag>
            <i v-else>unbesetzt</i>
          </th>
        </tr>

        <!-- Conductor index -->
        <tr>
          <td>Leiter</td>
          <th>
            <template v-if="wirePoint.conductorSystemData.index != null">
              {{ conductorLabelFn(wirePoint.conductorSystemData.index) }}
            </template>
            <i v-else>unbesetzt</i>
          </th>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<style scoped lang="css">
td,
th {
  @apply px-2 py-0.5 text-xs;
}

.additional-info td,
.additional-info th {
  @apply py-0 px-0.5 text-[9px] text-gray-400;
}
td {
  @apply text-gray-500 text-right align-top;
}
</style>
