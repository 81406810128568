import { MapMode, useMapMode } from '@/components/map/composables/useMapMode'
import { lineWidth, shimWidth, State } from '@/components/map/style/util'
import { FeatureLike } from 'ol/Feature'
import { Stroke, Style } from 'ol/style'
import { colorsByState } from './util'

export function spanStyle(feature: FeatureLike, resolution: number, state: State = 'default') {
  const mapMode = useMapMode()

  const strokeStyle = new Style({
    stroke: new Stroke({
      width: state === 'select' || mapMode.value === MapMode.VIEW ? lineWidth(resolution) : 0.1,
      color: colorsByState[state]()
    })
  })

  const shimStrokeStyle = new Style({
    stroke: new Stroke({
      width: shimWidth(resolution),
      color: colorsByState[state](0.2),
      lineCap: 'butt'
    })
  })

  if (state === 'default') {
    return [strokeStyle]
  } else {
    return [shimStrokeStyle, strokeStyle]
  }
}
