<script lang="ts">
import { RouteParams } from '@/router/routeParams'
import BranchTowerForm from '@/views/project/towers/BranchTowerForm.vue'
import { defineComponent } from 'vue'
import DetailPanel from '@/layouts/pages/DetailPanel.vue'
import {
  ConductorAllocationMapping,
  ConductorTypeId,
  TowerId,
  WirePositionLabeledConductors,
  WirePositionLabeledEarthwire
} from '@/model'
import TowerForm from '@/views/project/towers/TowerForm.vue'
import { ElMessage } from 'element-plus'
import { RouteLocationRaw } from 'vue-router'
import { useTowerTypeStore } from '@/stores/tower-type'
import { TowerRequest, TowerResponse } from '@gridside/hsb-api'
import { useOverheadLine } from '@/composables/useOverheadLine'
import { copy } from '@/util'

export type ConductorTowerTypeMapper = (
  conductorMapping: ConductorAllocationMapping,
  index: number
) => WirePositionLabeledConductors

export type EarthwireTowerTypeMapper = (
  conductorId: ConductorTypeId,
  index: number
) => WirePositionLabeledEarthwire

export default defineComponent({
  name: 'TowerDetail.vue',
  components: { BranchTowerForm, TowerForm, DetailPanel },

  setup() {
    const { overheadLineRecord, overheadLineStore } = useOverheadLine()
    const towerTypeStore = useTowerTypeStore()
    return {
      overheadLineRecord,
      overheadLineStore,
      towerTypeStore
    }
  },

  data: () => ({
    saving: false
  }),

  computed: {
    towerId() {
      return this.$route.params[RouteParams.TowerId] as string
    },

    create(): boolean {
      return !this.towerId
    },

    includesBranchTower() {
      return !!this.selectedItems.find((item) => (item.relatedTowers?.length || 0) > 0)
    },

    includeMultipleOverheadLines() {
      if (this.selectedItems.length === 0) {
        return false
      } else {
        const firstOverheadLine = this.selectedItems[0].overheadLine
        return this.selectedItems.some((tower) => tower.overheadLine !== firstOverheadLine)
      }
    },

    selectedItems(): TowerResponse[] {
      const draft = this.overheadLineStore.towerDraft
      const items: TowerResponse[] = []

      if (this.create && draft) {
        items.push(draft as TowerResponse)
      } else if (this.overheadLineStore.towersSelection.length > 0) {
        this.overheadLineStore.towersSelection
          .map((id: TowerId) => this.overheadLineStore.findTowerById(id))
          .filter((item): item is TowerResponse => item !== undefined)
          .forEach((tower) => items.push(tower))
      } else if (this.towerId) {
        const tower = this.overheadLineStore.findTowerById(this.towerId)
        if (tower) {
          items.push(tower)
        }
      }

      // copy to prevent side effects when form fields modify sub-array items
      return copy(items)
    },

    title(): string {
      if (this.create) {
        return 'Neuer Mast'
      }

      const selectionCount = this.selectedItems.length
      if (selectionCount > 1) {
        return `${selectionCount} Masten bearbeiten`
      } else {
        if (this.includesBranchTower) {
          return 'Abzweigmast bearbeiten'
        } else {
          return 'Mast bearbeiten'
        }
      }
    }
  },

  watch: {
    selectedItems() {
      if (this.selectedItems.length === 0) {
        this.onClose()
      }
    }
  },

  methods: {
    async onClose() {
      await this.$router.push(
        (this.$route.meta.returnRoute as RouteLocationRaw) || { name: 'overhead-line-towers' }
      )
      this.overheadLineStore.towerDraft = undefined

      // to make it possible to close the drawer but keep selection when multiple are selected
      if (this.overheadLineStore.towersSelection.length == 1) {
        this.overheadLineStore.towersSelection = []
      }
    },

    async onSave(items: TowerRequest[]) {
      this.saving = true
      try {
        for (const item of items) {
          await this.overheadLineStore.towerSave(item)
        }
        ElMessage.success(
          this.create ? 'Mast wurde erfolgreich angelegt.' : 'Daten wurden erfolgreich gespeichert.'
        )
      } finally {
        this.saving = false
      }
    },

    async onSaveChanges(changeset: Partial<TowerRequest>) {
      this.saving = true
      try {
        const towerIds = this.selectedItems.map((item) => item.id)
        await this.overheadLineStore.towerSaveBatch(towerIds, changeset)
        ElMessage.success('Daten wurden erfolgreich gespeichert.')
      } finally {
        this.saving = false
      }
    }
  }
})
</script>

<template>
  <DetailPanel :title="title" data-test="tower-detail" @close="onClose">
    <BranchTowerForm
      v-if="includesBranchTower && selectedItems.length === 1"
      :key="`branch-${towerId}`"
      :saving="saving"
      :tower="selectedItems[0]"
      @cancel="onClose"
      @submit="onSave"
    />
    <TowerForm
      v-else-if="!includesBranchTower"
      :key="towerId"
      :items="selectedItems"
      :saving="saving"
      @cancel="onClose"
      @submit="(items: TowerRequest[]) => create && onSave(items)"
      @submit:changes="(changes: Partial<TowerRequest>) => !create && onSaveChanges(changes)"
    />
    <div v-else>
      <el-alert type="warning" title="Mehrfachbearbeitung nicht möglich">
        Ihre Mehrfachauswahl beinhaltet einen oder mehrere Abzweigmasten. Die gleichzeitige
        Bearbeitung mehrerer Masten ist jedoch nur für reguläre Masten möglich.
      </el-alert>
    </div>
  </DetailPanel>
</template>

<style scoped lang="css"></style>
