import { OperationState } from '@gridside/hsb-api'

export enum FunctionsImplementedEnum {
  MIN = 'min',
  MAX = 'max',
  POW = 'pow',
  ABS = 'abs',
  SQRT = 'sqrt'
}

export function operationStateToString(opState: OperationState) {
  return opState.conductorStates.reduce(
    (previousValue, currentValue, currentIndex) =>
      `${previousValue} ${currentIndex > 0 ? '|' : ''} ${currentValue.current} A ∠ ${
        currentValue.angle
      }°`,
    ''
  )
}

export function operationStateAtomic(operationStateIndex: number) {
  return `op_${operationStateIndex + 1} ` // add space to not interfere with numbers inserted after atomic
}
export function operationStateLabel(operationStateIndex: number) {
  return `${operationStateIndex + 1}`
}

/**
 * Returns position after the first "("
 */
export function afterRoundBracketsOpen(text: string, offset: number = 0) {
  return offset + text.lastIndexOf('(') + 1
}
