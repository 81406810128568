<script lang="ts">
import { useProject } from '@/composables/useProject'
import { useCalculationStore } from '@/stores/calculation'
import { useSystemStore } from '@/stores/system'
import { csvToJson } from '@/util'
import { CalculationResultData } from '@gridside/hsb-api'
import { defineComponent } from 'vue'
import { formatNumber } from '../../../../util/helpers'

export default defineComponent({
  name: 'ResultTab',
  setup() {
    const { project } = useProject()
    return {
      calculationStore: useCalculationStore(),
      project,
      systemStore: useSystemStore()
    }
  },

  data: () => ({
    formatNumber,
    selectedSubResult: false as number | false
  }),

  computed: {
    isPipeProject() {
      return this.project?.mediaType === 'pipe'
    },

    result() {
      return this.calculationStore.result
    },

    operationStates() {
      return this.calculationStore.current?.studyCase?.operationStates || []
    },

    operationStateData() {
      if (this.selectedSubResult === false) {
        return undefined
      }
      return (
        this.calculationStore.current?.studyCase?.operationStates[this.selectedSubResult] ||
        undefined
      )
    },

    resultData(): CalculationResultData | undefined {
      if (this.selectedSubResult === false) {
        return this.result
      } else {
        return this.result?.subresult ? this.result?.subresult[this.selectedSubResult] : undefined
      }
    },

    selectItems(): Array<{ value: number | false; label: string }> {
      return [
        { value: false, label: 'Gesamtergebnis' },
        ...(this.result?.subresult?.map((_, index) => ({
          value: index,
          label: 'Teilergebnis ' + (index + 1)
        })) || [])
      ]
    },

    system() {
      return this.operationStateData
        ? this.systemStore.findById(this.operationStateData.system)
        : undefined
    },

    tableData() {
      if (this.isPipeProject) {
        return csvToJson(this.resultData?.loadFlow || '')
      } else {
        return csvToJson(this.resultData?.eMF || '')
      }
    }
  }
})
</script>

<template>
  <div>
    <div class="flex gap-6 w-full mb-4 items-center">
      <el-select v-model="selectedSubResult" class="flex-shrink !w-48">
        <el-option
          v-for="item in selectItems"
          :label="item.label"
          :value="item.value"
          :key="item.value"
        >
          <SystemTag
            v-if="item.value !== false"
            :system="operationStates[item.value].system"
            class="min-w-[1.5em] !p-1 text-xs mr-1"
            style="height: auto !important"
          >
            {{ item.value + 1 }}
          </SystemTag>
          {{ item.label }}
        </el-option>
      </el-select>
      <div v-if="operationStateData && system" class="flex-1 flex items-center gap-x-2">
        <SystemTag :system />
        <div
          v-for="(conductorState, index) in operationStateData.conductorStates"
          :key="index"
          class="border rounded px-2 py-1 text-sm bg-white"
        >
          <span class="font-semibold">L{{ index + 1 }}:</span>
          {{ formatNumber(conductorState.current, 'A') }}
          ∠
          {{ formatNumber(conductorState.angle, '°') }}
        </div>
      </div>
      <div
        v-if="resultData"
        class="py-2 px-4 flex items-center"
        :class="{ 'flex-1 justify-end': !operationStateData }"
      >
        <template v-if="isPipeProject">φ</template>
        <template v-else>U</template>
        <sub>max</sub>
        <span class="ml-4 font-bold">
          {{ $n(resultData.resultVoltage, { maximumFractionDigits: 1 }) }} V
        </span>
      </div>
    </div>
    <ResultTable :items="tableData" />
  </div>
</template>

<style scoped lang="css"></style>
