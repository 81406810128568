<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { useField } from 'vee-validate'
import { SelectItem } from '@prionect/ui'
import { FieldConfigWithBackendDefault } from '@/config/fields/project'

export default defineComponent({
  name: 'ProjectHSBLibParameterFieldRow',
  props: {
    fieldConfig: {
      type: Object as PropType<FieldConfigWithBackendDefault>,
      required: true
    }
  },
  setup(props) {
    /**
     * Vee-Validate field setup
     */
    const field = useField(props.fieldConfig.name, props.fieldConfig.rules, {
      label: props.fieldConfig.label
    })

    return {
      field
    }
  },
  computed: {
    disabled() {
      return this.isNull(this.field.value.value)
    },
    /**
     * Used to give non-null values of fields a default value
     */
    getFieldDefaultValue() {
      // current value on project
      if (this.field.meta.initialValue != undefined) {
        return this.field.meta.initialValue
      }
      // backend default
      if (this.fieldConfig.backendDefault !== undefined) {
        return this.fieldConfig.backendDefault
      }

      // type-based
      const type = this.fieldConfig.type as string
      if (type === 'number') {
        return Number()
      }
      if (
        type === 'select' &&
        'items' in this.fieldConfig &&
        typeof this.fieldConfig.items[0] === 'object'
      ) {
        return this.fieldConfig.items[0].value
      }
      if (type === 'switch') {
        return Boolean()
      }
      if (['text', 'textarea', 'email', 'autocomplete', 'password'].includes(type)) {
        return String()
      }
      return null
    }
  },
  methods: {
    /**
     * Common "null"-check for <switch> and :disabled
     * @param val
     */
    isNull(val: any) {
      return val === null
    },
    /**
     * Either choose HSB-Default by setting null or use the default value based on the field type
     */
    setNullOrDefaultValue(useBackendDefault: boolean) {
      this.field.setValue(useBackendDefault ? null : this.getFieldDefaultValue)
    },
    optionBinding(option: SelectItem | number | string): { label: string; value: any } {
      if (typeof option === 'number' || typeof option === 'string') {
        return {
          value: option,
          label: option.toString()
        }
      }
      return option
    }
  }
})
</script>

<template>
  <tr class="">
    <!-- Label + Info -->
    <td class="align-top">
      <div class="mb-1 font-semibold text-sm">{{ field.label }}</div>

      <div class="text-gray-400 text-xs leading-snug">
        {{ fieldConfig.hint }}
      </div>
    </td>

    <!-- Default Switch -->
    <td class="text-center align-top">
      <el-switch
        :model-value="isNull(field.value.value)"
        class="hsblib-parameter-switch"
        @change="setNullOrDefaultValue"
      ></el-switch>
    </td>

    <!-- Input -->
    <td :class="{ 'field--error': field.errorMessage.value }" class="align-center">
      <!-- Boolean -->
      <div v-if="fieldConfig.type == 'switch'" class="text-center">
        <el-checkbox v-model="field.value.value" :disabled="disabled" size="large"></el-checkbox>
      </div>

      <!-- Number - overriding type to "text" is important, else child-component is broken -->
      <p-number-input
        v-else-if="fieldConfig.type == 'number'"
        :model-value="field.value.value"
        :disabled="disabled"
        v-bind="{ ...fieldConfig, type: 'text' }"
        @change="field.value.value = $event"
      />

      <!-- Select -->
      <el-select
        v-else-if="fieldConfig.type == 'select' && 'items' in fieldConfig"
        v-model="field.value.value"
        :disabled="disabled"
      >
        <el-option
          v-for="(option, index) in fieldConfig.items"
          :key="index"
          v-bind="optionBinding(option)"
        ></el-option>
      </el-select>

      <!-- Text -->
      <el-input
        v-else
        :model-value="field.value.value"
        :disabled="disabled"
        @change="field.value.value = $event"
      ></el-input>

      <!--Error Message -->
      <div class="text-error-500 text-sm font-semibold">
        {{ field.errorMessage.value ?? '&nbsp;' }}
      </div>
    </td>
  </tr>
</template>

<style lang="css" scoped>
.field--error :deep(.el-input__wrapper) {
  box-shadow: 0 0 0 1px var(--tw-color-error-500) inset;
}
</style>
