<script lang="ts">
import { HsbApi } from '@/api'
import { useProject } from '@/composables/useProject'
import { CalculationStatusTranslations, CalculationStatusValue } from '@/model/calculation'
import { RouteParams } from '@/router/routeParams'
import { useCalculationStore } from '@/stores/calculation'
import { useStudyCaseStore } from '@/stores/study-case'
import { CalculationStatusStatus } from '@gridside/hsb-api'
import { PDialog } from '@prionect/ui'
import { SelectOption } from '@util/element-plus'
import { ElSelect } from 'element-plus'
import { defineComponent } from 'vue'

const CalculationApi = HsbApi.calculation

export default defineComponent({
  name: 'CalculationLauncher',
  components: { PDialog, ElSelect },

  data: () => ({
    clearingCalculation: false,
    showClearConfirm: false,
    RouteParams,
    CalculationStatusTranslations
  }),

  setup() {
    const calculationStore = useCalculationStore()
    const studyCaseStore = useStudyCaseStore()
    const { projectId } = useProject()

    calculationStore.ensureLoaded(projectId.value)
    studyCaseStore.ensureLoaded(projectId.value)

    return { projectId, calculationStore, studyCaseStore }
  },

  computed: {
    calculationId() {
      return this.calculationStore.current?.id
    },

    isProcessing(): boolean {
      if (this.status) {
        return (
          [
            CalculationStatusStatus.PENDING,
            CalculationStatusStatus.INITIALIZING,
            CalculationStatusStatus.RUNNING
          ].some((status) => status === this.status) || this.calculationStore.starting
        )
      }
      return false
    },

    isRecalculation(): boolean {
      return this.status === CalculationStatusStatus.FINISHED
    },

    selectedStudyCase() {
      return this.calculationStore.selectedStudyCase
    },

    status(): CalculationStatusValue | undefined {
      return this.calculationStore.starting ? 'PENDING' : this.calculationStore.current?.status
    },

    studyCaseItems(): SelectOption[] {
      const items = this.studyCaseStore.items
      return items.map((item) => ({
        value: item.id,
        label: item.name
      }))
    }
  },

  watch: {
    selectedStudyCase() {
      if (this.$route.name === 'project-map-study-case-edit') {
        this.$router.push({
          name: 'project-map-study-case-edit',
          params: { studyCaseId: this.selectedStudyCase }
        })
      }
    },
    studyCaseItems() {
      if (this.studyCaseItems[0] && this.selectedStudyCase === undefined) {
        this.calculationStore.selectedStudyCase = this.studyCaseItems[0].value
      }
    }
  },

  methods: {
    async cancel() {
      if (!this.calculationId) {
        return
      }
      await CalculationApi.cancelCalculation(this.projectId, this.calculationId)
      await this.calculationStore.ensureLoaded(this.projectId)
    },

    async clear() {
      if (!this.calculationId) {
        return
      }
      this.clearingCalculation = true
      await CalculationApi.clearCalculation(this.projectId, this.calculationId)
      await this.calculationStore.ensureLoaded(this.projectId)
      this.clearingCalculation = false
    },

    async clearAndStart() {
      await this.clear()
      await this.start()
    },

    async start() {
      if (!this.selectedStudyCase) {
        throw new Error('Could not start calculation, no study case selected.')
      }
      const calc = await this.calculationStore.start(this.projectId, this.selectedStudyCase)
      return calc
    }
  }
})
</script>

<template>
  <div class="flex flex-col mt-2">
    <div class="flex gap-2 mb-4">
      <el-select
        v-model="calculationStore.selectedStudyCase"
        placeholder="Berechnungsfall"
        aria-label="Berechnungsfall"
      >
        <el-option
          v-for="item in studyCaseItems"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>

      <p-btn
        icon
        :disabled="calculationStore.selectedStudyCase === undefined"
        title="Betriebsfall bearbeiten"
        @click="
          $router.push({
            name: 'project-map-study-case-edit',
            params: { [RouteParams.StudyCaseId]: calculationStore.selectedStudyCase }
          })
        "
      >
        <el-icon size="20"><EditIcon /></el-icon>
      </p-btn>
    </div>

    <template v-if="isProcessing">
      <div class="my-6">
        <el-progress
          :percentage="100"
          status="success"
          :indeterminate="true"
          :duration="1"
          :show-text="false"
        />
        <div class="text-center mt-2 mb-2 text-sm font-semibold text-gray-500">
          {{ status ? CalculationStatusTranslations[status] : 'unbekannter Status' }}...
        </div>
      </div>
      <p-btn class="w-full" type="warning" @click="cancel">Berechnung abbrechen</p-btn>
    </template>
    <template v-else>
      <p-btn
        :disabled="calculationStore.selectedStudyCase === undefined"
        type="primary"
        @click="start"
        @click.shift="clearAndStart()"
      >
        Berechnung
        <template v-if="isRecalculation">neu</template>
        starten
      </p-btn>
    </template>
    <div class="flex m-2">
      <el-button
        :loading="clearingCalculation"
        text
        size="small"
        class="grow"
        @click="showClearConfirm = true"
      >
        Berechnung zurücksetzen
      </el-button>
    </div>
  </div>
  <Teleport to="body">
    <p-dialog
      :show="showClearConfirm"
      title="Berechnung zurücksetzen"
      confirm-label="Fortsetzen"
      @confirm="clear"
      @close="showClearConfirm = false"
    >
      Es werden alle Ergebnisse entfernt, HSBlib-Worker angehalten und/oder neu initialisiert.
    </p-dialog>
  </Teleport>
</template>

<style scoped lang="css"></style>
