<script lang="ts">
import { computed, defineComponent, inject, watch } from 'vue'
import { useTowerTypeStore } from '@/stores/tower-type'
import { useProject } from '@/composables/useProject'
import { useConductorAllocationStore } from '@/stores/conductor-allocation'
import { FieldConfig, MIXED_VALUES, SelectItem } from '@prionect/ui'
import { FormContextKey } from 'vee-validate'
import { TowerTypeId } from '@/model'

export default defineComponent({
  name: 'TowerAllocationSelectField',
  setup(_, ctx) {
    const { projectId } = useProject()
    const towerTypeStore = useTowerTypeStore()
    const conductorAllocationStore = useConductorAllocationStore()
    const form = inject(FormContextKey)

    // paths with dot-notation ending with "allocation" and "type"
    const pathAllocation = (ctx.attrs as FieldConfig).name
    const pathTowerType = `${pathAllocation.substring(0, pathAllocation.lastIndexOf('.'))}.type`

    /**
     * "towerType" vee-validate Field and value
     */
    const towerTypeField = computed(() => form?.getPathState<string>(pathTowerType))
    const towerTypeId = computed<TowerTypeId>(() => towerTypeField?.value?.value)

    /**
     * Reset allocation field when all of:
     * - tower type id changed
     * - none of the values was ___MIXED_VALUES__ (happens on multiselect back to single-select)
     * - tower type positions count doesn't match anymore
     */
    watch(towerTypeId, (value, oldValue) => {
      if ([value, oldValue].includes(MIXED_VALUES)) {
        return
      }
      const lengthNew = towerTypeStore.getConductorCount(value)
      const lengthOld = towerTypeStore.getConductorCount(oldValue)
      if (lengthNew == lengthOld) {
        return
      }
      form?.setFieldValue(pathAllocation, null, false)
    })

    return {
      projectId,
      towerTypeStore,
      conductorAllocationStore,
      towerTypeId
    }
  },
  async created() {
    await this.towerTypeStore.ensureLoadedByProject(this.projectId)
    await this.conductorAllocationStore.ensureLoaded(this.projectId)
  },
  computed: {
    towerType() {
      return this.towerTypeStore.findById(this.towerTypeId)
    },
    towerConductorCount() {
      return this.towerType?.conductorPositions.length || 0
    },
    allocationNoDataText() {
      return `keine Leiterzuordnung für diesen Masttyp mit ${this.towerConductorCount} Positionen gefunden`
    },
    conductorAllocations(): SelectItem[] {
      return this.conductorAllocationStore
        .itemsWithConductorCount(this.towerConductorCount || 0)
        .map((type) => ({
          value: type.id,
          label: type.name
        }))
    }
  }
})
</script>

<template>
  <p-field
    v-bind="$attrs"
    :items="conductorAllocations"
    dense
    :disabled="!towerType"
    :placeholder="!towerType ? 'Bitte zuerst Masttyp auswählen' : 'Daten wählen'"
    :no-data-text="allocationNoDataText"
  />
</template>

<style scoped lang="css"></style>
