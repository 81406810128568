<script lang="ts">
import { UseMapItems } from '@/components/map/composables/useMap'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import ButtonControlBase, { ActionItem } from '@/components/map/controls/ButtonControlBase.vue'
import { useEventListener } from '@vueuse/core'
import { defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'FullscreenControl',
  components: { ButtonControlBase },

  setup() {
    const { fullscreen } = inject(UseMapInjectKeys.useMap) as UseMapItems

    useEventListener(document, 'fullscreenchange', () => {
      fullscreen.value = !!document.fullscreenElement
    })

    return { fullscreen }
  },

  computed: {
    actions(): ActionItem[] {
      return [
        this.fullscreen
          ? {
              icon: 'ExitFullscreen',
              action: this.toggleFullscreen,
              tooltip: 'Vollbildmodus verlassen'
            }
          : {
              icon: 'Fullscreen',
              action: this.toggleFullscreen,
              tooltip: 'Vollbildmodus'
            }
      ]
    }
  },

  methods: {
    toggleFullscreen() {
      let elem = document.documentElement

      if (!document.fullscreenElement) {
        elem.requestFullscreen().then(() => {
          this.fullscreen = true
        })
      } else {
        document.exitFullscreen()
        this.fullscreen = false
      }
    }
  }
})
</script>

<template>
  <ButtonControlBase :actions="actions" />
  <!--  <button class="bg-gray-50 p-2" title="Vollbild" @click="toggleFullscreen">
    <el-icon size="24">
      <ExitFullscreenIcon v-if="fullscreen" />
      <FullscreenIcon v-else />
    </el-icon>
  </button>-->
</template>

<style scoped lang="css"></style>
