import useMapInteraction from '@/components/map/composables/useMapInteraction'
import { MapMode, useMapMode } from '@/components/map/composables/useMapMode'
import { HitTolerance, SourceLayerContext, SystemsLayerContext } from '@/components/map/layer/util'
import { systemsStyle } from '@/components/map/style/systems'
import { Select } from 'ol/interaction'
import { pointerMove } from 'ol/events/condition'
import { sourceHoverStyle } from '@/components/map/style/source'
import { watch } from 'vue'

export function useSystemHover(ctx: SystemsLayerContext) {
  /**
   * Add map interaction to highlight  features on mouse hover
   */
  function highlightFeature() {
    const hoverInteraction = new Select({
      condition: pointerMove,
      filter: (feature) => {
        // Do not highlight selected features and drafts
        const featureIsSelected = ctx.selectedFeatures.value.includes(feature)
        const featureIsDraft = feature.get('_draft') === true
        return !(featureIsSelected || featureIsDraft)
      },
      hitTolerance: HitTolerance,
      layers: [ctx.layer],
      style: systemsStyle('hover')
    })

    // only activate on edit mode
    const mapMode = useMapMode()
    watch(mapMode, () => {
      hoverInteraction.setActive(mapMode.value === MapMode.EDIT)
    })

    useMapInteraction(hoverInteraction)

    return { hoverInteraction }
  }

  return highlightFeature()
}
