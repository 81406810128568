import { featureIsHidden } from '@/components/map/LayerItemConfig'
import constants from '@/components/map/style/constants'
import { lineWidth, shimWidth, State } from '@/components/map/style/util'
import { FeatureLike } from 'ol/Feature'
import { Fill, Stroke, Style, Text } from 'ol/style'
import { StyleFunction } from 'ol/style/Style'

export const systemsStyle: (state?: State) => StyleFunction = (state = 'default') => {
  return (feature: FeatureLike, resolution: number) => {
    if (featureIsHidden(feature)) {
      return undefined
    }

    const color = feature.get('color') || '#aaaaaa'
    const baseStyle = new Style({
      stroke: new Stroke({
        width: lineWidth(resolution) * (state === 'select' ? 1.5 : 1),
        color,
        lineCap: 'butt'
      }),
      text: new Text({
        placement: 'line',
        text: resolution < 8 ? `${feature.get('name')}` : undefined,
        offsetY: 11,
        font: `11px ${constants.fontFamily}`,
        fill: new Fill({ color })
      })
    })

    const shimStyle = new Style({
      stroke: new Stroke({
        width: shimWidth(resolution),
        lineCap: 'butt',
        color: color + '27'
      })
    })

    if (state === 'hover') {
      return [shimStyle, baseStyle]
    } else if (state === 'select') {
      return [shimStyle, baseStyle]
    } else {
      return [baseStyle]
    }
  }
}
