<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ConductorType, ConductorTypeEnum, ConductorTypeId } from '@/model'
import { useConductorTypeStore } from '@/stores/conductor-type'
import TypeSelectDialog from '@/components/common/TypeSelectDialog.vue'
import { RouteParams } from '@/router/routeParams'

function conductorTypesToListItems(types: ConductorType[]) {
  return types.map((item) => ({
    value: item.id,
    label: item.name
  }))
}

export default defineComponent({
  name: 'ConductorTypeSelectDialog',
  components: { TypeSelectDialog },

  props: {
    modelValue: String as PropType<ConductorTypeId>
  },
  emits: ['update:modelValue'],

  data: () => ({
    currentId: undefined as undefined | ConductorTypeId,
    ConductorTypeEnum
  }),

  computed: {
    conductorType(): ConductorType | null {
      return this.currentId ? this.store.findById(this.currentId) ?? null : null
    },
    globalItems() {
      return conductorTypesToListItems(this.store.globalItems)
    },

    projectId() {
      return this.$route.params[RouteParams.ProjectId] as string
    },
    projectItems() {
      return conductorTypesToListItems(this.store.projectItems)
    }
  },

  setup() {
    return {
      store: useConductorTypeStore()
    }
  },

  mounted() {
    this.currentId = this.modelValue
    this.store.ensureLoaded()
    this.store.ensureLoadedByProject(this.projectId)
  },

  methods: {
    async onSubmit() {
      let id = this.currentId
      if (!this.conductorType?.project && id) {
        // duplicate global type into project
        const newItem = await this.store.copyIntoProject(id, this.projectId)
        id = newItem.id
      }
      this.$emit('update:modelValue', id)
    }
  }
})
</script>

<template>
  <TypeSelectDialog
    v-if="store.loaded"
    v-bind="$attrs"
    :global-items="globalItems"
    :modelValue="modelValue"
    :onSubmit="onSubmit"
    :project-items="projectItems"
    title="Leitertyp auswählen"
    @update:modelValue="currentId = $event"
  >
    <template #default>
      <div v-if="conductorType" class="flex flex-col max-w-[60ch] h-full">
        <div class="w-full text-xl font-semibold pt-1 pb-4">{{ conductorType.name }}</div>

        <div class="flex-1 text-gray-500">
          <table>
            <tr>
              <th>Typ</th>
              <td>
                {{ conductorType.type === ConductorTypeEnum.SOLID ? 'Vollleiter' : 'Hohlleiter' }}
              </td>
            </tr>
            <tr>
              <th>Durchmesser</th>
              <td v-if="conductorType.type === ConductorTypeEnum.SOLID">
                {{ $n(conductorType.outerRadius) }} mm
              </td>
              <td v-else>
                innen / außen: {{ conductorType.innerRadius && $n(conductorType.innerRadius) }} /
                {{ $n(conductorType.outerRadius) }} mm
              </td>
            </tr>
            <tr>
              <!-- prettier-ignore -->
              <th>R<sub>DC</sub> 20°C</th>
              <td>{{ $n(conductorType.rDC) }} Ω/km</td>
            </tr>
            <tr>
              <!-- prettier-ignore -->
              <th>μ<sub>r</sub></th>
              <td>{{ $n(conductorType.muR) }}</td>
            </tr>

            <template v-if="(conductorType.subconductorCount || 0) > 1">
              <tr>
                <td colspan="2" class="pt-8 italic">Bündelleiter</td>
              </tr>
              <tr>
                <th>Anzahl Einzelleiter:</th>
                <td>{{ conductorType.subconductorCount }}</td>
              </tr>
              <tr>
                <th>Abstand:</th>
                <td>{{ conductorType.subconductorSpacing }} m</td>
              </tr>
              <tr>
                <th>Drehung:</th>
                <td>{{ conductorType.subconductorAngle }} °</td>
              </tr>
            </template>
          </table>
        </div>
        <div v-if="!conductorType?.project" class="text-base bg-info-200 text-info-700 p-4 mt-8">
          <p class="mb-2">
            Mit der Auswahl dieses Leitertyps aus der globalen Typenbibliothek wird er in die
            Projektbibliothek übernommen. Sie können den Leitertyp in der Projektbibliothek
            anschließend nach Bedarf anpassen.
          </p>
        </div>
      </div>
    </template>
  </TypeSelectDialog>
</template>

<style scoped lang="css">
th {
  text-align: left;
  font-weight: 600;
  @apply pr-8;
}
</style>
