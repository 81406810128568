import { Diagnostic, linter } from '@codemirror/lint'
import { EditorView } from '@codemirror/view'
import { syntaxTree } from '@codemirror/language'

// Linter-Funktion
export function linterSource(view: EditorView) {
  const diagnostics: Diagnostic[] = []
  syntaxTree(view.state)
    .cursor()
    .iterate((node) => {
      if (node.type.isError) {
        diagnostics.push({
          from: node.from,
          to: node.to,
          severity: 'error',
          message: 'Syntaxfehler'
        })
      }
    })
  return diagnostics
}

export const FormulaSyntaxLinter = linter(linterSource)
