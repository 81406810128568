export type State = 'default' | 'hover' | 'select'

export function constrainedSize(min: number, max: number, resolution: number) {
  return Math.max(min, Math.min(20 / resolution, max))
}

export function lineWidth(resolution: number) {
  return constrainedSize(3, 6, resolution)
}

export function shimWidth(resolution: number) {
  return lineWidth(resolution) * 4 + 1
}
