/**
 * https://carbondesignsystem.com/guidelines/icons/library/
 */
import {
  ChevronDown,
  ChevronRight,
  ChevronUp,
  Edit,
  GroupObjects,
  Locked,
  Play,
  Ruler,
  SkipBack,
  SkipForward,
  UngroupObjects,
  ZoomFit,
  ZoomIn,
  ZoomOut
} from '@vicons/carbon'

/**
 * https://ionic.io/ionicons/
 */
import {
  AddOutline,
  CloseOutline,
  CloudDownloadOutline,
  CloudUploadOutline,
  ContractOutline,
  CreateOutline,
  DuplicateOutline,
  EllipsisHorizontal,
  ExpandOutline,
  EyeOutline,
  HandRightOutline,
  LogOutOutline,
  Options,
  Person,
  PlayBackOutline,
  RefreshOutline,
  Search,
  SettingsOutline,
  TrashBinOutline,
  TriangleOutline,
  WarningOutline
} from '@vicons/ionicons5'

import MediaGroupAdd from '@/icons/custom/media-group-add.svg'
import MediaGroupDelete from '@/icons/custom/media-group-delete.svg'
import MediaGroupJoin from '@/icons/custom/media-group-join.svg'
import MediaGroupSplit from '@/icons/custom/media-group-split.svg'
import OverheadLineAdd from '@/icons/custom/overheadline-add.svg'
import OverheadLineDelete from '@/icons/custom/overheadline-delete.svg'
import TowerAdd from '@/icons/custom/tower-add.svg'
import TowerDelete from '@/icons/custom/tower-delete.svg'
import Rotate from '@/icons/custom/rotate.svg'
import Mirror from '@/icons/custom/mirror.svg'
import Remove from '@/icons/custom/remove.svg'
import Alert from '@/icons/custom/warning.svg'

/**
 * https://element-plus.org/en-US/component/icon.html#icon-collection
 */
import { CopyDocument, WarnTriangleFilled } from '@element-plus/icons-vue'

export default {
  Add: AddOutline,
  ChevronDown,
  ChevronUp,
  ChevronRight,
  Close: CloseOutline,
  Delete: TrashBinOutline,
  Download: CloudDownloadOutline,
  Import: CloudUploadOutline,
  Duplicate: DuplicateOutline,
  Duplicate2: CopyDocument,
  Edit: CreateOutline,
  Ellipsis: EllipsisHorizontal,
  ExitFullscreen: ContractOutline,
  First: SkipBack,
  Fullscreen: ExpandOutline,
  Logout: LogOutOutline,
  Locked,
  MediaGroupAdd,
  MediaGroupDelete,
  MediaGroupJoin,
  MediaGroupSplit,
  MediaGroupConcat: GroupObjects,
  MediaUngroup: UngroupObjects,
  Next: Play,
  Last: SkipForward,
  Options: Options,
  OverheadLineAdd,
  OverheadLineDelete,
  Pan: HandRightOutline,
  Pen: Edit,
  Previous: PlayBackOutline,
  Ruler,
  User: Person,
  Search: Search,
  Settings: SettingsOutline,
  TowerAdd,
  TowerDelete,
  Warning: WarningOutline,
  WarningFilled: WarnTriangleFilled,
  Show: EyeOutline,
  Refresh: RefreshOutline,
  ZoomFit,
  ZoomIn,
  ZoomOut,
  Rotate,
  Mirror,
  Remove,
  Alert,
  TriangleOutline
}
