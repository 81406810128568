import { FieldConfig } from '@prionect/ui/dist/types/components/form/types'
import { Tower } from '@gridside/hsb-api/dist/models/Tower'
import { TowerDirectionProperties } from '@gridside/hsb-api/dist/models/TowerDirectionProperties'
import { copy, keys } from '@/util'

const fields: Record<
  keyof Omit<Tower, 'id' | 'in' | 'out' | 'overheadLine' | 'relatedTowers'>,
  FieldConfig
> = {
  name: {
    name: 'name',
    label: 'Name'
  },
  position: {
    name: 'position',
    type: 'number',
    label: 'Position',
    min: 1,
    step: 1,
    maxFractionDigits: 0
  },
  x: {
    name: 'x',
    type: 'number',
    label: 'X-Koordinate',
    required: true
  },
  y: {
    name: 'y',
    type: 'number',
    label: 'Y-Koordinate',
    required: true
  },
  earthResistivity: {
    name: 'earthResistivity',
    type: 'number',
    label: 'Erdwiderstand ρE',
    required: true,
    step: 1,
    min: 0,
    unit: 'Ωm'
  }
}
export const towerDirectionPropertiesFields: Record<keyof TowerDirectionProperties, FieldConfig> = {
  type: {
    name: 'type',
    type: 'select',
    label: 'Masttyp',
    required: true,
    items: []
  },
  offset: {
    name: 'offset',
    type: 'number',
    label: 'Aufstockung',
    step: 1,
    unit: 'm'
  },
  allocation: {
    name: 'allocation',
    type: 'select',
    label: 'Leiterzuordnung',
    required: true
  },
  earthwires: {
    name: 'earthwires',
    type: 'select',
    items: [],
    label: 'Erdseil',
    required: true
  }
}

export type InOutProperty<T> = {
  in: T
  out: T
}
function getFieldsInOut(): InOutProperty<typeof towerDirectionPropertiesFields> {
  const fieldsIn = copy(towerDirectionPropertiesFields)
  const fieldsOut = copy(towerDirectionPropertiesFields)
  for (const key of keys(towerDirectionPropertiesFields)) {
    fieldsIn[key].name = `in.${fieldsIn[key].name}`
    fieldsOut[key].name = `out.${fieldsOut[key].name}`
  }
  return {
    in: fieldsIn,
    out: fieldsOut
  }
}
export const fieldsInOut = getFieldsInOut()

export default { ...fields, ...towerDirectionPropertiesFields }
