<script lang="ts">
import useContextMenu from '@/components/map/composables/useContextMenu'
import { UseMapItems } from '@/components/map/composables/useMap'
import { FeatureLike } from 'ol/Feature'
import { defineComponent, inject } from 'vue'
import useCoordinates from '@/composables/useCoordinates'
import { useProject } from '@/composables/useProject'
import { useEventListener } from '@vueuse/core'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import useHoverInformation from '@/components/map/composables/useHoverInformation'

export type ContextMenuItemBase = {
  id: string
  label: string
  icon?: string
  priority: number
}
export type ContextMenuItemAction = ContextMenuItemBase & {
  handler: (coordinate: { x: number; y: number }) => void
}
export type ContextMenuItemSubmenu = ContextMenuItemBase & {
  submenuTitle: string
  submenu: ContextMenuItemAction[]
}
export type ContextMenuItem = ContextMenuItemAction | ContextMenuItemSubmenu

export default defineComponent({
  name: 'ContextMenu',

  data: () => ({
    show: false,
    coordinate: [0, 0],
    menuPosition: { x: 0, y: 0 },
    menuItems: [] as ContextMenuItem[],
    featuresAtCoordinate: [] as FeatureLike[]
  }),

  setup() {
    const { map, selectedFeatures } = inject(UseMapInjectKeys.useMap) as UseMapItems
    const { disableHoverInformation } = useHoverInformation()
    const { project } = useProject()
    const { mapToProject } = useCoordinates(map.value, project.value?.crs)
    const { collectMenuItems } = useContextMenu()

    return {
      collectMenuItems,
      map,
      mapToProject,
      project,
      selectedFeatures,
      disableHoverInformation
    }
  },

  mounted() {
    const viewport = this.map.getViewport()
    // show on right click
    useEventListener(viewport, 'contextmenu', this.onRightClick)

    // hide on any other mouse event
    useEventListener(viewport, 'pointerdown', () => (this.show = false))
    useEventListener(viewport, 'wheel', () => (this.show = false))
  },

  unmounted() {
    this.disableHoverInformation = false
  },
  watch: {
    show(newValue: boolean) {
      this.disableHoverInformation = newValue
    }
  },

  methods: {
    onRightClick(e: MouseEvent) {
      e.preventDefault()

      let posX = e.offsetX
      let posY = e.offsetY

      // Fix bug where offsetX and offsetY does not get calculated correctly between browsers and browser versions
      const target = e.target as HTMLCanvasElement
      if (['absolute', 'fixed'].includes(getComputedStyle(target).position)) {
        const rect = target.getBoundingClientRect()
        posX = e.x - rect.left
        posY = e.y - rect.top
      }
      this.menuPosition = {
        x: posX,
        y: posY
      }

      const [x, y] = this.map.getEventCoordinate(e)
      const pixel = this.map.getEventPixel(e)
      this.featuresAtCoordinate = this.map.getFeaturesAtPixel(pixel)

      this.coordinate = this.mapToProject([x, y])

      this.menuItems = this.collectMenuItems(this.coordinate, {
        features: this.featuresAtCoordinate as FeatureLike[],
        selectedFeatures: this.selectedFeatures,
        mapCoordinate: [x, y],
        projectCoordinate: this.coordinate
      })

      this.show = true
    },
    calcPrio(menuItems: ContextMenuItem[], index: number) {
      const item = menuItems[index]
      return (
        index > 0 &&
        Math.floor(item.priority / 100) !== Math.floor(menuItems[index - 1].priority / 100)
      )
    }
  }
})
</script>

<template>
  <div
    class="context-menu"
    :style="{ left: `${menuPosition.x}px`, top: `${menuPosition.y}px` }"
    @click="show = false"
    @click.right="show = false"
    v-bind="$attrs"
    :class="{ 'scale-y-0': !show, 'h-0 ': !show }"
  >
    <!-- Marker -->
    <div v-if="show && featuresAtCoordinate.length === 0" class="marker"></div>
    <ul>
      <template v-for="(item, index) in menuItems" :key="item.label">
        <!-- divider  -->
        <hr v-if="calcPrio(menuItems, index)" class="my-1" />

        <!-- menu items -->
        <li>
          <div
            class="flex"
            @click="'handler' in item ? item.handler({ x: coordinate[0], y: coordinate[1] }) : null"
          >
            <div class="w-8">
              <el-icon v-if="item.icon" size="20">
                <component :is="`${item.icon}Icon`" />
              </el-icon>
            </div>

            <div class="flex-1">{{ item.label }}</div>
          </div>

          <!-- sub-menu-->
          <el-icon
            v-if="'submenu' in item"
            size="16"
            color="var(--tw-color-gray-600)"
            class="-right-0"
          >
            <ChevronRightIcon />
          </el-icon>
          <ul v-if="'submenu' in item">
            <div class="text-gray-400 text-xs px-2">{{ item.submenuTitle }}</div>
            <template v-for="(subItem, subindex) in item.submenu" :key="subItem.label">
              <!-- divider  -->
              <hr v-if="calcPrio(item.submenu, subindex)" class="my-1" />
              <!-- menu items -->
              <li @click="subItem.handler({ x: coordinate[0], y: coordinate[1] })">
                <div class="flex">
                  <div class="w-8" v-if="subItem.icon">
                    <el-icon size="20">
                      <component :is="`${subItem.icon}Icon`" />
                    </el-icon>
                  </div>

                  <div class="flex-1">{{ subItem.label }}</div>
                </div>
              </li>
            </template>
          </ul>
        </li>
      </template>
      <!-- Coordinate -->
      <div v-if="coordinate" class="p-2 text-xs text-gray-400">
        Koordinate:
        <br />
        X: {{ $n(coordinate[0], { useGrouping: false, maximumFractionDigits: 0 }) }}

        Y: {{ $n(coordinate[1], { useGrouping: false, maximumFractionDigits: 0 }) }}
      </div>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.context-menu {
  @apply absolute origin-top transition-transform;
}

.context-menu ul {
  @apply bg-white p-2 shadow-lg rounded text-xs w-56 relative;
}

// menu items
.context-menu li {
  position: relative;
  cursor: pointer;
  @apply px-2 py-1 min-h-[32px] text-xs rounded cursor-pointer transition-colors hover:bg-primary-50 flex items-center;
}

// sub menu
.context-menu li > ul {
  @apply absolute origin-top transition-transform w-64;
  left: 100%;
  top: -24px;
  transform: scaleY(0);
}

// sub menu show on hover
.context-menu li:hover > ul {
  transform: scaleY(1);
}

.marker {
  @apply rounded-[100%] border-4 absolute;
  height: 30px;
  width: 30px;
  right: calc(100% - 15px);
  bottom: calc(100% - 15px);
  border-color: rgb(0, 153, 255);
}
</style>
