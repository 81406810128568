import {
  CalculationResult,
  CalculationStatus,
  CalculationStatusStatus,
  Calculation
} from '@gridside/hsb-api'

export type { CalculationResult, CalculationStatus }

export type CalculationId = Calculation['id']
export type CalculationStatusValue = CalculationStatusStatus

export enum HsbLibObjectEnum {
  MESHED_MEDIUM_SIMULATION = 'MESHED_MEDIUM_SIMULATION',
  OVERHEADLINE = 'OVERHEADLINE'
}

export const CalculationStatusTranslations = {
  [CalculationStatusStatus.INACTIVE]: 'inaktiv',
  [CalculationStatusStatus.PENDING]: 'warten',
  [CalculationStatusStatus.INITIALIZING]: 'initialisieren',
  [CalculationStatusStatus.RUNNING]: 'berechnen',
  [CalculationStatusStatus.FINISHED]: 'beendet',
  [CalculationStatusStatus.CANCELED]: 'abgebrochen',
  [CalculationStatusStatus.ERROR]: 'Fehler'
}
