import { WebSocket } from '@/api'
import { Configuration, ProjectsApi } from '@gridside/hsb-api'

/**
 * API class that extends the generated REST API with websocket functions
 */
export class ProjectsExtendedApi extends ProjectsApi {
  constructor(configuration: Configuration, public webSocket: WebSocket) {
    super(configuration)
  }

  onProjectDeleted(handler: (data: any) => void) {
    return this.webSocket.subscribe('Project.deleted', handler)
  }

  onProjectUpdated(handler: (data: any) => void) {
    return this.webSocket.subscribe('Project.updated', handler)
  }
}
