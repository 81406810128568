import constants from '@/components/map/style/constants'
import { constrainedSize, lineWidth, shimWidth, State } from '@/components/map/style/util'
import { FeatureLike } from 'ol/Feature'
import { Circle, Fill, Stroke, Style, Text } from 'ol/style'
import { colorsByState } from './util'
import { FeaturePropEnum } from '@/components/map/LayerItemConfig'

function pointRadius(resolution: number) {
  return constrainedSize(4, 12, resolution)
}

export function towerStyle(feature: FeatureLike, resolution: number, state: State = 'default') {
  const textStyle = new Style({
    text: new Text({
      placement: 'point',
      textAlign: 'start',
      text:
        resolution < 10
          ? feature.get(FeaturePropEnum.name) || feature.get(FeaturePropEnum.position)?.toString()
          : '',
      offsetX: 1.4 * pointRadius(resolution),
      offsetY: -1.4 * pointRadius(resolution),
      font: `bold ${constrainedSize(13, 14, resolution)}px ${constants.fontFamily}`,
      fill: new Fill({ color: colorsByState[state]() }),
      stroke: new Stroke({ color: 'rgba(255,255,255,0.8)', width: 4 })
    })
  })

  const circleStyle = new Style({
    image: new Circle({
      radius: pointRadius(resolution),
      fill: new Fill({ color: 'white' }),
      stroke: new Stroke({
        color: colorsByState[state](),
        width: 0.75 * lineWidth(resolution)
      })
    })
  })

  const circleShimStyle = new Style({
    image: new Circle({
      radius: pointRadius(resolution) + shimWidth(resolution) / 2,
      fill: new Fill({ color: colorsByState[state](0.2) })
    })
  })

  if (state === 'default') {
    return [circleStyle, textStyle]
  } else {
    return [circleShimStyle, circleStyle, textStyle]
  }
}
