<script lang="ts">
import TypeSelectField from '@/components/form/TypeSelectField.vue'
import { defineComponent, PropType } from 'vue'
import { PipeTypeId, ProjectId } from '@/model'
import { usePipeTypeStore } from '@/stores/pipe-type'
import MediaGroupPipeTypeSelectDialog from '@/components/media-group/MediaGroupPipeTypeSelectDialog.vue'
import { MIXED_VALUES } from '@prionect/ui'
import { RouteParams } from '@/router/routeParams'

export default defineComponent({
  name: 'MediaGroupPipeTypeSelectField',
  components: { MediaGroupPipeTypeSelectDialog, TypeSelectField },

  props: {
    modelValue: {
      type: String as PropType<PipeTypeId>
    }
  },
  emits: ['update:modelValue'],

  setup() {
    const pipeTypeStore = usePipeTypeStore()

    return { pipeTypeStore }
  },
  created() {
    this.pipeTypeStore.ensureLoadedByProject(this.projectId)
  },
  computed: {
    projectId(): ProjectId {
      return this.$route.params[RouteParams.ProjectId] as string
    },
    pipeTypeName() {
      if (this.modelValue === MIXED_VALUES) {
        return MIXED_VALUES
      }
      if (!this.modelValue) {
        return ''
      }
      const item = this.pipeTypeStore.findById(this.modelValue)
      return item?.name ?? 'unbekannt'
    }
  }
})
</script>

<template>
  <TypeSelectField
    v-bind="$attrs"
    :modelValue="modelValue"
    :text="pipeTypeName"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <template #dialog="{ show, id, hide, handleChange }">
      <MediaGroupPipeTypeSelectDialog
        v-if="show"
        :modelValue="id"
        :show="show"
        @close="hide"
        @update:modelValue="handleChange"
      />
    </template>
  </TypeSelectField>
</template>

<style scoped lang="css"></style>
