import {
  autocompletion,
  Completion,
  CompletionContext,
  CompletionSection,
  CompletionSource
} from '@codemirror/autocomplete'
import { EditorView } from '@codemirror/view'
import { OperationState } from '@gridside/hsb-api'
import { useSystemStore } from '@/stores/system'
import {
  afterRoundBracketsOpen,
  FunctionsImplementedEnum,
  operationStateAtomic,
  operationStateLabel,
  operationStateToString
} from '@/util/codemirror/helper'

enum CompletionSectionsEnum {
  Functions = 'Funktionen',
  OperationStates = 'Betriebszustände'
}

/**
 * See: https://codemirror.net/docs/ref/#autocomplete.CompletionSection
 */
const CompletionSections: { [key in CompletionSectionsEnum]: CompletionSection } = {
  [CompletionSectionsEnum.OperationStates]: {
    name: CompletionSectionsEnum.OperationStates,
    rank: 1
  },
  [CompletionSectionsEnum.Functions]: {
    name: CompletionSectionsEnum.Functions,
    rank: 2
  }
} as const

/**
 * Extension for autocompletion
 */
export function createOperationStatesAutocompletion(operationsStates: OperationState[]) {
  const operationStateCompletionOptions: Completion[] = operationsStates.map((value, index) => {
    const systemStore = useSystemStore()
    const conductorStates = operationStateToString(value)
    const infoContainer = document.createElement('div')
    const systemName = systemStore.systemName(value.system)
    infoContainer.innerHTML = `System: <b>${systemName}</b> <br> Leiter: <b>${conductorStates}</b>`
    return {
      label: operationStateLabel(index),
      apply: operationStateAtomic(index),
      detail: `System: ${systemName}`,
      info: () => infoContainer,
      type: 'constant',
      section: CompletionSections[CompletionSectionsEnum.OperationStates]
    }
  })

  function operationStateCompletions(context: CompletionContext): ReturnType<CompletionSource> {
    const op = context.matchBefore(/\d*|\w*/)
    // console.log(context, op)
    if (op === null) {
      return null
    }
    if (op.from == op.to && !context.explicit) {
      return null
    }
    return {
      from: op.from,
      options: operationStateCompletionOptions
    }
  }

  return autocompletion({
    override: [functionCompletionsHandler, operationStateCompletions]
  })
}

/**
 * Dispatch an event for autocompletion & put the cursor after the first open bracket
 */
export function applyFunctionCompletion(
  view: EditorView,
  completion: Completion,
  from: number,
  to: number
) {
  view.dispatch({
    changes: { from, to, insert: completion.label },
    selection: { anchor: afterRoundBracketsOpen(completion.label, from) }
  })
}

const FunctionCompletions: { [key in FunctionsImplementedEnum]: Completion } = {
  [FunctionsImplementedEnum.MIN]: {
    label: 'min()',
    type: 'function',
    info: 'Returns the smallest of the numbers given as input parameters.',
    section: CompletionSections[CompletionSectionsEnum.Functions],
    apply: applyFunctionCompletion
  },
  [FunctionsImplementedEnum.MAX]: {
    label: 'max()',
    type: 'function',
    info: 'Return largest number of given parameters.',
    section: CompletionSections[CompletionSectionsEnum.Functions],
    apply: applyFunctionCompletion
  },
  [FunctionsImplementedEnum.POW]: {
    label: 'pow(,2)',
    type: 'function',
    detail: 'xʸ',
    info: 'Returns the value of a base raised to a power.\n\npow(x,y) = x^y',
    section: CompletionSections[CompletionSectionsEnum.Functions],
    apply: applyFunctionCompletion
  },
  [FunctionsImplementedEnum.ABS]: {
    label: 'abs()',
    type: 'function',
    detail: '|x|',
    info: 'Returns the absolute value of a number.',
    section: CompletionSections[CompletionSectionsEnum.Functions],
    apply: applyFunctionCompletion
  },
  [FunctionsImplementedEnum.SQRT]: {
    label: 'sqrt()',
    type: 'function',
    info: 'Returns the square root of a number.',
    detail: '√²',
    section: CompletionSections[CompletionSectionsEnum.Functions],
    apply: applyFunctionCompletion
  }
}
export function functionCompletionsHandler(
  context: CompletionContext
): ReturnType<CompletionSource> {
  const word = context.matchBefore(/\w*/)
  if (word === null) {
    return null
  }
  if (word.from == word.to && !context.explicit) {
    return null
  }
  return {
    from: word.from,
    options: Object.values(FunctionCompletions)
  }
}
