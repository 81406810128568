<script setup lang="ts">
import SystemTag from '@/components/system/SystemTag.vue'
import { useSystemStore } from '@/stores/system'

defineProps({
  hasError: { type: Boolean, default: false }
})
defineEmits(['click'])

const systemStore = useSystemStore()
</script>

<template>
  <el-dropdown
    trigger="hover"
    :hide-on-click="false"
    :teleported="false"
    :show-timeout="0"
    v-bind="$attrs"
  >
    <el-button
      :class="{
        '!border-2 !border-danger-500': hasError
      }"
    >
      System hinzufügen
      <el-icon class="el-icon--right"><ChevronDownIcon /></el-icon>
    </el-button>
    <template #dropdown>
      <el-dropdown-menu class="min-w-44">
        <el-dropdown-item
          v-for="system in systemStore.items"
          :key="system.id"
          @click="$emit('click', system)"
        >
          <SystemTag :system="system.id" class="flex-1" />
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
  <div v-if="hasError" class="text-error-500 font-semibold text-sm mt-1">
    Wählen Sie mindestens ein System aus.
  </div>
</template>

<style scoped lang="css"></style>
