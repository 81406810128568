<script lang="ts">
import { useAppAbility } from '@/permissions/useAppAbility'
import { defineComponent, PropType } from 'vue'
import EntityTableView from '@/components/common/EntityTableView.vue'
import { usePipeTypeStore } from '@/stores/pipe-type'
import { ConductorType, MediaGroupId, PipeType, PipeTypeId, ProjectId } from '@/model'
import { useMediaGroupStore } from '@/stores/media-group'
import { useCommonPermissions } from '@/permissions/useCommonPermissions'
import { useDuplicate } from '@/composables/crud-helpers/useDuplicate'
import { useDelete } from '@/composables/crud-helpers/useDelete'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'PipeTypeListView',
  components: { EntityTableView },
  props: {
    projectId: {
      type: String as PropType<ProjectId | undefined>
    }
  },
  setup({ projectId }) {
    const route = useRoute()
    const pipeTypeStore = usePipeTypeStore()
    const mediaGroupStore = useMediaGroupStore()

    pipeTypeStore.init()

    const { can } = useAppAbility()
    const { canImportLibrary } = useCommonPermissions()

    const { duplicateItems } = useDuplicate(pipeTypeStore.save)
    const { deleteItems } = useDelete(pipeTypeStore.delete, {
      check: (items: ConductorType[]) => items.some((type) => type.id === route.params.id),
      routeName: projectId ? 'project-pipetypes' : 'pipetypes'
    })

    return {
      can,
      canImportLibrary,
      pipeTypeStore,
      mediaGroupStore,
      duplicateItems,
      deleteItems
    }
  },

  computed: {
    pipeTypes(): PipeType[] {
      if (this.projectId) {
        // Add "deletable" flag to pipe type items
        return this.pipeTypeStore.projectItems.map((pipeType) => {
          const deletable = !Object.keys(this.usedPipeTypes).includes(pipeType.id)
          const deletableHint = deletable
            ? undefined
            : 'wird bei folgenden Mediengruppen genutzt:\n' +
              this.usedPipeTypes[pipeType.id]
                .map((mediaGroupId) => this.mediaGroupStore.findById(mediaGroupId)?.name)
                .join(', ')
          return { ...pipeType, deletable, deletableHint }
        })
      } else {
        return this.pipeTypeStore.globalItems
      }
    },

    readOnly(): boolean {
      return this.projectId ? false : !this.can('update', 'Library')
    },

    /**
     * Record with all used pipe types with the ids of all media-groups groups they are used in
     */
    usedPipeTypes(): Record<PipeTypeId, MediaGroupId[]> {
      if (!this.projectId) {
        return {}
      }
      const data: Record<PipeTypeId, MediaGroupId[]> = {}
      this.pipeTypeStore.projectItems.forEach((pipeType) => {
        const usedInMediaGroups = this.mediaGroupStore.items
          .filter((mediaGroup) =>
            'pipeType' in mediaGroup ? mediaGroup.pipeType === pipeType.id : false
          )
          .map((mediaGroup) => mediaGroup.id)
        if (usedInMediaGroups.length > 0) {
          data[pipeType.id] = usedInMediaGroups
        }
      })
      return data
    }
  },

  mounted() {
    this.pipeTypeStore.ensureLoaded()
    if (this.projectId) {
      this.pipeTypeStore.ensureLoadedByProject(this.projectId)
      this.mediaGroupStore.ensureLoaded(this.projectId)
    }
  }
})
</script>

<template>
  <EntityTableView
    v-model:selection="pipeTypeStore.selection"
    :allow-create="!readOnly"
    :allow-duplicate="!readOnly"
    :allow-delete="!readOnly"
    :allow-edit="false"
    create-label="Neuer Rohrleitungstyp"
    confirm-delete-label="Rohrleitungstyp löschen"
    default-sort="name"
    :items="pipeTypes"
    :loading="pipeTypeStore.loading"
    :search-properties="['name', 'type']"
    :title="projectId ? 'Projekt-Rohrleitungstypen' : 'Rohrleitungstypen'"
    @create="$router.push({ name: projectId ? 'project-pipetypes-create' : 'pipetypes-create' })"
    @edit="
      $router.push({
        name: projectId ? 'project-pipetypes-edit' : 'pipetypes-edit',
        params: { id: $event }
      })
    "
    @delete-items="deleteItems"
    @duplicate-items="duplicateItems"
  >
    <template v-if="!projectId" #extra-tools>
      <p-btn :disabled="!canImportLibrary" @click="$router.push({ name: 'pipetypes-import' })">
        Import
      </p-btn>
    </template>

    <template #columns>
      <el-table-column
        prop="name"
        label="Name"
        sortable
        class-name="font-semibold"
      ></el-table-column>
      <!--      <el-table-column prop="type" label="Typ" sortable>-->
      <!--        <template #default="{ row }">-->
      <!--          <template v-if="row.type">{{ $t(`pipetype.type.${row.type}`) }}</template>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column prop="outerRadius" label="Radius" sortable="">-->
      <!--        <template #default="{ row }">-->
      <!--          <template v-if="row.type === 'tubular'">-->
      <!--            {{ row.innerRadius === undefined ? '?' : $n(row.innerRadius) }} /-->
      <!--          </template>-->
      <!--          {{ $n(row.outerRadius) }} mm-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column prop="subconductorCount" label="Bündelleiter" sortable="">-->
      <!--        <template #default="{ row }">-->
      <!--          <template v-if="row.subconductorCount">{{ row.subconductorCount }}</template>-->
      <!--          <template v-else>&ndash;</template>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </template>

    <template #confirm-delete="{ items }">
      <p v-if="items.length === 1">
        Wollen Sie den Rohrleitungstyp
        <b>{{ items[0].name }}</b>
        wirklich löschen?
      </p>
      <p v-else>
        Wollen Sie diese Rohrleitungstypen wirklich löschen?
        <ul>
          <li v-for="item in items" :key="item.id"><b>{{ item.name }}</b></li>
        </ul>
      </p>
      <p class="!mt-2 text-gray-400 text-base">
        <template v-if="projectId">
          Das Löschen dieser Rohrleitungstypen hat keinen Einfluss auf die globale Typbibliothek.
        </template>
        <template v-else>
          Die Kopien dieser Rohrleitungstypen in Projekten bleiben erhalten.
        </template>
      </p>
    </template>
  </EntityTableView>
</template>

<style scoped lang="css"></style>
