<script lang="ts" setup>
import { UseMapItems } from '@/components/map/composables/useMap'
import ColorLegend from '@/components/map/layer/result/ColorLegend.vue'
import { useProject } from '@/composables/useProject'
import { Feature } from 'ol'
import { computed, inject } from 'vue'
import { LayerId } from '@/components/map'
import { useCalculationStore } from '@/stores/calculation'
import { Vector as VectorSource } from 'ol/source'
import { GeoJSON as GeoJSONFormat } from 'ol/format'
import { emfStyle } from '@/components/map/style/emf'
import { useProjectStore } from '@/stores/project'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import LayerVector from '@/components/map/layer/LayerVector.vue'
import { InformationOnHoverProp } from '@/components/map/layer/LayerBase.vue'
import { FeatureLike } from 'ol/Feature'
import { FeaturePropEnum } from '@/components/map/LayerItemConfig'

const { projectId } = useProject()
const calculationStore = useCalculationStore()
calculationStore.ensureLoaded(projectId.value)

/**
 * Result GeoJSON
 */
const geoJSON = computed(() => {
  const resultGeoJSON = calculationStore.resultEmfGeoJSON
  if (!resultGeoJSON) {
    return undefined
  }

  resultGeoJSON.features = resultGeoJSON.features.map((f) => ({
    ...f,
    properties: { ...f.properties, _type: 'emf' }
  }))

  return resultGeoJSON
})

/**
 * OpenLayers-Source made of GeoJSON
 */
const source = computed(() => {
  const map = inject(UseMapInjectKeys.map)
  if (!geoJSON.value || !map) {
    return undefined
  }

  const projectStore = useProjectStore()
  const project = projectStore.findById(projectId.value)

  const geoJsonOptions = {
    dataProjection: project?.crs,
    featureProjection: map.getView().getProjection()
  }

  return new VectorSource({
    features: new GeoJSONFormat(geoJsonOptions).readFeatures(geoJSON.value) as Feature[]
  })
})

const informationOnHover: InformationOnHoverProp = (item: FeatureLike) => {
  // because the results also return features with types (we already draw) we need to filter them out
  return !item.get(FeaturePropEnum.type)
}

const showLegend = computed(() => {
  const { layers } = inject(UseMapInjectKeys.useMap) as UseMapItems
  return layers.value['result'].options['showEmfResult']
})
</script>

<template>
  <div>
    <LayerVector
      v-if="source"
      v-bind="$attrs"
      :id="LayerId.EMF"
      :source="source"
      :layer-style="emfStyle"
      :information-on-hover="true"
      render-as-image
    />
    <ColorLegend v-if="showLegend" class="z-30 absolute right-10 top-2 w-[70px]" />
  </div>
</template>
