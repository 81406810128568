import { Circle, Fill, Stroke, Style } from 'ol/style'
import { Coordinate } from 'ol/coordinate'
import { LineString, MultiPoint } from 'ol/geom'
import constants from '@/components/map/style/constants'
import { StyleFunction } from 'ol/style/Style'
import { lineWidth, shimWidth } from '@/components/map/style/util'
import { strokeStyle } from '@/components/map/style/media-group'

const alpha = 0.15

export const mediaStyleDefault: StyleFunction = (feature, resolution) => {
  return [
    new Style({
      stroke: new Stroke({
        width: lineWidth(resolution),
        color: constants.media.default(0.01)
      })
    })
  ]
}

export const mediaStyleHover: StyleFunction = (feature, resolution) => {
  return [
    new Style({
      stroke: new Stroke({
        width: shimWidth(resolution),
        color: constants.media.hoverColor(alpha)
      })
    })
  ]
}

export const mediaStyleSelect: StyleFunction = (feature, resolution) => {
  return [
    new Style({
      stroke: new Stroke({
        width: lineWidth(resolution) + 6,
        color: 'white'
      })
    }),
    new Style({
      stroke: new Stroke({
        width: lineWidth(resolution),
        color: constants.selected.color()
      })
    }),
    // Line Vertices (points)
    new Style({
      image: new Circle({
        radius: lineWidth(resolution),
        fill: new Fill({ color: 'white' }),
        stroke: new Stroke({
          color: strokeStyle(resolution, 'select').getColor(),
          width: 0.75 * lineWidth(resolution)
        })
      }),
      geometry: function (feature) {
        // return the coordinates of the linestring
        // see https://openlayers.org/en/latest/examples/polygon-styles.html
        const geometry = feature.getGeometry()
        let coordinates: Coordinate[] = []
        if (geometry instanceof LineString) {
          coordinates = geometry.getCoordinates()
        }
        return new MultiPoint(coordinates)
      }
    })
  ]
}
