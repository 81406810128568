<script lang="ts">
import HsbMap from '@/components/map/HsbMap.vue'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MapView',
  components: { HsbMap },

  setup() {},

  mounted() {}
})
</script>

<template>
  <div class="flex-1 relative h-full shadow-inner bg-white">
    <HsbMap />
  </div>
</template>

<style scoped lang="css"></style>
