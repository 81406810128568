import useMapInteraction from '@/components/map/composables/useMapInteraction'
import { MapMode, useMapMode } from '@/components/map/composables/useMapMode'
import { HitTolerance, SourceLayerContext } from '@/components/map/layer/util'
import { Select } from 'ol/interaction'
import { pointerMove } from 'ol/events/condition'
import { sourceHoverStyle } from '@/components/map/style/source'
import { watch } from 'vue'
import { featureIsDraft } from '@/components/map/LayerItemConfig'

export function useSourceHover(ctx: SourceLayerContext) {
  /**
   * Add map interaction to highlight source features on mouse hover
   */
  function highlightFeature() {
    const hoverInteraction = new Select({
      condition: pointerMove,
      filter: (feature) => {
        // Do not highlight selected features and drafts
        const featureIsSelected = ctx.selectedFeatures.value.includes(feature)
        return !(featureIsSelected || featureIsDraft(feature))
      },
      hitTolerance: HitTolerance,
      layers: [ctx.layer],
      style: sourceHoverStyle
    })

    // only activate on edit mode
    const mapMode = useMapMode()
    watch(mapMode, () => {
      hoverInteraction.setActive(mapMode.value === MapMode.EDIT)
    })

    useMapInteraction(hoverInteraction)

    return { hoverInteraction }
  }

  return highlightFeature()
}
