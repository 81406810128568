import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { RouteParams } from '@/router/routeParams'
import { useOverheadLineStore } from '@/stores/overhead-lines'

export function useOverheadLine() {
  const route = useRoute()
  const overheadLineStore = useOverheadLineStore()
  const overheadLineId = computed(() => {
    if (route.params[RouteParams.OverheadLineId]) {
      return route.params[RouteParams.OverheadLineId] as string
    } else if (route.params[RouteParams.SpanId]) {
      const spanId = route.params[RouteParams.SpanId] as string
      return overheadLineStore.spansAll.find((span) => span.id === spanId)?.overheadLine
    } else if (route.params[RouteParams.TowerId]) {
      const towerId = route.params[RouteParams.TowerId] as string
      return overheadLineStore.towersAll.find((tower) => tower.id === towerId)?.overheadLine
    } else {
      return undefined
    }
  })
  const overheadLineRecord = computed(() =>
    overheadLineStore.findByIdOrFail(overheadLineId.value || '(undefined)')
  )

  return { overheadLineRecord, overheadLineId, overheadLineStore }
}
