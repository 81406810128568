<script lang="ts">
import { useProjectStore } from '@/stores/project'
import { FeatureCollection } from 'geojson'
import { defineComponent } from 'vue'
import MediaGroupImportStep from './MediaGroupImportStep.vue'
import { MediaGroup } from '@/model'
import MediaGroupPropertiesMapped from '@/components/media-group/MediaGroupPropertiesMapped.vue'
import SelectMediaGroupFileStep from '@/components/media-group/import-wizard/SelectMediaGroupFileStep.vue'

export default defineComponent({
  name: 'MediaGroupImportWizard',
  components: { SelectMediaGroupFileStep, MediaGroupPropertiesMapped, MediaGroupImportStep },

  data: () => ({
    fileIsValid: false,
    geoJson: null as FeatureCollection | null,
    importFile: undefined as File | undefined,
    mediaGroups: [] as MediaGroup[]
  }),

  setup() {
    return {
      projectStore: useProjectStore()
    }
  },

  computed: {
    steps() {
      return [
        {
          id: 'file',
          title: 'Schritt 1/3: Importdatei für Mediengruppen auswählen',
          nextButtonDisabled: !this.fileIsValid
        },
        {
          id: 'mediaGroupProperties',
          title: 'Schritt 2/3: Mediengruppen-Eigenschaften festlegen'
        },
        { id: 'import', title: 'Schritt 3/3: Import durchführen', nextButtonLabel: 'Schließen' }
      ]
    }
  }
})
</script>

<template>
  <p-wizard :steps="steps" class="!w-[80rem]">
    <template #step:file>
      <SelectMediaGroupFileStep
        v-model="geoJson"
        v-model:file="importFile"
        v-model:isValid="fileIsValid"
      />
    </template>
    <template #step:mediaGroupProperties>
      <MediaGroupPropertiesMapped
        v-if="geoJson"
        v-model="mediaGroups"
        :geo-json="geoJson"
        class="gap-x-12 gap-y-0 max-w-[80ch] mx-auto"
      />
    </template>
    <template #step:import>
      <MediaGroupImportStep :geoJson="geoJson" :media-groups="mediaGroups" />
    </template>
  </p-wizard>
</template>

<style lang="css" scoped></style>
