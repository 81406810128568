<script setup lang="ts">
import { useProject } from '@/composables/useProject'
import { useSystemStore } from '@/stores/system'
import { Feature } from 'ol'
import { computed, PropType } from 'vue'

const props = defineProps({
  feature: {
    type: Object as PropType<Feature>,
    required: true
  }
})
const systemStore = useSystemStore()
const { projectId } = useProject()
systemStore.ensureLoaded(projectId.value)

const system = computed(() => systemStore.findById(props.feature?.getId() as string))
</script>

<template>
  <div if="system">
    <h3 class="flex">
      <div class="w-4 h-4 rounded mr-2" :style="{ backgroundColor: system?.color }"></div>
      {{ system?.name }}
    </h3>
    <table>
      <tbody>
        <tr>
          <th>Leiteranzahl</th>
          <td>{{ system?.wireCount }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
