import { MapState } from '@/components/map/composables/useMapState'
import { useLayerTreeConfig } from '@/components/map/controls/layers/config'
import { LayerTreeItemType } from '@/components/map/controls/layers/types'
import { useProject } from '@/composables/useProject'
import deepmerge from 'deepmerge'
import { Map } from 'ol'
import { Layer } from 'ol/layer'
import BaseLayer from 'ol/layer/Base'
import { computed, ref, Ref, watch } from 'vue'

type MapLayersOptions = {
  map: Ref<Map>
  mapState: Ref<MapState>
}

export type LayerStatus = {
  id: string
  getMapLayer: () => BaseLayer | undefined
  options: Record<string, any>
  visible: boolean
  visibleFeatures?: string[]
}

export type LayerState = Record<string, LayerStatus>

type LayerLike = string | Layer

export default function useMapLayers(options: MapLayersOptions) {
  const { mapState, map } = options
  const { project } = useProject()
  if (!project.value) {
    throw new Error('useMapLayers: No project given')
  }
  const layerConfig = useLayerTreeConfig()

  const defaultState = computed(() => {
    const state: LayerState = {}

    // retrieve option defaults
    layerConfig.value.forEach((layer) => {
      const layerOptions: LayerStatus['options'] = {}
      if (layer.type === LayerTreeItemType.LAYER) {
        layer.options?.forEach((option) => {
          if (option.defaultValue !== undefined) {
            layerOptions[option.id] = option.defaultValue
          }
        })
      }

      // build layer status object
      state[layer.id] = {
        id: layer.id,
        visible: layer.defaultValue ?? true,
        options: layerOptions,
        getMapLayer: () => undefined
      }
    })
    return state
  })

  const existingStateFromLocalStorage = mapState.value?.layers

  const layers = ref<LayerState>(
    deepmerge(defaultState.value, existingStateFromLocalStorage) as LayerState
  )

  /**
   * Array of layer ids which are managed by the useMapLayer() composable. This is a subset of
   * all layers, since basemap and tool layers are not in this list
   */
  const managedLayers = computed(() => layerConfig.value.map((definition) => definition.id))

  // update map state whenever the layers change (note that the layers state is a ref itself)
  watch(
    layers,
    () => {
      const persistentLayers: LayerState = {}
      Object.entries(layers.value)
        .filter(([key, value]) => managedLayers.value.includes(key))
        .forEach(([key, value]) => {
          persistentLayers[key] = value
        })

      mapState.value.layers = persistentLayers
    },
    { immediate: true, deep: true }
  )

  const mapLayerCollection = map.value.getLayers()
  mapLayerCollection.on('add', (event) => {
    const addedLayer = event.element
    const layerId = addedLayer.get('id')
    if (managedLayers.value.includes(layerId)) {
      layers.value[layerId] = { ...defaultState.value[layerId], getMapLayer: () => addedLayer }
      syncToMap()
    }
  })

  /**
   * Sync layer visibilities to the OpenLayers map
   *
   * Note that only base (root) layers are synced
   */
  function syncToMap() {
    const layerState = layers.value

    Object.keys(layerState).forEach((layerId) => {
      if (typeof layerState[layerId].getMapLayer === 'function') {
        const layer = layerState[layerId].getMapLayer()
        layer?.setVisible(layerState[layerId].visible)
      }
    })
  }

  function getLayerOption(layer: LayerLike, option: string) {
    const mapLayer = toLayer(layer)
    return (mapLayer && layers.value[mapLayer.get('id')].options[option]) || undefined
  }

  function setLayerOption(layer: LayerLike, option: string, value: any) {
    const mapLayer = toLayer(layer)
    if (mapLayer) {
      layers.value[mapLayer.get('id')].options[option] = value
    }
  }

  function showLayer(layer: string | Layer, show = true, updateState = true) {
    const mapLayer = toLayer(layer)
    if (!mapLayer) {
      console.log('tried to access non-existent layer', layer)
      return
    }

    const layerId = mapLayer.get('id')
    mapLayer.setVisible(show)

    if (updateState) {
      layers.value[layerId].visible = show
    }
  }

  function toggleLayer(layer: string | Layer) {
    const layerId = typeof layer === 'string' ? layer : layer.get('id')
    showLayer(layer, !layers.value[layerId].getMapLayer()?.getVisible() ?? true)
  }

  function toLayer(layer: LayerLike) {
    return typeof layer === 'string' ? layers.value[layer]?.getMapLayer() : layer
  }

  return { getLayerOption, layers, setLayerOption, showLayer, toggleLayer }
}
