import { useHttpClient } from '@/api/useHttpClient'
import { computed, ref, watch } from 'vue'
import type { AuthProviderComposable } from '@prionect/ui'
import { AppAbility, defineRulesFor } from '@/permissions/ability'
import { User } from '@/model'
import { HsbApi, useWebSocket } from '@/api'
import { pendingAuthentication } from '@/composables/useGridsideAzureAuthentication'

const currentUser = ref<Partial<User> | null>(null)
const { authToken } = useHttpClient()

export const LOCALSTORAGE_KEY = 'HSB_E2E_TOKEN'
watch(authToken, (token) => {
  if (token) {
    localStorage.setItem(LOCALSTORAGE_KEY, token)
  } else {
    localStorage.removeItem(LOCALSTORAGE_KEY)
  }
})

const doLogin = async (token: string) => {
  try {
    // Login WS
    await useWebSocket().connect({ email: token })

    // Login HTTP
    authToken.value = token
    currentUser.value = await HsbApi.users.getCurrentUser()

    // Update user access
    AppAbility.update(defineRulesFor(currentUser.value as User))

    // Token refresh (not needed)
  } catch (e) {
    throw 'Login fehlgeschlagen - ' + (e as Error).message
  }
}

/**
 * Authentication for Gridside Websocket with email address as access token
 * (used in E2E tests to bypass Azure authentication)
 */
export const useTestMockAuthentication: AuthProviderComposable = () => ({
  currentUser,
  isAuthenticated: computed(() => !!currentUser.value && !!authToken.value),
  async initialize() {
    pendingAuthentication.value = true

    // Get local storage data
    const existingToken = localStorage.getItem(LOCALSTORAGE_KEY)
    if (!existingToken) {
      pendingAuthentication.value = false
      return
    }

    // Perform login
    await doLogin(existingToken)
    pendingAuthentication.value = false
  },

  async login(options: any) {
    if (options.username) {
      const token = options.username || 'NONE'
      await doLogin(token)
      return true
    }
    return false
  },
  async logout() {
    currentUser.value = null
    authToken.value = null
  }
})
