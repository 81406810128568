<script lang="ts">
import DebugPanel from '@/components/map/DebugPanel.vue'
import OsmLayer from '@/components/map/layer/basemap/OsmLayer.vue'
import BasemapGrayLayer from '@/components/map/layer/basemap/BasemapGrayLayer.vue'
import SatelliteLayer from '@/components/map/layer/basemap/SatelliteLayer.vue'
import { useMap } from './composables/useMap'

import 'ol/ol.css'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'BasicMap',
  components: { DebugPanel, SatelliteLayer, BasemapGrayLayer, OsmLayer },

  setup() {
    const mapTarget = ref()
    const mapItems = useMap({ target: mapTarget, featureCrs: 'EPSG:3857' })
    return { ...mapItems, mapTarget }
  },

  computed: {
    mapType() {
      return this.mapState.basemap.type || 'grayscale'
    },
    mapOpacity() {
      return this.mapState.basemap.opacity ?? 1
    }
  }
})
</script>

<template>
  <div class="bg-white h-full overflow-hidden relative">
    <!-- Container for OL-Map canvas -->
    <div ref="mapTarget" class="h-full w-full" />

    <!-- Slots after to stack on top of map -->
    <BasemapGrayLayer id="grayscale" :visible="mapType === 'grayscale'" :opacity="mapOpacity" />
    <SatelliteLayer id="satellite" :visible="mapType === 'satellite'" :opacity="mapOpacity" />
    <OsmLayer :visible="mapType === 'osm'" id="osm" :opacity="mapOpacity" />

    <slot :map="map"></slot>

    <div class="tool-slot left-4 top-4">
      <slot name="top-left" :map="map"></slot>
    </div>

    <div class="tool-slot left-1/2 top-4 -translate-x-1/2">
      <slot name="top-center" :map="map"></slot>
    </div>

    <div class="tool-slot right-4 top-4">
      <slot name="top-right" :map="map"></slot>
    </div>

    <div class="tool-slot left-4 bottom-6">
      <slot name="bottom-left" :map="map"></slot>
    </div>

    <div class="tool-slot left-1/2 bottom-6 -translate-x-1/2">
      <slot name="bottom-center" :map="map"></slot>
    </div>

    <div class="tool-slot right-4 bottom-6">
      <slot name="bottom-right" :map="map"></slot>
    </div>

    <DebugPanel v-if="false" class="absolute left-80 z-50 inset-y-4 w-1/6"></DebugPanel>
  </div>
</template>

<style scoped>
.tool-slot {
  @apply absolute flex space-x-4;
}

:deep(.ol-scale-line) {
  @apply left-1/2 -translate-x-1/2 bottom-2;
}
</style>
