// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
export const parser = LRParser.deserialize({
  version: 14,
  states: "$zOVQPOOOOQO'#C_'#C_OOQO'#C`'#C`OqQPO'#CaOvQPO'#CaO{QPO'#CaQ!QQQOOOOQO'#C^'#C^OVQPO'#C^O!`QPO,58{OVQPO,58{OVQPO,58{OVQPO,58}OVQPO,58}O!eQQO,58xO!vQPO'#CbO#OQPO1G.gO#TQQO1G.gO#fQQO1G.gO#wQQO1G.iOOQO1G.i1G.iOOQO1G.d1G.dO!`QPO'#CdO$`QPO,58|OOQO7+$R7+$RO$hQPO7+$ROOQO,59O,59OOOQO-E6b-E6bO$mQPO<<GmOOQOAN=XAN=X",
  stateData: "$r~OZOS~O[PO]QO^RO_WObROcSOdTOeTO~O_XO~O_YO~O_ZO~Of[Og[Oh]Oi]O~O]QO~OaeOf[Og[Oh]Oi]O~O`fOaUX~OahO~O`iOf[Og[Oh]Oi]O~OahOf[Og[Oh]Oi]O~Oh]Oi]OXVifVigViaVi`Vi~O`fOaUa~O[PO~OamO~O",
  goto: "!hXPPYlw!V!_!V!bQUOQ^WQaYQbZQc[Rd][VOWYZ[]Rli[VOWYZ[]Q_XRjf]VOWYZ[]R`XQg_Rkg",
  nodeNames: "⚠ Formula Expression Number Variable FunctionCall VariableList BinaryExpression",
  maxTerm: 25,
  skippedNodes: [0],
  repeatNodeCount: 1,
  tokenData: "'W~RgX^!jpq!jxy#_yz#dz{#i{|#n|}#s}!O#x!P!Q$k!Q![$Q#T#U$p#a#b%R#c#d%r#d#e&^#g#h&o#y#z!j$f$g!j#BY#BZ!j$IS$I_!j$I|$JO!j$JT$JU!j$KV$KW!j&FU&FV!j~!oYZ~X^!jpq!j#y#z!j$f$g!j#BY#BZ!j$IS$I_!j$I|$JO!j$JT$JU!j$KV$KW!j&FU&FV!j~#dO_~~#iOa~~#nOh~~#sOf~~#xO`~R#}PgQ!Q![$QP$VQ[P!O!P$]!Q![$QP$`P!Q![$cP$hP[P!Q![$c~$pOi~~$sP#U#V$v~$yP#g#h$|~%ROd~~%UQ#T#U%[#]#^%g~%_P#l#m%b~%gOb~~%jP#b#c%m~%rO^~~%uP#d#e%x~%{P#R#S&O~&RP!Q![&U~&ZP]~!Q![&U~&aP#c#d&d~&gP#k#l&j~&oOc~~&rP#e#f&u~&xP#f#g&{~'OP#h#i'R~'WOe~",
  tokenizers: [0, 1],
  topRules: {"Formula":[0,1]},
  tokenPrec: 0
})
