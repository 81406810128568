import { Ref } from 'vue'

export default function useFileReader(
  onload: (data: string | ArrayBuffer | null, file: File) => any,
  errorBag: Ref<(string | Error)[]>,
  filetype?: string | '.json' | '.csv'
) {
  const fileReader = new FileReader()

  fileReader.onerror = (ev) => {
    errorBag.value.push(JSON.stringify(ev))
  }

  function onFileChange(file: File): any {
    errorBag.value = []

    // file type check
    if (filetype && !file.name.endsWith(filetype)) {
      errorBag.value.push(`Invalid file format. Only "${filetype}" files are supported.`)
      return
    }

    // call user defined onload function
    fileReader.onload = (ev) => {
      errorBag.value = []
      onload(ev.target ? ev.target.result : null, file)
    }

    // read file
    fileReader.readAsText(file)
  }

  return { onFileChange }
}
