import { OverheadLineSide } from '@/components/map/layer/systems/util'
import { OverheadLineId, SystemId } from '@/model'
import { useConductorAllocationStore } from '@/stores/conductor-allocation'
import { useOverheadLineStore } from '@/stores/overhead-lines'
import { useTowerTypeStore } from '@/stores/tower-type'
import { ConductorAllocation, TowerResponse, TowerType } from '@gridside/hsb-api'
import { computed, Ref, ref } from 'vue'
import { mean } from 'lodash'

type SystemsSpanAllocation = {
  /** Ordered list of systems on the left-hand side of a span/tower */
  [OverheadLineSide.LEFT]: SystemId[]
  [OverheadLineSide.RIGHT]: SystemId[]
}

type OverheadLineSystemAllocation = Array<{ in: SystemsSpanAllocation; out: SystemsSpanAllocation }>

export type OverheadLineSystems = {
  spans: OverheadLineSystemAllocation
  maxLeftSystemCount: number
  maxRightSystemCount: number
}

export type SystemsData = Record<OverheadLineId, OverheadLineSystems>

/**
 * Structured data of overheadline / systems allocation
 */
export const systemsData = computed(() => {
  const overheadLineStore = useOverheadLineStore()
  const result: SystemsData = {}
  overheadLineStore.items.forEach((overheadlineRecord) => {
    const overheadLineId = overheadlineRecord.overheadLine.id
    result[overheadLineId] = getSystemsDataByOverheadLine(overheadLineId)
  })
  return result
})

/**
 * Structured data of system allocations on a given overhead line
 */
const getSystemsDataByOverheadLine = (overheadLineId: OverheadLineId) => {
  const overheadLineStore = useOverheadLineStore()
  const conductorAllocationStore = useConductorAllocationStore()
  const towerTypeStore = useTowerTypeStore()

  const result: OverheadLineSystems = { spans: [], maxLeftSystemCount: 0, maxRightSystemCount: 0 }

  const overheadLineRecord = overheadLineStore.findById(overheadLineId)
  if (!overheadLineRecord) {
    return result
  }

  const towers = Object.values(overheadLineRecord.towersById).sort(
    (a, b) => a.position - b.position
  )

  if (!conductorAllocationStore.loaded) {
    return result
  }

  towers.forEach((tower, towerIndex) => {
    const towerType = towerTypeStore.findById(tower.in.type)
    const inAllocation = conductorAllocationStore.findById(tower.in.allocation)
    const outAllocation = tower.out?.allocation
      ? conductorAllocationStore.findById(tower.out.allocation)
      : inAllocation

    if (!inAllocation || !outAllocation || !towerType) {
      return undefined
    }

    if (towerIndex < towers.length - 1) {
      result.spans[towerIndex] = {
        in: { [OverheadLineSide.LEFT]: [], [OverheadLineSide.RIGHT]: [] },
        out: { [OverheadLineSide.LEFT]: [], [OverheadLineSide.RIGHT]: [] }
      }

      const systems = systemsAtAllocation(outAllocation, towerType)
      result.spans[towerIndex].out = systems
      result.maxLeftSystemCount = Math.max(
        result.maxLeftSystemCount,
        systems[OverheadLineSide.LEFT].length
      )
      result.maxRightSystemCount = Math.max(
        result.maxRightSystemCount,
        systems[OverheadLineSide.RIGHT].length
      )
    }

    if (towerIndex > 0) {
      const systems = systemsAtAllocation(inAllocation, towerType)
      result.spans[towerIndex - 1].in = systems
      result.maxLeftSystemCount = Math.max(
        result.maxLeftSystemCount,
        systems[OverheadLineSide.LEFT].length
      )
      result.maxRightSystemCount = Math.max(
        result.maxRightSystemCount,
        systems[OverheadLineSide.RIGHT].length
      )
    }
  })
  return result
}

/**
 * Returns ordered lists of systems for both left and right-hand side of an allocation (span/tower)
 */
function systemsAtAllocation(
  allocation: ConductorAllocation,
  towerType: TowerType
): SystemsSpanAllocation {
  const usedSystems = Object.keys(
    allocation.mapping.reduce<Record<SystemId, Boolean>>((result, mapping) => {
      if (mapping.system) {
        result[mapping.system] = true
      }
      return result
    }, {})
  )
  const systemPositions: Array<{ system: SystemId; position: number }> = []

  usedSystems.forEach((systemId) => {
    const position = calculateAvgSystemPosition(allocation, towerType, systemId)
    systemPositions.push({ system: systemId, position })
  })

  systemPositions.sort((a, b) => a.position - b.position)
  return {
    [OverheadLineSide.LEFT]: systemPositions
      .filter((item) => item.position < 0)
      .map((item) => item.system)
      .reverse(),
    [OverheadLineSide.RIGHT]: systemPositions
      .filter((item) => item.position >= 0)
      .map((item) => item.system)
  }
}

/**
 * Calculates the average x position of a system on a given tower type
 */
function calculateAvgSystemPosition(
  allocation: ConductorAllocation,
  towerType: TowerType,
  systemId: SystemId
): number {
  const systemPositionsOnTower = allocation.mapping
    // Find positions in the allocation where this system is used
    .reduce<number[]>((result, mapping, currentIndex) => {
      if (mapping.system === systemId) {
        result.push(currentIndex)
      }
      return result
    }, [])
    // get their x coordinates from the tower type
    .map((index) => {
      return towerType.conductorPositions[index]?.x || -66
    })

  // return the average value of the system's x positions
  return mean(systemPositionsOnTower)
}
