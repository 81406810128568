import { computed, reactive } from 'vue'
import { AxiosError } from 'axios'

export function useProgress() {
  const progress = reactive({
    pending: false,
    of: 0,
    errors: [] as (string | Error | AxiosError)[],
    done: 0,
    donePercent: computed((): number => {
      const value = parseFloat(((progress.done / progress.of) * 100).toFixed(2))
      return Number.isNaN(value) ? 0 : value
    }),
    /**
     * Status declaration
     * See: https://element-plus.org/en-US/component/progress.html#linear-progress-bar
     */
    status: computed((): 'success' | 'exception' | 'warning' | '' => {
      if (progress.errors.length === progress.of) {
        return 'exception'
      } else if (progress.errors.length > 0) {
        return 'warning'
      } else if (progress.done === progress.of) {
        return 'success'
      }
      return ''
    }),
    reset() {
      progress.pending = false
      progress.done = 0
      progress.of = 0
      progress.errors = []
    }
  })
  return progress
}

export type Progress = ReturnType<typeof useProgress>
