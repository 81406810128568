import { useI18n } from 'vue-i18n'
import { ref } from 'vue'

/**
 * Helper type which filters out only keys of T where the value is a string
 */
export type KeysOfStringValues<T extends object> = {
  [Key in keyof T]: T[Key] extends string ? Key : never
}[keyof T]

/**
 * Returns a sort function based on given attribute
 * @param prop
 */
export const alphabeticallyBy = <T extends object>(prop: KeysOfStringValues<T>) => {
  return (a: T, b: T) => {
    return (a[prop] as string).localeCompare(b[prop] as string)
  }
}

export function isNumber(x: any): x is number {
  return typeof x === 'number'
}
export function filterUndefined<T>(item: T | undefined): item is T {
  return item !== undefined
}

/**
 * Checks if array A is equal to array b
 */
export function arrayEquals(a: (string | number)[], b: (string | number)[]) {
  return a.length === b.length && a.every((val, index) => val === b[index])
}

export function lastItem<T>(items: T[]): T | undefined {
  return items[items.length - 1]
}

/**
 * Find a unique name when duplicating entities
 *
 * @param baseName The name of the original entity
 * @param existingNames All existing names to avoid conflicts with
 * @param suffix Text that should be appended to the copy
 */
export function findUniqueCopyName(baseName: string, existingNames: string[], suffix = '') {
  let i = 1
  let nameExists = false
  let nameToCheck: string
  do {
    if (i === 1) {
      nameToCheck = `${baseName}${suffix}`
    } else {
      nameToCheck = `${baseName}${suffix} (${i})`
    }
    nameExists = existingNames.includes(nameToCheck)
    i++
  } while (nameExists)
  return nameToCheck
}

export function formatNumber(value: any, unit: string = '') {
  const i18n = useI18n()
  return typeof value === 'number'
    ? `${i18n.n(value, { maximumFractionDigits: 2 })} ${unit}`
    : value
}

/**
 * Shorthand for Object.values()
 * @param object
 */
export const values = <T>(object: { [s: string | number]: T } | ArrayLike<T>): T[] => {
  return Object.values(object)
}

/**
 * Triggers a file download with given content and filename.
 *
 * @param {BlobPart} content - The content to be downloaded.
 * @param {string} filename - The name of the file to be downloaded.
 * @param type
 */
export function triggerDownload(
  content: BlobPart,
  filename: string,
  type: MediaType = 'text/plain'
) {
  const blob = new Blob([content], { type })
  const url = URL.createObjectURL(blob)

  // Create a new anchor element and set its href attribute to the object URL
  const link = document.createElement('a')
  link.href = url
  link.download = filename

  // Append the anchor element to the document body and call its click method to start the download
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(link.href)
}

type MediaType = 'text/plain' | 'image/svg+xml' | 'application/json' | string

/**
 * Mock a "ref" to be used for <el-popover :virtual-ref>
 */
export function createVirtualRef() {
  const virtualTooltipEl = ref({
    position: DOMRect.fromRect(),
    getBoundingClientRect: (): DOMRect => {
      return virtualTooltipEl.value.position
    }
  })
  return virtualTooltipEl
}
