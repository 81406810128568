import { v4 } from 'uuid'

export function useDuplicate<T extends object>(duplicateFn: (item: T) => Promise<any>) {
  async function duplicateItems(items: T[]) {
    for (const item of items) {
      const newItem = {
        ...item,
        id: v4(),
        name: ('name' in item ? item.name : '') + ' - Kopie'
      }
      await duplicateFn(newItem)
    }
  }
  return { duplicateItems }
}
