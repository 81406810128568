import fields, { fieldsConductorState } from '@/config/fields/study-case'
import { CalculationModes, SuperpositionPreset } from '@/model'
import { parser } from '@/util/codemirror/formula'
import { OperationState, StudyCase } from '@gridside/hsb-api'
import { z } from 'zod'

const OperationStateSchemaBase = z.object({
  conductorStates: z
    .object({
      current: z.number().min(fieldsConductorState.current.min, 'Mindestens eine Phase'),
      angle: z.number().min(fieldsConductorState.angle.min).max(fieldsConductorState.angle.max)
    })
    .array(),
  system: z.string().uuid()
})
export const AutoFailSchema = z.object({
  system: z.string().uuid(),
  current: z.number()
})
export const AutoAWESchema = z.object({
  system: z.string().uuid(),
  current: z.number(),
  angle: z.number()
})
export const ManualSchema = OperationStateSchemaBase.extend({
  mirrored: z.boolean()
})
export type AutoFailConfig = z.infer<typeof AutoFailSchema>
export type AutoAWEConfig = z.infer<typeof AutoAWESchema>
export type ManualConfig = z.infer<typeof ManualSchema>

export const StudyCaseConfigBase = z
  .object({
    modeSelected: z.nativeEnum(CalculationModes),
    superpositionPreset: z.nativeEnum(SuperpositionPreset)
  })
  .passthrough()
export const ConfigManual = StudyCaseConfigBase.extend({
  manual: ManualSchema.array().min(1, 'Mindestens ein Betriebszustand A!')
}).passthrough()
export const ConfigAutoFail = StudyCaseConfigBase.extend({
  autoFail: AutoFailSchema.array().min(1, 'Mindestens ein Betriebszustand B!')
}).passthrough()
export const ConfigAutoAWE = StudyCaseConfigBase.extend({
  autoAWE: AutoAWESchema.array().min(1, 'Mindestens ein Betriebszustand C!')
}).passthrough()

export const StudyCaseConfigSchema = StudyCaseConfigBase.and(ConfigAutoAWE)
  .and(ConfigAutoFail)
  .and(ConfigManual)
export type StudyCaseConfig = z.infer<typeof StudyCaseConfigSchema>

export const CalculationModesSchemas = {
  [CalculationModes.AUTO_FAIL]: ConfigAutoFail,
  [CalculationModes.AUTO_AWE]: ConfigAutoAWE,
  [CalculationModes.MANUAL]: ConfigManual
}

export const StudyCaseSchemaBase = z.object({
  id: z.string().uuid(),
  name: z.string().min(fields.name.min, fields.name.required),
  description: z.string().nullish(),
  configuration: z.any().optional(),
  operationStates: OperationStateSchemaBase.array(),
  limitVoltage: z.number().min(fields.limitVoltage.min),
  expectationFactor: z.number().min(fields.expectationFactor.min).max(fields.expectationFactor.max),
  project: z.string(),
  superposition: z.string().superRefine((value, ctx) => {
    // Use Lezer parse to check errors in syntax
    const tree = parser.parse(value)
    let syntaxErrors = 0
    tree.iterate({
      enter(node): boolean | void {
        if (node.type.isError) {
          syntaxErrors++
        }
      }
    })
    if (syntaxErrors > 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `${syntaxErrors} Syntaxfehler`
      })
    }
  })
})

/**
 * Type-checking between: API-Client type <=> Zod-schema type
 */
export const StudyCaseSchema: z.ZodType<StudyCase> = StudyCaseSchemaBase
export const OperationStateSchema: z.ZodType<OperationState> = OperationStateSchemaBase
