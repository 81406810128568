<script lang="ts">
import { EpsgApi } from '@/api'
import BasicMap from '@/components/map/BasicMap.vue'
import { useMapSelection } from '@/components/map/composables/useMapSelection'
import CalculationControl from '@/components/map/controls/calculation/CalculationControl.vue'
import FullscreenControl from '@/components/map/controls/FullscreenControl.vue'
import LayersControl from '@/components/map/controls/layers/LayersControl.vue'
import { MEDIA_GROUP_LAYER_Z_INDEX } from '@/components/map/controls/layers/types'
import BaseMapControl from '@/components/map/controls/map/BaseMapControl.vue'
import MapModeControl from '@/components/map/controls/MapModeControl.vue'
import MapZoomControl from '@/components/map/controls/MapZoomControl.vue'
import DebugPanel from '@/components/map/DebugPanel.vue'
import MeasureLayer from '@/components/map/layer/measure/MeasureLayer.vue'
import MediaGroupLayer from '@/components/map/layer/media-group/MediaGroupLayer.vue'
import ResultLayer from '@/components/map/layer/result/ResultLayer.vue'
import SourceLayer from '@/components/map/layer/source/SourceLayer.vue'
import SystemsLayer from '@/components/map/layer/systems/SystemsLayer.vue'
import MapHoverInformation from '@/components/map/MapHoverInformation.vue'
import { useOverheadLine } from '@/composables/useOverheadLine'
import { useProject } from '@/composables/useProject'
import { useCalculationStore } from '@/stores/calculation'
import { useCorridorStore } from '@/stores/corridor'
import { useMediaGroupStore } from '@/stores/media-group'
import { usePipeTypeStore } from '@/stores/pipe-type'
import { useProjectStore } from '@/stores/project'
import { useEventListener } from '@vueuse/core'
import { defineComponent } from 'vue'
import ContextMenu from './ContextMenu.vue'

export default defineComponent({
  name: 'HsbMap',
  components: {
    SystemsLayer,
    MeasureLayer,
    MediaGroupLayer,
    MapModeControl,
    CalculationControl,
    BaseMapControl,
    LayersControl,
    MapZoomControl,
    FullscreenControl,
    MapHoverInformation,
    DebugPanel,
    ResultLayer,
    ContextMenu,
    BasicMap,
    SourceLayer
  },
  data: () => ({
    projectionLoaded: false,
    MEDIA_GROUP_LAYER_Z_INDEX
  }),

  setup() {
    const { project, projectId } = useProject()
    const { overheadLineRecord, overheadLineStore } = useOverheadLine()
    const { clear: clearSelection, selectedFeatures } = useMapSelection()

    // Ensure there is no corridor from a previous project left
    const corridorStore = useCorridorStore()
    if (corridorStore.projectId !== projectId.value) {
      corridorStore.clear()
    }

    // Clear selection on ESC
    useEventListener(document, 'keydown', (event) => {
      if (event.key === 'Escape') {
        clearSelection()
      }
    })

    return {
      calculationStore: useCalculationStore(),
      corridorStore,
      mediaGroupStore: useMediaGroupStore(),
      projectStore: useProjectStore(),
      overheadLineRecord,
      overheadLineStore,
      pipeTypeStore: usePipeTypeStore(),
      project,
      projectId,
      selectedFeatures
    }
  },

  async mounted() {
    const loads = [
      this.mediaGroupStore.ensureLoaded(this.projectId),
      this.corridorStore.load(this.projectId),
      this.calculationStore.ensureLoaded(this.projectId),
      this.pipeTypeStore.ensureLoaded(),
      this.pipeTypeStore.ensureLoadedByProject(this.projectId),
      this.projectStore.ensureLoaded()
    ]
    await Promise.all(loads)
  },

  computed: {
    crs() {
      return this.project ? this.project.crs : undefined
    },
    hasResult() {
      return this.calculationStore.result
    },
    loading() {
      return (
        !this.overheadLineStore.loaded || !this.mediaGroupStore.loaded || !this.projectStore.loaded
      )
    }
  },

  watch: {
    // Make sure the project's CRS is known to proj4 library
    crs: {
      handler() {
        if (this.crs) {
          this.loadProjectionDefinition(this.crs)
        }
      },
      immediate: true
    },
    selectedFeatures() {
      if (this.selectedFeatures.length === 0) {
        // Hide all edit forms when no item is selected
        this.$router.push({ name: 'project-map' })
      }
    }
  },

  methods: {
    async loadProjectionDefinition(projectionCode: string) {
      await EpsgApi.loadProjection(projectionCode)
      this.projectionLoaded = true
    }
  }
})
</script>

<template>
  <BasicMap
    v-if="crs && projectionLoaded"
    v-loading="loading"
    :element-loading-background="`rgba(240, 240, 240, 0.5)`"
  >
    <template #default="{ map }">
      <ResultLayer v-if="hasResult" :z-index="20" />
      <MediaGroupLayer :z-index="MEDIA_GROUP_LAYER_Z_INDEX" />
      <SourceLayer :z-index="30" />
      <MeasureLayer :z-index="40" />
      <SystemsLayer />
      <ContextMenu />

      <MapHoverInformation :map="map"></MapHoverInformation>

      <DebugPanel v-if="false" />
    </template>

    <template #top-left><LayersControl /></template>
    <template #top-center><MapModeControl /></template>
    <template #top-right>
      <MapZoomControl />
      <FullscreenControl />
    </template>
    <template #bottom-left><CalculationControl /></template>
    <template #bottom-right><BaseMapControl /></template>
  </BasicMap>
</template>

<style scoped lang="css">
/** Openlayers zoom control to the top right corner **/
:deep(.ol-zoom) {
  left: unset;
  right: 8px;
}
</style>
