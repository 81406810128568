<script lang="ts">
import { defineComponent } from 'vue'
import TowerListView from '@/views/project/towers/TowerListView.vue'
import CenteredPage from '@/layouts/pages/CenteredPage.vue'
import { useOverheadLine } from '@/composables/useOverheadLine'
import SpanListView from '@/views/project/spans/SpanListView.vue'
import { lastItem } from '@/util/helpers'

export default defineComponent({
  name: 'OverheadLineSplitView',
  components: { CenteredPage, TowerListView, SpanListView },
  data: () => ({
    activeName: 'towers',
    search: '',
    tabs: [
      { id: 'towers', label: 'Masten' },
      { id: 'spans', label: 'Spannfelder' }
    ]
  }),
  setup() {
    const { overheadLineRecord } = useOverheadLine()
    return {
      overheadLineRecord
    }
  },
  computed: {
    title() {
      return this.overheadLineRecord.overheadLine.name
    }
  },
  mounted() {
    const last = lastItem(this.$route.matched)
    if (last?.path.includes('spans')) {
      this.activeName = 'spans'
    } else if (last?.path.includes('towers')) {
      this.activeName = 'towers'
    }
  }
})
</script>

<template>
  <CenteredPage :title="title">
    <template #top-right>
      <el-input
        v-model="search"
        style="width: 30ch"
        placeholder="Suche"
        prefix-icon="SearchIcon"
        clearable
        class="mt-2"
      />
    </template>
    <p-tabs :tabs="tabs" v-model="activeName">
      <template #tab:towers>
        <TowerListView :searchString="search" class="!pt-8" style="padding-top: 30px" />
      </template>
      <template #tab:spans>
        <SpanListView :searchString="search" />
      </template>
    </p-tabs>
  </CenteredPage>
</template>

<style scoped lang="css">
:deep(.el-tabs__content) {
  overflow: visible;
}
</style>
