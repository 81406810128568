<script lang="ts">
import { defineComponent, inject } from 'vue'
import LayerBase from '@/components/map/layer/LayerBase.vue'
import VectorLayer from 'ol/layer/Vector'
import { Vector as VectorSource } from 'ol/source'
import { LayerId } from '@/components/map'
import { UseMapInjectKeys, UseMapItems } from '@/components/map/composables'
import Draw from 'ol/interaction/Draw.js'
import useMapInteraction from '@/components/map/composables/useMapInteraction'
import { MapMode, useMapMode } from '@/components/map/composables/useMapMode'
import { watchImmediate } from '@vueuse/core'
import { Collection, Feature } from 'ol'
import { never } from 'ol/events/condition'
import { Snap } from 'ol/interaction'
import { measureStyleDone, measureStyleSketch } from '@/components/map/style/measure'
import { useMeasureContextMenu } from '@/components/map/layer/measure/useMeasureContextMenu'

export type MeasureLayerContext = {
  source: VectorSource
  drawInteraction: Draw
}

export default defineComponent({
  name: 'MeasureLayer',
  components: { LayerBase },
  data() {
    return {
      LayerId
    }
  },
  setup() {
    // setup layer
    const source = new VectorSource()
    const layer = new VectorLayer({
      source,
      style: measureStyleDone
    })
    const { map } = inject(UseMapInjectKeys.useMap) as UseMapItems

    const drawnFeatures = new Collection<Feature>()
    const draw = new Draw({
      freehandCondition: never,
      features: drawnFeatures,
      source: source,
      type: 'LineString',
      style: measureStyleSketch,
      // only left clicks
      condition: (event) => event.type === 'pointerdown' && event.originalEvent?.buttons === 1
    })

    // snap for drawn features
    const snap = new Snap({
      source
    })

    watchImmediate(useMapMode(), (newMode) => {
      draw.setActive(newMode === MapMode.MEASURE)
      snap.setActive(newMode === MapMode.MEASURE)

      // erase all features when entering/leaving mode
      source.clear()
    })

    useMapInteraction(draw)
    useMapInteraction(snap)

    const ctx: MeasureLayerContext = {
      source,
      drawInteraction: draw
    }
    useMeasureContextMenu(ctx)

    return { layer, map }
  }
})
</script>

<template>
  <LayerBase :id="LayerId.MEASURE" :layer="layer" v-bind="$attrs" />
</template>
<style scoped lang="css"></style>
