<script lang="ts">
import {
  FeaturePropertyMapping,
  MappingMode,
  PipeTypeMappingModeOptions
} from '@/components/media-group/MediaGroupMappingTypes'
import MediaGroupPipeTypeSelectField from '@/components/media-group/MediaGroupPipeTypeSelectField.vue'
import { useProject } from '@/composables/useProject'
import fieldConfig from '@/config/fields/media-group'
import { MediaGroup, MediaGroupPipe, MediaGroupWire, ProjectId } from '@/model'
import { MediaGroupBase } from '@gridside/hsb-api'
import { Feature, FeatureCollection, MultiLineString } from 'geojson'
import { v4 } from 'uuid'
import { defineComponent, PropType } from 'vue'
import { importDateTimeString } from '@/util'

export default defineComponent({
  name: 'MediaGroupPropertiesMapped',
  components: { MediaGroupPipeTypeSelectField },
  props: {
    modelValue: {
      type: Array as PropType<MediaGroup[]>,
      required: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    geoJson: {
      type: Object as PropType<FeatureCollection>,
      required: true
    }
  },
  data() {
    return {
      pipeTypeMappingModeSelected: MappingMode.ONE_FOR_ALL as MappingMode.ONE_FOR_ALL | string,
      mappingIndividual: [] as FeaturePropertyMapping[],
      mappingOneForAll: '',
      mediaGroupProperties: {
        height: 0,
        pipeType: '',
        rhoE: ''
      },
      MappingMode,
      fieldConfig
    }
  },
  setup() {
    return { project: useProject() }
  },
  emits: ['update:modelValue'],
  watch: {
    /**
     * Depending on selected mapping mode emit different modelValue
     */
    pipeTypeMappingModeSelected() {
      if (this.pipeTypeMappingModeSelected === MappingMode.ONE_FOR_ALL) {
        return
      }
      this.mappingIndividual = this.getFeaturePropertyMapping(
        this.pipeTypeMappingModeSelected,
        this.geoJson
      )
    },
    mediaGroup: {
      handler(value) {
        this.$emit('update:modelValue', value)
      },
      immediate: true
    }
  },
  computed: {
    isPipe() {
      return this.project.project.value?.mediaType === 'pipe'
    },
    mediaGroup(): MediaGroup[] {
      const mediaGroups: MediaGroup[] = []
      const properties = this.mediaGroupProperties
      const mode = this.pipeTypeMappingModeSelected
      const geoJson = this.geoJson
      const mappingOneForAll = this.mappingOneForAll
      const mappingIndividual = this.mappingIndividual
      const nameBase = `Importierte Mediengruppe vom ${importDateTimeString()}`
      const propertiesCommon = {
        height: properties.height,
        project: this.project.projectId.value
      }

      // one for all mapping
      if (this.isPipe && mode === MappingMode.ONE_FOR_ALL) {
        mediaGroups.push(
          this.makeMediaGroup(
            this.toMultiLineString(this.geoJson.features),
            nameBase,
            {
              type: 'pipe',
              pipeType: mappingOneForAll,
              rhoE: parseInt(properties.rhoE)
            },
            propertiesCommon
          )
        )
      }

      // individual mapping
      if (this.isPipe && mode !== MappingMode.ONE_FOR_ALL) {
        for (const mapping of mappingIndividual) {
          mediaGroups.push(
            this.makeMediaGroup(
              this.toMultiLineString(mapping.features),
              `${mapping.featurePropertyValue} (${nameBase})`,
              {
                type: 'pipe',
                pipeType: mapping.pipeTypeId,
                rhoE: parseInt(properties.rhoE)
              },
              propertiesCommon
            )
          )
        }
      }

      // wire media-group
      if (!this.isPipe) {
        mediaGroups.push(
          this.makeMediaGroup(
            this.toMultiLineString(geoJson.features),
            nameBase,
            { type: 'wire' },
            propertiesCommon
          )
        )
      }
      return mediaGroups
    },
    /**
     * Extract all properties from GeoJSON features
     */
    geoJsonFeatureProperties(): string[] {
      if (!this.geoJson || !this.isPipe) {
        return []
      }
      const featureProperties = new Set<string>()
      for (const feature of this.geoJson.features) {
        if (feature.properties === null) {
          continue
        }
        for (const propertiesKey in feature.properties) {
          featureProperties.add(propertiesKey)
        }
      }
      return Array.from(featureProperties)
    },
    /**
     * Items for select field
     */
    pipeTypeMappingItems(): PipeTypeMappingModeOptions {
      const options: PipeTypeMappingModeOptions = [
        { label: 'ohne (alle Mediengruppen vom gleichen Typ)', value: MappingMode.ONE_FOR_ALL }
      ]
      for (const prop of this.geoJsonFeatureProperties) {
        options.push({ label: prop, value: prop })
      }
      return options
    }
  },
  methods: {
    makeMediaGroup(
      geometry: MultiLineString,
      name: string,
      propertiesType:
        | Omit<MediaGroupPipe, keyof MediaGroupBase>
        | Omit<MediaGroupWire, keyof MediaGroupBase>,
      propertiesCommon: { height: number; project: ProjectId }
    ): MediaGroup {
      return {
        id: v4(),
        ...propertiesCommon,
        ...propertiesType,
        name,
        mediaGeometry: geometry
      } as MediaGroup
    },
    /**
     * Creates mappings of unique values for given key in GeoJSON features
     */
    getFeaturePropertyMapping(key: string, geoJson: FeatureCollection): FeaturePropertyMapping[] {
      const mappings: { [key in string]: FeaturePropertyMapping } = {}

      for (const feature of geoJson.features) {
        // extract value from feature
        const featurePropertyValue: string | undefined =
          typeof feature.properties?.[key] === 'string' ? feature.properties[key] : undefined

        // handle value empty case
        if (!featurePropertyValue) {
          if ('empty' in mappings) {
            mappings.empty.features.push(feature)
          } else {
            mappings.empty = { featurePropertyValue: '(leer)', pipeTypeId: '', features: [feature] }
          }
          continue
        }

        // handle value exists case
        const exists = mappings[featurePropertyValue]
        if (exists) {
          exists.features.push(feature)
          continue
        }
        mappings[featurePropertyValue] = {
          featurePropertyValue,
          features: [feature],
          pipeTypeId: ''
        }
      }
      return Object.values(mappings)
    },
    toMultiLineString(features: Feature[]): MultiLineString {
      const multiLineString: MultiLineString = {
        type: 'MultiLineString',
        coordinates: []
      }
      for (const feature of features) {
        if (feature.geometry.type === 'MultiLineString') {
          multiLineString.coordinates.push(...feature.geometry.coordinates)
        }
        if (feature.geometry.type === 'LineString') {
          multiLineString.coordinates.push([...feature.geometry.coordinates])
        }
      }

      // Fix anormal z dimension in GeoJSON
      multiLineString.coordinates = multiLineString.coordinates.map((line) =>
        line.map((coord) => coord.slice(0, 2))
      )
      return multiLineString
    }
  }
})
</script>

<template>
  <!-- telecommunication  -->
  <div v-if="!isPipe" v-bind="$attrs">
    <p-field v-model="mediaGroupProperties.height" :dense="dense" v-bind="fieldConfig.height" />
  </div>

  <!-- pipe -->
  <div v-else class="grid grid-cols-3 gap-x-6 gap-y-2" v-bind="$attrs">
    <div class="col-span-2">
      <p-field
        v-model="pipeTypeMappingModeSelected"
        :items="pipeTypeMappingItems"
        label="Feld für Rohrleitungstyp (GeoJSON-Feature-Property)"
        name="_mappingMode"
        required
        type="select"
      ></p-field>

      <!-- case: mapping "one for all"-->
      <div v-if="pipeTypeMappingModeSelected === MappingMode.ONE_FOR_ALL">
        <MediaGroupPipeTypeSelectField v-model="mappingOneForAll" v-bind="fieldConfig.pipeType" />
      </div>

      <!-- case: mapping "individual"-->
      <div v-else class="table-simple table-simple--striped">
        <table class="w-full">
          <thead>
            <tr>
              <th>Wert</th>
              <th></th>
              <th>Rohrleitungstyp</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(mapping, index) in mappingIndividual" :key="mapping.featurePropertyValue">
              <td>{{ mapping.featurePropertyValue }}</td>
              <td class="text-center text-gray-500">
                ({{ mapping.features.length }}
                {{ mapping.features.length === 1 ? 'Medium' : 'Medien' }})
              </td>
              <td>
                <MediaGroupPipeTypeSelectField
                  v-model="mapping.pipeTypeId"
                  :dense="dense"
                  :name="`${index}`"
                  required
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-span-1">
      <p-field v-model="mediaGroupProperties.height" :dense="dense" v-bind="fieldConfig.height" />
      <p-field v-model="mediaGroupProperties.rhoE" :dense="dense" v-bind="fieldConfig.rhoE" />
    </div>
  </div>
</template>
