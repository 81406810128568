/**
 * Returns CSS-able color
 */
export function randomColor(mode: 'hex' | 'rgb' = 'hex') {
  const rgb = [
    Math.floor(Math.random() * 150) + 50,
    Math.floor(Math.random() * 150) + 50,
    Math.floor(Math.random() * 150) + 50
  ]

  if (mode === 'hex') {
    return `#${rgb.map((col) => col.toString(16).padStart(2, '0')).join('')}`
  }

  return `rgb(${rgb.join(',')})`
}

/**
 * Returns 'black' or 'white' depending on which color contrasts more with the given background color.
 * TODO: replace with APCA algorithm
 * @param hexColor The background color in hex format.
 */
export function getContrastColor(hexColor: string): 'black' | 'white' {
  if (hexColor.startsWith('rgb')) {
    const { r, g, b } = extractRGB(hexColor)
    hexColor = rgbToHex(r, g, b)
  }
  // Convert Hex to RGB
  const r = parseInt(hexColor.slice(1, 3), 16)
  const g = parseInt(hexColor.slice(3, 5), 16)
  const b = parseInt(hexColor.slice(5, 7), 16)

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

  // Compare luminance with threshold. Threshold value can be adjusted, usually 0.5 is used
  // The higher the threshold, the more white
  return luminance > 0.7 ? 'black' : 'white'
}

/**
 * Converts an RGB color to hex format.
 * @param r The red component of the color.
 * @param g The green component of the color.
 * @param b The blue component of the color.
 */
export function rgbToHex(r: number, g: number, b: number): string {
  // Schritt 2 & 3: Konvertiere RGB zu Hex und füge führende Null hinzu, wenn nötig
  const toHex = (colorValue: number) => {
    const hex = colorValue.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }

  // Schritt 4: Kombiniere die Hex-Werte
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}

/**
 * Extracts the integer values for r, g, b from an rgb string.
 * @param rgbString The rgb string, e.g., "rgb(100,100,100)".
 */
export function extractRGB(rgbString: string): { r: number; g: number; b: number } {
  const cleanedString = rgbString.replace('rgb(', '').replace(')', '')

  const [r, g, b] = cleanedString.split(',').map((num) => parseInt(num.trim(), 10))

  return { r, g, b }
}
