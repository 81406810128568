<script lang="ts">
import { defineComponent } from 'vue'
import EntityTableView from '@/components/common/EntityTableView.vue'
import { useStudyCaseStore } from '@/stores/study-case'
import { CalculationModes, ProjectId, StudyCaseId, SuperpositionPreset, SystemId } from '@/model'
import { useSystemStore } from '@/stores/system'
import { useDuplicate } from '@/composables/crud-helpers/useDuplicate'
import { useDelete } from '@/composables/crud-helpers/useDelete'
import { useRoute } from 'vue-router'
import { RouteParams } from '@/router/routeParams'
import { StudyCase } from '@gridside/hsb-api'
import { formatNumber } from '../../../util/helpers'

export default defineComponent({
  name: 'StudyCaseListView',
  components: { EntityTableView },

  setup() {
    const route = useRoute()
    const studyCaseStore = useStudyCaseStore()
    const systemStore = useSystemStore()

    const { duplicateItems } = useDuplicate(studyCaseStore.save)
    const { deleteItems } = useDelete(studyCaseStore.delete, {
      check: (items: StudyCase[]) => items.some((item) => item.id === route.params.id),
      routeName: 'project-studyCasees'
    })

    return { studyCaseStore, systemStore, duplicateItems, deleteItems }
  },

  computed: {
    projectId(): ProjectId {
      return this.$route.params[RouteParams.ProjectId] as string
    },
    searchStrings(): string[] {
      return this.studyCaseStore.items.map((item) => item.name)
    },
    studyCaseId(): StudyCaseId {
      return this.$route.params[RouteParams.StudyCaseId] as string
    }
  },

  watch: {
    studyCaseId() {
      this.studyCaseStore.selection = this.studyCaseId ? [this.studyCaseId] : []
    }
  },

  mounted() {
    this.studyCaseStore.load(this.projectId)
    this.studyCaseStore.selection = this.studyCaseId ? [this.studyCaseId] : []
    this.systemStore.ensureLoaded(this.projectId)
  },

  methods: {
    formatCalculationMode(mode: CalculationModes) {
      const mapping = {
        [CalculationModes.MANUAL]: 'manuell',
        [CalculationModes.AUTO_FAIL]: 'Fehlerfall',
        [CalculationModes.AUTO_AWE]: 'AWE-Fall'
      }
      return mapping[mode] || 'unbekannt'
    },

    formatNumber,

    formatSuperpositionPreset(preset: SuperpositionPreset) {
      const mapping = {
        [SuperpositionPreset.COMPLEX]: 'komplex',
        [SuperpositionPreset.ABSOLUTE]: 'betragsmäßig',
        [SuperpositionPreset.SQUARE]: 'quadratisch',
        [SuperpositionPreset.MAXIMUM]: 'maximal',
        [SuperpositionPreset.CUSTOM]: 'eigene Definition'
      }
      return mapping[preset] || 'unbekannt'
    },

    async onCreate() {
      this.studyCaseStore.selection = []
      this.$router.push({ name: 'project-study-case-create' })
    },

    async onEdit(id: string) {
      this.$router.push({ name: 'project-study-case-edit', params: { studyCaseId: id } })
    },

    asStudyCase(row: any): StudyCase {
      return row
    }
  }
})
</script>

<template>
  <EntityTableView
    v-model:selection="studyCaseStore.selection"
    :allow-edit="false"
    allow-duplicate
    create-label="Neuer Berechnungsfall"
    confirm-delete-label="Berechnungsfall löschen"
    default-sort="name"
    :items="studyCaseStore.items"
    :loading="studyCaseStore.loading"
    :search-strings="searchStrings"
    title="Berechnungsfälle"
    @create="onCreate"
    @edit="onEdit"
    @delete-items="deleteItems"
    @duplicate-items="duplicateItems"
  >
    <template #columns>
      <!-- Name -->
      <el-table-column prop="name" label="Name" sortable class-name="font-semibold" />

      <!-- limitVoltage -->
      <el-table-column prop="limitVoltage" label="Grenzwert" sortable width="150" v-slot="{ row }">
        {{ formatNumber(row.limitVoltage, 'V') }}
      </el-table-column>

      <!-- Betriebszustände -->
      <el-table-column prop="operationStates" width="120" label="Betriebszustände" v-slot="{ row }">
        {{ $n(asStudyCase(row).operationStates.length) }}
      </el-table-column>

      <!-- Betriebszustände -->
      <el-table-column label="Typ" width="120" sortable v-slot="{ row }">
        {{ formatCalculationMode(row.configuration?.modeSelected) }}
      </el-table-column>

      <!-- Überlagerung -->
      <el-table-column
        prop="superposition"
        label="Überlagerung"
        sortable
        width="200"
        v-slot="{ row }"
      >
        {{ formatSuperpositionPreset(row.configuration.superpositionPreset) }}
      </el-table-column>
    </template>

    <!-- Empty hint -->
    <template #empty>
      <p class="text-gray-500 text-xl">Sie haben bisher keine Berechnungsfälle angelegt.</p>
      <p class="mt-8 mb-12 font-normal text-gray-400 text-lg leading-relaxed"></p>
      <p-btn @click="onCreate">Neuen Berechnungsfall anlegen</p-btn>
    </template>

    <!-- Delete -->
    <template #confirm-delete="{ items }">
      <div v-if="items.length === 1">
        Wollen Sie den Berechnungsfall
        <b>{{ asStudyCase(items[0]).name }}</b>
        wirklich löschen?
      </div>
      <div v-else>
        Wollen Sie diese Berechnungsfälle wirklich löschen?
        <ul>
          <li v-for="item in items" :key="item.id">
            <b>{{ asStudyCase(item).name }}</b>
          </li>
        </ul>
      </div>
    </template>
  </EntityTableView>
</template>
