import useCoordinates from '@/composables/useCoordinates'
import { MediaGroupId, OverheadLineId } from '@/model'
import { Feature, Map } from 'ol'
import { Point } from 'ol/geom'
import { Draw, Select } from 'ol/interaction'
import { Group as LayerGroup } from 'ol/layer'
import BaseLayer from 'ol/layer/Base'
import VectorLayer from 'ol/layer/Vector'
import RenderFeature from 'ol/render/Feature'
import VectorSource from 'ol/source/Vector'
import { Ref } from 'vue/dist/vue'

export const HitTolerance = 8

type LayerContextBase = {
  map: Map
  layer: VectorLayer<any>
  mapToProject: ReturnType<typeof useCoordinates>['mapToProject']
  projectToMap: ReturnType<typeof useCoordinates>['projectToMap']
  selectedFeatures: Ref<Feature[]>
}
export type MediaLayerContext = Omit<LayerContextBase, 'layer'> & {
  layer: VectorLayer<VectorSource<Feature>>
  selectedMediaGroup: Ref<Feature[]>
  selectedMedia: Ref<Feature[]>
  mediaGroupSelect?: Select
  mediaSelect?: Select
  drawInteraction?: Draw
  getFeatureById: (id: string | number) => Feature | RenderFeature[] | null
  visibleFeatures: Ref<MediaGroupId[] | undefined>
}

export type SourceLayerContext = Omit<LayerContextBase, 'layer'> & {
  layer: VectorLayer<VectorSource<Feature>>
  selectedOverheadLines: Ref<Feature[]>
  selectedSpans: Ref<Feature[]>
  selectedTowers: Ref<Feature<Point>[]>
  selectInteraction?: Select
  showCorridor: Ref<boolean>
  showSpans: Ref<boolean>
  showTowers: Ref<boolean>
  visibleFeatures: Ref<OverheadLineId[] | undefined>
}

export type SystemsLayerContext = Omit<LayerContextBase, 'layer'> & {
  layer: VectorLayer<VectorSource<Feature>>
  resolution: Ref<number>
  selectedSystems: Ref<Feature[]>
  lonLatToProject: ReturnType<typeof useCoordinates>['projectToLonLat']
  projectToLonLat: ReturnType<typeof useCoordinates>['lonLatToProject']
  visibleFeatures: Ref<string[] | undefined>
}

/**
 * Traverses the layers of given map and returns the layer with given ID
 * @param map
 * @param layerId
 */
export function findLayer<T extends BaseLayer>(map: Map, layerId: string): T | undefined {
  // recursive helper function
  const traverse = (root: LayerGroup): T | undefined => {
    for (const layer of root.getLayers().getArray() as T[]) {
      if (layer.get('id') === layerId) {
        return layer
      } else if (layer instanceof LayerGroup) {
        const found = traverse(layer)
        if (found) {
          return found
        }
      }
    }
    return undefined
  }
  return traverse(map.getLayerGroup())
}
