<script lang="ts">
import EntityTableViewPlaceholder from '@/components/common/EntityTableViewPlaceholder.vue'
import { fieldLabel, fieldUnit } from '@/config/fields'
import CenteredPage from '@/layouts/pages/CenteredPage.vue'
import { computed, defineComponent, PropType, ref, watch, watchEffect } from 'vue'
import TableActionMenu, { ActionMenuItem } from '@/components/common/TableActionMenu.vue'
import TablePagination from '@/components/common/TablePagination.vue'
import { ElTable } from 'element-plus'
import { arrayEquals } from '@/util/helpers'
import { filterUndefined } from '@/util'

export type EntityItem = {
  id: any
  name?: string | null // name property of TowerBase is nullish
  deletable?: boolean
  deletableHint?: string // text why deletion is not allowed
} & Record<string, any>

export type ActionItem = {
  label: string
  action: (item: EntityItem) => void
  icon?: any
}
export type ActionItemMultiselect = {
  label: string
  action: (selected: SelectionValue[]) => void
  icon?: any
}

type SelectionValue = string | number
export default defineComponent({
  name: 'EntityTableView',
  components: {
    TablePagination,
    TableActionMenu,
    EntityTableViewPlaceholder,
    CenteredPage
  },
  inheritAttrs: false,

  props: {
    items: {
      type: Array as PropType<EntityItem[]>,
      default: () => []
    },
    /**
     * Required v-model because we need reference to an object holding the selection
     */
    selection: {
      type: Array as PropType<SelectionValue[]>,
      required: true
    },
    allowCreate: {
      type: Boolean,
      default: true
    },
    allowDelete: {
      type: Boolean,
      default: true
    },
    allowDuplicate: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    confirmDeleteLabel: {
      type: String,
      default: 'Löschen'
    },
    createLabel: {
      type: String,
      default: 'Neu'
    },
    defaultSort: {
      type: [String, Object]
    },
    emptyText: {
      type: String,
      default: 'Sie haben bisher keine Einträge erstellt.'
    },
    extraActions: {
      type: Array as PropType<ActionItem[]>,
      default: () => []
    },
    extraActionsMultiselect: {
      type: Array as PropType<ActionItemMultiselect[]>,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    noHeader: {
      type: Boolean,
      default: false
    },
    searchString: {
      type: String,
      default: ''
    },
    /**
     * Properties which are evaluated by search field
     */
    searchProperties: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    searchStrings: {
      type: Array as PropType<string[]>
    },
    title: String,
    rowKey: { type: String, default: 'id' }
  },
  emits: ['create', 'edit', 'delete-items', 'duplicate-items', 'update:selection'],

  setup(props, ctx) {
    const search = ref('')
    const page = ref(1)
    const pageSize = ref(10)
    const itemsToDelete = ref<null | EntityItem[]>(null)
    const tableRef = ref<InstanceType<typeof ElTable>>()

    /**
     * Rows filtered by search string
     */
    const rowsFiltered = computed((): EntityItem[] => {
      const needle = (props.noHeader ? props.searchString : search.value).trim().toLowerCase()

      if (needle === '') {
        return props.items
      }

      return props.items.filter((_, index) => {
        return (
          (props.searchStrings && props.searchStrings[index]?.includes(needle)) ||
          defaultSearchStrings.value[index]?.includes(needle)
        )
      })
    })

    /**
     * Create search-strings array. One string for each row in the table
     */
    const defaultSearchStrings = computed((): string[] => {
      // Create from given props
      if (props.searchProperties?.length > 0) {
        return props.items.map((item: any) => {
          return props.searchProperties
            .map((prop) => item[prop])
            .join(' ')
            .toLowerCase()
        })
      }

      // Create from all props (except id and project-id)
      return props.items.map((item) => {
        return Object.keys(item)
          .filter((key) => !['id', 'project'].includes(key))
          .map((key) => (item as any)[key])
          .map(
            (column: any) => (typeof column !== 'object' && column?.toString().toLowerCase()) || ''
          )
          .join(' ')
      })
    })

    /**
     * Display rows according to page
     */
    const rowsOnPage = computed((): EntityItem[] => {
      const start = (page.value - 1) * pageSize.value
      const end = page.value * pageSize.value
      return rowsFiltered.value.slice(start, end)
    })

    /**
     * Update selection on source data changed
     */
    watch(rowsOnPage, handleSelectionUpdate)

    /**
     * Sync prop-selection with table selected elements
     */
    watchEffect(() => {
      // depends on items, selection and table
      const table = tableRef.value
      const selection = props.selection
      const items = props.items

      if (!table) {
        return
      }

      // de-select old
      const rows: EntityItem[] = table.getSelectionRows()
      for (const row of rows) {
        if (!selection.includes(row.id)) {
          table.toggleRowSelection(row, false)
        }
      }
      // select new
      selection.forEach((id) => {
        const record = items.find((item) => item.id === id)
        if (record) {
          table.toggleRowSelection(record, true)
        }
      })
    })

    /**
     * Trigger update:selection when <el-table> selection is NOT equal prop-selection
     */
    function handleSelectionUpdate() {
      const table = tableRef.value
      if (!table) {
        return
      }
      const rows = table.getSelectionRows() as EntityItem[] | undefined
      const selectedIds = rows?.map((row) => row[props.rowKey]) || []
      const updateProp = !arrayEquals(props.selection, selectedIds)
      // console.log('updateProp', updateProp, selectedIds)
      if (updateProp) {
        ctx.emit('update:selection', selectedIds)
      }
    }

    const emitEdit = (row: EntityItem) => ctx.emit('edit', row[props.rowKey])
    const emitDelete = () =>
      itemsToDelete.value ? ctx.emit('delete-items', itemsToDelete.value) : null
    const emitDuplicate = (items: EntityItem[]) => ctx.emit('duplicate-items', items)

    /**
     * Handle clicking a row to
     * - toggle-select it
     * - shift-click multiple
     * - ctrl-click several
     */
    const handleRowClick = (row: EntityItem, _: unknown, event: MouseEvent) => {
      const shift = !!event?.shiftKey
      const ctrl = !!event?.ctrlKey

      if (ctrl) {
        // @ts-ignore - can actually be called with one param
        tableRef.value?.toggleRowSelection(row)
        return
      }

      const currentSelection = tableRef.value?.getSelectionRows() || []
      tableRef.value?.clearSelection()
      // Todo its actually buggy when table sort is different from order of "items" prop
      if (shift) {
        // get the index of the selected row
        const currentRowIndex = props.items?.findIndex((item) => item.id === row.id)

        // find first selected item
        let firstSelectedRowIndex = 0
        for (const i in props.items) {
          if (props.selection?.includes(props.items[i].id)) {
            firstSelectedRowIndex = parseInt(i)
            break
          }
        }

        // select all items between them
        const ascending = currentRowIndex > firstSelectedRowIndex
        for (
          let s = firstSelectedRowIndex;
          ascending ? s <= currentRowIndex : s >= currentRowIndex;
          ascending ? s++ : s--
        ) {
          tableRef.value?.toggleRowSelection(props.items[s], true)
        }
        return
      }

      if (currentSelection.includes(row)) {
        tableRef.value?.toggleRowSelection(row, false)
      } else {
        tableRef.value?.toggleRowSelection(row, true)
        emitEdit(row)
      }
    }

    return {
      fieldLabel,
      fieldUnit,
      handleSelectionUpdate,
      tableRef,
      page,
      pageSize,
      rowsOnPage,
      handleRowClick,
      search,
      emitEdit,
      emitDelete,
      emitDuplicate,
      itemsToDelete
    }
  },

  computed: {
    defaultSortAsProp(): any {
      return typeof this.defaultSort === 'string'
        ? { prop: this.defaultSort, order: 'ascending' }
        : this.defaultSort
    },
    showActionsColumns() {
      return (
        this.extraActions.length ||
        this.extraActionsMultiselect.length ||
        this.allowEdit ||
        this.allowDuplicate ||
        this.allowDelete
      )
    },
    selectionAsItems(): EntityItem[] {
      return this.selection
        .map((sel) => this.items.find((item) => item[this.rowKey] === sel))
        .filter(filterUndefined)
    }
  },

  methods: {
    /**
     * Selection Menu Items
     */
    getMultiSelectMenuItems(): ActionMenuItem[] {
      const selected = this.selection.length
      const baseText = `${selected} ${selected > 1 ? 'Elemente' : 'Element'}`
      const items: ActionMenuItem[] = [
        {
          label: `${baseText} duplizieren`,
          icon: 'DuplicateIcon',
          action: () => this.emitDuplicate(this.selectionAsItems)
        },
        {
          label: `${baseText} löschen`,
          icon: 'DeleteIcon',
          class: 'text-danger-500',
          action: () => {
            this.itemsToDelete = this.selectionAsItems.slice()
          }
        }
      ]

      if (this.extraActionsMultiselect.length) {
        items.push(
          ...this.extraActionsMultiselect.map((item, index) => ({
            ...item,
            action: () => item.action(this.selection),
            divided: index === 0
          }))
        )
      }
      return items
    },

    /**
     * Menu Items per row
     */
    getRowMenuItems(row: EntityItem): ActionMenuItem[] {
      const items: ActionMenuItem[] = []
      if (this.allowEdit) {
        items.push({
          label: 'bearbeiten',
          icon: 'EditIcon',
          action: () => this.emitEdit(row)
        })
      }

      if (this.allowDuplicate) {
        items.push({
          label: 'duplizieren',
          icon: 'DuplicateIcon',
          action: () => this.emitDuplicate([row])
        })
      }

      if (this.allowDelete) {
        items.push({
          label: 'löschen...',
          icon: 'DeleteIcon',
          class: 'text-danger-500',
          disabled: row.deletable === false,
          hint: row.deletableHint,
          action: () => (this.itemsToDelete = [row])
        })
      }

      if (this.extraActions.length) {
        items.push(
          ...this.extraActions.map((item, index) => ({
            ...item,
            action: () => item.action && item.action(row),
            divided: index === 0
          }))
        )
      }
      return items
    },

    rowClass({ row }: any) {
      let cssClass = 'cursor-pointer'

      const rowIsSelected = this.tableRef
        ?.getSelectionRows()
        .find((selected: EntityItem) => selected[this.rowKey] === row[this.rowKey])

      if (rowIsSelected) {
        cssClass += ' current-row'
      }
      return cssClass
    }
  }
})
</script>

<template>
  <CenteredPage
    :title="noHeader ? undefined : title"
    :class="{ 'overflow-visible': noHeader }"
    :no-padding="noHeader"
  >
    <!-- Search & Actions-->
    <template v-if="!noHeader" #top-right>
      <div class="flex space-x-4 items-center">
        <slot name="extra-tools"></slot>
        <el-input
          v-model="search"
          style="width: 30ch"
          placeholder="Suche"
          prefix-icon="SearchIcon"
        />
        <el-button
          v-if="allowCreate"
          type="primary"
          data-test="create-btn"
          @click="$emit('create')"
        >
          {{ createLabel }}
        </el-button>
      </div>
    </template>

    <!-- Table -->
    <template #default>
      <el-table
        v-if="rowsOnPage.length > 0"
        ref="tableRef"
        data-component="entity-table"
        class="w-full mb-8 shadow rounded"
        empty-text="keine Einträge gefunden"
        table-layout="auto"
        :data="rowsOnPage"
        :row-class-name="rowClass"
        :row-key="rowKey"
        :select-on-indeterminate="false"
        :default-sort="defaultSortAsProp"
        @row-click="handleRowClick"
        @selection-change="handleSelectionUpdate"
        @mousedown.shift.prevent
        @mousedown.ctrl.prevent
      >
        <!-- No Data Hint-->
        <template #empty>
          <template v-if="loading">Daten werden geladen...</template>
        </template>

        <!-- Selection Checkbox -->
        <el-table-column type="selection" />

        <!-- Columns -->
        <slot name="columns" v-bind="{ fieldLabel, fieldUnit }"></slot>

        <!-- Action Column (three dots menu) -->
        <el-table-column v-if="showActionsColumns" width="100" align="center">
          <template #header>
            <TableActionMenu
              :items="getMultiSelectMenuItems()"
              :disabled="selection.length < 1"
              @click.stop
            />
          </template>
          <template #default="{ row }">
            <TableActionMenu :items="getRowMenuItems(row)" @click.stop />
          </template>
        </el-table-column>

        <!-- Table Pagination -->
        <template #append>
          <TablePagination v-model:page="page" v-model:page-size="pageSize" :count="items.length" />
        </template>
      </el-table>

      <!-- Loading Placeholder -->
      <div v-if="loading" v-show="!loading || items?.length === 0">
        <EntityTableViewPlaceholder />
      </div>

      <!-- Empty View -->
      <div
        v-else-if="items?.length === 0"
        class="border-2 border-dashed rounded-lg py-32 px-[20%] text-center text-lg font-medium text-gray-400"
      >
        <slot name="empty">
          {{ emptyText }}
        </slot>
      </div>
    </template>
  </CenteredPage>

  <!-- Dialog - Delete Confirm -->
  <p-dialog
    :show="!!itemsToDelete"
    :title="confirmDeleteLabel"
    confirm-label="Löschen"
    danger
    @update:show="itemsToDelete = null"
    @confirm="emitDelete"
  >
    <slot v-if="itemsToDelete" name="confirm-delete" :items="itemsToDelete">
      Sind Sie sicher, dass Sie
      <!-- Single item -->
      <template v-if="itemsToDelete.length === 1">
        <b v-if="itemsToDelete[0].name">{{ itemsToDelete[0].name }}</b>
        <template v-else>den Eintrag</template>
      </template>

      <!-- Multiple items -->
      <b v-else>{{ itemsToDelete.length }} Einträge</b>
      löschen möchten?
    </slot>
  </p-dialog>
</template>

<style scoped lang="css">
::v-deep(.current-row:hover td) {
  background: var(--el-color-primary-light-8) !important;
}

::v-deep(.el-table__row:hover td) .actions {
  opacity: 1;
}
</style>
