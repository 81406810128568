<script lang="ts">
import ConductorTypeSelectField from '@/components/conductor-type/ConductorTypeSelectField.vue'
import TowerTypeSelectField from '@/components/tower-type/TowerTypeSelectField.vue'
import { RouteParams } from '@/router/routeParams'
import { OverheadLine, TowerResponse } from '@gridside/hsb-api'
import { defineComponent, PropType } from 'vue'
import { formatTowerName, TowerId } from '@/model'
import { useTowerTypeStore } from '@/stores/tower-type'
import { tower } from '@/config/fields'
import { MIXED_VALUES } from '@prionect/ui'
import TowerSVGMapped from '@/components/tower/TowerSVGMapped.vue'
import { ArrayType, Path, PathValue } from '@util/type-helpers'

import isEqual from 'lodash.isequal'
import { fieldsInOut } from '@/config/fields/tower'
import TowerAllocationSelectField from '@/components/tower/TowerAllocationSelectField.vue'
import { useProject } from '@/composables/useProject'
import { useOverheadLine } from '@/composables/useOverheadLine'

/**
 * If value of specified field of items is homogeneous returns the value
 * Otherwise return MIXED_VALUES
 * @param items
 * @param path
 */
export function getItemsValue<T extends object, P extends Path<T>>(
  items: T[],
  path: P
): PathValue<T, P> | typeof MIXED_VALUES {
  const firstItem = items[0]
  const firstItemValue = getByPath(firstItem, path)
  const allEqual = items.every((item) => {
    const value = getByPath(item, path)
    return isEqual(value, firstItemValue)
  })
  return allEqual ? firstItemValue : MIXED_VALUES
}

function getByPath<T extends object, P extends Path<T>>(obj: T, path: P): PathValue<T, P> {
  const keys = typeof path === 'string' ? path.split('.') : [path]
  let result: any = obj

  while (keys.length) {
    const key = keys.shift() as ArrayType<typeof keys>
    if (result === null || result === undefined) {
      return result
    }
    result = result[key]
  }
  return result
}

export default defineComponent({
  name: 'TowerForm',
  components: {
    TowerAllocationSelectField,
    ConductorTypeSelectField,
    TowerTypeSelectField,
    TowerSVGMapped
  },

  props: {
    items: {
      type: Array as PropType<Partial<TowerResponse>[]>,
      default: () => []
    }
  },

  data: () => ({
    fieldConfig: tower,
    fieldConfigInOut: fieldsInOut,
    changingAllocation: false,
    earthwiresInCount: 0,
    earthwiresOutCount: 0
  }),

  setup() {
    const { projectId } = useProject()
    const { overheadLineRecord, overheadLineStore } = useOverheadLine()
    return {
      overheadLineRecord,
      overheadLineStore,
      towerTypeStore: useTowerTypeStore(),
      projectId
    }
  },

  async mounted() {
    await this.towerTypeStore.ensureLoadedByProject(this.projectId)

    if (this.create) {
      // Defer autofocus after animation hast finished
      setTimeout(() => {
        const nameInput: HTMLInputElement = this.$el.querySelector('[data-field="name"] input')
        nameInput?.focus()
      }, 150)
    }
  },

  computed: {
    create(): boolean {
      return !this.id
    },

    id(): TowerId {
      return this.$route.params[RouteParams.TowerId] as string
    },

    overheadLine(): OverheadLine | undefined {
      const firstItem = this.items[0]
      if (firstItem && firstItem.overheadLine) {
        return this.overheadLineStore.findById(firstItem.overheadLine)?.overheadLine
      } else {
        return undefined
      }
    }
  },

  watch: {
    items: {
      immediate: true,
      handler() {
        const out = getItemsValue(this.items, 'out')
        this.changingAllocation = !(out === undefined || out === null)
      }
    }
  },

  methods: {
    asTower(value: any) {
      return value as TowerResponse | Partial<TowerResponse>
    },

    formatTowerName(id: TowerId | undefined): string {
      if (!id) {
        return ''
      }
      const tower = this.overheadLineStore.findTowerById(id)
      return tower ? formatTowerName(tower) : ''
    },

    /**
     * Use "$nextTick" hack to update after dismount of vee-validate components
     */
    handleOutValue(value: boolean, setFieldValue: (key: string, value: any) => void) {
      if (value) {
        return
      }
      this.$nextTick(() => setFieldValue('out', null))
    }
  }
})
</script>

<template>
  <p-multi-form v-if="items" v-bind="$attrs" :items="items" cancelable resettable>
    <template
      v-if="towerTypeStore.loadedProject === projectId"
      #default="{ values, setFieldValue }"
    >
      <div
        v-if="values.x && values.y && items.length === 1"
        class="mb-4 text-gray-400 text-sm absolute -mt-7 z-20 leading-none"
      >
        Koordinaten: X {{ $n(values.x) }} / Y {{ $n(values.y) }}
        <br />
      </div>

      <div class="flex space-x-6">
        <p-field
          v-bind="fieldConfig.name"
          class="flex-1"
          dense
          :placeholder="
            (asTower(values).position &&
              items.length === 1 &&
              formatTowerName(asTower(values).id)) ||
            undefined
          "
        />
        <p-field
          v-if="!create"
          v-bind="fieldConfig.position"
          class="w-32"
          :disabled="items.length > 1"
          dense
        />
      </div>
      <p-field v-bind="fieldConfig.earthResistivity" dense />

      <p-form-section title="Leiterzuordnung">
        <div class="mt-4 mb-2">
          <p-field
            v-model="changingAllocation"
            type="switch"
            name="changingAllocation"
            standalone
            label="Belegung wechselt (hybrider Mast)"
            @change="handleOutValue($event, setFieldValue)"
          />
        </div>

        <div
          class="grid gap-x-6 gap-y-3"
          :class="changingAllocation ? 'grid-cols-2' : 'grid-cols-1'"
        >
          <template v-if="changingAllocation">
            <div class="font-semibold py-1 border-b mb-3 text-sm col-span-1">eingehend</div>
            <div class="font-semibold py-1 border-b mb-3 text-sm col-span-1">ausgehend</div>
          </template>

          <!-- Tower SVG (IN/OUT) -->
          <TowerSVGMapped :tower="asTower(values)" />

          <!-- IN-->
          <div>
            <!-- offset -->
            <p-field v-bind="fieldConfigInOut.in.offset" dense required />

            <!-- type -->
            <TowerTypeSelectField
              v-bind="fieldConfigInOut.in.type"
              v-model:earthwire-count="earthwiresInCount"
            />

            <!-- conductor allocation -->
            <TowerAllocationSelectField v-bind="fieldConfigInOut.in.allocation" />

            <!-- earthwire allocation -->
            <ConductorTypeSelectField
              v-for="n in earthwiresInCount"
              :key="n"
              :field-config="fieldConfigInOut.in.earthwires"
              :index="n - 1"
              :conductors="asTower(values).in?.earthwires"
              dense
            />
          </div>

          <!-- OUT -->
          <div v-if="changingAllocation">
            <!-- offset  -->
            <p-field v-bind="fieldConfigInOut.out.offset" dense />

            <!-- type -->
            <TowerTypeSelectField
              v-bind="fieldConfigInOut.out.type"
              v-model:earthwire-count="earthwiresOutCount"
            />

            <!-- conductor allocation -->
            <TowerAllocationSelectField v-bind="fieldConfigInOut.out.allocation" />

            <!-- earthwire allocation -->
            <ConductorTypeSelectField
              v-for="n in earthwiresOutCount"
              :key="n"
              :field-config="fieldConfigInOut.out.earthwires"
              :index="n - 1"
              :conductors="asTower(values).out?.earthwires"
              dense
            />
          </div>
        </div>
      </p-form-section>
    </template>
  </p-multi-form>
</template>
