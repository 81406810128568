<script lang="ts">
import ImportStep from './import-geo-json-steps/ImportStep.vue'
import SelectFileStep from './import-geo-json-steps/SelectFileStep.vue'
import SpanPropertiesStep from './import-geo-json-steps/SpanPropertiesStep.vue'
import TowerPropertiesStep from './import-geo-json-steps/TowerPropertiesStep.vue'
import { FeatureCollection } from 'geojson'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OverheadLineImportGeoJsonDialog',
  components: { ImportStep, SpanPropertiesStep, TowerPropertiesStep, SelectFileStep },

  data: () => ({
    fileIsValid: false,
    geoJson: undefined as FeatureCollection | undefined,
    importFile: undefined as File | undefined,
    defaultValues: {
      span: {
        corridor: 1000,
        sag: 0,
        rs: 1,
        rk: 1,
        rx: 1
      }
    }
  }),

  computed: {
    steps() {
      return [
        {
          id: 'file',
          title: 'Schritt 1/4: Importdatei auswählen',
          nextButtonDisabled: !this.fileIsValid
        },
        {
          id: 'towerProperties',
          title: 'Schritt 2/4: Masteigenschaften festlegen'
        },
        {
          id: 'spanProperties',
          title: 'Schritt 3/4: Spannfeld-Eigenschaften festlegen',
          nextButtonLabel: 'Import starten'
        },
        { id: 'import', title: 'Schritt 4/4: Import durchführen', nextButtonLabel: 'Schließen' }
      ]
    }
  }
})
</script>

<template>
  <p-wizard :steps="steps" :default-values="defaultValues" class="!w-[80rem]">
    <template #step:file>
      <SelectFileStep v-model="geoJson" v-model:isValid="fileIsValid" v-model:file="importFile" />
    </template>
    <template #step:towerProperties="{ values }">
      <TowerPropertiesStep :values="values" />
    </template>
    <template #step:spanProperties>
      <SpanPropertiesStep />
    </template>
    <template #step:import="{ values }">
      <ImportStep :formValues="values" :geojson="geoJson" />
    </template>
  </p-wizard>
</template>

<style scoped lang="css"></style>
