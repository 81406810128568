<script lang="ts">
import { useProject } from '@/composables/useProject'
import { studyCase } from '@/config/fields'
import { CalculationModesSchemas, StudyCaseSchemaBase } from '@/config/schemas/study-case'
import {
  CalculationModes,
  getValidStudyCaseWithConfig,
  StudyCaseId,
  StudyCaseWithConfig,
  SuperpositionPreset
} from '@/model'
import { RouteParams } from '@/router/routeParams'
import { useCalculationStore } from '@/stores/calculation'
import { useStudyCaseStore } from '@/stores/study-case'
import { useSystemStore } from '@/stores/system'
import { copy } from '@/util'
import { useOperationStatesDraft } from '@/views/project/study-cases/operation-states/draft-functions'
import StudyCaseOperationStatesTab from '@/views/project/study-cases/operation-states/StudyCaseOperationStatesTab.vue'
import StudyCaseBaseTab from '@/views/project/study-cases/StudyCaseBaseTab.vue'
import StudyCaseSuperpositionForm from '@/views/project/study-cases/superposition/StudyCaseSuperpositionForm.vue'
import { generateSuperpositionPresetExpression } from '@/views/project/study-cases/superposition/util'
import { StudyCase } from '@gridside/hsb-api'
import type { TabConfig } from '@prionect/ui/dist/types/components/tabs/PTabs.vue'
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'
import { computed, defineComponent, provide, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'StudyCaseForm',
  components: {
    StudyCaseSuperpositionForm,
    StudyCaseOperationStatesTab,
    StudyCaseBaseTab
  },

  data: () => ({
    CalculationModes,
    fieldConfig: studyCase
  }),

  emits: {
    submit: (val: StudyCase) => val,
    cancel: (val: any) => val
  },

  setup(_, { emit }) {
    const studyCaseStore = useStudyCaseStore()
    const systemStore = useSystemStore()
    const calculationStore = useCalculationStore()
    const { projectId } = useProject()
    studyCaseStore.ensureLoaded(projectId.value)
    systemStore.ensureLoaded(projectId.value)
    calculationStore.ensureLoaded(projectId.value)

    const tabs = ref<TabConfig[]>([
      { id: 'base', label: 'Allgemein', error: false },
      { id: 'operationStates', label: 'Betriebszustände', error: false },
      { id: 'superposition', label: 'Überlagerung', error: false }
    ])

    /**
     * Setup route item
     */
    const route = useRoute()
    const routeId = computed(() => route.params[RouteParams.StudyCaseId] as StudyCaseId | undefined)
    const routeItem = computed(() =>
      routeId.value ? studyCaseStore.findById(routeId.value) : undefined
    )

    /**
     * Setup vee-validate Form
     * - merge initial values with default values
     * - setup dynamic validation based on selected calculationMode
     * (Attention: "validationSchema" overrides rules on <Field>)
     * (Attention: "validationSchema" overrides rules on <Field>)
     */
    const initialValues = getValidStudyCaseWithConfig(routeItem.value)
    const calculationMode = ref<CalculationModes>(initialValues.configuration.modeSelected)
    const validationSchema = computed(() => {
      const schema = StudyCaseSchemaBase.extend({
        configuration: CalculationModesSchemas[calculationMode.value]
      })
      return toTypedSchema(schema)
    })

    const studyCaseForm = useForm<StudyCaseWithConfig>({
      initialValues,
      validationSchema,
      keepValuesOnUnmount: true // because of switching modes
    })

    const [superposition] = studyCaseForm.defineField('superposition')
    const wasSubmitted = ref(false)

    const operationStatesDraft = useOperationStatesDraft(studyCaseForm)

    // Make accessible in child components
    provide('studyCaseForm', studyCaseForm)
    provide('operationStatesDraft', operationStatesDraft)

    /**
     * Handle tab errors
     */
    watch(studyCaseForm.errors, (value) => {
      // don't show errors when never touched
      if (!studyCaseForm.meta.value.touched) {
        return
      }
      const errors = Object.keys(value)
      tabs.value[2].error = errors.some((key) => key.startsWith('superposition'))
      tabs.value[1].error = errors.some((key) => key.startsWith('configuration'))
    })

    /**
     * Load StudyCase when route param changes.
     * Use resetForm + force to reset initial values instead of merging
     * See: https://vee-validate.logaretm.com/v4/api/use-form/#api-reference
     */
    watch(routeItem, (value, prev) => {
      const studyCaseWithConfig = getValidStudyCaseWithConfig(value)
      studyCaseForm.resetForm({ values: studyCaseWithConfig }, { force: true })
      if (value?.id !== prev?.id) {
        wasSubmitted.value = false
      }
    })

    /**
     * Emit StudyCase with operation states based on submitted form
     */
    const studyCaseFormSubmit = studyCaseForm.handleSubmit((values) => {
      const studyCase: StudyCaseWithConfig = copy(values)
      wasSubmitted.value = true

      // Apply draft value to final operation states
      studyCase.operationStates = operationStatesDraft.value

      // Sync generated operation states to manual
      studyCase.configuration.manual = studyCase.operationStates.map((opState) => ({
        ...opState,
        mirrored: false
      }))
      studyCaseForm.setFieldValue('operationStates', studyCase.operationStates)

      // Update superposition expression (unless custom)
      if (studyCase.configuration.superpositionPreset !== SuperpositionPreset.CUSTOM) {
        studyCase.superposition = generateSuperpositionPresetExpression(
          studyCase.configuration.superpositionPreset,
          studyCase.operationStates
        )
      }

      emit('submit', studyCase)
    })

    return {
      operationStatesDraft,
      studyCaseStore,
      tabs,
      studyCaseForm,
      studyCaseFormSubmit,
      routeItem,
      calculationStore,
      projectId,
      superposition,
      wasSubmitted
    }
  },

  computed: {
    submitButtonDisabled() {
      return this.wasSubmitted && !this.studyCaseForm.meta.value.valid
    }
  },
  methods: {
    copy,
    async handleStartCalculation() {
      if (!this.routeItem) {
        return
      }
      const calculation = await this.calculationStore.start(this.projectId, this.routeItem.id)
      this.$router.push({
        name: 'project-map',
        query: { calculationId: calculation.id, studyCaseId: this.routeItem.id }
      })
    }
  }
})
</script>

<template>
  <form v-if="studyCaseStore.loaded" @submit="studyCaseFormSubmit">
    <p-field v-bind="fieldConfig.name" />

    <p-tabs :tabs="tabs">
      <!-- Base properties -->
      <template #tab:base>
        <StudyCaseBaseTab />
      </template>

      <!-- Operation States -->
      <template #tab:operationStates>
        <StudyCaseOperationStatesTab />
      </template>

      <!-- superposition -->
      <template #tab:superposition>
        <StudyCaseSuperpositionForm
          v-model="superposition"
          :operation-states="studyCaseForm.values.operationStates"
        />
      </template>
    </p-tabs>

    <div class="py-4 flex">
      <el-button
        type="success"
        :loading="studyCaseForm.isSubmitting.value"
        @click="studyCaseFormSubmit"
      >
        Speichern
      </el-button>
      <el-button text @click="$emit('cancel', true)">Abbrechen</el-button>
      <div class="flex-grow"></div>
      <el-button :disabled="routeItem === undefined" @click="handleStartCalculation">
        Berechnung starten
      </el-button>
    </div>
  </form>
</template>
<style scoped></style>
