import { LayerId } from '@/components/map'
import { useProject } from '@/composables/useProject'
import { useCalculationStore } from '@/stores/calculation'
import { useMediaGroupStore } from '@/stores/media-group'
import { useOverheadLineStore } from '@/stores/overhead-lines'
import { useSystemStore } from '@/stores/system'
import { computed } from 'vue'
import { LayerTreeConfig, LayerTreeItem, LayerTreeItemType } from './types'

/**
 * Defines the tree in the layers control panel
 */
export const useLayerTreeConfig = () => {
  const { project } = useProject()
  return computed<LayerTreeConfig>(() => {
    const overheadLineItems: LayerTreeItem[] = useOverheadLineStore().items.map((item) => ({
      type: LayerTreeItemType.FEATURE,
      id: item.overheadLine.id,
      label: item.overheadLine.name,
      layer: LayerId.SOURCE
    }))

    const systemItems: LayerTreeItem[] = useSystemStore().items.map((item) => ({
      type: LayerTreeItemType.FEATURE,
      id: item.id,
      label: item.name,
      layer: LayerId.SYSTEMS
    }))

    const mediaGroupItems: LayerTreeItem[] = useMediaGroupStore().items.map((item) => ({
      type: LayerTreeItemType.FEATURE,
      id: item.id,
      label: item.name,
      layer: LayerId.MEDIA_GROUP
    }))

    const calculationStore = useCalculationStore()
    const hasResult = !!calculationStore.result

    const baseItems: LayerTreeConfig = [
      {
        type: LayerTreeItemType.LAYER,
        id: LayerId.SOURCE,
        label: 'Freileitungen',
        children: overheadLineItems,
        options: [
          { id: 'showTowers', label: 'Masten', defaultValue: true },
          { id: 'showSpans', label: 'Spannfelder', defaultValue: true },
          { id: 'showCorridor', label: 'Beeinflussungskorridor', defaultValue: true }
        ]
      },
      {
        type: LayerTreeItemType.LAYER,
        id: LayerId.SYSTEMS,
        label: 'Systeme',
        children: systemItems,
        defaultValue: false
      },
      {
        type: LayerTreeItemType.LAYER,
        id: LayerId.MEDIA_GROUP,
        label: 'Mediengruppen',
        children: mediaGroupItems
      }
    ]

    const resultItems: LayerTreeConfig = [
      {
        type: LayerTreeItemType.LAYER,
        id: LayerId.RESULT,
        label: 'Berechnungsergebnis',
        options: [
          project.value?.mediaType === 'telecom'
            ? { id: 'showEmfResult', label: 'Längspannungen', defaultValue: true }
            : { id: 'showLoadFlow', label: 'Lastfluss', defaultValue: true },
          {
            id: 'showRelations',
            label: 'Beeinflussungsabschnitte',
            defaultValue: false
          }
        ]
      }
    ]

    if (hasResult) {
      return [...baseItems, ...resultItems]
    } else {
      return baseItems
    }
  })
}
