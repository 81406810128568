export enum LayerId {
  MEASURE = 'measure',
  SOURCE = 'source',
  MEDIA_GROUP = 'mediaGroup',
  MEDIA = 'media',
  RESULT = 'result',
  SYSTEMS = 'systems',
  // The following layer ids are not used in the layer config, but inside
  // other layers (i.e. results):
  CORRIDOR = 'corridor',
  LOAD_FLOW = 'loadFlow',
  EMF = 'emf',
  RELATIONS = 'relations',
  MAX_VOLTAGE = 'maxVoltage'
}

export const MEDIA_GROUP_LAYER_Z_INDEX = 10

export type TreeNode = {
  id: string
  label: string
  children?: TreeNode[]
  config?: LayerTreeItem
}

export type LayerTreeConfig = LayerTreeItem[]
export type LayerTreeItem = LayerTreeLayerItem | LayerTreeFeatureListItem

type LayerTreeItemBase = {
  type: LayerTreeItemType
  id: string
  label: string
  defaultValue?: any
}

type LayerTreeLayerItem = LayerTreeItemBase & {
  type: LayerTreeItemType.LAYER
  id: LayerId
  label: string
  options?: LayerTreeLayerOptions
  children?: LayerTreeItem[]
}

type LayerTreeFeatureListItem = LayerTreeItemBase & {
  type: LayerTreeItemType.FEATURE
  layer: LayerId
}

export enum LayerTreeItemType {
  LAYER = 'LAYER',
  FEATURE = 'FEATURE'
}

export type LayerTreeLayerOptions = Array<{
  id: string
  label: string
  defaultValue: boolean
}>
