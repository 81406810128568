<script setup lang="ts">
import { LayerId } from '@/components/map'
import { UseMapInjectKeys, UseMapItems, useMapSelection } from '@/components/map/composables'

import LayerBase from '@/components/map/layer/LayerBase.vue'
import { useSystemSelect } from '@/components/map/layer/systems/useSystemSelect'
import { useSystemsLayerOptions } from '@/components/map/layer/systems/useSystemsLayerOptions'
import { SystemsLayerContext } from '@/components/map/layer/util'
import { featureOfType, FeatureTypeEnum } from '@/components/map/LayerItemConfig'
import { systemsStyle } from '@/components/map/style/systems'
import useCoordinates from '@/composables/useCoordinates'
import { useProject } from '@/composables/useProject'
import { refThrottled } from '@vueuse/core'
import { Feature as OlFeature } from 'ol'
import { GeoJSON } from 'ol/format'

import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'
import { computed, inject, ref, watch } from 'vue'
import { useSystemHover } from './useSystemHover'
import { useSystemLines } from './useSystemLines'

const { map, zoom, layers } = inject(UseMapInjectKeys.useMap) as UseMapItems
const { selectedFeatures } = useMapSelection()
const { project } = useProject()

const { mapToProject, projectToMap, lonLatToProject, projectToLonLat } = useCoordinates(
  map.value,
  project.value?.crs
)

const source = ref<VectorSource>()
const layer = new VectorLayer({
  source: source.value,
  properties: { id: LayerId.SOURCE },
  style: systemsStyle()
})
watch(source, () => layer.setSource(source.value || null))

const resolution = ref<number>(1)
watch(
  // Throttle ref to prevent continuous recalculations during zooming
  refThrottled(zoom, 500),
  () => {
    resolution.value = map.value.getView().getResolution() || 1
  },
  { immediate: true }
)

const selectedSystems = computed(() =>
  selectedFeatures.value.filter((f) => featureOfType(f, FeatureTypeEnum.system))
)

const visibleFeatures = computed(() => layers.value[LayerId.SYSTEMS].options.visibleFeatures)

const ctx: SystemsLayerContext = {
  layer,
  map: map.value,
  selectedFeatures,
  selectedSystems,
  mapToProject,
  projectToMap,
  lonLatToProject,
  projectToLonLat,
  resolution,
  visibleFeatures
}

const layerIsVisible = computed(() => layers.value[LayerId.SYSTEMS].visible || false)

useSystemsLayerOptions(ctx)
const systemsGeoJson = useSystemLines(ctx)
useSystemHover(ctx)
useSystemSelect(ctx)

// Update the OpenLayers source each time the GeoJSON data changes
watch(
  systemsGeoJson,
  () => {
    if (!project.value || !systemsGeoJson.value) {
      return
    }

    source.value = new VectorSource({
      features: new GeoJSON({
        dataProjection: project.value.crs,
        featureProjection: map.value.getView().getProjection()
      }).readFeatures(systemsGeoJson.value) as OlFeature[]
    })
  },
  { immediate: true }
)
</script>

<template>
  <LayerBase
    v-if="source"
    v-bind="$attrs"
    :id="LayerId.SYSTEMS"
    :layer="layer"
    information-on-hover
    :visible="layerIsVisible"
    :z-index="10"
  />
</template>
