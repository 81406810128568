<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { useEventListener } from '@vueuse/core'
import { nextTick, PropType, ref } from 'vue'

export type ActionMenuItem = {
  action?: () => void
  class?: string
  disabled?: boolean
  divided?: boolean
  hint?: string
  icon?: string
  label: string
}

defineProps({
  items: { type: Array as PropType<ActionMenuItem[]>, default: () => [] },
  disabled: { type: Boolean, default: false }
})

const triggerEl = ref<InstanceType<typeof MenuButton> | null>(null)
const menuEl = ref<InstanceType<typeof MenuItems> | null>(null)
const menuInnerEl = ref<HTMLDivElement>()

const open = ref(false)
const left = ref(0)
const top = ref(0)

const onMenuClick = () => {
  open.value = !open.value

  nextTick(() => {
    if (!triggerEl.value || !menuEl.value || !menuInnerEl.value) {
      return
    }
    const triggerBBox = triggerEl.value.$el.getBoundingClientRect()
    const innerMenuBBox = menuInnerEl.value.getBoundingClientRect()

    left.value = triggerBBox.left - innerMenuBBox.width / 2 + 32
    top.value = triggerBBox.bottom - 8
  })
}

useEventListener(document, 'mouseup', () => (open.value = false))
useEventListener(document, 'wheel', () => (open.value = false))
</script>

<template>
  <Menu as="div" class="action-menu text-left">
    <MenuButton
      v-if="!disabled"
      ref="triggerEl"
      as="div"
      class="action-menu__button hover:text-gray-900 p-1 rounded transition-colors inline-block cursor-pointer"
      @click="onMenuClick"
    >
      <el-icon size="24">
        <EllipsisIcon />
      </el-icon>
    </MenuButton>
    <teleport to="body">
      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-y-0 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-y-0 opacity-0"
      >
        <MenuItems
          v-if="open"
          ref="menuEl"
          static
          class="action-menu__menu fixed min-w-[10rem] border origin-top rounded-md bg-white shadow-lg focus:outline-none z-[10000] text-sm"
          :style="{ left: `${left}px`, top: `${top}px` }"
        >
          <div ref="menuInnerEl">
            <MenuItem
              v-for="item in items"
              :key="item.label"
              :as="item.disabled ? 'div' : 'a'"
              class="action-menu__item px-3 py-2 transition-colors flex items-center"
              :class="
                item.class +
                (item.divided ? ' border-t' : '') +
                (item.disabled ? ' opacity-60' : ' cursor-pointer hover:bg-gray-100')
              "
              :title="item.hint"
              @click.stop="
                typeof item.action === 'function' && !item.disabled ? item.action() : undefined
              "
            >
              <div class="w-7">
                <el-icon v-if="item.icon" size="16">
                  <component :is="item.icon"></component>
                </el-icon>
              </div>
              <div class="flex-1">{{ item.label }}</div>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </teleport>
  </Menu>
</template>
