import { Feature } from 'ol'
import { FeatureLike } from 'ol/Feature'

export enum FeaturePropEnum {
  type = '_type',
  draft = '_draft',
  hidden = '_hidden',

  mediaGroupIndex = '_mediaGroupIndex',
  mediaGroupId = '_mediaGroupId',

  name = 'name',
  position = 'position',
  color = 'color',
  voltage = 'voltage',
  EMF_abs = 'EMF_abs',
  UR_abs = 'UR_abs'
}
export enum FeatureTypeEnum {
  emf = 'emf',
  loadFlow = 'loadFlow',
  mediaGroup = 'mediaGroup',
  media = 'media',
  span = 'span',
  system = 'system',
  tower = 'tower',
  overheadLine = 'overheadLine'
}

export function featureIsHidden(feat: any | Feature) {
  if (!(feat instanceof Feature)) {
    return false
  }
  return feat.get(FeaturePropEnum.hidden) === true
}
export function featureIsDraft(feat: any | Feature) {
  if (!(feat instanceof Feature)) {
    return false
  }
  return feat.get(FeaturePropEnum.draft) === true
}
export function featureOfType(feat: FeatureLike, type: FeatureTypeEnum | FeatureTypeEnum[]) {
  const featType = feat.get(FeaturePropEnum.type)
  if (Array.isArray(type)) {
    return type.includes(featType)
  }
  return feat.get(FeaturePropEnum.type) === type
}
export function featureOfTypeFn(type: FeatureTypeEnum | FeatureTypeEnum[]) {
  return (feat: FeatureLike) => {
    return featureOfType(feat, type)
  }
}
