<script lang="ts">
import { UseMapItems } from '@/components/map/composables/useMap'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import ButtonControlBase, { ActionItem } from '@/components/map/controls/ButtonControlBase.vue'
import { useEventListener } from '@vueuse/core'
import { defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'FullscreenControl',
  components: { ButtonControlBase },

  setup() {
    const { zoomIn, zoomOut, zoomToFit } = inject(UseMapInjectKeys.useMap) as UseMapItems
    return { zoomIn, zoomOut, zoomToFit }
  },

  computed: {
    actions(): ActionItem[] {
      return [
        {
          icon: 'ZoomOut',
          action: this.zoomOut,
          tooltip: 'verkleinern'
        },
        {
          icon: 'ZoomFit',
          action: this.zoomToFit,
          tooltip: 'Karte einpassen'
        },
        {
          icon: 'ZoomIn',
          action: this.zoomIn,
          tooltip: 'vergößern'
        }
      ]
    }
  }
})
</script>

<template>
  <ButtonControlBase :actions="actions" />
</template>

<style scoped lang="css"></style>
