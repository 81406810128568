import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import { sourceEditStyle } from '@/components/map/style/source'
import { useOverheadLineStore } from '@/stores/overhead-lines'

import { Collection, Feature, Map } from 'ol'

import { Point } from 'ol/geom'
import { Modify, Snap } from 'ol/interaction'
import { inject, onBeforeUnmount, watch } from 'vue'
import { SourceLayerContext } from '../util'
import { featureOfType, FeatureTypeEnum } from '@/components/map/LayerItemConfig'
import useHoverInformation from '@/components/map/composables/useHoverInformation'

export function useSourceModify(ctx: SourceLayerContext) {
  const { disableHoverInformation } = useHoverInformation()
  const map = inject(UseMapInjectKeys.map) as Map
  const overheadLineStore = useOverheadLineStore()
  let modifyInteraction: Modify
  let snapInteraction: Snap

  watch(ctx.selectedFeatures, (selected) => {
    removeInteraction()
    const towers = selected.filter((f) => featureOfType(f, FeatureTypeEnum.tower))
    if (towers.length > 0) {
      modifyInteraction = new Modify({
        features: new Collection<Feature>(towers),
        style: sourceEditStyle
      })
      map.addInteraction(modifyInteraction)

      /**
       * While modifying disable hover information
       */
      modifyInteraction.on('modifystart', () => (disableHoverInformation.value = true))

      /**
       * 1. Restore disabled hover information
       * 2. Save modified tower
       */
      modifyInteraction.on('modifyend', (event) => {
        disableHoverInformation.value = false

        const towerFeature = event.features.item(0) as Feature<Point>
        const tower = overheadLineStore.findTowerById(towerFeature.getId() as string)
        const coordinates = towerFeature.getGeometry()?.getCoordinates()!
        const transformedCoordinates = ctx.mapToProject(coordinates)

        if (tower) {
          tower.x = transformedCoordinates[0]
          tower.y = transformedCoordinates[1]
          overheadLineStore.towerSave(tower)
        } else if (overheadLineStore.towerDraft) {
          overheadLineStore.towerDraft.x = transformedCoordinates[0]
          overheadLineStore.towerDraft.y = transformedCoordinates[1]
        }
      })

      snapInteraction = new Snap({ source: ctx.layer.getSource() || undefined })
      map.addInteraction(snapInteraction)
    }
  })

  onBeforeUnmount(removeInteraction)

  function removeInteraction() {
    if (modifyInteraction) {
      map.removeInteraction(modifyInteraction)
    }
    if (snapInteraction) {
      map.removeInteraction(snapInteraction)
    }
  }
}
