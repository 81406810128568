<script lang="ts">
import { useAppAbility } from '@/permissions/useAppAbility'
import { defineComponent } from 'vue'
import DetailPanel from '@/layouts/pages/DetailPanel.vue'
import NumberField from '@/components/form/NumberField.vue'
import { ElMessage, FormInstance, FormRules } from 'element-plus'
import { PipeType, PipeTypeId } from '@/model'
import { usePipeTypeStore } from '@/stores/pipe-type'
import pipeTypeFieldConfig from '@/config/fields/pipe-type'
import { NumberFieldConfig } from '@prionect/ui'
import { useProject } from '@/composables/useProject'

export default defineComponent({
  name: 'PipeTypeForm',
  components: { NumberField, DetailPanel },

  setup() {
    const { can } = useAppAbility()
    const pipeTypeStore = usePipeTypeStore()
    const { projectId } = useProject()
    return { can, pipeTypeStore, projectId }
  },

  data: () => ({
    fields: {} as Partial<PipeType>,
    pipeTypeFieldConfig,
    saving: false
  }),

  computed: {
    create(): boolean {
      return !this.id
    },
    pipeTypeNumberFieldConfigs(): NumberFieldConfig[] {
      return Object.values(pipeTypeFieldConfig).filter(
        (config): config is NumberFieldConfig => config.type == 'number'
      )
    },
    id(): PipeTypeId {
      return this.$route.params.id as string
    },
    item(): PipeType | undefined {
      return this.pipeTypeStore.findById(this.id)
    },
    readOnly(): boolean {
      if (this.projectId && this.can('update', 'LibraryProject')) {
        return false
      }
      return !this.can('update', 'Library')
    },
    title(): string {
      if (this.readOnly) {
        return 'Rohrleitungstyp-Eigenschaften'
      } else if (this.create) {
        return 'Neuer Rohrleitungstyp'
      } else {
        return 'Rohrleitungstyp bearbeiten'
      }
    },
    /**
     * This view should have a route-name glob of "*-edit" or "*-create".
     * This way we can redirect to parent by slicing off everything after "-"
     */
    routeNameBack() {
      const routeName = String(this.$route.name)
      return routeName.slice(0, routeName.lastIndexOf('-'))
    },
    validationRules(): FormRules {
      return {
        name: {
          required: true,
          type: 'string',
          trigger: 'blur',
          message: 'Bitte geben Sie einen Namen ein.'
        },
        alpha: { required: true, message: 'Bitte Wert eingeben' },
        coatingResistance: { required: true, message: 'Bitte Wert eingeben' },
        coatingThickness: { required: true, message: 'Bitte Wert eingeben' },
        diameter: { required: true, message: 'Bitte Wert eingeben' },
        epsR: { required: true, message: 'Bitte Wert eingeben' },
        materialResistivity: { required: true, message: 'Bitte Wert eingeben' },
        muR: { required: true, message: 'Bitte Wert eingeben' }
      }
    }
  },

  mounted() {
    this.fields = { ...this.item }
    if (this.create) {
      setTimeout(() => {
        this.$el.querySelector('input[autofocus]').focus()
      }, 100)
    }
  },

  watch: {
    item() {
      this.fields = { ...this.item }
      ;(this.$refs.form as FormInstance).resetFields()
    }
  },

  methods: {
    async save() {
      const valid = await (this.$refs.form as FormInstance).validate(() => {})
      if (valid) {
        this.saving = true
        try {
          if (this.projectId) {
            this.fields.project = this.projectId
          }
          const savedItem = await this.pipeTypeStore.save(this.fields as PipeType)

          if (this.create) {
            ElMessage.success('Rohrleitungstyp wurde erfolgreich angelegt.')
            this.$router.push({ name: this.routeNameBack + '-edit', params: { id: savedItem.id } })
          } else {
            ElMessage.success('Daten wurden erfolgreich gespeichert.')
          }
        } finally {
          this.saving = false
        }
      }
    }
  }
})
</script>

<template>
  <DetailPanel :title="title" @close="$router.push({ name: routeNameBack })">
    <el-form
      ref="form"
      label-position="top"
      require-asterisk-position="right"
      :model="fields"
      :rules="validationRules"
    >
      <el-form-item prop="name" label="Name">
        <el-input
          v-model="fields.name"
          class="font-semibold"
          autofocus
          data-field="name"
          :disabled="readOnly"
        />
      </el-form-item>

      <div class="grid grid-cols-2 gap-x-6">
        <!-- Alpha -->
        <el-form-item
          :prop="pipeTypeFieldConfig.alpha.name"
          :label="pipeTypeFieldConfig.alpha.label"
        >
          <NumberField
            v-model="fields.alpha"
            :unit="pipeTypeFieldConfig.alpha.unit"
            :data-field="pipeTypeFieldConfig.alpha.name"
            :disabled="readOnly"
          />
        </el-form-item>

        <!-- coatingResistance -->
        <el-form-item
          :prop="pipeTypeFieldConfig.coatingResistance.name"
          :label="pipeTypeFieldConfig.coatingResistance.label"
        >
          <NumberField
            v-model="fields.coatingResistance"
            :unit="pipeTypeFieldConfig.coatingResistance.unit"
            :data-field="pipeTypeFieldConfig.coatingResistance.name"
            :disabled="readOnly"
          />
        </el-form-item>

        <!-- coatingThickness -->
        <el-form-item
          :prop="pipeTypeFieldConfig.coatingThickness.name"
          :label="pipeTypeFieldConfig.coatingThickness.label"
        >
          <NumberField
            v-model="fields.coatingThickness"
            :unit="pipeTypeFieldConfig.coatingThickness.unit"
            :data-field="pipeTypeFieldConfig.coatingThickness.name"
            :disabled="readOnly"
          />
        </el-form-item>

        <!-- diameter -->
        <el-form-item
          :prop="pipeTypeFieldConfig.diameter.name"
          :label="pipeTypeFieldConfig.diameter.label"
        >
          <NumberField
            v-model="fields.diameter"
            :unit="pipeTypeFieldConfig.diameter.unit"
            :data-field="pipeTypeFieldConfig.diameter.name"
            :disabled="readOnly"
          />
        </el-form-item>

        <!-- epsR -->
        <el-form-item :prop="pipeTypeFieldConfig.epsR.name" :label="pipeTypeFieldConfig.epsR.label">
          <NumberField
            v-model="fields.epsR"
            :unit="pipeTypeFieldConfig.epsR.unit"
            :data-field="pipeTypeFieldConfig.epsR.name"
            :disabled="readOnly"
          />
        </el-form-item>

        <!-- materialResistivity -->
        <el-form-item
          :prop="pipeTypeFieldConfig.materialResistivity.name"
          :label="pipeTypeFieldConfig.materialResistivity.label"
        >
          <NumberField
            v-model="fields.materialResistivity"
            :unit="pipeTypeFieldConfig.materialResistivity.unit"
            :data-field="pipeTypeFieldConfig.materialResistivity.name"
            :disabled="readOnly"
          />
        </el-form-item>

        <!-- muR -->
        <el-form-item :prop="pipeTypeFieldConfig.muR.name" :label="pipeTypeFieldConfig.muR.label">
          <NumberField
            v-model="fields.muR"
            :unit="pipeTypeFieldConfig.muR.unit"
            :data-field="pipeTypeFieldConfig.muR.name"
            :disabled="readOnly"
          />
        </el-form-item>
      </div>
    </el-form>

    <div v-if="!readOnly" class="mt-8">
      <el-button type="primary" :loading="saving" data-test="save-btn" @click="save">
        Speichern
      </el-button>
      <el-button text @click="$router.push({ name: routeNameBack })">Abbrechen</el-button>
    </div>
  </DetailPanel>
</template>

<style scoped lang="css"></style>
