import { ref } from 'vue'

export enum MapMode {
  VIEW = 'view',
  EDIT = 'edit',
  MEASURE = 'measure'
}

const mapMode = ref<MapMode>(MapMode.VIEW)

export function useMapMode() {
  return mapMode
}
