import { LayerId } from '@/components/map'
import useContextMenu, { CollectorFunction } from '@/components/map/composables/useContextMenu'
import { UseMapItems } from '@/components/map/composables/useMap'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import { MapMode, useMapMode } from '@/components/map/composables/useMapMode'
import { createMediaGroupDraft } from '@/components/map/layer/media-group/functions'
import { MediaGroupId } from '@/model'
import { useMediaGroupStore } from '@/stores/media-group'
import { MediaGroupResponse } from '@gridside/hsb-api'
import { Feature } from 'ol'
import { MultiLineString } from 'ol/geom'
import { inject, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { MediaLayerContext } from '../util'
import { ContextMenuItem } from '@/components/map/ContextMenu.vue'
import { featureIsDraft, FeaturePropEnum } from '@/components/map/LayerItemConfig'
import { filterUndefined, isNumber } from '@/util/helpers'

export default function useMediaGroupContextMenu(ctx: MediaLayerContext) {
  const { registerCollector, unregisterCollector } = useContextMenu()

  const { showLayer } = inject(UseMapInjectKeys.useMap) as UseMapItems

  const router = useRouter()
  const mediaGroupStore = useMediaGroupStore()
  const mapMode = useMapMode()

  const collectorFunction: CollectorFunction = (coordinate, options) => {
    if (mapMode.value !== MapMode.EDIT) {
      return []
    }

    const items: ContextMenuItem[] = [
      {
        id: 'MediaGroupAdd',
        label: 'Neue Mediengruppe...',
        handler: async () => {
          showLayer(LayerId.MEDIA_GROUP)
          await createMediaGroupDraft({ x: coordinate[0], y: coordinate[1] }, ctx)
          router.push({ name: 'project-map-media-group-create' })
        },
        priority: 312,
        icon: 'MediaGroupAdd'
      }
    ]

    const mediaGroupIds = ctx.selectedMediaGroup.value
      .filter((feature) => !featureIsDraft(feature))
      .map((feature) => feature.getId() as MediaGroupId)
    const medias = ctx.selectedMedia.value
    const mediaGroupCount = mediaGroupIds.length
    const mediaGroups = mediaGroupIds
      .map((id) => mediaGroupStore.findById(id))
      .filter(filterUndefined)

    if (mediaGroupCount > 0) {
      items.push({
        id: 'MediaGroupDelete',
        label:
          mediaGroupCount > 1 ? `${mediaGroupCount} Mediengruppen löschen` : 'Mediengruppe löschen',
        handler: async () => {
          // Setting `itemsToDelete` should trigger a confirm dialog in the MediaGroupLayer component
          mediaGroupStore.itemsToDelete = ctx.selectedMediaGroup.value
            .filter((feature) => !featureIsDraft(feature))
            .map((feature) => mediaGroupStore.findById(feature.getId() as MediaGroupId))
            .filter((item) => item !== undefined) as MediaGroupResponse[]
        },
        priority: 320,
        icon: 'MediaGroupDelete'
      })
    }

    if (mediaGroupCount === 1) {
      const mediaGroup = ctx.selectedMediaGroup.value[0]
      const mediaGroupGeo = mediaGroup.getGeometry() as MultiLineString
      if (medias.length >= 1 && medias.length != mediaGroupGeo.getCoordinates().length) {
        items.push({
          id: 'MediaUngroup',
          label: `${medias.length > 1 ? 'Medien' : 'Medium'} aus Gruppe trennen`,
          handler: async () => {
            const indices = medias
              .map((media) => media.get(FeaturePropEnum.mediaGroupIndex))
              .filter(isNumber)
            await mediaGroupStore.detachLineStringsFromMediaGroup(mediaGroup.getId(), indices)
          },
          priority: 215,
          icon: 'MediaUngroup'
        })
      }
      if (medias.length === 1) {
        items.push({
          id: 'MediaSplit',
          label: 'Medium teilen',
          handler: async () => {
            const feature = ctx.selectedMediaGroup.value[0] as Feature<MultiLineString>
            const splitMediaGroup = await mediaGroupStore.split(
              feature.getId() as MediaGroupId,
              medias[0].get(FeaturePropEnum.mediaGroupIndex),
              ctx.mapToProject(options.mapCoordinate)
            )
            const featuresSelected = ctx.mediaGroupSelect?.getFeatures()
            if (!featuresSelected) {
              return
            }
            featuresSelected.clear()
            const splitFeature = ctx.getFeatureById(splitMediaGroup.id)
            if (!(splitFeature instanceof Feature)) {
              return
            }
            featuresSelected.push(splitFeature)
          },
          priority: 315,
          icon: 'MediaGroupSplit'
        })
      }
      if (medias.length >= 2) {
        items.push({
          id: 'MediaJoin',
          label: `${medias.length} Medien verbinden`,
          handler: async () => {
            await mediaGroupStore.joinLineStrings(
              mediaGroups[0].id,
              medias.map((feat) => feat.get(FeaturePropEnum.mediaGroupIndex))
            )
            // clear old line features
            ctx.mediaSelect?.getFeatures().clear()
          },
          priority: 310,
          icon: 'MediaGroupJoin'
        })
      }
    }

    if (mediaGroupCount >= 2) {
      items.push({
        id: 'MediaGroupConcat',
        label: `${mediaGroupCount} Mediengruppen zusammenfassen`,
        priority: 310,
        icon: 'MediaGroupConcat',
        submenuTitle: 'Mediengruppe als Basis auswählen:',
        submenu: mediaGroups.map((mediaGroup) => ({
          id: mediaGroup.id,
          label: mediaGroup.name,
          priority: 0,
          handler: async () => {
            await mediaGroupStore.concatMediaGroups(mediaGroupIds, mediaGroup)
          }
        }))
      })
    }
    return items
  }

  onMounted(() => registerCollector(collectorFunction))

  onUnmounted(() => unregisterCollector(collectorFunction))
}
