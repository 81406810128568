import { WebSocket } from '@/api'
import { Configuration, TowerTypesApi } from '@gridside/hsb-api'

/**
 * API class that extends the generated REST API with websocket functions
 */
export class TowerTypesExtendedApi extends TowerTypesApi {
  constructor(configuration: Configuration, public webSocket: WebSocket) {
    super(configuration)
  }

  onTowerTypeUpdated(handler: (data: any) => void) {
    return this.webSocket.subscribe('TowerType.updated', handler)
  }

  onTowerTypeDeleted(handler: (data: any) => void) {
    return this.webSocket.subscribe('TowerType.deleted', handler)
  }
}
