import { MapMode, useMapMode } from '@/components/map/composables/useMapMode'
import { ContextMenuItem } from '@/components/map/ContextMenu.vue'
import { onMounted, onUnmounted } from 'vue'
import useContextMenu, { CollectorFunction } from '@/components/map/composables/useContextMenu'
import { MeasureLayerContext } from './MeasureLayer.vue'

export function useMeasureContextMenu(ctx: MeasureLayerContext) {
  const { registerCollector, unregisterCollector } = useContextMenu()

  const mapMode = useMapMode()

  const collectorFunction: CollectorFunction = () => {
    if (mapMode.value !== MapMode.MEASURE) {
      return []
    }

    const items: ContextMenuItem[] = []

    if (ctx.source.getFeatures().length > 0) {
      items.push({
        id: 'clearMeasurements',
        label: 'Messungen löschen',
        priority: 100,
        handler: async () => {
          ctx.drawInteraction.abortDrawing()
          ctx.source.clear()
        }
      })
    }

    return items
  }

  onMounted(() => registerCollector(collectorFunction))
  onUnmounted(() => unregisterCollector(collectorFunction))
}
