<script lang="ts">
import TowerTypeSelectDialog from './TowerTypeSelectDialog.vue'
import TypeSelectField from '@/components/form/TypeSelectField.vue'
import { computed, defineComponent, inject, watch } from 'vue'
import { useTowerTypeStore } from '@/stores/tower-type'
import { useProject } from '@/composables/useProject'
import { FieldConfig, MIXED_VALUES } from '@prionect/ui'
import { FormContextKey } from 'vee-validate'
import { LineTowerTypeTranslations, TowerTypeId } from '@/model'

export default defineComponent({
  name: 'TowerTypeSelectField',
  components: { TowerTypeSelectDialog, TypeSelectField },

  props: {
    earthwireCount: Number
  },
  emits: ['update:earthwireCount'],

  setup(_, ctx) {
    const { projectId } = useProject()
    const towerTypeStore = useTowerTypeStore()
    const form = inject(FormContextKey)
    const field = computed(() => {
      return form?.getPathState<string>((ctx.attrs as FieldConfig).name)
    })

    const towerTypeId = computed<TowerTypeId>(() => {
      return field?.value?.value
    })

    /**
     * Depending on selected tower type emit earthwire count
     */
    watch(
      towerTypeId,
      (value) => {
        ctx.emit('update:earthwireCount', towerTypeStore.earthwireCountForType(value))
      },
      {
        immediate: true
      }
    )

    return { towerTypeStore, projectId, towerTypeId }
  },
  async created() {
    await this.towerTypeStore.ensureLoaded()
    await this.towerTypeStore.ensureLoadedByProject(this.projectId)
  },
  computed: {
    fieldText() {
      if (this.towerTypeId === undefined) {
        return ' '
      }
      if (this.towerTypeId === MIXED_VALUES) {
        return this.towerTypeId
      }
      const item = this.towerTypeStore.findById(this.towerTypeId)
      if (item) {
        return `${item.name} (${LineTowerTypeTranslations[item.lineTowerType]})`
      }
      return 'unbekannt'
    }
  }
})
</script>

<template>
  <TypeSelectField v-bind="$attrs" :text="fieldText">
    <template #dialog="{ show, id, hide, handleChange }">
      <TowerTypeSelectDialog
        v-if="show"
        :modelValue="id"
        :show="show"
        @close="hide"
        @update:modelValue="handleChange"
      />
    </template>
  </TypeSelectField>
</template>

<style scoped lang="css"></style>
