import { StudyCaseConfig } from '@/config/schemas/study-case'
import { CalculationModes, StudyCaseWithConfig } from '@/model'
import { useSystemStore } from '@/stores/system'
import { copy } from '@/util'
import { OperationState, System } from '@gridside/hsb-api'
import { FormContext } from 'vee-validate'
import { computed, watch } from 'vue'

const systemStore = useSystemStore()

/**
 * Computed array of operation states according to current form configuration ("draft")
 *
 * Operation states are finally generated on form submit, depending on the configuration setting
 * (manual, failure, awe). But because already need the generated states before submitting
 * (i.e. for superposition editing), a "draft" is used to preview the states that will be
 * generated from the current configuration.
 */
export const useOperationStatesDraft = (form: FormContext<StudyCaseWithConfig>) => {
  const draft = computed<OperationState[]>(() => {
    const config = copy(form.values.configuration)
    if (config.modeSelected === CalculationModes.MANUAL) {
      return config.manual
    } else if (config.modeSelected === CalculationModes.AUTO_FAIL) {
      return generateFailureOperationStates(config)
    } else if (config.modeSelected === CalculationModes.AUTO_AWE) {
      return generateAweOperationStates(config)
    }
    return []
  })
  return draft
}

/**
 * Generates operation states for an AWE configuration
 */
function generateAweOperationStates(config: StudyCaseConfig): OperationState[] {
  const operationStates: OperationState[] = []
  config.autoAWE.forEach((autoAWE) => {
    const system = systemStore.findById(autoAWE.system)
    if (!system) {
      return
    }

    const AWEAngle = autoAWE.angle
    const AWECurrent = autoAWE.current

    // construct a conductor state for each wire/phase on the system
    for (let AWEPhase = 0; AWEPhase < system.wireCount; AWEPhase++) {
      const operationState: OperationState = {
        system: autoAWE.system,
        conductorStates: []
      }
      for (let phase = 0; phase < system.wireCount; phase++) {
        let angle = 0
        if (AWEPhase === 0 && phase === 2) {
          angle = -AWEAngle
        } else if (AWEPhase === 1 && phase === 0) {
          angle = -AWEAngle
        } else if (AWEPhase === 2 && phase === 1) {
          angle = -AWEAngle
        } else {
          angle = 0
        }
        operationState.conductorStates.push({
          current: AWEPhase === phase ? 0 : AWECurrent,
          angle
        })
      }
      operationStates.push(operationState)
    }
  })
  return operationStates
}

/**
 * Generates operation states for a failure configuration
 */
function generateFailureOperationStates(config: StudyCaseConfig): OperationState[] {
  const operationStates: OperationState[] = []
  config.autoFail.forEach((autoFail) => {
    const system = systemStore.findById(autoFail.system)
    if (!system) {
      return
    }
    let setIndex = 0
    for (let indexRow = 0; indexRow < system.wireCount; indexRow++) {
      const operationState: OperationState = {
        system: autoFail.system,
        conductorStates: []
      }
      for (let indexCol = 0; indexCol < system.wireCount; indexCol++) {
        operationState.conductorStates.push({
          current: setIndex === indexCol ? autoFail.current : 0,
          angle: 0
        })
      }
      setIndex++
      operationStates.push(operationState)
    }
  })
  return operationStates
}
