import {
  Decoration,
  DecorationSet,
  EditorView,
  MatchDecorator,
  ViewPlugin,
  ViewUpdate,
  WidgetType
} from '@codemirror/view'
import { OperationState } from '@gridside/hsb-api'
import { useSystemColor } from '@/composables/useSystemColor'
import { getContrastColor } from '@/util/colors'

/**
 * Regex for e.g. op_1, op_2
 */
function createOperationStateRegex(operationStateIndices: number[]) {
  const numberPattern = operationStateIndices.map(String).join('|')
  const regexString = `op_(${numberPattern})`
  const regex = new RegExp(regexString, 'g')
  // console.log(regex)
  return regex
}

export function createOperationStatesViewPlugin(operationsStates: OperationState[]) {
  /**
   * Regex to detect atomic ranges
   */
  const AtomicRangeDecorator = new MatchDecorator({
    regexp: createOperationStateRegex(operationsStates.map((_, index) => index + 1).reverse()),
    decoration: (match) => {
      const opIndex = parseInt(match[1]) - 1
      const system = operationsStates[opIndex].system
      const colorBackground = useSystemColor(system)
      const colorText = getContrastColor(colorBackground)

      return Decoration.replace({
        widget: new OperationStateAtomicRangeWidget(opIndex + 1, colorBackground, colorText)
      })
    }
  })

  return ViewPlugin.fromClass(
    class {
      placeholders: DecorationSet
      constructor(view: EditorView) {
        this.placeholders = AtomicRangeDecorator.createDeco(view)
      }
      update(update: ViewUpdate) {
        this.placeholders = AtomicRangeDecorator.updateDeco(update, this.placeholders)
      }
    },
    {
      decorations: (instance) => instance.placeholders,
      provide: (plugin) =>
        EditorView.atomicRanges.of((view) => {
          return view.plugin(plugin)?.placeholders || Decoration.none
        })
    }
  )
}

/**
 * Widget for styling the Atomic Range
 */
export class OperationStateAtomicRangeWidget extends WidgetType {
  constructor(private opIndex: number, private backgroundColor: string, private textColor: string) {
    super()
  }
  eq(other: OperationStateAtomicRangeWidget) {
    return this.opIndex == other.opIndex
  }
  toDOM() {
    const elt = document.createElement('span')
    elt.style.cssText = `
      border-radius: 4px;
      padding: 0 0.5rem;
      color: ${this.textColor};
      background: ${this.backgroundColor};`
    elt.textContent = this.opIndex.toString()
    // console.log(this.opIndex)
    return elt
  }
  ignoreEvent() {
    return true
  }
}
