import { Fill, Stroke, Style, Text } from 'ol/style.js'
import { LineString, Point } from 'ol/geom'
import { Coordinate } from 'ol/coordinate'
import { Options as StrokeOptions } from 'ol/style/Stroke'
import { StyleFunction } from 'ol/style/Style'
import { toRadians } from 'ol/math'
import { RegularShape } from 'ol/style'
import { Options as RegularShapeOptions } from 'ol/style/RegularShape'
import { getLength } from 'ol/sphere'
import constants from '@/components/map/style/constants'
import CircleStyle from 'ol/style/Circle'

type AngleAndPos = { angleRad: number; pos: Coordinate }
const baseColor = '#d2a010'
const baseWidth = 3
const baseStrokeOptions: StrokeOptions = {
  color: baseColor,
  width: baseWidth,
  lineCap: 'butt',
  lineJoin: 'round'
}

export const measureStyleFn = (stroke: StrokeOptions): StyleFunction => {
  return (feature, res) => {
    const geometry = feature.getGeometry()
    if (geometry instanceof Point) {
      return indicatorStyle
    }
    if (!(geometry instanceof LineString)) {
      return
    }

    const coordinates = geometry.getCoordinates()
    if (coordinates.length < 2) {
      return
    }

    const styles = [lineStyle(geometry, stroke, res)]

    if (stroke.lineDash) {
      return styles
    }
    const lineFirst = coordinates[0]
    const lineSecond = coordinates[1]
    const lineLast = coordinates[coordinates.length - 1]
    const lineSecondLast = coordinates[coordinates.length - 2]

    const start = geometry.getFirstCoordinate()
    const end = geometry.getLastCoordinate()

    const angleRadStart = Math.atan2(lineSecond[1] - lineFirst[1], lineSecond[0] - lineFirst[0])
    const angleRadEnd = Math.atan2(lineLast[1] - lineSecondLast[1], lineLast[0] - lineSecondLast[0])

    styles.push(
      ...createStartEndLines(
        { angleRad: angleRadStart, pos: start },
        { angleRad: angleRadEnd, pos: end },
        res
      )
    )
    styles.push(
      ...createStartEndArrows(
        { angleRad: angleRadStart, pos: start },
        { angleRad: angleRadEnd, pos: end }
      )
    )

    return styles
  }
}
function createStartEndArrows(start: AngleAndPos, end: AngleAndPos) {
  const size = 6
  const baseOptions: RegularShapeOptions = {
    stroke: new Stroke({
      width: baseWidth,
      lineJoin: 'miter',
      color: baseColor
    }),
    fill: new Fill({ color: baseColor }),
    points: 3,
    radius: size
  }

  // Add start arrow
  const arrowStart = new Style({
    geometry: new Point(start.pos),
    image: new RegularShape({
      ...baseOptions,
      angle: toRadians(-90), // angle of shape to be perpendicular
      displacement: [size + baseWidth / 2, 0], // displacement to make tip of triangle touch end of line
      rotation: -start.angleRad // rotate relative to line
    })
  })

  // Add end arrow
  const arrowEnd = new Style({
    geometry: new Point(end.pos),
    image: new RegularShape({
      ...baseOptions,
      angle: toRadians(90),
      displacement: [-size - baseWidth / 2, 0],
      rotation: -end.angleRad
    })
  })
  return [arrowStart, arrowEnd]
}
export const measureStyleDone = measureStyleFn(baseStrokeOptions)
export const measureStyleSketch = measureStyleFn({ ...baseStrokeOptions, lineDash: [10, 10] })

const lineStyle = (line: LineString, options: StrokeOptions, res: number) => {
  const text = formatLength(line)
  // Stil für die Linie
  return new Style({
    stroke: new Stroke(options),
    text:
      res < 50 || options.lineDash // only on draw and not zoomed too far out
        ? new Text({
            text,
            font: `bold 13px ${constants.fontFamily}`,
            fill: new Fill({
              color: baseColor
            }),
            stroke: new Stroke({
              width: 4,
              color: 'rgba(255,255,255,0.8)'
            }),
            overflow: true,
            placement: 'line',
            offsetY: -10
          })
        : undefined
  })
}

const indicatorStyle = new Style({
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: baseColor
    }),
    fill: new Fill({
      color: 'rgba(255,255,255,0.36)'
    })
  })
})

function formatLength(line: LineString): string {
  const length = getLength(line) // Length in meters
  let output: string

  if (length >= 100000) {
    // Greater than or equal to 100 km
    output = Math.round(length / 1000) + ' km'
  } else if (length >= 1000) {
    // From 1 km to 99.9 km
    output = Math.round((length / 1000) * 10) / 10 + ' km'
  } else if (length >= 100) {
    // From 100 m to 999 m
    output = Math.round(length) + ' m'
  } else {
    // From 0.1 m to 99.99 m
    output = Math.round(length * 100) / 100 + ' m'
  }

  return output
}
function createPerpendicularLineString(
  point: Coordinate,
  angle: number,
  length: number
): LineString {
  // Calculate the midpoint offset
  const midOffsetX = Math.cos(angle) * length
  const midOffsetY = Math.sin(angle) * length

  // Calculate the start and end points of the line string
  const startPoint: Coordinate = [point[0] - midOffsetX, point[1] - midOffsetY]
  const endPoint: Coordinate = [point[0] + midOffsetX, point[1] + midOffsetY]

  // Create a new line string geometry from the start and end points
  return new LineString([startPoint, endPoint])
}

function createStartEndLines(start: AngleAndPos, end: AngleAndPos, res: number) {
  // Length of the perpendicular line
  const length = res * 10

  // Create the perpendicular line geometries
  const startLine = createPerpendicularLineString(start.pos, start.angleRad + Math.PI / 2, length)
  const endLine = createPerpendicularLineString(end.pos, end.angleRad + Math.PI / 2, length)

  // Create styles for the perpendicular lines
  return [
    new Style({
      geometry: startLine,
      stroke: new Stroke({
        lineCap: 'butt',
        color: baseColor,
        width: baseWidth
      })
    }),
    new Style({
      geometry: endLine,
      stroke: new Stroke({
        lineCap: 'butt',
        color: baseColor,
        width: baseWidth
      })
    })
  ]
}
