import { WebSocket } from '@/api'
import { Configuration, PipeTypesApi } from '@gridside/hsb-api'

/**
 * API class that extends the generated REST API with websocket functions
 */
export class PipeTypesExtendedApi extends PipeTypesApi {
  constructor(configuration: Configuration, public webSocket: WebSocket) {
    super(configuration)
  }

  onPipeTypeUpdated(handler: (data: any) => void) {
    return this.webSocket.subscribe('PipeType.updated', handler)
  }

  onPipeTypeDeleted(handler: (data: any) => void) {
    return this.webSocket.subscribe('PipeType.deleted', handler)
  }
}
