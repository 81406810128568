import { ResponseError } from '@gridside/hsb-api'
import { App } from 'vue'
import { ElMessage } from 'element-plus'
import { useAuthentication } from '@prionect/ui'
import * as Sentry from '@sentry/vue'

export function setupErrorHandlers(app: App) {
  const { logout } = useAuthentication()

  /**
   * Handle uncaught Vue Errors
   * For when its triggered see: https://vuejs.org/api/application.html#app-config-errorhandler
   */
  app.config.errorHandler = async (error, vm, info) => {
    let message = (error as any).toString()
    if (error instanceof ResponseError) {
      let responseBody: any = {}

      try {
        responseBody = JSON.parse(await error.response.text())
      } catch (e) {
        // ignore parse errors
      }

      message = `Serverfehler Status ${error.response?.status}: ${
        responseBody.message || responseBody.error?.message || '(unbekannter Fehler)'
      }`
    }
    ElMessage.error({
      dangerouslyUseHTMLString: true,
      message: `<span class="font-bold">Fehler: </span>` + message,
      showClose: true,
      duration: 15000
    })
    console.error('app.config.errorHandler', error)
    Sentry.captureException(error, { tags: { trigger: 'app.config.errorHandler' } })
  }

  /**
   * Handle non-handled (synchronous) runtime errors (SyntaxError, TypeError, ...)
   */
  window.addEventListener('error', (error) => {
    // Ignore uncritical errors
    if (
      error.message.includes('ResizeObserver loop') ||
      error.message.includes('Request aborted')
    ) {
      return
    }

    ElMessage.error('Fehler: ' + error.message)
    // show error  in console
    console.error(error)
    Sentry.captureException(error, { tags: { trigger: 'window.eventListener.error' } })
  })

  /**
   * Handle non-handled (asynchronous) promise rejections (HTTPRequests, ...)
   */
  let handlingNotAuthorized = false
  window.onunhandledrejection = async (error: PromiseRejectionEvent) => {
    console.error(error.reason, error.reason.response)

    const axiosResponseStatus = error.reason?.response?.status
    if ([403, 401].includes(axiosResponseStatus)) {
      // Handling parallel requests running into 401/403
      if (handlingNotAuthorized) {
        return
      }
      handlingNotAuthorized = true
      ElMessage.error({
        dangerouslyUseHTMLString: true,
        message: 'Sie haben keine Berechtigung für diese Operation.',
        showClose: true,
        duration: 5000
      })
      await logout()
      handlingNotAuthorized = false
      return
    }
    const message = error.reason.toString()
    ElMessage.error({
      dangerouslyUseHTMLString: true,
      message: message,
      showClose: true,
      duration: 10000
    })
    Sentry.captureException(error.reason, { tags: { trigger: 'window.onunhandledrejection' } })
  }
}
