import { MediaLayerContext } from '@/components/map/layer/util'
import { FeaturePropEnum } from '@/components/map/LayerItemConfig'
import { watch } from 'vue'

export const useMediaGroupLayerOptions = (ctx: MediaLayerContext) => {
  const { layer, visibleFeatures } = ctx

  const syncLayerOptions = () => {
    const source = layer.getSource()
    const visibleMediaGroups = visibleFeatures.value
    source?.getFeatures().forEach((feature) => {
      feature.set(
        FeaturePropEnum.hidden,
        !(
          visibleMediaGroups === undefined || visibleMediaGroups.includes(feature.getId() as string)
        )
      )
    })
  }

  watch(visibleFeatures, syncLayerOptions, { immediate: true })
  ctx.layer.on('change:source', syncLayerOptions)
}
