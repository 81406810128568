<script lang="ts">
import { useProjectStore } from '@/stores/project'
import { unifyProjectionCode } from '@/util'
import { FeatureCollection, LineString } from 'geojson'
import { v4 } from 'uuid'
import { defineComponent, PropType, ref } from 'vue'
import { ReplaceTowersRequest } from '@gridside/hsb-api/dist/models'
import { useOverheadLine } from '@/composables/useOverheadLine'
import { TowerRequest } from '@gridside/hsb-api/dist/models/TowerRequest'
import { useProject } from '@/composables/useProject'
import { OverheadLine } from '@gridside/hsb-api'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ImportStep',

  props: {
    formValues: {
      type: Object,
      default: () => ({ tower: undefined, span: undefined })
    },
    geojson: {
      type: Object as PropType<FeatureCollection>
    }
  },

  data: () => ({
    error: false as false | string,
    firstRun: true,
    importing: false,
    message: undefined as undefined | string,
    success: true
  }),
  setup() {
    const { overheadLineStore } = useOverheadLine()
    const project = useProject()
    const i18n = useI18n()

    const newOverheadLine = ref<OverheadLine>({
      id: v4(),
      project: project.projectId.value,
      name:
        'Importierte Freileitung vom ' +
        new Date().toLocaleDateString('de-DE', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        }),
      description: '',
      towerCount: 0
    })
    return { overheadLineStore, project, newOverheadLine }
  },

  computed: {
    spanDefaults() {
      return this.formValues.span
    },
    towerDefaults() {
      return this.formValues.tower
    },
    towers(): TowerRequest[] {
      if (!this.geojson) {
        return []
      }
      const towerCoordinates = (this.geojson.features[0].geometry as LineString).coordinates
      return towerCoordinates.map((coord) => ({
        id: v4(),
        x: coord[0],
        y: coord[1],
        overheadLine: this.newOverheadLine.id,
        ...this.towerDefaults
      }))
    }
  },

  methods: {
    async runImport() {
      this.importing = true
      this.firstRun = false
      this.success = false

      try {
        this.error = false
        if (!this.geojson || !this.towers || !this.spanDefaults) {
          throw Error('Ungültige Parameter aus den vorherigen Schritten übergeben.')
        }

        const payload: ReplaceTowersRequest = {
          list: this.towers,
          spanDefaults: this.spanDefaults
        }
        this.message = 'Freileitung anlegen...'
        const response = await this.overheadLineStore.save(this.newOverheadLine)

        this.message = 'Masten importieren...'
        await this.overheadLineStore.towersReplaceAll(payload, response.id)

        this.message = 'Referenzsystem setzen...'
        const projectStore = useProjectStore()
        const project = projectStore.findById(response.project)
        if (!project) {
          throw Error('Projekt nicht gefunden. Konnte CRS nicht aktualisieren.')
        }
        project.crs = unifyProjectionCode((this.geojson as any).crs?.properties?.name)
        await projectStore.save(project)

        this.success = true
      } catch (e: any) {
        this.error = e.toString()
      } finally {
        this.importing = false
      }
    }
  }
})
</script>

<template>
  <div class="flex flex-col min-h-[20rem] justify-center items-center max-w-[60ch] mx-auto">
    <transition mode="out-in" name="fade">
      <div v-if="firstRun || importing" class="flex flex-col space-y-4 items-center text-gray-500">
        <p class="text-center">Beim Import werden {{ towers.length }} Masten neu angelegt.</p>

        <p class="pb-4">Sind Sie sicher, dass Sie den Import durchführen wollen?</p>
        <p-btn :loading="importing" type="warning" @click="runImport">
          {{ message || 'Ja, Import starten' }}
        </p-btn>
      </div>
      <div v-else-if="error" class="bg-error-200 text-error-700 p-4 rounded w-full">
        <b>Fehler:</b>
        {{ error }}
      </div>
      <div v-else-if="success" class="flex flex-col items-center">
        <p-success-icon class="text-success-500 w-20 mb-4" />
        <p class="text-success-600 text-lg">Der Import war erfolgreich.</p>
      </div>
    </transition>
  </div>
</template>

<style lang="css" scoped></style>
