import { ProjectionLike, transform } from 'ol/proj'

import { Map } from 'ol'

type Coordinate = number[]

/**
 * Provides transformation between map and project coordinate-systems.
 */
export default function useCoordinates(
  mapCrs: Map | ProjectionLike = 'EPSG:3857',
  projectCrs: ProjectionLike
) {
  const mapProjection = mapCrs instanceof Map ? mapCrs.getView().getProjection() : mapCrs

  const mapToProject = (c: Coordinate): Coordinate => {
    if (projectCrs) {
      return transform(c, mapProjection, projectCrs)
    } else {
      console.warn('Could not transform from project to map coordinates, no project CRS given.')
      return [...c]
    }
  }

  const projectToMap = (c: Coordinate): Coordinate => {
    if (projectCrs) {
      return transform(c, projectCrs, mapProjection)
    } else {
      console.warn('Could not transform from map to project coordinates, no project CRS given.')
      return [...c]
    }
  }

  const projectToLonLat = (c: Coordinate): Coordinate => {
    if (projectCrs) {
      return transform(c, projectCrs, 'EPSG:4326')
    } else {
      console.warn('Could not transform from project to WGS84 coordinates, no project CRS given.')
      return [...c]
    }
  }

  const lonLatToProject = (c: Coordinate): Coordinate => {
    if (projectCrs) {
      return transform(c, 'EPSG:4326', projectCrs)
    } else {
      console.warn('Could not transform from WGS84 to project coordinates, no project CRS given.')
      return [...c]
    }
  }

  return {
    mapProjection,
    projectCrs,
    lonLatToProject,
    mapToProject,
    projectToMap,
    projectToLonLat
  }
}
