<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TablePagination',

  data: () => ({
    internalPage: 1,
    internalPageSize: 10
  }),

  props: {
    page: {
      type: Number,
      default: 1
    },
    count: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 10
    },
    pageSizeOptions: {
      type: Array,
      default: () => [10, 15, 25, 50, 100]
    }
  },
  emits: ['update:page', 'update:pageSize'],

  computed: {
    numPages() {
      return Math.ceil(this.count / this.internalPageSize)
    }
  },

  mounted() {
    this.internalPage = this.page
    this.internalPageSize = this.pageSize
  },

  watch: {
    count() {
      this.internalPage = 1
    },
    pageSize() {
      this.internalPage = 1
    },
    internalPage() {
      this.$emit('update:page', this.internalPage)
    },
    internalPageSize() {
      this.$emit('update:pageSize', this.internalPageSize)
    }
  },

  methods: {
    first() {
      this.internalPage = 1
    },
    formatCount() {
      return this.count === 1 ? '1 Eintrag' : `${this.count} Einträge`
    },
    next() {
      if (this.internalPage < this.numPages) {
        this.internalPage++
      }
    },
    last() {
      this.internalPage = this.numPages
    },
    previous() {
      if (this.internalPage > 1) {
        this.internalPage--
      }
    }
  }
})
</script>

<template>
  <div class="table-pagination flex w-full bg-gray-50 justify-between items-center px-6 py-3">
    <div class="text-sm text-gray-400">
      <template v-if="count > 0 && count >= pageSize">
        {{ (page - 1) * pageSize + 1 }} - {{ Math.min(page * pageSize, count) }} von
        {{ count }} Einträgen
      </template>
      <template v-else>{{ formatCount() }}</template>
    </div>
    <div v-if="count > 0 && count >= pageSize" class="flex items-center">
      <label class="text-sm mr-2 text-gray-400">Einträge pro Seite:</label>
      <el-select class="!w-20 mr-4 text-xs" v-model="internalPageSize">
        <el-option v-for="value in pageSizeOptions" :value="value" :key="value">
          {{ value }}
        </el-option>
      </el-select>
      <el-button-group>
        <p-btn @click="first" :disabled="internalPage === 1" class="!h-[34px]">
          <el-icon><FirstIcon /></el-icon>
        </p-btn>
        <p-btn @click="previous" :disabled="internalPage === 1" class="!h-[34px]">
          <el-icon class="rotate-180"><NextIcon /></el-icon>
        </p-btn>
        <p-btn @click="next" :disabled="internalPage === numPages" class="!h-[34px]">
          <el-icon><NextIcon /></el-icon>
        </p-btn>
        <p-btn @click="last" :disabled="internalPage === numPages" class="!h-[34px]">
          <el-icon><LastIcon /></el-icon>
        </p-btn>
      </el-button-group>
    </div>
    <div v-else>
      <label class="text-sm mr-2 text-gray-400">Einträge pro Seite:</label>
      <el-select class="w-20 mr-4 text-xs" v-model="internalPageSize">
        <el-option v-for="value in pageSizeOptions" :value="value" :key="value">
          {{ value }}
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<style scoped lang="css"></style>
