import { useMapSelection } from '@/components/map/composables'
import { deleteDraftFeatures } from '@/components/map/layer/source/functions'
import { SourceLayerContext } from '@/components/map/layer/util'
import { mediaGroupSelectStyle } from '@/components/map/style/media-group'

import { useOverheadLineStore } from '@/stores/overhead-lines'
import { useEventListener } from '@vueuse/core'
import { Collection, Feature } from 'ol'
import { LineString, Point } from 'ol/geom'
import { Draw, Snap } from 'ol/interaction'
import { DrawEvent } from 'ol/interaction/Draw'
import { v4 } from 'uuid'
import { nextTick } from 'vue'
import { FeaturePropEnum, FeatureTypeEnum } from '@/components/map/LayerItemConfig'

type XYCoordinates = { x: number; y: number }
const { select } = useMapSelection()
let snapInteraction: Snap

export async function createOverheadLineDraft(coordinates: XYCoordinates, ctx: SourceLayerContext) {
  deleteDraftFeatures(ctx)

  // use context menu position for first tower by default
  let firstPointCoordinates = ctx.projectToMap([coordinates.x, coordinates.y])
  // if there is a single tower selected, use it's position to create a branched overhead line
  if (ctx.selectedTowers.value.length === 1) {
    const selectedTowerCoordinate = ctx.selectedTowers.value[0].getGeometry()?.getFirstCoordinate()

    if (selectedTowerCoordinate) {
      firstPointCoordinates = selectedTowerCoordinate
    }
  }

  const features = new Collection<Feature>([new Feature(new Point(firstPointCoordinates))])
  const draw = new Draw({ type: 'LineString', features, style: mediaGroupSelectStyle as any })
  const map = ctx.map

  const store = useOverheadLineStore()
  store.overheadLineDraft = undefined

  // disable select interaction to not accidentally select spans or towers
  ctx.selectInteraction?.setActive(false)
  map.addInteraction(draw)
  draw.appendCoordinates([firstPointCoordinates])
  draw.setActive(true)

  snapInteraction = new Snap({ source: ctx.layer.getSource() || undefined })
  map.addInteraction(snapInteraction)

  useEventListener(
    document,
    'keydown',
    (event) => {
      if (event.key === 'Escape') {
        draw.abortDrawing()
        map.removeInteraction(draw)
        // re-enable select interaction
        ctx.selectInteraction?.setActive(true)
      }
    },
    { once: true }
  )

  return new Promise((resolve) => {
    draw.once('drawend', (e: DrawEvent) => {
      nextTick(() => {
        draw.setActive(false)
        map.removeInteraction(draw)
        map.removeInteraction(snapInteraction)
        // re-enable select interaction
        ctx.selectInteraction?.setActive(true)

        resolve(true)
      })

      const initialPath = (e.feature.getGeometry() as LineString)
        .getCoordinates()
        .map((coordinates) => ctx.mapToProject(coordinates))
        .map(([x, y]) => ({ x, y }))

      const overheadLineId = v4()
      store.overheadLineDraft = {
        overheadLine: {
          id: overheadLineId,
          name: 'Neue Freileitung'
        },
        path: initialPath,
        tower: {
          name: 'Neuer Mast'
        }
      }
      e.feature.setId(overheadLineId)
      e.feature.set(FeaturePropEnum.type, FeatureTypeEnum.overheadLine)
      e.feature.set(FeaturePropEnum.draft, true)
      select(e.feature)
    })
  })
}
