<script setup lang="ts">
import { useProject } from '@/composables/useProject'
import { useCalculationStore } from '@/stores/calculation'
import { computed } from 'vue'

const calculationStore = useCalculationStore()
const { projectId } = useProject()
calculationStore.ensureLoaded(projectId.value)

const calculations = computed(() =>
  Object.values(calculationStore.calculations).filter(
    (calc) => calc.studyCase?.id === calculationStore.selectedStudyCase
  )
)
</script>

<template>
  <div class="">
    <div>Selected Study Case: {{ calculationStore.selectedStudyCase }}</div>
    <div>Berechnungen ({{ calculations.length }}):</div>
    <div class="text-xs">
      {{
        calculations.map((c) => ({
          id: c.id,
          status: c.status || 'undefined',
          progress: c.progress
        }))
      }}
    </div>
    <hr />
    <p>Aktuelle Berechnung (running): {{ calculationStore.current?.id }}</p>
  </div>
</template>

<style scoped lang="css"></style>
