<script lang="ts">
import ConductorTypeSelectField from '@/components/conductor-type/ConductorTypeSelectField.vue'
import { tower } from '@/config/fields'
import { Tower } from '@gridside/hsb-api'
import { useTowerTypeStore } from '@/stores/tower-type'
import { computed, defineComponent, inject, PropType, ref, watch } from 'vue'
import TowerTypeSelectField from '@/components/tower-type/TowerTypeSelectField.vue'
import { towerDirectionPropertiesFields } from '@/config/fields/tower'
import TowerAllocationSelectField from '@/components/tower/TowerAllocationSelectField.vue'
import { useProject } from '@/composables/useProject'
import { copy, keys } from '@/util'
import { FormContextKey } from 'vee-validate'

export default defineComponent({
  name: 'TowerPropertiesStep',
  components: { TowerAllocationSelectField, ConductorTypeSelectField, TowerTypeSelectField },

  props: {
    values: {
      type: Object as PropType<{ tower?: Tower }>,
      default: () => ({})
    }
  },
  setup() {
    const towerTypeStore = useTowerTypeStore()
    const { projectId } = useProject()

    const form = inject(FormContextKey)
    const earthwiresCount = ref(0)

    const fieldConfig = computed(() => {
      const fieldConfig = copy(tower)
      for (const key of keys(fieldConfig)) {
        fieldConfig[key].name = 'tower.' + fieldConfig[key].name
      }
      return fieldConfig
    })
    const fieldConfigIn = computed(() => {
      const fieldConfig = copy(towerDirectionPropertiesFields)
      for (const key of keys(fieldConfig)) {
        fieldConfig[key].name = 'tower.in.' + fieldConfig[key].name
      }
      return fieldConfig
    })

    /**
     * Because <p-wizard> uses "keep-values" we need to manually reset earthwires values when count changes
     */
    watch(earthwiresCount, (value, oldValue) => {
      for (let i = 0; i <= oldValue; i++) {
        // only delete earthwires which had higher count
        if (i > value) {
          form?.unsetPathValue(`${fieldConfigIn.value.earthwires.name}.${i - 1}`)
        }
      }
    })
    return { towerTypeStore, projectId, earthwiresCount, fieldConfig, fieldConfigIn }
  },

  mounted() {
    this.towerTypeStore.ensureLoaded()
    this.towerTypeStore.ensureLoadedByProject(this.projectId)
  }
})
</script>

<template>
  <div class="flex space-x-16">
    <div class="flex-1">
      <p-form-section title="Masttyp">
        <TowerTypeSelectField
          v-bind="fieldConfigIn.type"
          v-model:earthwire-count="earthwiresCount"
          class="col-span-2"
        />

        <!-- Offset value required in this case-->
        <p-field v-bind="fieldConfigIn.offset" required />
        <p-field v-bind="fieldConfig.earthResistivity" />
      </p-form-section>
    </div>
    <div class="flex-1">
      <p-form-section title="Leiterzuordnung">
        <!-- conductor allocation -->
        <TowerAllocationSelectField
          v-bind="fieldConfigIn.allocation"
          :tower-type-id="values.tower?.in.type"
        />

        <!-- earthwire allocation -->
        <ConductorTypeSelectField
          v-for="n in earthwiresCount"
          :key="n"
          :field-config="fieldConfigIn.earthwires"
          :index="n - 1"
          required="Bitte Leitertyp für das Erdseil auswählen"
          :conductors="values.tower?.in.earthwires"
          dense
        />
      </p-form-section>
    </div>
  </div>
</template>
