<script setup lang="ts">
import { computed, PropType } from 'vue'
import { Feature } from 'ol'
import { useMediaGroupStore } from '@/stores/media-group'
import { usePipeTypeStore } from '@/stores/pipe-type'

const props = defineProps({
  feature: {
    type: Object as PropType<Feature>,
    required: true
  }
})

const mediaGroupStore = useMediaGroupStore()
const pipeTypeStore = usePipeTypeStore()

const mediaGroup = computed(() => mediaGroupStore.findById(props.feature.getId() as string))
const pipeType = computed(() => {
  if (mediaGroupStore.isPipe(mediaGroup.value)) {
    return pipeTypeStore.findById(mediaGroup.value.pipeType)?.name
  }
  return 'unbekannt'
})
</script>

<template>
  <div v-if="mediaGroup">
    <h3>
      {{ mediaGroup.type === 'wire' ? ' Telekommunikationsleitung' : 'Rohrleitung' }}:
      {{ mediaGroup.name }}
    </h3>
    <table>
      <tr v-if="mediaGroup.type === 'pipe'">
        <th>Rohrleitungstyp</th>
        <td>{{ pipeType }}</td>
      </tr>
      <tr>
        <th>Höhe / Tiefe</th>
        <td>{{ mediaGroup.height }} m</td>
      </tr>
      <tr v-if="mediaGroupStore.isPipe(mediaGroup)">
        <th>spez. Erdwiderstand (ρE)</th>
        <td>{{ mediaGroup.rhoE }} Ωm</td>
      </tr>
    </table>
  </div>
</template>

<style scoped lang="css"></style>
