<script setup lang="ts">
import { UseMapInjectKeys, UseMapItems } from '@/components/map/composables'
import { inject, PropType } from 'vue'
import { LayerTreeLayerOptions } from './types'

const props = defineProps({
  options: { type: Object as PropType<LayerTreeLayerOptions>, default: [] },
  layer: { type: String, required: true }
})

const { layers, setLayerOption } = inject(UseMapInjectKeys.useMap) as UseMapItems

const toggleOption = (optionId: string) => {
  const currentValue = layers.value[props.layer]?.options[optionId] || false
  layers.value[props.layer].options[optionId] = !currentValue
}
</script>

<template>
  <div class="layer-options relative px-1" @click.stop>
    <el-icon size="20" class="opacity-50 hover:opacity-100 align-middle">
      <OptionsIcon />
    </el-icon>
    <div class="layer-options__panel">
      <div class="font-medium text-gray-400 uppercase mb-1">Ebenen-Optionen</div>
      <div v-for="option in options" :key="option.id" class="layer-options__option">
        <div class="mr-2">
          <el-switch
            size="small"
            :model-value="layers[props.layer]?.options[option.id]"
            @update:modelValue="(v: boolean) => setLayerOption(props.layer, option.id, v)"
          />
        </div>
        <div class="flex-1" @click="toggleOption(option.id)">{{ option.label }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="css">
.layer-options__panel {
  @apply shadow bg-gray-50 text-xs p-3;
  position: fixed;
  margin-top: -32px;
  margin-left: -16px;
  display: none;
  z-index: 4000;
  font-weight: normal;
}

.layer-options:hover .layer-options__panel {
  display: block;
}

.layer-options__option {
  @apply flex items-center py-0.5;
}
</style>
