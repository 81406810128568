import { SystemsLayerContext } from '@/components/map/layer/util'
import { FeaturePropEnum } from '@/components/map/LayerItemConfig'
import { watch } from 'vue'

export function useSystemsLayerOptions(ctx: SystemsLayerContext) {
  const { layer, visibleFeatures } = ctx

  const syncLayerOptions = () => {
    const source = layer.getSource()
    const visibleSystems = visibleFeatures.value

    source?.getFeatures().forEach((feature) => {
      feature.set(
        FeaturePropEnum.hidden,
        !(visibleSystems === undefined || visibleSystems.includes(feature.getId() as string))
      )
    })
  }

  watch(visibleFeatures, syncLayerOptions, { immediate: true })
  ctx.layer.on('change:source', syncLayerOptions)
}
