<script lang="ts">
import { HsbApi } from '@/api'
import { MediaGroup } from '@/model'
import { useMediaGroupStore } from '@/stores/media-group'
import { useProjectStore } from '@/stores/project'
import { unifyProjectionCode } from '@/util'
import { FeatureCollection } from 'geojson'
import { defineComponent, PropType } from 'vue'
import { useProject } from '@/composables/useProject'
import { MediaGroupRequest } from '@gridside/hsb-api'

export default defineComponent({
  name: 'MediaGroupImportStep',

  props: {
    mediaGroups: {
      type: Array as PropType<MediaGroup[]>,
      required: true
    },
    geoJson: {
      type: Object as PropType<FeatureCollection | null>,
      required: true
    }
  },
  setup() {
    const { projectId } = useProject()
    return { projectId }
  },

  data: () => ({
    error: false as false | string,
    firstRun: true,
    importing: false,
    message: undefined as undefined | string,
    success: true
  }),
  computed: {
    mediaCount() {
      return this.mediaGroups.reduce((prev, cur) => prev + cur.mediaGeometry.coordinates.length, 0)
    }
  },
  methods: {
    async runImport() {
      this.importing = true
      this.firstRun = false
      this.success = false

      const mediaGroupStore = useMediaGroupStore()

      try {
        this.error = false
        if (!this.geoJson || !this.mediaGroups.length) {
          throw 'Ungültige Parameter aus den vorherigen Schritten übergeben.'
        }

        this.message = 'Mediengruppen importieren...'

        await HsbApi.mediaGroups.replaceAllMediaGroups(
          this.projectId,
          this.mediaGroups as MediaGroupRequest[]
        )

        this.message = 'Referenzsystem setzen...'
        const projectStore = useProjectStore()
        const project = projectStore.findById(this.projectId)
        if (!project) {
          throw 'Projekt nicht gefunden. Konnte CRS nicht aktualisieren.'
        }
        project.crs = unifyProjectionCode((this.geoJson as any).crs?.properties?.name)
        if (project.crs) {
          await projectStore.save(project)
        }

        this.success = true
      } catch (e: any) {
        this.error = e.toString()
      } finally {
        this.importing = false
        await mediaGroupStore.load(this.projectId)
      }
    }
  }
})
</script>

<template>
  <div class="flex flex-col min-h-[20rem] justify-center items-center max-w-[60ch] mx-auto">
    <transition mode="out-in" name="fade">
      <div v-if="firstRun || importing" class="flex flex-col space-y-4 items-center text-gray-500">
        <p class="text-center">
          Beim Import
          {{
            mediaGroups.length === 1
              ? ' wird eine Mediengruppe'
              : ` werden ${mediaGroups.length} Mediengruppen`
          }}
          mit insgesamt {{ mediaCount === 1 ? 'einem Medium' : `${mediaCount} Medien` }}
          neu angelegt.
          <br />
          <b class="text-warning-500">Alle bestehenden Mediengruppen werden gelöscht.</b>
        </p>

        <p class="pb-4">Sind Sie sicher, dass Sie den Import durchführen wollen?</p>
        <p-btn :loading="importing" type="warning" @click="runImport">
          {{ message || 'Ja, Import starten' }}
        </p-btn>
      </div>
      <div v-else-if="error" class="bg-error-200 text-error-700 p-4 rounded w-full">
        <b>Fehler:</b>
        {{ error }}
      </div>
      <div v-else-if="success" class="flex flex-col items-center">
        <p-success-icon class="text-success-500 w-20 mb-4" />
        <p class="text-success-600 text-lg">Der Import war erfolgreich.</p>
      </div>
    </transition>
  </div>
</template>

<style lang="css" scoped></style>
