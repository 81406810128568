import { StudyCaseConfig } from '@/config/schemas/study-case'
import { copy } from '@/util'
import { StudyCase } from '@gridside/hsb-api'
import deepmerge from 'deepmerge'
import { v4 } from 'uuid'

export type StudyCaseId = StudyCase['id']

export enum CalculationModes {
  MANUAL = 'manual',
  AUTO_FAIL = 'auto_fail',
  AUTO_AWE = 'auto_awe'
}
export const CalculationModesTranslation: { [key in CalculationModes]: string } = {
  [CalculationModes.MANUAL]: 'manuell',
  [CalculationModes.AUTO_FAIL]: 'automatisch (Fehlerfall)',
  [CalculationModes.AUTO_AWE]: 'automatisch (AWE-Fall)'
}

export enum SuperpositionPreset {
  ABSOLUTE = 'ABSOLUTE',
  COMPLEX = 'COMPLEX',
  SQUARE = 'SQUARE',
  MAXIMUM = 'MAXIMUM',
  CUSTOM = 'CUSTOM'
}

export type StudyCaseWithConfig = StudyCase & {
  configuration: StudyCaseConfig
}

export function createDefaultStudyCaseConfig(): StudyCaseConfig {
  return {
    modeSelected: CalculationModes.MANUAL,
    autoAWE: [],
    autoFail: [],
    manual: [],
    superpositionPreset: SuperpositionPreset.COMPLEX
  }
}

export function createDefaultStudyCase(): StudyCaseWithConfig {
  return {
    id: v4(),
    project: '',
    name: '',
    description: '',
    configuration: createDefaultStudyCaseConfig(),
    limitVoltage: 60,
    expectationFactor: 1,
    superposition: '',
    operationStates: []
  }
}

/**
 * Merge initial values with default values
 * @param item
 */
export function getValidStudyCaseWithConfig(item?: StudyCase): StudyCaseWithConfig {
  let studyCase = createDefaultStudyCase()
  if (item) {
    studyCase = deepmerge(studyCase, item, {
      arrayMerge: (_, source) => {
        return copy(source)
      }
    }) as StudyCaseWithConfig
  }

  // if there are operation states but not in the configuration, update config
  // (this might occur after importing overheadlines)
  const cfg = studyCase.configuration
  if (
    cfg.modeSelected === CalculationModes.MANUAL &&
    cfg.manual.length === 0 &&
    studyCase.operationStates.length > 0
  ) {
    studyCase.configuration.manual = [
      ...studyCase.operationStates.map((item) => ({ ...item, mirrored: false }))
    ]
  }
  return studyCase
}
