<script lang="ts">
import { defineComponent, toRefs, watch } from 'vue'
import { useSystemStore } from '@/stores/system'
import { ConductorAllocationMapping, SystemId } from '@/model'
import { conductorAllocation } from '@/config/fields'
import { FieldEntry, useFieldArray } from 'vee-validate'
import { conductorLabelFn, towerConductorsLabelFn } from '@/util'
import { useProject } from '@/composables/useProject'
import { SelectOption } from '@util/element-plus'

export default defineComponent({
  name: 'ConductorAllocationFormMapping',
  data: () => {
    return {
      fieldConfig: conductorAllocation
    }
  },
  props: {
    positionCount: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const { positionCount } = toRefs(props)
    const { projectId } = useProject()
    const systemStore = useSystemStore()
    const mappingArray = useFieldArray<ConductorAllocationMapping>('mapping')

    /**
     * Sync fields amount with positionCount
     */
    watch(positionCount, (value, oldValue) => {
      if (value > oldValue) {
        while (mappingArray.fields.value.length != value) {
          mappingArray.push({ system: null, index: null })
        }
      }

      if (value < oldValue) {
        while (mappingArray.fields.value.length != value) {
          mappingArray.remove(mappingArray.fields.value.length - 1)
        }
      }
    })

    return { systemStore, mappingArray, projectId }
  },
  computed: {
    systemItems(): SelectOption<string>[] {
      return this.systemStore.items.map((system) => ({
        value: system.id,
        label: system.name
      }))
    },
    /**
     * Returns a map of already used system conductors for the given mapping.
     */
    usedWires(): Record<SystemId, Record<number, boolean>> {
      const used: Record<SystemId, Record<number, boolean>> = {}
      this.mappingArray.fields.value.forEach((field) => {
        const { system, index } = field.value
        if (!system) {
          return
        }
        if (!used[system]) {
          used[system] = {}
        }
        if (index !== null && index !== undefined) {
          used[system][index] = true
        }
      })
      return used
    }
  },
  mounted() {
    this.systemStore.ensureLoaded(this.projectId)
  },
  methods: {
    towerConductorsLabelFn,

    /**
     * Gets the list of available conductors for a given system
     */
    getSystemConductorItems(field: FieldEntry<ConductorAllocationMapping>): SelectOption<number>[] {
      const items: SelectOption<number>[] = []
      const systemId = field.value.system
      if (!systemId) {
        return items
      }

      const system = this.systemStore.findById(systemId)
      if (!system) {
        return items
      }
      const usedWires = this.usedWires
      for (let i = 0; i < system.wireCount; i++) {
        items.push({
          value: i,
          label: conductorLabelFn(i),
          disabled: usedWires[systemId] && usedWires[systemId][i]
        })
      }

      return items
    }
  }
})
</script>

<template>
  <div
    v-if="systemStore.loaded"
    class="grid gap-x-4 gap-y-4 mb-8"
    style="grid-template-columns: 4em 2fr 1fr"
  >
    <label class="el-form-item__label !justify-start">Position</label>
    <label class="el-form-item__label !justify-start">System *</label>
    <label class="el-form-item__label !justify-start">Leiter *</label>
    <template v-for="(field, position) in mappingArray.fields.value" :key="position">
      <!-- Position -->
      <div class="text-center pt-2 font-semibold text-gray-500">
        {{ towerConductorsLabelFn(position) }}
      </div>

      <!-- System - because VeeValidate uses $nextTick itself, we need use $nextTick to set system/index back to null-->
      <p-field
        v-bind="fieldConfig.system"
        :name="`mapping.${position}.system`"
        :items="systemItems"
        dense
        clearable
        @clear="$nextTick(() => (field.value = { system: null, index: null }))"
        @change="$nextTick(() => (field.value.index = null))"
      />

      <!-- Conductor -->
      <div>
        <!--
          Only render if system is set, to properly setup "required".
          Use "keep-value" because we don't want field to be removed from form when
          another ConductorAllocation is selected and Field-dismount delete gets triggered.
          -->
        <p-field
          v-if="field.value.system"
          v-bind="fieldConfig.conductor"
          required
          keep-value
          :name="`mapping.${position}.index`"
          :items="getSystemConductorItems(field)"
          dense
        />
      </div>
    </template>
  </div>
</template>

<style scoped lang="css"></style>
