import { FieldConfig } from '@prionect/ui/dist/types/components/form/types'
import { OverheadLine } from '@gridside/hsb-api'

const fields: Record<keyof Pick<OverheadLine, 'name' | 'description'>, FieldConfig> = {
  name: {
    name: 'name',
    label: 'Name',
    required: true
  },
  description: {
    name: 'description',
    label: 'Beschreibung',
    type: 'textarea'
  }
} as const
export default fields
