<script setup lang="ts">
import { useOverheadLineStore } from '@/stores/overhead-lines'
import { computed, PropType } from 'vue'
import { Feature } from 'ol'
import { formatNumber } from '@/util/helpers'

const props = defineProps({
  feature: {
    type: Object as PropType<Feature>,
    required: true
  }
})
const store = useOverheadLineStore()

const overheadLine = computed(() => store.findById(props.feature?.getId() as string)?.overheadLine)
</script>

<template>
  <div v-if="overheadLine">
    <h3 class="">{{ overheadLine.name }}</h3>
    <table>
      <tbody>
        <tr>
          <th>Masten</th>
          <td>{{ formatNumber(overheadLine.towerCount) }}</td>
        </tr>
        <tr>
          <th>Länge</th>
          <td>{{ $n(overheadLine.length || 0, { maximumFractionDigits: 1 }) }} km</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
