import { RouteRecordRaw } from 'vue-router'
import { prependColon, RouteParams } from '@/router/routeParams'

const overheadLineRoutes: RouteRecordRaw = {
  path: 'overhead-lines',
  component: () => import('@/layouts/pages/SplitView.vue'),
  meta: {
    title: 'Freileitungen'
  },
  children: [
    {
      path: '',
      name: 'overhead-lines-index',
      component: () => import('@/views/project/overhead-lines/OverheadLineListView.vue')
    },
    {
      path: 'import-geo-json',
      name: 'overhead-line-import-geo-json',
      meta: {
        title: 'Freileitung importieren'
      },
      components: {
        default: () => import('@/views/project/overhead-lines/OverheadLineListView.vue'),
        overlay: () => import('@/views/project/overhead-lines/OverheadLineImportGeoJson.vue')
      }
    },
    {
      path: 'import-hsb',
      name: 'overhead-line-import-hsb',
      meta: {
        title: 'Freileitung importieren'
      },
      components: {
        default: () => import('@/views/project/overhead-lines/OverheadLineListView.vue'),
        overlay: () => import('@/views/project/overhead-lines/OverheadLineImportHSB.vue')
      }
    },
    {
      path: prependColon(RouteParams.OverheadLineId),
      name: 'overhead-line-base',
      children: [
        {
          path: 'edit',
          name: 'overhead-line-edit',
          components: {
            default: () => import('@/views/project/overhead-lines/OverheadLineListView.vue'),
            detail: () => import('@/views/project/overhead-lines/OverheadLineForm.vue')
          }
        },
        {
          path: '',
          name: 'overhead-line',
          components: {
            default: () => import('@/views/project/overhead-lines/OverheadLineView.vue')
          }
        },
        // Towers
        {
          path: 'towers',
          meta: {
            title: 'Masten'
          },
          children: [
            {
              path: '',
              name: 'overhead-line-towers',
              components: {
                default: () => import('@/views/project/overhead-lines/OverheadLineView.vue')
              }
            },
            {
              path: 'create',
              name: 'overhead-line-tower-create',
              meta: { create: true },
              components: {
                default: () => import('@/views/project/overhead-lines/OverheadLineView.vue'),
                detail: () => import('@/views/project/towers/TowerDetail.vue')
              }
            },
            {
              path: ':towerId',
              name: 'overhead-line-tower-edit',
              components: {
                default: () => import('@/views/project/overhead-lines/OverheadLineView.vue'),
                detail: () => import('@/views/project/towers/TowerDetail.vue')
              }
            }
          ]
        },
        // Spans
        {
          path: 'spans',
          meta: {
            title: 'Spannfelder'
          },
          children: [
            {
              path: '',
              name: 'overhead-line-spans',
              components: {
                default: () => import('@/views/project/overhead-lines/OverheadLineView.vue')
              }
            },
            {
              path: ':spanId',
              name: 'overhead-line-span-edit',
              components: {
                default: () => import('@/views/project/overhead-lines/OverheadLineView.vue'),
                detail: () => import('@/views/project/spans/SpanDetail.vue')
              }
            }
          ]
        }
      ]
    }
  ]
}

export default overheadLineRoutes
