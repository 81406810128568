import z from 'zod'
import { CorridorModeEnum } from '@/model'
import { HsbLibParameters, LogLevel } from '@gridside/hsb-api'

export const HsbLibParameterSchema: z.ZodType<HsbLibParameters> = z.object({
  corridorFlatend: z.boolean().nullable(),
  corridorFlatstart: z.boolean().nullable(),
  corridorMode: z.nativeEnum(CorridorModeEnum).nullable(), // in spec as string, but we want to have it as enum parsed
  displayPrint: z.boolean().nullable(),
  loggingTraceback: z.boolean().nullable(),
  logLevel: z.nativeEnum(LogLevel).nullable(),
  flagInnerImpedanceBesselFunction: z.boolean().nullable(),
  flagDebugging: z.boolean().nullable(),
  pipelineSegmentation: z.number().nullable(),
  relAbstol: z.number().nullable(),
  relAngletol: z.number().nullable(),
  relMaxAngle: z.number().nullable(),
  relMinSegmentLength: z.number().nullable(),
  relSimplifyMedium: z.boolean().nullable(),
  trimTillDecimalPoints: z.number().nullable(),
  utmReimportTolerance: z.number().nullable(),
  utmTrimMode: z.boolean().nullable()
})
