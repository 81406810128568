import { z } from 'zod'
import {
  GeometryElementType,
  LineStringCoordinates,
  MediaGroupBaseType,
  MediaGroupResponse,
  WireMediaGroupRequestType
} from '@gridside/hsb-api'

const LineStringsSchema: z.ZodType<LineStringCoordinates> = z.array(z.number().array())
const MediaGroupBase = z.object({
  height: z.number(),
  mediaGeometry: z.object({
    type: z.literal(GeometryElementType.MULTI_LINE_STRING),
    coordinates: z.array(LineStringsSchema)
  }),
  name: z.string(),
  type: z.nativeEnum(MediaGroupBaseType),
  project: z.string()
})

const MediaGroupPipe = MediaGroupBase.extend({
  type: z.literal(WireMediaGroupRequestType.PIPE),
  pipeType: z.string(),
  rhoE: z.number()
})

const MediaGroupWire = MediaGroupBase.extend({
  type: z.literal(WireMediaGroupRequestType.WIRE)
})

export const MediaGroupSchema: z.ZodType<MediaGroupResponse> = z.union([
  MediaGroupPipe.extend({ id: z.string() }),
  MediaGroupWire.extend({ id: z.string() })
])
