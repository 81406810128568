import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import useMapLayers from '@/components/map/composables/useMapLayers'
import { MapMode, useMapMode } from '@/components/map/composables/useMapMode'
import { useMapPosition } from '@/components/map/composables/useMapPosition'
import { useMapSelection } from '@/components/map/composables/useMapSelection'
import { usePersistentMapState } from '@/components/map/composables/usePersistentMapState'
import { useProject } from '@/composables/useProject'
import { Map, View } from 'ol'
import { Attribution, ScaleLine } from 'ol/control'
import { ProjectionLike } from 'ol/proj'
import {
  computed,
  onBeforeUnmount,
  onMounted,
  provide,
  Ref,
  ref,
  shallowRef,
  triggerRef,
  watch
} from 'vue'
import { useMapState } from './useMapState'

export type UseMapOptions = {
  /**
   * Ref to the HTML target element where to render the map to
   */
  target: Ref<HTMLElement>

  /**
   * Coordinate Reference System / Projection of the actual features
   *
   * defaults to `EPGS:3857`
   */
  featureCrs: ProjectionLike
}

export type UseMapItems = ReturnType<typeof useMap>

/**
 * useMap() is a composable to use an OpenLayers Map inside a Vue Component (usually BasicMap or HsbMap)
 *
 * Note that useMap() generates and returns a *new* map instance. If you want to access the current
 * map or the items returned by useMap(), use `inject(UseMapInjectKeys.map)` or `inject(UseMapInjectKeys.useMap) inside the
 * map's components (i.e. layers and control panels)
 *
 * @param options
 */
export const useMap = (options: UseMapOptions) => {
  const { target, featureCrs = 'EPSG:3857' } = options
  if (!target) {
    throw new Error('useMap: missing required "target" option')
  }

  const map = shallowRef<Map>(
    new Map({ target: undefined, controls: [new Attribution(), new ScaleLine()] })
  )

  const view = computed(() => map.value?.getView())
  const fullscreen = ref(false)

  const { projectId } = useProject()

  // Add composable for map zoom and positioning
  const { center, reset, setPosition, zoom, zoomIn, zoomOut, zoomToFit } = useMapPosition({
    map: map.value,
    featureCrs
  })

  // Add composable to build a reactive map state object
  const { mapState } = useMapState({ zoom, center, projectId: projectId.value })
  usePersistentMapState({ map, mapState, projectId: projectId.value, setPosition, reset })

  // Add composable to manage selection state
  const { selectedFeatures, clear: clearSelection } = useMapSelection()

  // Add reactive layer state
  const layerItems = useMapLayers({ map, mapState })

  onMounted(() => {
    map.value.setTarget(target.value)
  })

  onBeforeUnmount(() => {
    map.value.setTarget(undefined)
    clearSelection()
  })

  // Create a new view when target changes (usually during mount)
  watch(target, createView, { immediate: true })
  function createView() {
    if (target.value) {
      map.value.setView(
        new View({
          center: center.value,
          zoom: zoom.value,
          constrainResolution: true
        })
      )
      triggerRef(map)
    }
  }

  map.value.on('change:target', () => triggerRef(map))
  map.value.on('change:view', () => triggerRef(map))

  // Deselect all when leaving edit map mode
  const mapMode = useMapMode()
  watch(mapMode, () => {
    if (mapMode.value !== MapMode.EDIT) {
      clearSelection()
    }
  })

  // Use Vue provide/inject dependency injection to access map components
  const mapItems = {
    map,
    view,
    center,
    fullscreen,
    ...layerItems,
    mapState,
    selectedFeatures,
    zoom,
    zoomIn,
    zoomOut,
    zoomToFit
  }
  provide(UseMapInjectKeys.map, map.value)
  provide(UseMapInjectKeys.useMap, mapItems)
  provide(UseMapInjectKeys.selectedFeatures, selectedFeatures)

  return mapItems
}
