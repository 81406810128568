import { useHttpClient } from '@/api/useHttpClient'
import { useWebSocket } from '@/api/useWebSocket'
import { getEnv } from '@/util'
import { Configuration, PaginatedResult as ApiPaginatedResult } from '@gridside/hsb-api'

import { EpsgApiClass } from './external/EpsgApiClass'
import { AuthMiddleware, HsbApiBase } from './generated'

export * from './useWebSocket'

const httpClient = useHttpClient()
const webSocket = useWebSocket()

/**
 * Provide configured generated Apis from @gridside/hsb-api
 */
export const HsbApi = new HsbApiBase(
  new Configuration({
    basePath: getEnv('VITE_REST_BASE_URL', '/'),
    middleware: [new AuthMiddleware()]
  }),
  webSocket
)

// External APIs
export const EpsgApi = new EpsgApiClass(httpClient, webSocket)

export type PaginatedResult<T> = Omit<ApiPaginatedResult, 'results'> & { results: T[] }
