import { WebSocket } from '@/api'
import { User, UserId } from '@/model'
import { Configuration, UsersApi } from '@gridside/hsb-api'

/**
 * API class that extends the generated REST API with websocket functions
 */
export class UsersExtendedApi extends UsersApi {
  constructor(configuration: Configuration, public webSocket: WebSocket) {
    super(configuration)
  }

  onUserDeleted(handler: (id: UserId) => void) {
    this.webSocket.subscribe('User.deleted', (data) => {
      handler(data.id)
    })
  }

  onUserUpdated(handler: (user: User) => void) {
    this.webSocket.subscribe('User.updated', handler)
  }
}
