import { OverheadLineId } from '@/model'

export enum OverheadLineSide {
  LEFT = 'left',
  RIGHT = 'right'
}

export type OffsetFeatureProperties = {
  index: number
  overheadLine: OverheadLineId
  part: 'in' | 'out'
  side: OverheadLineSide
  span: number
}

/**
 * Convert pixels to geographical distance, depending on the current resolution
 */
export function pixelsToMeters(pxDistance: number, resolution: number) {
  return pxDistance * resolution
}
