import { LayerId } from '@/components/map'
import { UseMapItems } from '@/components/map/composables/useMap'
import { MapMode, useMapMode } from '@/components/map/composables/useMapMode'
import { ContextMenuItem } from '@/components/map/ContextMenu.vue'
import { createOverheadLineDraft } from '@/components/map/layer/source/overhead-line/createOverheadLine'
import { OverheadLineId } from '@/model/overhead-line'
import { useOverheadLineStore } from '@/stores/overhead-lines'
import { OverheadLine } from '@gridside/hsb-api'
import { inject, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { TowerId } from '@/model'
import useContextMenu, { CollectorFunction } from '@/components/map/composables/useContextMenu'
import { SourceLayerContext } from '../util'

import { createTowerDraft, deleteTowers } from './functions'

import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import { featureIsDraft } from '@/components/map/LayerItemConfig'

export function useSourceContextMenu(ctx: SourceLayerContext) {
  const { registerCollector, unregisterCollector } = useContextMenu()
  const { showLayer } = inject(UseMapInjectKeys.useMap) as UseMapItems

  const router = useRouter()
  const mapMode = useMapMode()

  const collectorFunction: CollectorFunction = (coordinate) => {
    if (mapMode.value !== MapMode.EDIT) {
      return []
    }

    const { selectedTowers, selectedOverheadLines } = ctx
    const overheadLineCount = selectedOverheadLines.value.length
    const towerCount = selectedTowers.value.length

    const xyCoord = { x: coordinate[0], y: coordinate[1] }
    const items: ContextMenuItem[] = [
      {
        id: 'overheadLineCreate',
        label: 'Neue Freileitung... ',
        priority: 100,
        icon: 'OverheadLineAdd',
        handler: async () => {
          showLayer(LayerId.SOURCE)
          await createOverheadLineDraft(xyCoord, ctx)
          router.push({ name: 'project-map-overheadLine-create' })
        }
      }
    ]

    if (towerCount > 0) {
      items.push({
        id: 'towerDelete',
        label: towerCount === 1 ? 'Mast löschen' : `${towerCount} Masten löschen`,
        icon: 'TowerDelete',
        priority: 201,
        handler: async () => {
          const towerIds = selectedTowers.value
            .filter((feature) => !featureIsDraft(feature))
            .map((feature) => feature.getId() as TowerId)
          await deleteTowers(towerIds)
        }
      })
    }

    if (overheadLineCount > 0) {
      items.push({
        id: 'overheadLineDelete',
        label:
          overheadLineCount === 1
            ? 'Freileitung löschen...'
            : `${overheadLineCount} Freileitungen löschen...`,
        priority: 190,
        icon: 'OverheadLineDelete',
        handler: async () => {
          const store = useOverheadLineStore()
          // Setting `itemsToDelete` should trigger a confirm dialog in the SourceLayer component
          store.itemsToDelete = selectedOverheadLines.value
            .filter((feature) => !featureIsDraft(feature))
            .map((feature) => store.findById(feature.getId() as OverheadLineId)?.overheadLine)
            .filter((item) => item !== undefined) as OverheadLine[]
        }
      })
    }

    if (overheadLineCount === 1) {
      items.push({
        id: 'towerCreate',
        label: 'Neuer Mast... ',
        handler: () => {
          showLayer(LayerId.SOURCE)
          createTowerDraft(xyCoord, ctx)
        },
        priority: 200,
        icon: 'TowerAdd'
      })
    }

    return items
  }

  onMounted(() => registerCollector(collectorFunction))

  onUnmounted(() => unregisterCollector(collectorFunction))
}
