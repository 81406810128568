<script lang="ts">
import { ProjectExportData, ProjectExportResponse } from '@gridside/hsb-api'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ImportPreview',
  props: {
    data: {
      type: Object as PropType<ProjectExportResponse>,
      required: true
    }
  },

  methods: {
    renderCount(type: keyof ProjectExportData, singular: string, plural: string) {
      const data = this.data.data || {}
      const count = data[type]?.length || 0
      return `${count} ${count === 1 ? singular : plural}`
    }
  }
})
</script>

<template>
  <div class="grid grid-cols-2 gap-8" style="grid-template-columns: auto auto">
    <div class="col-span-2 font-semibold">Import-Vorschau:</div>
    <div class="grid grid-cols-2 gap-y-2 h-min">
      <label>Projektname:</label>
      <span class="font-bold">{{ data.name }}</span>
      <label>Kunde:</label>
      <span>{{ data.customer || '-' }}</span>
      <label>Medientyp:</label>
      <span>{{ data.mediaType === 'pipe' ? 'Rohrleitung' : 'Telekommunikationsleitung' }}</span>
      <label>Koordinaten-Referenzsystem:</label>
      <span>{{ data.crs }}</span>
    </div>
    <div class="text-gray-500">
      <p>{{ renderCount('overheadLine', 'Freileitung', 'Freileitungen') }}</p>
      <p>{{ renderCount('system', 'System', 'Systeme') }}</p>
      <p>{{ renderCount('conductorAllocation', 'Leiterzuordnung', 'Leiterzuordnungen') }}</p>
      <p class="mt-2">{{ renderCount('mediaGroup', 'Mediengruppe', 'Mediengruppen') }}</p>
      <p class="mt-2">{{ renderCount('studyCases', 'Berechnungsfall', 'Berechnungsfälle') }}</p>
      <p class="mt-2">{{ renderCount('conductorType', 'Leitertyp', 'Leitertypen') }}</p>
      <p>{{ renderCount('pipeType', 'Rohrleitungstyp', 'Rohrleitungstypen') }}</p>
      <p>{{ renderCount('towerType', 'Masttyp', 'Masttypen') }}</p>
    </div>
  </div>
</template>

<style scoped lang="css">
label {
  @apply text-gray-500;
}
</style>
