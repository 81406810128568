<script lang="ts">
import basemapImage from '@/assets/map/basemap-thumbnail.jpg'
import osmImage from '@/assets/map/osm-thumbnail.jpg'
import satelliteImage from '@/assets/map/satellite-thumbnail.jpg'
import { UseMapItems } from '@/components/map/composables/useMap'
import { useMapControlState } from '@/components/map/composables/useMapControlState'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import MapSelector from '@/components/map/controls/map/MapSelector.vue'
import MapControlHeading from '@/components/map/controls/MapControlHeading.vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'BaseMapControl',
  components: { MapSelector, PopoverPanel, PopoverButton, Popover, MapControlHeading },

  setup() {
    const { mapState } = inject(UseMapInjectKeys.useMap) as UseMapItems

    const { expanded } = useMapControlState('basemap')
    return { expanded, mapState }
  }
})
</script>

<template>
  <Popover class="flex flex-col-reverse items-end z-50">
    <PopoverButton
      class="background"
      @click="expanded = !expanded"
      @keydown.enter="expanded = !expanded"
    >
      <div class="w-40 h-9 background" :class="{ 'trigger--expanded': expanded }">
        <MapControlHeading class="px-3" :expanded="!expanded">
          <MapSelector :size="24" />
        </MapControlHeading>
      </div>
    </PopoverButton>
    <transition
      enter-active-class="transition duration-150 origin-bottom-right"
      enter-from-class="scale-y-0 scale-x-0 opacity-0"
      enter-to-class="scale-y-100 scale-x-100 opacity-100"
      leave-active-class="transition duration-150 origin-bottom-right"
      leave-from-class="scale-y-100 scale-x-100 "
      leave-to-class="scale-y-0 scale-x-0 opacity-0 "
    >
      <PopoverPanel
        v-show="expanded"
        class="panel background"
        :class="{ 'panel--expanded': expanded }"
        static
      >
        <MapControlHeading :expanded="!expanded" @click="expanded = false">
          Basiskarte
        </MapControlHeading>
        <MapSelector />
        <el-slider
          size="small"
          aria-label="Transparenz"
          title="Kartentransparenz"
          :min="0"
          :max="100"
          :step="10"
          :modelValue="mapState.basemap.opacity * 100"
          @update:modelValue="mapState.basemap.opacity = Number($event) / 100"
          :show-tooltip="false"
        />
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<style scoped lang="css">
.background {
  @apply bg-gray-50 bg-opacity-80 backdrop-blur-lg rounded-md shadow-lg;
}

.trigger--expanded {
  @apply rounded-t-none bg-opacity-60;
}

.panel {
  @apply absolute w-[20em] pt-2 pb-8 px-4 bg-gray-50 bg-opacity-90 bottom-0 space-y-3;
}
</style>
