<script lang="ts">
import { defineComponent } from 'vue'
import EntityTableView from '@/components/common/EntityTableView.vue'
import { useConductorAllocationStore } from '@/stores/conductor-allocation'
import { ProjectId } from '@/model'
import { ConductorAllocation } from '@gridside/hsb-api'
import { useDuplicate } from '@/composables/crud-helpers/useDuplicate'
import { useDelete } from '@/composables/crud-helpers/useDelete'
import { useRoute } from 'vue-router'
import { RouteParams } from '@/router/routeParams'

export default defineComponent({
  name: 'ConductorAllocationListView',
  components: { EntityTableView },
  setup() {
    const route = useRoute()
    const conductorAllocationStore = useConductorAllocationStore()

    const { duplicateItems } = useDuplicate(conductorAllocationStore.save)
    const { deleteItems } = useDelete(conductorAllocationStore.delete, {
      check: (items: ConductorAllocation[]) => items.some((item) => item.id === route.params.id),
      routeName: 'project-conductor-allocations'
    })

    return { conductorAllocationStore, duplicateItems, deleteItems }
  },

  computed: {
    projectId(): ProjectId {
      return this.$route.params[RouteParams.ProjectId] as string
    }
  },

  mounted() {
    this.conductorAllocationStore.load(this.projectId)
  },

  methods: {
    asConductorAllocation(item: any): ConductorAllocation {
      return item as ConductorAllocation
    }
  }
})
</script>

<template>
  <EntityTableView
    v-model:selection="conductorAllocationStore.selection"
    :allow-edit="false"
    allow-duplicate
    create-label="Neue Leiterzuordnung"
    default-sort="name"
    :items="conductorAllocationStore.items"
    :loading="conductorAllocationStore.loading"
    :search-properties="['name']"
    title="Leiterzuordnungen"
    @create="$router.push({ name: 'project-conductor-allocation-create' })"
    @edit="$router.push({ name: 'project-conductor-allocation-edit', params: { id: $event } })"
    @delete-items="deleteItems"
    @duplicate-items="duplicateItems"
  >
    <template #columns>
      <el-table-column prop="name" label="Name" sortable class-name="font-semibold" />
      <el-table-column prop="wireCount" label="Leiteranzahl" sortable>
        <template #default="{ row }">
          {{ Object.keys(row.mapping).length }}
        </template>
      </el-table-column>
    </template>

    <template #empty>
      <p class="text-gray-500 text-xl">Sie haben bisher keine Leiterzuordnungen angelegt.</p>

      <p class="mt-8 mb-12 font-normal text-gray-400 text-lg leading-relaxed">
        Mit Leiterzuordnungen legen Sie fest, wie Systeme am Mast allokiert werden.
        <br />
        Sie können eine Leiterzuordnung einem Mast nur zuweisen, wenn dieser über die gleiche Anzahl
        Leiterpositionen verfügt wie die Zuordnung.
      </p>

      <p-btn @click="$router.push({ name: 'project-conductor-allocation-create' })">
        Neue Leiterzuordnung anlegen
      </p-btn>
    </template>

    <template #confirm-delete="{ items }">
      <p v-if="items.length === 1">
        Wollen Sie die Leiterzuordnung
        <b>{{ asConductorAllocation(items[0]).name }}</b>
        wirklich löschen?
      </p>
      <p v-else>
        Wollen Sie diese Leiterzuordnungen wirklich löschen?
        <ul>
          <li v-for="item in items" :key="item.id"><b>{{ asConductorAllocation(item).name }}</b></li>
        </ul>
      </p>
      <p class="!mt-2 text-gray-400 text-base">
        Wenn die Leiterzuordnung noch an Masten verwendet wird, ist ein Löschen nicht möglich.
      </p>
    </template>
  </EntityTableView>
</template>
