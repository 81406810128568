<script lang="ts" setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import MediaGroupImportWizard from '@/components/media-group/import-wizard/MediaGroupImportWizard.vue'

const show = ref(true)
const router = useRouter()

const onClose = () => {
  show.value = false
  setTimeout(() => {
    router.push({ name: 'project-media-group' })
  }, 200)
}
</script>

<template>
  <MediaGroupImportWizard v-bind="$attrs" v-model:show="show" @close="onClose" />
</template>
