<script lang="ts">
import { defineComponent, PropType, Ref } from 'vue'
import SystemTag from '@/components/system/SystemTag.vue'

export default defineComponent({
  name: 'SystemTagRefWrap',
  components: { SystemTag },
  props: {
    systemRef: {
      type: Object as PropType<Ref<string>>,
      required: true
    }
  }
})
</script>

<template>
  <SystemTag :system="systemRef.value" type="large" />
</template>
