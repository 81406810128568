import { useEventListener } from '@vueuse/core'
import { nextTick } from 'vue'
import { v4 } from 'uuid'
import { Collection, Feature } from 'ol'
import { LineString, Point } from 'ol/geom'
import { Draw, Snap } from 'ol/interaction'
import { DrawEvent } from 'ol/interaction/Draw'
import { Coordinate } from 'ol/coordinate'
import { mediaGroupSelectStyle } from '@/components/map/style/media-group'
import { useMapSelection } from '@/components/map/composables/useMapSelection'
import { useMediaGroupStore } from '@/stores/media-group'
import { MediaLayerContext } from '../util'
import { featureIsDraft } from '@/components/map/LayerItemConfig'

type XYCoordinates = { x: number; y: number }

const { deselect, clear } = useMapSelection()
let snapInteraction: Snap

export function createMediaGroupDraft(coordinates: XYCoordinates, ctx: MediaLayerContext) {
  const mediaGroupStore = useMediaGroupStore()
  mediaGroupStore.draft = null
  mediaGroupStore.drawing = true

  deleteDraftFeatures(ctx)

  const mapCoordinates = ctx.projectToMap([coordinates.x, coordinates.y])
  const id = v4()
  const features = new Collection<Feature>([new Feature(new Point(mapCoordinates))])
  const draw = new Draw({ type: 'LineString', features, style: mediaGroupSelectStyle as any })
  const map = ctx.map

  clear()

  map.addInteraction(draw)
  draw.appendCoordinates([mapCoordinates])
  draw.setActive(true)
  ctx.drawInteraction = draw

  snapInteraction = new Snap({ source: ctx.layer.getSource() || undefined })
  map.addInteraction(snapInteraction)

  // Cancel on Esc
  useEventListener(
    document,
    'keydown',
    (event) => {
      if (event.key === 'Escape') {
        draw.abortDrawing()
      }
    },
    { once: true }
  )

  // Handle drawend
  return new Promise((resolve) => {
    draw.once('drawend', (e: DrawEvent) => {
      nextTick(() => {
        mediaGroupStore.drawing = false
        ctx.drawInteraction = undefined
        draw.setActive(false)
        map.removeInteraction(draw)
        map.removeInteraction(snapInteraction)

        resolve(true)
      })

      let initialCoordinates: Coordinate[] = []
      const geom = e.feature.getGeometry()
      if (geom instanceof LineString) {
        initialCoordinates = geom
          .getCoordinates()
          .map((coordinates) => ctx.mapToProject(coordinates))
      }

      mediaGroupStore.draft = {
        name: 'Neue Mediengruppe',
        id,
        mediaGeometry: { type: 'MultiLineString', coordinates: [initialCoordinates] }
      }
      mediaGroupStore.selection = [id]
    })
  })
}

export function deleteDraftFeatures(ctx: MediaLayerContext) {
  const source = ctx.layer.getSource()
  if (source) {
    source
      .getFeatures()
      .filter(featureIsDraft)
      .forEach((feature: Feature) => {
        deselect(feature)
        source.removeFeature(feature)
      })
  }

  const mediaGroupStore = useMediaGroupStore()
  mediaGroupStore.draft = null

  //ctx.drawInteraction = undefined
  if (snapInteraction) {
    ctx.map.removeInteraction(snapInteraction)
  }
}

export async function deleteMediaGroups() {
  const mediaGroupStore = useMediaGroupStore()
  mediaGroupStore.itemsToDelete.forEach((item) => {
    mediaGroupStore.delete(item.id)
    deselect(item.id)
  })
  mediaGroupStore.itemsToDelete = []
}
