<script lang="ts">
import { defineComponent, PropType } from 'vue'

/**
 * NumberField is a wrapper around <el-input-field> to display and enter locale number formats
 */
export default defineComponent({
  name: 'NumberField',
  emits: ['blur', 'change', 'update:modelValue'],

  props: {
    max: Number,
    min: Number,
    modelValue: {
      type: Number as PropType<number | null>
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    step: Number,
    unit: {
      type: String
    }
  },

  data: () => ({
    rawValue: null as number | null,
    displayPrefix: '',
    displaySuffix: ''
  }),

  computed: {
    /**
     * The decimal separator for the current locale
     */
    decimalSeparator(): string {
      const n = 1.1
      return this.$n(n).substring(1, 2)
    },

    /**
     * Convert the raw number value into localized format
     */
    displayValue(): string {
      let displayValue = ''
      if (this.rawValue !== null) {
        displayValue = this.$n(this.rawValue, {
          // don't limit to the default 3 fraction digits
          maximumFractionDigits: 20
        })
      }
      return this.displayPrefix + displayValue + this.displaySuffix
    },

    /**
     * The thousands group separator for the current locale
     */
    groupSeparator(): string {
      const n = 1234
      const separator = this.$n(n).substring(1, 2)
      return separator !== '2' ? separator : ''
    },

    inputEl(): HTMLInputElement | undefined {
      return (this.$refs.input as any)?.$el?.querySelector('input')
    }
  },

  watch: {
    modelValue(v) {
      this.rawValue = v || v === 0 ? v : null
    }
  },

  mounted() {
    this.rawValue = this.modelValue ?? null

    if (this.inputEl) {
      this.inputEl.addEventListener('wheel', this.onWheel)
    }
  },

  methods: {
    onInput(parsedValue: string) {
      const floatValue = parseFloat(parsedValue)
      this.rawValue = isNaN(floatValue) ? null : floatValue
      this.$emit('update:modelValue', this.rawValue)
    },

    onChange() {
      if (this.rawValue !== null) {
        if (this.max !== undefined && this.rawValue > this.max) {
          this.rawValue = this.max
        }
        if (this.min !== undefined && this.rawValue < this.min) {
          this.rawValue = this.min
        }
      }
      this.$emit('change', this.rawValue)
    },

    /**
     * Event handler - Mouse wheel was used on the Input element
     * @param event {WheelEvent}
     */
    onWheel(event: WheelEvent) {
      // abort if the input field is not in focus
      if (document.activeElement !== event.target) {
        return
      }

      // increment or decrement?
      const increment = event.deltaY < 0

      // to increment/decrement whe need a valid start value
      let value = this.rawValue ? this.rawValue : 0

      // step width: use prop value or calculate step automatically (by value magnitude)
      const step = this.step ? this.step : this.calculateAutomaticStep(value, increment)

      value = Math.floor(value / step) * step + (increment ? 1 : -1) * step

      if (this.min !== undefined && value < this.min) {
        value = this.min
      }
      if (this.max !== undefined && value > this.max) {
        value = this.max
      }

      this.rawValue = value
      this.$emit('update:modelValue', this.rawValue)

      event.preventDefault()
    },

    /**
     * Calculates the appropriate step width depending on the given value
     *
     * @private
     * @param value {number}
     * @param increment {boolean} true if increment, false if decrement
     * @returns {number}
     */
    calculateAutomaticStep(value: number, increment: boolean) {
      const magnitude = Math.floor(Math.log(Math.abs(value)) / Math.log(10))
      let step = Math.pow(10, magnitude)
      if ((increment && step > value) || (!increment && step >= value)) {
        step = Math.pow(10, magnitude - 1)
      }
      return Math.max(1, step)
    },

    /**
     * Parses and normalizes the localized user input
     */
    parseValue(v: string) {
      this.displayPrefix = ''
      this.displaySuffix = ''

      const isNegative = v.includes('-')

      let numberString = v
        // remove group separators
        .replaceAll(this.groupSeparator, '')
        // replace locale decimal separator by "."
        .replaceAll(this.decimalSeparator, '.')
        // remove everything else
        .replace(/[^[0-9.]/g, '')
        // remove all decimal separators but the last
        .replace(/(\.)(.*\..*)$/g, '$2')

      // Retain trailing separator (display "10," even if the rawValue is "10")
      if (numberString.slice(-1) === '.') {
        this.displaySuffix = this.decimalSeparator
      }

      // Retain trailing zeroes during input
      const matches = numberString.match(/\.(\d*?)(0+)$/)
      if (matches) {
        this.displaySuffix = (matches[1] === '' ? this.decimalSeparator : '') + matches[2]
      }

      // Minus sign handling
      if (isNegative) {
        numberString = '-' + numberString
        // display minus sign even if no number has been entered yet
        if (numberString === '-') {
          this.displayPrefix = '-'
        }
      }

      return numberString
    }
  }
})
</script>

<template>
  <el-input
    ref="input"
    v-bind="$attrs"
    :modelValue="displayValue"
    :disabled="disabled"
    :formatter="(v: string) => v"
    :parser="parseValue"
    @change="onChange"
    @update:modelValue="onInput"
  >
    <template v-if="unit" #suffix>{{ unit }}</template>
  </el-input>
</template>

<style scoped lang="css"></style>
