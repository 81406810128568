import { useGridsideAzureAuthentication } from '@/composables/useGridsideAzureAuthentication'
import { useTestMockAuthentication } from '@/composables/useTestMockAuthentication'
import de from '@/locales/de.json'
import { AppAbility } from '@/permissions/ability'
import icons from '@/icons'
import { TypeComponentsMap } from 'element-plus/es/utils/index'
import translation from 'zod-i18n-map/locales/de/zod.json'
import { makeZodI18nMap } from 'zod-i18n-map'
import { z } from 'zod'
import '@/css/main.css'
import { getEnv, isDevelopment, isProduction, isTest } from '@/util'
import { setupErrorHandlers } from '@/util/error-handling'
import { hideLoadingScreen, setLoadingMessage } from '@/util/loading-screen'
import { abilitiesPlugin } from '@casl/vue'
import PrionectUI, { PrionectAuthOptions, PrionectPluginOptions } from '@prionect/ui'
import { BrowserTracing } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import { createPinia } from 'pinia'
import { Component, createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { init, t } from 'i18next'

export const authConfig: PrionectAuthOptions = {
  provider: useGridsideAzureAuthentication(),
  enableSignup: false,
  methods: ['microsoft']
}

try {
  setLoadingMessage('App wird initialisiert...')
  const app = createApp(App)

  /**
   * App config
   * See: https://vuejs.org/api/application.html#app-config
   */
  app.config.performance = isDevelopment()

  /**
   * Router (vue-router)
   * See: https://router.vuejs.org/introduction.html
   */
  app.use(router)

  /**
   * Monitoring (Sentry)
   * See: https://docs.sentry.io/platforms/javascript/guides/vue/#vue-3
   */
  Sentry.init({
    app,
    dsn: getEnv('VITE_SENTRY_DSN'),
    enabled: isProduction(),
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay({
        blockAllMedia: false,
        maskAllText: false,
        maskAllInputs: false
      })
    ],
    logErrors: true,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1, // record replays for all errors
    tracesSampleRate: 1.0
  })

  /**
   * State Management (Pinia)
   * See: https://pinia.vuejs.org/getting-started.html#Installation
   */
  const pinia = createPinia()
  // uncomment if store debugging is needed
  // pinia.use(PiniaLoggerPlugin)
  app.use(pinia)

  /**
   * Authorization Management (CASL)
   * See: https://casl.js.org/v4/en/package/casl-vue
   */
  app.use(abilitiesPlugin, AppAbility)

  // Bypass Azure authentication when in testing mode (for E2E tests)
  // Needs the backend to run with "testing=1" flag, too.
  if (isTest()) {
    authConfig.provider = useTestMockAuthentication()
    authConfig.methods = ['password']
  }

  /**
   * Component Library (Prionect UI)
   * See: https://prionect.gitlab.io/prionect-ui/
   */
  app.use(PrionectUI, {
    auth: authConfig,
    i18n: {
      locale: 'de',
      messages: { de }
    }
  } as PrionectPluginOptions)

  /**
   * Icon components (xicons)
   * See: https://github.com/07akioni/xicons
   * Icons: https://www.xicons.org/#/
   */
  for (const [key, component] of Object.entries(icons)) {
    app.component(key + 'Icon', component as Component)
  }

  /**
   * Override Element plus Default icons
   * See: https://github.com/element-plus/element-plus/blob/dev/packages/utils/vue/icon.ts
   */
  TypeComponentsMap.warning = icons.Alert
  // TypeComponents.Close = icons.Close
  // ValidateComponentsMap.error = icons.Alert

  /**
   * Schema Validation Translations (zod-i18n)
   * See: https://github.com/aiji42/zod-i18n
   */
  init({
    lng: 'de',
    resources: {
      de: { zod: translation }
    }
  }).then(() => z.setErrorMap(makeZodI18nMap({ ns: 'zod', t })))

  app.mount('#app')
  setupErrorHandlers(app)
} catch (e) {
  // hide loading screen on error
  hideLoadingScreen()
  throw e
}
