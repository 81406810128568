import { EditorView, hoverTooltip, Tooltip, TooltipView } from '@codemirror/view'
import { OperationState } from '@gridside/hsb-api'
import { createApp, ref } from 'vue'
import SystemTagRefWrap from '@/components/system/SystemTagRefWrap.vue'
import { operationStateToString } from '@/util/codemirror/helper'

/**
 * Setup Programmatically mount SystemTag vue component
 */
const tempElement = document.createElement('div')
const systemRef = ref('6a539bc7-75e3-4a4a-a6e7-2d657408b4ce')
const app = createApp(SystemTagRefWrap, { systemRef })
app.mount(tempElement)

/**
 * Returns Codemirror Extension to create tooltip
 * @param regex
 * @param operationStates
 */
export function createOperationStateHover(regex: RegExp = /\w/, operationStates: OperationState[]) {
  return [
    EditorView.theme({
      '.cm-tooltip': {
        borderRadius: '0.25rem'
      }
    }),
    hoverTooltip((view, pos) => {
      const { from, to, text } = view.state.doc.lineAt(pos)

      let start = pos
      let end = pos

      while (start > from && regex.test(text[start - from - 1])) start--
      while (end < to && regex.test(text[end - from])) end++

      const word = text.slice(start - from, end - from)

      // find corresponding operation state
      const opStateIndex = parseInt(word.split('_')[1]) - 1
      const opState = operationStates?.[opStateIndex]

      // dont show tooltip
      if (!opState) {
        return null
      }

      const tooltip: Tooltip = {
        pos: start,
        end,
        above: true,
        create(): TooltipView {
          const tooltipContainer = document.createElement('div')
          tooltipContainer.style.borderRadius = '10px'
          tooltipContainer.style.padding = '5px'

          // render tooltip & wait for vue refresh cycle
          systemRef.value = opState.system
          const conductorStates = operationStateToString(opState)
          requestAnimationFrame(() => {
            tooltipContainer.innerHTML = tempElement.innerHTML
            tooltipContainer.appendChild(document.createTextNode(conductorStates))
          })

          return {
            dom: tooltipContainer,
            offset: { x: -75, y: 5 }
          }
        }
      }
      return tooltip
    })
  ]
}
