<script lang="ts">
import { useAppAbility } from '@/permissions/useAppAbility'
import { defineComponent } from 'vue'
import DetailPanel from '@/layouts/pages/DetailPanel.vue'
import { ConductorType, ConductorTypeEnum, ConductorTypeId } from '@/model'
import NumberField from '@/components/form/NumberField.vue'
import { ElMessage, FormInstance, FormRules } from 'element-plus'
import { useConductorTypeStore } from '@/stores/conductor-type'
import { useProject } from '@/composables/useProject'

export default defineComponent({
  name: 'ConductorTypeForm',
  components: { NumberField, DetailPanel },

  setup() {
    const { can } = useAppAbility()
    const store = useConductorTypeStore()
    const { projectId } = useProject()
    return { can, store, projectId }
  },

  data: () => ({
    fields: {} as Partial<ConductorType>,
    saving: false,
    ConductorTypeEnum
  }),

  computed: {
    create(): boolean {
      return !this.id
    },
    isBundleConductor(): boolean {
      return !!(this.fields?.subconductorCount && this.fields.subconductorCount > 1)
    },
    id(): ConductorTypeId {
      return this.$route.params.id as string
    },
    item(): ConductorType | Partial<ConductorType> {
      const item = this.store.findById(this.id)
      const projectId = this.projectId

      if (item) {
        return item
      }
      return { type: ConductorTypeEnum.SOLID, project: projectId, subconductorCount: 1 }
    },
    readOnly(): boolean {
      if (this.projectId && this.can('update', 'LibraryProject')) {
        return false
      }
      return !this.can('update', 'Library')
    },
    title(): string {
      if (this.readOnly) {
        return 'Leitertyp-Eigenschaften'
      } else if (this.create) {
        return 'Neuer Leitertyp'
      } else {
        return 'Leitertyp bearbeiten'
      }
    },
    /**
     * This view should have a route-name glob of "*-edit" or "*-create".
     * This way we can redirect to parent by slicing off everything after "-"
     */
    routeNameBack() {
      const routeName = String(this.$route.name)
      return routeName.slice(0, routeName.lastIndexOf('-'))
    },
    validationRules(): FormRules {
      return {
        name: {
          required: true,
          type: 'string',
          trigger: 'blur',
          message: 'Bitte geben Sie einen Namen ein.'
        },
        type: { required: true, message: 'Bitte wählen Sie einen Wert aus.' },
        rDC: { required: true, message: 'Bitte Wert eingeben' },
        muR: { required: true, message: 'Bitte Wert eingeben' },
        innerRadius: { required: true, message: 'Bitte Wert eingeben' },
        outerRadius: { required: true, message: 'Bitte Wert eingeben' },
        subconductorSpacing: { required: true, message: 'Wert eingeben' },
        subconductorAngle: { required: true, message: 'Wert eingeben' }
      }
    }
  },

  mounted() {
    this.fields = { ...this.item }
    if (this.create) {
      setTimeout(() => {
        this.$el.querySelector('input[autofocus]').focus()
      }, 100)
    }
  },

  watch: {
    item() {
      this.fields = { ...this.item }
      ;(this.$refs.form as FormInstance).resetFields()
    }
  },

  methods: {
    setSubconductorCount(v: boolean) {
      if (v) {
        this.fields.subconductorCount = 3
      } else {
        // defaults for non-bundle conductors in HSBLib
        this.fields.subconductorCount = 1
        this.fields.subconductorSpacing = 0
        this.fields.subconductorAngle = 45
      }
    },
    async save() {
      const valid = await (this.$refs.form as FormInstance).validate(() => {})
      if (valid) {
        this.saving = true

        try {
          const savedItem = await this.store.save(this.fields as ConductorType)
          if (this.create) {
            ElMessage.success('Leitertyp wurde erfolgreich angelegt.')
            this.$router.push({ name: this.routeNameBack + '-edit', params: { id: savedItem.id } })
          } else {
            ElMessage.success('Daten wurden erfolgreich gespeichert.')
          }
        } finally {
          this.saving = false
        }
      }
    }
  }
})
</script>

<template>
  <DetailPanel :title="title" @close="$router.push({ name: routeNameBack })">
    <el-form
      ref="form"
      label-position="top"
      require-asterisk-position="right"
      :model="fields"
      :rules="validationRules"
    >
      <el-form-item prop="name" label="Name">
        <el-input
          v-model="fields.name"
          class="font-semibold"
          autofocus
          data-field="name"
          :disabled="readOnly"
        />
      </el-form-item>

      <el-form-item label="Typ" prop="type">
        <el-select v-model="fields.type" class="w-full" data-field="type" :disabled="readOnly">
          <el-option :value="ConductorTypeEnum.TUBULAR" :label="$t('conductorType.type.tubular')" />
          <el-option :value="ConductorTypeEnum.SOLID" :label="$t('conductorType.type.solid')" />
        </el-select>
      </el-form-item>

      <div class="grid grid-cols-2 gap-x-6">
        <el-form-item prop="rDC">
          <!-- prettier-ignore -->
          <template #label>
            R<sub>DC</sub> 20°C
          </template>
          <NumberField v-model="fields.rDC" unit="Ω/km" data-field="rDC" :disabled="readOnly" />
        </el-form-item>

        <el-form-item prop="muR">
          <!-- prettier-ignore -->
          <template #label>
            μ<sub>r</sub>
          </template>
          <NumberField v-model="fields.muR" data-field="muR" :disabled="readOnly" />
        </el-form-item>

        <el-form-item
          v-if="fields.type === ConductorTypeEnum.TUBULAR"
          prop="innerRadius"
          label="Innenradius"
        >
          <NumberField
            v-model="fields.innerRadius"
            unit="mm"
            data-field="innerRadius"
            :disabled="readOnly"
          />
        </el-form-item>

        <el-form-item
          :class="{ 'col-span-2': fields.type !== ConductorTypeEnum.TUBULAR }"
          prop="outerRadius"
          label="Außenradius"
          data-field="outerRadius"
        >
          <NumberField v-model="fields.outerRadius" unit="mm" :disabled="readOnly" />
        </el-form-item>
      </div>

      <el-form-item class="mt-4 pb-2" :class="{ 'border-b': isBundleConductor }">
        <el-switch
          :modelValue="isBundleConductor"
          :disabled="readOnly"
          @update:modelValue="setSubconductorCount"
          active-text="Bündelleiter"
          data-field="hasSubconductors"
        />
      </el-form-item>
      <div v-if="isBundleConductor" class="grid grid-cols-2 gap-x-6">
        <div>
          <el-form-item label="Anzahl Leiter" prop="subconductorCount">
            <el-select
              v-model="fields.subconductorCount"
              class="w-full"
              data-field="subconductorCount"
              :disabled="readOnly"
            >
              <el-option v-for="count in [2, 3, 4, 5, 6]" :key="count" :value="count" />
            </el-select>
          </el-form-item>
          <el-form-item prop="subconductorSpacing" label="Abstand">
            <NumberField
              v-model="fields.subconductorSpacing"
              :disabled="readOnly"
              unit="m"
              :min="0"
              :step="0.1"
              data-field="subconductorSpacing"
            />
          </el-form-item>
          <el-form-item prop="subconductorAngle" label="Drehung">
            <NumberField
              v-model="fields.subconductorAngle"
              :disabled="readOnly"
              unit="°"
              :min="0"
              :max="359"
              :step="30"
              data-field="subconductorAngle"
            />
          </el-form-item>
        </div>
        <div class="text-gray-400 p-6 text-center">[Visualisierung]</div>
      </div>
    </el-form>

    <div v-if="!readOnly" class="mt-8">
      <el-button type="primary" @click="save" :loading="saving" data-test="save-btn">
        Speichern
      </el-button>
      <el-button text @click="$router.push({ name: routeNameBack })">Abbrechen</el-button>
    </div>
  </DetailPanel>
</template>

<style scoped lang="css"></style>
