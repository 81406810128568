import { HsbApi } from '@/api'
import { ProjectId, StudyCaseId } from '@/model'
import { CalculationId } from '@/model/calculation'
import { csvToJson } from '@/util'
import { Calculation, CalculationLogMessage, CalculationResultData } from '@gridside/hsb-api'
import { CalculationStatusStatus } from '@gridside/hsb-api/dist/models/CalculationStatus'
import { Feature, FeatureCollection } from 'geojson'
import { defineStore } from 'pinia'

type CalculationStoreState = {
  calculations: Record<CalculationId, Calculation>
  initialized: boolean
  projectId?: ProjectId
  loading: boolean
  logs: Record<CalculationId, CalculationLogMessage[]>
  results: Record<CalculationId, CalculationResultData>
  selectedStudyCase?: StudyCaseId
  starting: boolean
}

const CalculationApi = HsbApi.calculation
const StudyCaseApi = HsbApi.studyCases

export const useCalculationStore = defineStore('calculation', {
  state: (): CalculationStoreState => ({
    calculations: {},
    initialized: false,
    loading: false,
    logs: {},
    projectId: undefined,
    results: {},
    selectedStudyCase: undefined,
    starting: false
  }),

  getters: {
    /**
     * Gets the most current calculation (running or finished)
     */
    current(): Calculation | undefined {
      const calculationsForStudyCase = Object.values(this.calculations).filter(
        (calculation) => calculation.studyCase?.id === this.selectedStudyCase
      )
      return calculationsForStudyCase.pop()
    },

    findById() {
      return (calculationId: CalculationId): Calculation | undefined =>
        this.calculations[calculationId]
    },

    findByStudyCase() {
      return (studyCaseId: StudyCaseId): Calculation[] =>
        Object.values(this.calculations).filter(
          (calculation) => calculation.studyCase?.id === studyCaseId
        )
    },

    result(): CalculationResultData | undefined {
      if (this.current?.status === CalculationStatusStatus.FINISHED) {
        return this.results[this.current.id]
      } else {
        return undefined
      }
    },

    resultEmf(): any {
      return csvToJson(this.result?.eMF || '')
    },

    resultEmfGeoJSON(): FeatureCollection | undefined {
      return this.result?.emfResult as FeatureCollection | undefined
    },

    resultLoadFlow(): any {
      return csvToJson(this.result?.loadFlow || '')
    },

    resultLoadFlowGeoJSON(): FeatureCollection | undefined {
      return this.result?.loadFlowGeojson as FeatureCollection | undefined
    },

    resultRelations(): FeatureCollection | undefined {
      const allFeatures: Feature[] = []
      this.result?.relations?.forEach((item) => {
        allFeatures.push(...(item.relations.features as Feature[]))
      })
      return {
        type: 'FeatureCollection',
        features: allFeatures
      }
    },

    resultVoltage(): number | undefined {
      return this.result?.resultVoltage
    }
  },

  actions: {
    async ensureLoaded(projectId: ProjectId) {
      if (this.projectId !== projectId && !this.loading) {
        await this.load(projectId)
      }
    },

    /**
     * Initialize the store, i.e. add websocket listeners
     */
    init() {
      if (this.initialized) {
        return
      }

      // Register listener functions on websocket
      CalculationApi.onStatusUpdated(async (status) => {
        const calculationId = status.calculation

        // ignore updates for other projects
        if (status.project !== this.projectId || !calculationId) {
          return
        }

        let calculation = this.calculations[calculationId]
        if (!calculation) {
          await this.load(this.projectId, calculationId)
          calculation = this.calculations[calculationId]
        }

        if (calculation) {
          if (status.status === 'FINISHED') {
            await this.loadResult(this.projectId, calculationId)
          } else {
            delete this.results[calculationId]
          }

          this.calculations[calculationId] = {
            ...calculation,
            status: status.status,
            progress: status.progress
          }
        }
      })

      CalculationApi.onLogUpdated((entry) => {
        // ignore updates for other projects
        if (entry.project !== this.projectId) {
          return
        }

        if (!this.logs[entry.calculation]) {
          this.logs[entry.calculation] = []
        }
        this.logs[entry.calculation].push(entry)
      })

      CalculationApi.onCalculationDeleted((id) => {
        delete this.logs[id]
        delete this.calculations[id]
        delete this.results[id]
      })

      HsbApi.result.onInvalidated((data) => {
        if (data.project === this.projectId) {
          const previousStudyCase = this.selectedStudyCase
          const previousProjectId = this.projectId
          this.reset()
          this.projectId = previousProjectId
          this.selectedStudyCase = previousStudyCase
        }
      })

      this.initialized = true
    },

    initLog(calculationId: CalculationId) {
      const debugItem: CalculationLogMessage = {
        calculation: calculationId,
        data: `HSB GUI calculation ID: ${calculationId}`,
        project: this.projectId!,
        level: 'DEBUG'
      }
      this.logs[calculationId] = [debugItem]
    },

    /**
     * Loads all or a single calculation for a project
     * @param projectId
     * @param calculationId
     */
    async load(projectId: ProjectId, calculationId?: CalculationId) {
      this.loading = true
      this.projectId = projectId

      if (!calculationId) {
        this.calculations = {}
        this.logs = {}
        this.results = {}
      }

      const calculations = calculationId
        ? [await CalculationApi.getCalculation(projectId, calculationId)]
        : (await CalculationApi.getCalculations(projectId)).results

      calculations.forEach((calculation) => {
        this.calculations[calculation.id] = calculation
        this.loadLog(projectId, calculation.id)
        if (calculation.status === 'FINISHED') {
          this.loadResult(projectId, calculation.id)
        }
      })
      this.loading = false
    },

    async loadLog(projectId: ProjectId, calculationId: CalculationId) {
      const log = await CalculationApi.getCalculationLog(projectId, calculationId, true)
      this.initLog(calculationId)
      if (log) {
        this.logs[calculationId].push(...log)
      }
    },

    async loadResult(projectId: ProjectId, calculationId: CalculationId) {
      const result = await CalculationApi.getCalculationResult(projectId, calculationId)
      if (result) {
        this.results[calculationId] = result.result
      }
    },

    reset() {
      const wasInitialized = this.initialized
      this.$reset()
      this.initialized = wasInitialized
    },

    async start(projectId: ProjectId, studyCaseId: StudyCaseId) {
      this.starting = true
      let calculation: Calculation
      try {
        calculation = await StudyCaseApi.startStudyCaseCalculation(projectId, studyCaseId)
        const calculationId = calculation.id
        this.calculations[calculationId] = calculation
        this.initLog(calculationId)
      } finally {
        this.starting = false
      }
      return calculation
    }
  }
})
