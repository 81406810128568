import { LayerTreeConfig, LayerTreeItemType, TreeNode } from './types'

export function configToTreeData(treeConfig: LayerTreeConfig, idPrefix?: string): TreeNode[] {
  return treeConfig.map((configItem) => {
    let children: TreeNode[] | undefined = undefined
    if (configItem.type === LayerTreeItemType.LAYER && configItem.children) {
      children = configToTreeData(configItem.children, configItem.id)
    }

    return {
      id: idPrefix ? [idPrefix, configItem.id].join('.') : configItem.id,
      label: configItem.label,
      children,
      config: configItem
    }
  })
}
