import { isDevelopment } from '@/util'
import { createRouter, createWebHistory } from 'vue-router'
import { createAuthRoutes, useAuthentication } from '@prionect/ui'

import libraryRoutes from './library-routes'
import projectRoutes from './project/project-routes'
import userRoutes from './user-routes'

export const routes = [
  ...createAuthRoutes(() => import('@/layouts/LoginLayout.vue')),
  {
    path: '/',
    name: 'home',
    redirect: { name: 'projects' }
  },
  // {
  //   path: '/debug',
  //   name: 'debug',
  //   component: () => import('@/views/debug.vue')
  // },
  ...libraryRoutes,
  ...projectRoutes,
  ...userRoutes
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from) => {
  const { isAuthenticated } = useAuthentication()

  if (isDevelopment()) {
    console.debug(
      `%c[router.beforeEach] %c ${to.name as string} %c ${to.fullPath} `,
      'color: #aaa',
      'color: #543; background: #fec;',
      'color: #999',
      'params:',
      to.params
    )
  }

  // redirect login
  if (!isAuthenticated.value && to.name !== 'auth-login') {
    return { name: 'auth-login', query: { path: to.path } }
  }

  // redirect home
  if (isAuthenticated.value && to.name === 'auth-login') {
    return { name: 'home' }
  }
})

export default router
