import { ProjectId } from '@/model'

import { reactive, ref, Ref } from 'vue'
import { LayerStatus } from './useMapLayers'

type ControlState = {
  visible: boolean
  height?: number
  width?: number
}

export type MapState = {
  basemap: {
    type: 'osm' | 'grayscale' | 'satellite'
    opacity: number
  }
  center: number[]
  controls: Record<string, ControlState>
  layers: Record<string, Partial<LayerStatus>>
  project: ProjectId
  zoom: number
}

type MapStateOptions = {
  zoom: Ref
  center: Ref
  projectId: ProjectId
}

export function useMapState(options: MapStateOptions) {
  const { zoom, center, projectId } = options

  const mapState = ref<MapState>(
    reactive({
      basemap: {
        type: 'grayscale',
        opacity: 0.5
      },
      controls: {},
      zoom: zoom,
      center: center,
      project: projectId,
      layers: {}
    })
  )

  return { mapState }
}
