import { computed, Ref } from 'vue'

export default function useSearch<T>(
  items: Ref<T[]>,
  searchString: Ref<string>,
  stringify?: (item: T) => string
) {
  // Pre-index items using computed for reactivity
  const indexedItems = computed(() => {
    return items.value.map((item) => {
      const indexedString = stringify ? stringify(item) : JSON.stringify(item)
      return {
        original: item,
        indexedString: indexedString.toLowerCase()
      }
    })
  })

  const searchResult = computed<T[]>(() => {
    return indexedItems.value
      .filter(({ indexedString }) => indexedString.includes(searchString.value.toLowerCase()))
      .map(({ original }) => original)
  })

  return { searchResult }
}
