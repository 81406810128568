import { overheadLineStyle } from '@/components/map/style/source/overheadLine'
import { spanStyle } from '@/components/map/style/source/span'
import { towerStyle } from '@/components/map/style/source/tower'
import { State } from '@/components/map/style/util'
import { StyleFunction } from 'ol/style/Style'
import { featureIsHidden, featureOfType, FeatureTypeEnum } from '@/components/map/LayerItemConfig'

export const sourceStyle: StyleFunction = createStyleFunction('default')
export const sourceHoverStyle: StyleFunction = createStyleFunction('hover')
export const sourceEditStyle: StyleFunction = createStyleFunction('select')

export function createStyleFunction(state: State = 'default'): StyleFunction {
  return (feat, resolution) => {
    if (featureIsHidden(feat)) {
      return undefined
    }

    if (featureOfType(feat, FeatureTypeEnum.overheadLine)) {
      return overheadLineStyle(feat, resolution, state)
    }
    if (featureOfType(feat, FeatureTypeEnum.span)) {
      return spanStyle(feat, resolution, state)
    }
    if (featureOfType(feat, FeatureTypeEnum.tower)) {
      return towerStyle(feat, resolution, state)
    }
    return undefined
  }
}
