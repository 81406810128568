import { UseMapItems } from '@/components/map/composables/useMap'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import { inject, ref, watch } from 'vue'

export function useMapControlState(id: string) {
  const { mapState } = inject(UseMapInjectKeys.useMap) as UseMapItems

  const setControlValue = (key: string, value: any) => {
    mapState.value.controls[id] = { ...mapState.value.controls[id], [key]: value }
  }

  const expanded = ref(mapState.value.controls[id]?.visible || false)
  watch(expanded, () => setControlValue('visible', expanded.value))

  const height = ref(mapState.value.controls[id]?.height)
  watch(height, () => setControlValue('height', height.value))

  const width = ref(mapState.value.controls[id]?.width)
  watch(width, () => setControlValue('width', width.value))

  return { expanded, height, width }
}
