import { HSBLibConductorType } from '@/config/schemas/hsb-lib/ConductorType'
import { v4 } from 'uuid'
import { Container } from '@/config/schemas/hsb-lib/util'
import {
  ConductorAllocation,
  ConductorAllocationMappingItem,
  ConductorType,
  ConductorTypeType
} from '@gridside/hsb-api'

export const ConductorTypeEnum = ConductorTypeType

export type { ConductorType, ConductorAllocation }

export type ConductorAllocationId = ConductorAllocation['id']
export type ConductorAllocationMapping = ConductorAllocationMappingItem

export type ConductorTypeId = ConductorType['id']
export type ConductorTypeCreate = Omit<ConductorType, 'id'>

export function fromHSBConductorTypes(
  container: Container<HSBLibConductorType>
): Container<ConductorType> {
  const conductorTypes: Container<ConductorType> = {}
  for (const objectId in container) {
    const el = container[objectId]
    if (conductorTypes[objectId]) {
      throw Error(`ConductorType ${objectId} mehrmals definiert!`)
    }

    const name = `${el.subconductor_number > 1 ? el.subconductor_number + 'x ' : ''}${
      el.type === 0 ? 'Vollleiter' : 'Hohlleiter'
    } ${el.type !== 0 ? el.inner_radius + ' / ' : ''}${el.outer_radius} mm`

    conductorTypes[objectId] = {
      id: v4(),
      type: el.type === 0 ? ConductorTypeEnum.SOLID : ConductorTypeEnum.TUBULAR,
      muR: el.mu_r,
      rDC: el.R_dc20,
      name,
      innerRadius: el.inner_radius,
      outerRadius: el.outer_radius,
      subconductorAngle: el.subconductor_angle,
      subconductorSpacing: el.subconductor_spacing,
      subconductorCount: el.subconductor_number
    }
  }
  return conductorTypes
}
