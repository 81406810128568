<script lang="ts">
import { mediaGroup } from '@/config/fields'
import { MediaGroup, MediaGroupId, MediaGroupMediaInfo, MediaGroupPipe } from '@/model'
import { defineComponent, PropType } from 'vue'
import { MultiLineString } from 'ol/geom'
import MediaGroupPipeTypeSelectField from '@/components/media-group/MediaGroupPipeTypeSelectField.vue'
import { MediaGroupBaseType } from '@gridside/hsb-api'
import { useMediaGroupStore } from '@/stores/media-group'
import { getLength } from 'ol/sphere'

export default defineComponent({
  name: 'MediaGroupForm',
  components: { MediaGroupPipeTypeSelectField },
  emits: ['submit'],

  props: {
    items: {
      type: Array as PropType<MediaGroup[]>,
      default: () => []
    },
    dense: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    fieldConfig: mediaGroup
  }),

  computed: {
    create(): boolean {
      return !this.id
    },

    id(): MediaGroupId {
      return this.$route.params.id as string
    },

    typeItems() {
      return ['pipe', 'wire'].map((type) => ({
        value: type,
        label: this.$t(`mediaGroup.type.${type}`)
      }))
    },
    mediaGroupsWithInfo() {
      return this.items.map((item) => ({
        ...item,
        info: this.mediaInfo(item)
      }))
    }
  },

  setup() {
    return { mediaGroupStore: useMediaGroupStore() }
  },

  methods: {
    isPipe(mediaGroup: MediaGroup) {
      return mediaGroup.type == MediaGroupBaseType.PIPE
    },

    async onSubmit(items: MediaGroup[]) {
      const submitItems: MediaGroup[] = []
      for (const item of items) {
        const original = this.items.find((mediaGroup) => mediaGroup.id == item.id)
        if (items.length > 1) {
          // make sure we do not submit "__MIXED_VALUES__" for "mediaGeometry"
          item.mediaGeometry = original?.mediaGeometry ?? {
            type: 'MultiLineString',
            coordinates: []
          }
        }
        submitItems.push(item)
      }
      this.$emit('submit', submitItems)
    },

    asMediaGroupPipe(item: any): MediaGroupPipe {
      return item
    },

    /**
     * Extract LineString info about MediaGroup geometry
     * @param mediaGroup
     */
    mediaInfo(mediaGroup: MediaGroup): MediaGroupMediaInfo {
      let pointCount = 0
      let length = 0
      let mediaCount = 0
      const multiLineString = new MultiLineString(mediaGroup.mediaGeometry.coordinates)
      multiLineString.getLineStrings().forEach((lineString) => {
        mediaCount++
        length += getLength(lineString)
        pointCount += lineString.getCoordinates().length
      })
      return { mediaCount, pointCount, length }
    }
  }
})
</script>

<template>
  <p-multi-form v-bind="$attrs" :items="items" keep-values @submit="onSubmit">
    <template #default="{ values }">
      <!-- name -->
      <p-field v-bind="fieldConfig.name" />

      <!-- type -->
      <p-field
        class="hidden"
        v-bind="fieldConfig.type"
        :items="typeItems"
        disabled
        title="Der Medientyp entspricht der Einstellung im Projekt und kann nicht geändert werden."
      />

      <div class="grid grid-cols-2 gap-x-6 gap-y-2">
        <!-- height -->
        <p-field
          :class="{ 'col-span-2': !isPipe(values) }"
          :dense="dense"
          v-bind="fieldConfig.height"
        />
        <!-- pipeType -->
        <template v-if="isPipe(values)">
          <p-field :dense="dense" v-bind="fieldConfig.rhoE" />
          <MediaGroupPipeTypeSelectField
            class="col-span-2"
            v-bind="fieldConfig.pipeType"
            :model-value="asMediaGroupPipe(values).pipeType"
          />
        </template>
      </div>

      <p-form-section title="Enthaltene Medien" class="mb-8">
        <div
          class="text-sm grid gap-y-2 gap-x-4 text-gray-700"
          :class="mediaGroupsWithInfo.length === 1 ? 'grid-cols-3' : 'grid-cols-[auto_1fr_1fr_1fr]'"
        >
          <span v-if="mediaGroupsWithInfo.length > 1" class="font-semibold text-gray-500">
            Mediengruppe
          </span>
          <span class="text-center font-semibold text-gray-500">Medien</span>
          <span class="text-center font-semibold text-gray-500">Punkte</span>
          <span class="text-center font-semibold text-gray-500">Länge</span>
          <template v-for="(mediaSegments, index) in mediaGroupsWithInfo" :key="index">
            <span
              v-if="mediaGroupsWithInfo.length > 1"
              class="text-ellipsis overflow-hidden whitespace-nowrap"
            >
              {{ mediaSegments.name }}
            </span>
            <span class="text-center whitespace-nowrap">
              {{ $n(mediaSegments.info.mediaCount) }}
            </span>
            <span class="text-center whitespace-nowrap">
              {{ $n(mediaSegments.info.pointCount) }}
            </span>
            <span class="text-center whitespace-nowrap">
              {{ $n(mediaSegments.info.length / 1000, { maximumFractionDigits: 1 }) }} km
            </span>
          </template>
        </div>
      </p-form-section>
    </template>
  </p-multi-form>
</template>
