<script lang="ts">
import { defineComponent } from 'vue'
import { ElMessage, FormInstance, FormRules } from 'element-plus'
import DetailPanel from '@/layouts/pages/DetailPanel.vue'
import { useUserStore } from '@/stores/user'
import { User, UserRole } from '@/model'

export default defineComponent({
  name: 'UserForm',
  components: { DetailPanel },

  setup() {
    const userStore = useUserStore()
    userStore.ensureLoaded()
    return { userStore }
  },

  data: () => ({
    fields: {} as Partial<User>
  }),

  computed: {
    create(): boolean {
      return !!this.$route.meta.create
    },
    saving(): boolean {
      return this.userStore.saving
    },
    user(): Partial<User> {
      return this.userId ? this.userStore.findById(this.userId) : { role: UserRole.USER }
    },
    userId(): string | undefined {
      return this.$route.params?.userId?.toString()
    },
    validationRules(): FormRules {
      return {
        name: {
          required: true,
          type: 'string',
          message: 'Bitte geben Sie einen Namen ein.'
        },
        email: {
          required: true,
          type: 'email',
          trigger: 'blur',
          message: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.'
        },
        role: {
          required: true,
          message: 'Bitte wählen Sie eine Rolle aus.'
        }
      }
    }
  },

  mounted() {
    this.fields = { ...this.user }
  },

  watch: {
    user() {
      this.fields = { ...this.user }
      ;(this.$refs.form as FormInstance).resetFields()
    }
  },

  methods: {
    async save() {
      const valid = await (this.$refs.form as FormInstance).validate(() => {})
      if (!valid) {
        ElMessage.error('Die eingegebenen Daten sind nicht gültig, bitte prüfen Sie Ihre Eingaben.')
        return
      }

      try {
        await this.userStore.save(this.fields as User)
        ElMessage.success('Die Nutzerdaten wurden erfolgreich gespeichert.')
        await this.$router.push({ name: 'users' })
      } catch (e: any) {
        if (e.response?.status === 409 && e.response?.data?.message.includes('already in use')) {
          ElMessage.error('Ein Nutzer mit dieser E-Mail-Adresse existiert bereits.')
        } else {
          throw e
        }
      }
    }
  }
})
</script>

<template>
  <DetailPanel
    :title="create ? 'Nutzer hinzufügen' : 'Nutzer bearbeiten'"
    @close="$router.push({ name: 'users' })"
  >
    <el-form
      ref="form"
      label-position="top"
      :rules="validationRules"
      :model="fields"
      require-asterisk-position="right"
    >
      <!-- INVITE USER -->
      <div v-if="create">
        <div class="text-sm mb-4">
          Geben Sie den Namen und die E-Mail-Adresse des Nutzers sowie seine vorgesehene Rolle an.
          Er kann sich danach mit seinem Microsoft-Account an der Anwendung anmelden.
        </div>
        <el-alert type="info" show-icon :closable="false">
          Es wird keine automatische Einladungs-Mail versendet, Sie müssen den Nutzer persönlich
          informieren.
        </el-alert>
      </div>
      <el-alert v-else ctype="info" show-icon :closable="false">
        Name und E-Mail dieses Nutzers werden durch seinen Microsoft-Account bestimmt. Sie können
        jedoch die Rolle anpassen.
      </el-alert>

      <el-form-item label="Name" prop="name" class="mt-6">
        <el-input v-model="fields.name" class="font-semibold" autofocus></el-input>
      </el-form-item>

      <el-form-item label="E-Mail" prop="email">
        <el-input v-model="fields.email"></el-input>
      </el-form-item>

      <el-form-item label="Rolle" prop="role">
        <el-select v-model="fields.role" class="w-full">
          <el-option
            v-for="role in ['admin', 'user', 'guest']"
            :key="role"
            :value="role"
            :label="$t('user.role.' + role)"
          />
        </el-select>
      </el-form-item>

      <el-descriptions v-if="!create" :column="1" border class="text-xs mt-6" size="small">
        <el-descriptions-item label="zuletzt angemeldet:">
          {{ fields.lastLogin ? $d(fields.lastLogin, 'datetime-long') : 'noch nie angemeldet' }}
        </el-descriptions-item>
        <el-descriptions-item label="Login-Provider:">Azure</el-descriptions-item>
        <el-descriptions-item label="Provider-ID:">
          {{ fields.providerId || fields.id }}
        </el-descriptions-item>
      </el-descriptions>

      <div class="mb-4 mt-8">
        <el-button type="primary" :loading="saving" @click="save">Speichern</el-button>
        <el-button text @click="$router.push({ name: 'users' })">Abbrechen</el-button>
      </div>
    </el-form>
  </DetailPanel>
</template>
