<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import OverheadLineImportHSBDialog from '@/components/overhead-line/OverheadLineImportHSBDialog.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'OverheadLineImportHSB',
  components: { OverheadLineImportHSBDialog },
  setup() {
    const importDialog = ref(true)
    const router = useRouter()

    // wait for close animation
    watch(importDialog, (value) => {
      if (!value) {
        setTimeout(() => {
          router.push({ name: 'overhead-lines-index' })
        }, 200)
      }
    })
    return {
      importDialog
    }
  }
})
</script>

<template>
  <OverheadLineImportHSBDialog v-model="importDialog" />
</template>

<style scoped lang="css"></style>
