<script lang="ts">
import { defineComponent, PropType } from 'vue'
import icons from '@/icons'

export type ActionItem = {
  icon: string
  action: () => any
  tooltip?: string
}

export default defineComponent({
  name: 'ButtonControlBase',
  props: {
    actions: {
      type: Array as PropType<ActionItem[]>,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },

  setup() {
    return { icons }
  }
})
</script>

<template>
  <div class="button-control background space-x-0">
    <el-icon
      v-for="(action, index) in actions"
      as="button"
      class="icon"
      :class="{ 'icon--active': active }"
      size="20"
      :key="index"
      :title="action.tooltip"
      @click="action.action"
    >
      <component
        :is="
          //@ts-ignore
          icons[action.icon]
        "
      />
    </el-icon>
  </div>
</template>

<style scoped lang="css">
.background {
  @apply flex items-center justify-center backdrop-blur-lg shadow-lg cursor-pointer;
}

.icon {
  @apply h-9 min-w-[36px] text-gray-500 hover:text-gray-700 bg-gray-50 bg-opacity-80 transition-colors hover:bg-opacity-100;
}

.icon--active {
  @apply text-primary-600 hover:text-primary-600 bg-opacity-100 cursor-default border-2 border-primary-500;
}

.icon:first-child {
  @apply rounded-l-md;
}

.icon:last-child {
  @apply rounded-r-md;
}
</style>
