<script lang="ts">
import basemapImage from '@/assets/map/basemap-thumbnail.jpg'
import osmImage from '@/assets/map/osm-thumbnail.jpg'
import satelliteImage from '@/assets/map/satellite-thumbnail.jpg'
import { UseMapItems } from '@/components/map/composables/useMap'
import { useMapControlState } from '@/components/map/composables/useMapControlState'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import { defineComponent, inject } from 'vue'

const mapItems = [
  { value: 'grayscale', label: 'Basemap.de (Graustufen)', image: basemapImage },
  { value: 'osm', label: 'OpenStreetMap (farbig)', image: osmImage },
  { value: 'satellite', label: 'MapTiler (Satellit)', image: satelliteImage }
]

export default defineComponent({
  name: 'MapSelector',
  props: {
    size: {
      type: Number,
      default: 50
    }
  },

  setup() {
    const { mapState } = inject(UseMapInjectKeys.useMap) as UseMapItems
    return { mapItems, mapState }
  }
})
</script>

<template>
  <div class="flex space-x-2 mr-2">
    <img
      v-for="item in mapItems"
      :key="item.value"
      class="opacity-90 flex-1 cursor-pointer hover:opacity-100 transition-opacity object-cover border-2 border-white rounded-sm"
      :style="{ height: `${size}px` }"
      :class="{
        '!border-primary-500 opacity-100 ': item.value === mapState.basemap.type
      }"
      :src="item.image"
      :title="item.label"
      @click.stop="mapState.basemap.type = item.value as any"
    />
  </div>
</template>

<style scoped lang="css"></style>
