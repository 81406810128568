import { WirePosition, WirePositionLabeled } from '@/model'
import { AxiosError, AxiosHeaders } from 'axios'
import { ResponseError } from '@gridside/hsb-api'

export const runningVitest = typeof window === 'undefined'

export function getEnv<T extends string | undefined>(name: string, defaultValue?: string): T {
  if (typeof window !== 'undefined') {
    // We are in a browser -> run time
    if (name === 'MODE') {
      return (
        window.ENV_VARS?.VITE_MODE ||
        import.meta.env.VITE_MODE ||
        import.meta.env.MODE ||
        defaultValue
      )
    } else {
      return window.ENV_VARS?.[name] || import.meta.env[name] || defaultValue
    }
  } else {
    // Build time or during tests
    return import.meta.env[name] || defaultValue
  }
}

export function int(val: string | number) {
  if (typeof val === 'string') {
    return parseInt(val)
  }
  return Math.trunc(val)
}
export function keys<T extends Record<keyof T, any>>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[]
}
export function copy<T extends Exclude<object | any[], undefined>>(obj: T): T {
  return JSON.parse(JSON.stringify(obj))
}
export const isProduction = () => {
  return getEnv('MODE') === 'production'
}
export const isDevelopment = () => {
  return !isProduction()
}
export function filterUndefined<T>(item: T | undefined): item is Exclude<T, undefined> {
  return item !== undefined
}
export function filterNull<T>(item: T | null): item is Exclude<T, null> {
  return item !== null
}
export const isTest = () => {
  return ['testing', 'test'].includes(getEnv('MODE'))
}

/**
 * Used to add a date to name of imported entity
 * @param dateOrUnix
 */
export function importDateTimeString(dateOrUnix?: Date | number) {
  return new Date(dateOrUnix ?? new Date()).toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  })
}
/**
 * Returns a function for creating a label:
 * - converting tower conductor positions (0,1,3,4 into letters A,B,C,D)
 * - using a prefix (0,1,3,4 into <prefix>1,<prefix>2,<prefix>3,<prefix>4)
 */
function createLabelPrefixFn(prefix?: string | number) {
  const indexOfA = 'A'.charCodeAt(0)

  return (i: number): string => {
    if (prefix) {
      return `${prefix}${i + 1}`
    }
    return i < 26 ? String.fromCharCode(indexOfA + i) : `${i + 1}`
  }
}
/**
 * Helper functions for labeling conductors and earthwires
 */
export const towerConductorsLabelFn = createLabelPrefixFn()
export const towerEarthwireLabelFn = createLabelPrefixFn('E') // Erdseil
export const conductorLabelFn = createLabelPrefixFn('L') // Leiter

/**
 * Simply label wire data
 *
 * @param type
 * @param positions
 * @return WirePositionLabeledBase
 */
export function labelWirePositions(
  type: 'conductor' | 'earthwire',
  positions?: WirePosition[]
): WirePositionLabeled[] {
  return (
    positions?.map((el, i) => ({
      ...el,
      type,
      label: type == 'conductor' ? towerConductorsLabelFn(i) : towerEarthwireLabelFn(i)
    })) || []
  )
}

/**
 * Converts a CSV data string into a JSON structure
 */
export function csvToJson(csv: string): any {
  const lines = csv.split('\n')
  if (lines.length === 0) {
    return {}
  }
  const headers = lines[0].split(';')

  const json = []
  for (let l = 1; l < lines.length; l++) {
    if (lines[l].trim() === '') {
      continue
    }
    const item: Record<string, any> = {}
    const lineTokens = lines[l].split(';')

    headers.forEach((key, index) => {
      let token: string | number = lineTokens[index]
      // try to convert into a number
      const toNumber = token && parseFloat(token.replace(',', '.'))
      if (typeof toNumber === 'number') {
        token = toNumber
      }
      item[key.trim() !== '' ? key : index] = token
    })
    json.push(item)
  }

  return json
}

export function unifyProjectionCode(projectionCode: string) {
  const pattern = /urn:ogc:def:crs:(.*?)::(.*?)$/i
  const matches = pattern.exec(projectionCode)

  if (matches && matches.length >= 3) {
    const authority = matches[1]
    const code = matches[2]
    return `${authority}:${code}`
  }

  const shortPattern = /(.*?:.*?)$/i
  const shortMatches = shortPattern.exec(projectionCode)
  if (shortMatches && shortMatches.length >= 2) {
    return shortMatches[1]
  }

  return undefined
}

export async function toAxiosErrorFromErrorResponse(e: ResponseError): Promise<AxiosError> {
  const headers = new AxiosHeaders()
  e.response.headers.forEach((value, key) => {
    headers.set(key, value)
  })
  return new AxiosError(e.message, e.response.status.toString(), undefined, null, {
    headers,
    data: await e.response.json(),
    status: e.response.status,
    statusText: e.response.statusText,
    config: {
      headers
    }
  })
}
